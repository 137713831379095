<h1>Jira-Export</h1>
<h2>Jira-Instanz konfigurieren ({{this.jiraUrl}})</h2>
<div class="jira-export-site">
  <div class="jira-export__form">
    <mat-card class="mat-elevation-z0">
      <h3 *ngIf="areAllAlreadyExported$|async">Alle nicht-funktionale Anforderungen zu dieser Anwendung sind bereits exportiert!</h3>
      <mat-form-field class="inputFieldLong" appearance="outline">
        <mat-label>Benutzername</mat-label>
        <input matInput #Benutzername id ="username" placeholder="Benutzername" [(ngModel)]="username" required
          [disabled]="areAllAlreadyExported$|async">
        <mat-error>
          {{this.currentError.username}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="inputFieldLong" appearance="outline">
        <mat-label>Passwort</mat-label>
        <input matInput #Passwort id = "password" placeholder="Passwort" type="password" [(ngModel)]="password" required
          [disabled]="areAllAlreadyExported$|async">
        <mat-error>
          {{this.currentError.password}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="inputFieldLong" appearance="outline">
        <mat-label>Projektschlüssel</mat-label>
        <input matInput #Projektschluessel id ="projectKey" placeholder="Projektschlüssel" [(ngModel)]="projectKey" required
          [disabled]="areAllAlreadyExported$|async">
        <mat-error>
          {{this.currentError.projectKey}}
        </mat-error>
      </mat-form-field>
    </mat-card>
  </div>
  <div class="buttonRight">
    <button mat-flat-button
            color="primary"
            class="preview-buttonmat-mdc-unelevated-button-primary"
            [disabled]="!username || !password || !projectKey || !jiraUrl || (isExporting$|async)"
            (click)="exportIssues()">
      <!--Primär Jira-Export-->
      <mat-icon>import_export</mat-icon>
      Exportieren
    </button>
    <button class="mat-mdc-unelevated-button-tertiary"
            type="button"
            mat-flat-button
            routerLink="/applications/{{applicationId}}/ten"
            [disabled]="isExporting$|async">
      <mat-icon>arrow_left</mat-icon>
      Zurück
    </button>
  </div>
</div>
<ng-container *ngIf="isExporting$|async">
  <div  class="jira-export__notification">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <div>
    <span class="jira-export__notification">
      Bitte haben Sie einen Moment Geduld. Ihre nicht-funktionalen Anforderungen werden nach Jira exportiert...
    </span>
  </div>
</ng-container>
