const keycloakProdConfig = {
  url: '/auth',
  realm: 'qumap',
  clientId: 'qumap-frontend'
};
export const environment = {
  production: true,
  buildVersion: '2.6.4',
  releaseVersion: '1', // tag version here
  keycloak: keycloakProdConfig
};
