<ng-container *ngIf="overlay;else progressSpinner">
  <div class="overlay">
    <ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
  </div>
</ng-container>

<ng-template #progressSpinner>
  <div class="center">
    <mat-progress-spinner
      [diameter]="diameter"
      [mode]="mode"
      [color]="color"
      [strokeWidth]="strokeWidth"
      [value]="value">
    </mat-progress-spinner>
    <span>{{loadingText}}</span>
  </div>
</ng-template>
