import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TestMethodsAdapterService } from '../adapter/test-methods-adapter.service';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app.reducer';
import {
  LoadCancel,
  LoadTestDesignTechniques,
  LoadTestDesignTechniquesFailure,
  LoadTestDesignTechniquesSuccess,
  LoadTestTypes,
  LoadTestTypesFailure,
  LoadTestTypesSuccess,
  TestMethodsActions
} from './test-methods.actions';
import { Sorter } from '../../shared/utils/sorter';
import { areDesignTechniquesLoaded, areTypesLoaded } from './test-methods.reducer';

@Injectable()
export class TestMethodsEffects {

  constructor(
    private readonly store$: Store<AppState>,
    private readonly actions$: Actions,
    private readonly adapter: TestMethodsAdapterService
  ) {
  }

   loadTestTypes$: Observable<LoadTestTypesSuccess | LoadTestTypesFailure | LoadCancel> = createEffect(() => this.actions$.pipe(
    ofType<LoadTestTypes>(TestMethodsActions.LoadTestTypes),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(areTypesLoaded))
    )),
    switchMap(([action, alreadyLoaded]) => {
      if (alreadyLoaded) {
        return of(new LoadCancel());
      }
      if (action.language !== null && action.language.toString() !== null) {
        return this.adapter.getTestTypes(action.language.toString()).pipe(
          map(data => {
            data.sort((a, b) => Sorter.compare(a.labelKey, b.labelKey, true));
            return new LoadTestTypesSuccess(data);
          }),
          catchError(error => of(new LoadTestTypesFailure({error})))
        );
      }
    })
  ));

   loadTestDesignTechniques$:
    Observable<LoadTestDesignTechniquesSuccess | LoadTestDesignTechniquesFailure | LoadCancel> = createEffect(() => this.actions$.pipe(
    ofType<LoadTestDesignTechniques>(TestMethodsActions.LoadTestDesignTechniques),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(areDesignTechniquesLoaded))
    )),
    switchMap(([action, alreadyLoaded]) => {
      if (alreadyLoaded) {
        return of(new LoadCancel());
      }
      if (action.language !== null && action.language.toString() !== null) {
        return this.adapter.getTestDesignTechniques(action.language.toString()).pipe(
          map(data => {
            data.sort((a, b) => Sorter.compare(a.labelKey, b.labelKey, true));
            return new LoadTestDesignTechniquesSuccess(data);
          }),
          catchError(error => of(new LoadTestDesignTechniquesFailure({error})))
        );
      }
    })
  ));
}
