import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Treenode } from '../shared/sidenav/treenode';
import { InformationDialogComponent } from '../shared/information-dialog/information-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class QumapHelpService {

  constructor(private http: HttpClient) {
  }

  getQumapHelp(id: number | string): Observable<any> {
    return this.http.get<any>(`assets/help/content/${id}.html`, {responseType: 'text' as any});
  }

  getQumapHelpAndShowInDialog(id: number | string, dialog: MatDialog): void {
    this.getQumapHelp(id).subscribe((helpContent) => {
      dialog.open(InformationDialogComponent, {
        panelClass: 'information-dialog-container',
        data: {
          informationType: 'help',
          header: 'Hilfe',
          message: helpContent,
          closingDialog: 'Schließen'
        },
        autoFocus: "dialog"
      });
    });
  }

  getHelpCategories(language: string): Observable<Treenode[]> {
    return this.http.get<any>('assets/help/help.categories.json').pipe(
      map((source) => source as Treenode[]),
      catchError(() => throwError('Not able to get Help Categories'))
    );
  }

}
