import { Component, Input } from '@angular/core';
import { Bitv } from '../../types/bitv';

@Component({
  selector: 'balm-evaluation-progression',
  templateUrl: './balm-evaluation-progression.component.html',
  styleUrls: ['./balm-evaluation-progression.component.scss']
})
export class BalmEvaluationProgressionComponent {

  @Input()
  bitv: Bitv;
  @Input()
  evaluationstepsCount: number;
  @Input()
  compliant;
  @Input()
  conformCount: number;
  @Input()
  notConformCount: number;
  @Input()
  openCount: any;
  @Input()
  notApplicableCount: number;

  getEvaluationResult(): string {
    return this.compliant ? 'Konform' : 'Nicht konform';
  }

  getResultIcon(): string {
    return this.compliant ? 'check' : 'close';
  }

  calculateBarLength(stepcount: number): string {
    return this.evaluationstepsCount === 0 || this.evaluationstepsCount === undefined ?
      '' :
      `max-width: ${stepcount / this.evaluationstepsCount * 100}%`;
  }

  calculateProgressionPercentage(stepcount: number): number{
    return this.evaluationstepsCount === 0 || this.evaluationstepsCount === undefined ? 0 : Math.round(stepcount / this.evaluationstepsCount * 100);
  }
}
