<div *ngIf="isLoading" class = "loading">
  <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="!isLoading && application">
  <ng-container *ngIf="factors$ | async as factors">
    <form #itNfrForm="ngForm">
      <div *ngIf="catalogState$ | async as catalogState">

        <div *ngIf="catalogState.error === null; else noContent">
          <div class="page-header">
            <div class="page-header__heading">
              <button mat-flat-button
                      class ="mat-mdc-unelevated-button-primary"
                      color="primary"
                      (click)="navbarExpanderClick(sharedSidenav)"
                aria-label="Navigationsbereich ein/ausblenden">
                <mat-icon class="navbar__expand-toggle--expanded icon-no-margin" *ngIf="navbarExpanded">chevron_left</mat-icon>
                <mat-icon class="navbar__expand-toggle--collapsed icon-no-margin" *ngIf="!navbarExpanded">chevron_right</mat-icon>
              </button>
              <h1>NFA auswählen für "{{application.name}}"</h1>
              <button mat-icon-button aria-label="Hilfe" data-cy="helpBtn" class="helpBtn helpIcon" (click)="onHelpButtonClick()">
                <mat-icon class="helpButtonIcon" matTooltip="Hilfe">
                  help_outline
                </mat-icon>
              </button>

            </div>
            <div class="page-header__actions">
              <!--TODO übergabe von richtigen daten bei jira export-->
              <button id="jira-export-button" mat-flat-button class="preview-button mat-mdc-unelevated-button-secondary" (click)="onJiraExportButtonClick()">
                <mat-icon>import_export</mat-icon>
                Jira-Export
              </button>
              <button id="pdf-preview-button" mat-flat-button class="preview-button mat-mdc-unelevated-button-secondary" (click)="onPdfPreviewButtonClick()">
                <mat-icon>picture_as_pdf</mat-icon>
                PDF
              </button>
            </div>
          </div>
          <div>
            <mat-sidenav-container>

              <mat-sidenav #sharedSidenav mode="side" [opened]="navbarExpanded" class="mat-sidenav--fixed-height">
                <nfa-sidenav [treeRoots]="headers" (itemSelected)="navigateToItem($event)"
                  [documentIdentifier]="documentIdentifier">
                </nfa-sidenav>
              </mat-sidenav>
              <mat-sidenav-content class="mat-sidenav-container--leanten">
                <mat-card class="inhaltsbereich">

                  <mat-slide-toggle class="toggle-for-recommendations" (change)="toggleShowRecommendations()">
                    <span class="toggle-text-for-recomendations">Vorgeschlagene Metriken und NFA anzeigen</span>
                  </mat-slide-toggle>
                  <nfa-information-header [factor]="selectedFactorWrapper" [criterion]="selectedCriterionWrapper"
                                          [metrics]="selectedCriterionWrapper.metrics" [selectedMetric]="selectedMetric"
                                          [nfrRecommendations]="nfrsOfSystemType" [nfrsOfMetrics]="catalogState.nfrs"
                                          [showRecommendations]="showRecommendations"
                                          (selectedMetricChanged)="changeSelectedMetric($event)">
                  </nfa-information-header>

                  <!-- NFR -->
                  <!-- Einzelne NFR -->
                  <div *ngFor="let nfr of catalogState.nfrs[selectedMetric.identifier]"
                    [ngClass]="{'expantion-panel-nfr--highlighted':isRecommendation(nfr.id)}">
                    <form [id]="'nfrForm-' + nfr.id">
                      <div [id]="'nfr-' + nfr.id">
                        <mat-expansion-panel class="expantion-panel-nfr" *ngIf="sharesSameSectorAsApplication(nfr)" id="header{{nfr.id}}">
                          <mat-expansion-panel-header>
                            <div>
                              <div class="mat-panel-title__nfr mat-panel-title--flex">
                                <input class="checkbox-nfa"
                                       type="checkbox"
                                       (click)="$event.stopPropagation();"
                                       [(ngModel)]="this.applicationNfrs[nfr.id].active"
                                       [name]="'nfr_active' + nfr.id"
                                       (ngModelChange)="onApplicationNfrActiveStateToggle(nfr)"
                                       id="checkbox{{nfr.id}}"/>
                                <span class="header-style"
                                  [ngStyle]="{'margin-left': '15px', 'margin-button': '5px', 'font-size': '18px', 'margin-top': '5px'}"
                                  id="name{{nfr.id}}">
                                  {{nfr.nameGerman}}</span>
                                <span *ngIf="nfrnamecounter[nfr.id] >= 3"> 1</span>
                              </div>
                              <!--Question-->
                              <div class="questionAndNfr">
                                <div class="questionAndNfrTable" id="question{{nfr.id}}">
                                  {{nfr.questionGerman}}
                                </div>
                                <!--Nfr-->
                                <div class="questionAndNfrTable">
                                  <!--TODO: Platzhalter auslesen-->
                                  <div (keydown.Space)="$event.stopPropagation()">
                                    <span *ngFor="let element of splitFormulation[nfr.id]" [ngSwitch]="element">
                                      <span *ngSwitchCase="'<RV>'">
                                        <mat-form-field id="mat-form-field-rv">
                                          <mat-select (click)="$event.stopPropagation();"
                                            placeholder="Rechtliche Verbindlichkeit"
                                            [(ngModel)]="applicationNfrs[nfr.id].rv"
                                            (ngModelChange)="setEditedFlag(nfr.id)"
                                            [name]="'rv' + nfr.id" required
                                            [disabled]="!applicationNfrs[nfr.id].active"
                                            id="rv{{nfr.id}}">
                                            <!--TODO speichern der Eingabe-->
                                            <span *ngFor="let text of templateNodes[2].templateTexts">
                                              <mat-option [value]="text.description"
                                                *ngIf="nfr.plural && (text.identifier == '3.2' || text.identifier == '3.4' || text.identifier == '3.6' || text.identifier == '3.8')">
                                                {{text.description}}</mat-option>
                                              <mat-option [value]="text.description"
                                                *ngIf="!nfr.plural && (text.identifier == '3.1' || text.identifier == '3.3' || text.identifier == '3.5' || text.identifier == '3.7')">
                                                {{text.description}}</mat-option>
                                            </span>
                                          </mat-select>
                                          <mat-error>
                                            Bitte rechtliche Verbindlichkeit auswählen
                                          </mat-error>
                                        </mat-form-field>
                                      </span>
                                      <span *ngSwitchCase="'<WERT>'">
                                        <mat-form-field>
                                          <input matInput (click)="$event.stopPropagation();" placeholder="Wert"
                                            [(ngModel)]="this.applicationNfrs[nfr.id].wert"
                                            (ngModelChange)="setEditedFlag(nfr.id)"
                                            [name]="'wert' + nfr.id"
                                            [disabled]="!this.applicationNfrs[nfr.id].active"
                                            id="wert{{nfr.id}}" required>
                                          <mat-error>
                                            Bitte Wert eingeben
                                          </mat-error>
                                        </mat-form-field>
                                      </span>
                                      <span *ngSwitchCase="'<VW>'">
                                        <mat-form-field>
                                          <input matInput (click)="$event.stopPropagation();" placeholder="Variabler Wert"
                                                 [(ngModel)]="this.applicationNfrs[nfr.id].vw"
                                                 (ngModelChange)="setEditedFlag(nfr.id)"
                                                 [disabled]="!this.applicationNfrs[nfr.id].active" [name]="'vw' + nfr.id"
                                                 id="vw{{nfr.id}}"
                                                 required>
                                          <mat-error>
                                            Bitte variablen Wert eingeben
                                          </mat-error>
                                        </mat-form-field>
                                      </span>
                                      <span *ngSwitchCase="'<KW>'">
                                        <mat-form-field>
                                          <input matInput (click)="$event.stopPropagation();"
                                            placeholder="Konkretisierender Wert"
                                            [(ngModel)]="this.applicationNfrs[nfr.id].kw"
                                            (ngModelChange)="setEditedFlag(nfr.id)"
                                            [disabled]="!this.applicationNfrs[nfr.id].active" [name]="'kw' + nfr.id"
                                            id="kw{{nfr.id}}"
                                            required>
                                          <mat-error>
                                            Bitte konkretisierenden Wert eingeben
                                          </mat-error>
                                        </mat-form-field>
                                      </span>
                                      <span *ngSwitchCase="'<VB>'">
                                        <mat-form-field>
                                          <input matInput (click)="$event.stopPropagation();"
                                            placeholder="Variabler Betrachtungsgegenstand"
                                            [disabled]="!this.applicationNfrs[nfr.id].active"
                                            [(ngModel)]="this.applicationNfrs[nfr.id].vb"
                                            (ngModelChange)="setEditedFlag(nfr.id)"
                                            [name]="'vb' + nfr.id" required id="vb{{nfr.id}}">
                                          <mat-error>
                                            Bitte variablen Betrachtungsgegenstand eingeben
                                          </mat-error>
                                        </mat-form-field>
                                      </span>
                                      <span *ngSwitchCase="'<OW>'">
                                        <mat-form-field>
                                          <input matInput (click)="$event.stopPropagation();" placeholder="Oberer Wert"
                                                 [(ngModel)]="this.applicationNfrs[nfr.id].ow"
                                                 (ngModelChange)="setEditedFlag(nfr.id)"
                                                 [disabled]="!this.applicationNfrs[nfr.id].active"
                                                 [name]="'ow' + nfr.id"
                                                 id="ow{{nfr.id}}"
                                                 required>
                                          <mat-error>
                                            Bitte oberen Wert eingeben
                                          </mat-error>
                                        </mat-form-field>
                                      </span>
                                      <span *ngSwitchCase="'<WERTEBEREICH>'">
                                        <mat-form-field>
                                          <input matInput (click)="$event.stopPropagation();" placeholder="Wertebereich"
                                                 [(ngModel)]="this.applicationNfrs[nfr.id].wertebereich"
                                                 (ngModelChange)="setEditedFlag(nfr.id)"
                                                 [disabled]="!this.applicationNfrs[nfr.id].active"
                                                 [name]="'wertebereich' + nfr.id"
                                                 id="wertebereich{{nfr.id}}"
                                                 required>
                                          <mat-error>
                                            Bitte Wertebereich eingeben
                                          </mat-error>
                                        </mat-form-field>
                                      </span>
                                      <span *ngSwitchDefault>{{element}} </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </mat-expansion-panel-header>
                          <div class="nfr-details-container">
                            <mat-form-field class="nfr-details-half-row" appearance="outline">
                              <mat-label>Priorität</mat-label>
                              <mat-select [name]="'priority' + nfr.id" [disabled]="!this.applicationNfrs[nfr.id].active"
                                          [(ngModel)]="this.applicationNfrs[nfr.id].priority"
                                          (ngModelChange)="setEditedFlag(nfr.id)"
                                          class="form-control"
                                          placeholder="Priorität"
                                          id="priority{{nfr.id}}">
                                <mat-option *ngFor="let priority of priorityOptions"
                                            value="{{ priority }}">
                                  {{ priority }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field class="nfr-details-half-row" appearance="outline">
                              <mat-label>Basiert auf</mat-label>
                              <input matInput [(ngModel)]="this.applicationNfrs[nfr.id].basedOn"
                                     (ngModelChange)="setEditedFlag(nfr.id)"
                                     [disabled]="!this.applicationNfrs[nfr.id].active"
                                     placeholder="Basiert auf"
                                     [name]="'basedOn' + nfr.id"
                                     id="basedOn{{nfr.id}}">
                            </mat-form-field>

                            <mat-form-field class="nfr-details-half-row" appearance="outline">
                              <mat-label>Kritikalität</mat-label>
                              <mat-select [name]="'criticality' + nfr.id" [disabled]="!this.applicationNfrs[nfr.id].active"
                                          [(ngModel)]="this.applicationNfrs[nfr.id].criticality"
                                          (ngModelChange)="setEditedFlag(nfr.id)"
                                          class="form-control" placeholder="Kritikalität"
                                          id="criticality{{nfr.id}}">
                                <mat-option *ngFor="let criticality of criticalityOptions"
                                value="{{ criticality }}">{{ criticality }}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field class="nfr-details-half-row" appearance="outline">
                              <mat-label>Version</mat-label>
                              <input matInput [(ngModel)]="this.applicationNfrs[nfr.id].version"
                                     (ngModelChange)="setEditedFlag(nfr.id)"
                                     [disabled]="!this.applicationNfrs[nfr.id].active"
                                     placeholder="Version"
                                     [name]="'version' + nfr.id"
                                     id="version{{nfr.id}}">
                            </mat-form-field>

                            <mat-form-field class="nfr-details-half-row" appearance="outline">
                              <mat-label>Schutzbedarf</mat-label>
                              <mat-select [name]="'protectionNeeds' + nfr.id"
                                          [disabled]="!this.applicationNfrs[nfr.id].active"
                                          [(ngModel)]="this.applicationNfrs[nfr.id].protectionNeeds"
                                          (ngModelChange)="setEditedFlag(nfr.id)"
                                          class="form-control" placeholder="Schutzbedarf"
                                          id="protectionNeeds{{nfr.id}}">
                                <mat-option *ngFor="let protectionNeed of protectionNeedOptions"
                                  value="{{ protectionNeed }}">{{ protectionNeed }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field class="nfr-details-whole-row" appearance="outline">
                              <mat-label>Anmerkung</mat-label>
                              <label>
                                <textarea matInput [(ngModel)]="this.applicationNfrs[nfr.id].annotation"
                                          (ngModelChange)="setEditedFlag(nfr.id)"
                                          [disabled]="!this.applicationNfrs[nfr.id].active"
                                          placeholder="Anmerkung"
                                          [name]="'annotation' + nfr.id"
                                          id="annotation{{nfr.id}}">
                              </textarea>
                              </label>
                            </mat-form-field>
                          </div>

                          <!-------------Anzeige der Referenz, Prozesse, Branchen und "Definiert in Projektphase"----------------------------------->
                          <div class="nfr-details-container">
                            <div class="nfr-details-half-row--no-margin">
                              <span class="detail-row__label">Referenz: </span>
                              <span class="detail-row__data" *ngIf="this.nfr.reference !== ''"> {{nfr.reference}}</span>
                              <span class="detail-row__data default-value-reference" *ngIf="!this.nfr.reference "> Keine
                                Referenz </span>
                            </div>
                            <div class="nfr-details-half-row--no-margin">
                              <span class="detail-row__label">Projekte: </span>
                              <!-- <span class="detail-row__data">{{nfr.processes!.join(', ')}}</span> -->
                              <span class="detail-row__data default-value-reference"> In noch keinem Projekt ausgewählt
                              </span>
                            </div>
                            <div class="nfr-details-half-row--no-margin">
                              <span class="detail-row__label">Branchen: </span>
                              <span class="detail-row__data"
                                *ngFor="let sector of nfr.sectors; let i = index">{{sector.name}}<span
                                  *ngIf="i < nfr.sectors.length - 1">, </span></span>
                            </div>
                            <div class="nfr-details-half-row--no-margin">
                              <span class="detail-row__label" *ngIf="this.applicationNfrs[nfr.id].projectPhase !== ''">Definiert
                                in Projektphase: </span>
                              <span class="detail-row__data">{{this.applicationNfrs[nfr.id].projectPhase}}</span>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </div>
                    </form>
                  </div>
                  <mat-expansion-panel *ngFor="let customNfr of getCustomNfrs(application, selectedMetric.identifier)">
                    <mat-expansion-panel-header>
                      <input class="checkbox-nfa"
                             type="checkbox"
                             (click)="$event.stopPropagation();"
                             [checked]="true"
                             [(ngModel)]="customNfr.active"
                             (ngModelChange)="editInputCustomNfr(customNfr.id)"
                             [name]="'custom active' + customNfr.id"
                      />
                      &nbsp;
                      <div class="custom-nfr-header">
                        <div class="mat-panel-title__nfr mat-panel-title--flex">
                          <mat-form-field class="custom-nfr-header-form-fields" appearance="outline">
                            <mat-label>Name</mat-label>
                            <label>
                              <input matInput
                                      (keydown.space)="$event.stopPropagation()"
                                      (click)="$event.stopPropagation()"
                                      [(ngModel)]="customNfr.name"
                                      (ngModelChange)="editInputCustomNfr(customNfr.id)"
                                      [name]="'custom name' + customNfr.id"
                                      [disabled]="!customNfr.active"
                                      placeholder="Name">
                            </label>
                          </mat-form-field>
                        </div>
                        <div>
                          <mat-form-field class="custom-nfr-header-form-fields" appearance="outline">
                            <mat-label>Formulierung</mat-label>
                            <textarea matInput
                                      (keydown.Space)="$event.stopPropagation()"
                                      (click)="$event.stopPropagation()"
                                      [(ngModel)]="customNfr.formulation"
                                      (ngModelChange)="editInputCustomNfr(customNfr.id)"
                                      [name]="'custom formulation' + customNfr.id"
                                      [disabled]="!customNfr.active"
                                      placeholder="Formulierung">
                      </textarea>
                          </mat-form-field>
                        </div>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="nfr-details-container">
                      <mat-form-field class="nfr-details-half-row" appearance="outline">
                        <mat-label>Priorität</mat-label>
                        <mat-select class="form-control"
                                    [(ngModel)]="customNfr.priority"
                                    (ngModelChange)="editInputCustomNfr(customNfr.id)"
                                    [name]="'custom priority' + customNfr.id"
                          [disabled]="!customNfr.active">
                          <mat-option *ngFor="let priority of priorityOptions"
                                      value="{{ priority }}">{{ priority }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="nfr-details-half-row" appearance="outline">
                        <mat-label>Basiert auf</mat-label>
                        <input matInput
                               [(ngModel)]="customNfr.basedOn"
                               (ngModelChange)="editInputCustomNfr(customNfr.id)"
                               [name]="'custom basedOn' + customNfr.id"
                          [disabled]="!customNfr.active">
                      </mat-form-field>
                      <mat-form-field class="nfr-details-half-row" appearance="outline">
                        <mat-label>Kritikalität</mat-label>
                        <mat-select
                          [(ngModel)]="customNfr.criticality"
                          (ngModelChange)="editInputCustomNfr(customNfr.id)"
                          [name]="'custom criticality' + customNfr.id"
                          [disabled]="!customNfr.active"
                          class="form-control">
                          <mat-option *ngFor="let criticality of criticalityOptions"
                                      value="{{ criticality }}">{{ criticality }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="nfr-details-half-row" appearance="outline">
                        <mat-label>Version</mat-label>
                        <input matInput
                               [(ngModel)]="customNfr.version"
                               (ngModelChange)="editInputCustomNfr(customNfr.id)"
                               [name]="'custom version' + customNfr.id"
                               [disabled]="!customNfr.active">
                      </mat-form-field>
                      <mat-form-field class="nfr-details-half-row" appearance="outline">
                        <mat-label>Schutzbedarf</mat-label>
                        <mat-select
                          [(ngModel)]="customNfr.protectionNeeds"
                          (ngModelChange)="editInputCustomNfr(customNfr.id)"
                          [name]="'custom protectionNeeds' + customNfr.id"
                          [disabled]="!customNfr.active"
                          class="form-control">
                          <mat-option *ngFor="let protectionNeed of protectionNeedOptions"
                                      value="{{ protectionNeed }}">{{ protectionNeed }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="nfr-details-whole-row" appearance="outline">
                        <mat-label>Anmerkung</mat-label>
                        <label>
                          <textarea matInput
                            [(ngModel)]="customNfr.annotation"
                            (ngModelChange)="editInputCustomNfr(customNfr.id)"
                            [name]="'custom annotation' + customNfr.id"
                            [disabled]="!customNfr.active">
                    </textarea>
                        </label>
                      </mat-form-field>
                    </div>
                  </mat-expansion-panel>
                  <div class="button-bar">
                    <button mat-flat-button class="mat-mdc-unelevated-button-secondary"
                      (click)="openCreateNewCustomNfr(selectedMetric)"
                      title="Neue nicht-funktionale Anforderung für die Metrik {{selectedMetric.label}} erstellen">
                      <mat-icon class="icon-no-margin">add</mat-icon>
                    </button>
                    <div id="cancel-button" class="divButtonstoSaveAndCancel">
                      <button id="save-button" mat-flat-button
                              class="mat-mdc-unelevated-button-primary"
                              color="primary" (click)="saveNfr()"
                              [disabled]="!itNfrForm.valid">
                              <!--Primär TEN-->
                              <mat-icon>save</mat-icon>
                              Speichern
                      </button>
                      <button class="go-back-button-nfa mat-mdc-unelevated-button-tertiary" type="button" mat-flat-button (click)="goBack()">
                        <mat-icon>arrow_left</mat-icon>
                        Zurück
                      </button>
                    </div>
                  </div>
                </mat-card>
              </mat-sidenav-content>
            </mat-sidenav-container>
          </div>
        </div>
        <ng-template #noContent>
          <div class="title-flex-layout">
            <h1>NFA auswählen für "{{application.name}}"
              <a class="helpBtn" routerLink="/qumap-help/16" target="_blank" mat-icon-button>
                <mat-icon class="helpButtonIcon" matTooltip="Hilfe">
                  help_outline
                </mat-icon>
              </a>
            </h1>
          </div>
          Es können keine Daten geladen werden!
        </ng-template>
      </div>
      <br/>
    </form>

  </ng-container>
</ng-container>
