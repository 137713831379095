export type TestToolCategory = keyof TestTools;

export interface TestTools {
  software?: TestTool[];
  operatingSystems?: TestTool[];
  screenreaders?: TestTool[];
  webbrowsers?: TestTool[];
  others?: TestTool[];
}

export function isToolSection(key: string): key is TestToolCategory {
  const emptyObject: Required<TestTools> = {
    operatingSystems: null,
    screenreaders: null,
    webbrowsers: null,
    software: null,
    others: null
  };
  return Object.keys(emptyObject).includes(key);
}

export interface TestTool {
  name: string;
  version: string;
}
