import { Component, OnInit } from '@angular/core';
import { JiraExportService } from './jira-export.service';
import { ActivatedRoute } from '@angular/router';
import { InformationDialogComponent } from 'src/app/shared/information-dialog/information-dialog.component';
import { take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../app-state/app.reducer';
import { allNfasExported, isExportingToJira } from '../application-state/application.reducer';
import { exportToJira, exportToJiraFailure, exportToJiraSuccess } from '../application-state/application.actions';
import { HilfeFunktionen } from '../../shared/global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from "@angular/material/dialog";
import {SaveConfSnackbarComponent} from "../../shared/save-confirm-snackbar/save-confirm-snackbar-component";

const germanError: Record<string, string> = {
  username: 'Bitte geben Sie den Benutzernamen Ihrer Jira-Instanz ein.',
  password: 'Bitte geben Sie das Passwort Ihrer Jira-Instanz ein.',
  projectKey: 'Bitte geben Sie den Projektschlüssel Ihres Jira-Projekts ein.',
};
const englishError: Record<string, string> = {
  username: 'Bitte geben Sie den Benutzernamen Ihrer Jira-Instanz ein.',
  password: 'Bitte geben Sie das Passwort Ihrer Jira-Instanz ein.',
  projectKey: 'Bitte geben Sie den Projektschlüssel Ihres Jira-Projekts ein.',
};

@Component({
  selector: 'nfa-jira-export',
  templateUrl: './jira-export.component.html',
  styleUrls: ['./jira-export.component.scss'],
})
export class JiraExportComponent implements OnInit {

  // Daten der Eingabefelder
  username = '';
  password = '';
  projectKey = '';

  isExporting$ = this.store.pipe(select(isExportingToJira));
  areAllAlreadyExported$ = this.store.pipe(select(allNfasExported));
  currentError = germanError;

  jiraUrl = '';
  noJiraUrlText = '';

  applicationId: number;

  constructor(
    private jiraExportService: JiraExportService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private store: Store<AppState>
  ) {
  }


  ngOnInit() {
    HilfeFunktionen.setApplicationTitle('TEN - Jira-Export');
    this.applicationId = +this.route.snapshot.paramMap.get('id');
    this.getJiraUrl();
  }

  getJiraUrl() {
    this.jiraExportService.getJiraInstanceUrl(this.applicationId).subscribe(jiraInstance => {
      this.jiraUrl = jiraInstance.jiraUrl;
    });
  }

  exportIssues() {
    this.store.dispatch(exportToJira());
    this.jiraExportService.invokeJiraExport({
      username: this.username,
      password: this.password,
      projectKey: this.projectKey,
      applicationId: this.applicationId,
    }).pipe(take(1)).subscribe(() => {
      this.snackBar.openFromComponent(SaveConfSnackbarComponent, {
        data: { message: 'Jira-Export wurde erfolgreich durchgeführt.'}});
        this.store.dispatch(exportToJiraSuccess());
    },
    error => {
      if (error.status === 401) {
        this.openJiraExportFailedDialog('Der Benutzername oder das Passwort ist falsch.');
      }
      else if (error.status === 403) {
        this.openJiraExportFailedDialog(`Der eingegebene Benutzer besitzt in der eingegebenen Jira-Instanz keine
                                        ausreichende Berechtigung zum Anlegen eines Jira-Vorgangs. Bitte geben
                                        Sie einen anderen Benutzernamen ein oder wenden Sie sich an Ihren Jira-Administrator,
                                        damit dieser Ihnen die Berechtigung zum Anlegen eines Jira-Vorgangs zuweist.`);
        } else if (error.status === 404) {
          this.openJiraExportFailedDialog(`Für den eingegebenen Projektschlüssel konnte kein Projekt in der eingegebenen
                                         Jira-Instanz gefunden werden. Bitte überprüfen Sie den Projektschlüssel und
                                         versuchen Sie es erneut.`);
        } else if (error.status === 901) {
          this.openJiraExportFailedDialog(`Die eingegebene Jira-Instanz konnte nicht erreicht werden. Bitte überprüfen Sie,
                                         ob diese zur Verfügung steht. Falls diese zur Verfügung steht, wenden Sie sich
                                         bitte an support.qumap@msg.group.`);
      } else if (error.status === 500) {
        this.openJiraExportFailedDialog('Der Jira-Export konnte aus unbekannten Gründen nicht durchgeführt werden.');
      } else if (error.status === 409) {
        this.openJiraExportFailedDialog(JSON.parse(error.statusText).map(item => this.convertToMessage(item)).join('\n'));
      } else {
        this.openJiraExportFailedDialog('Der Jira-Export konnte aus unbekannten Gründen nicht durchgeführt werden.');
      }
      this.store.dispatch(exportToJiraFailure());
    });
  }

  openJiraExportFailedDialog(message: string) {
    const headerMessage = 'Jira-Export fehlgeschlagen';
    this.dialog.open(InformationDialogComponent, {
      panelClass: 'information-dialog-container',
      data: {informationType: 'error', header: headerMessage, message},
      autoFocus: "dialog"
    });
  }

  private convertToMessage(item): string {
    if (item.type === 'IssueType') {
      return 'Die Erstellung eines Eintrags vom Typ "' + item.title + '" ist in dem gewählten Jira-Projekt nicht zulässig.';
    }
    return item.type + ' ' + item.title + ': ' + item.message;
  }
}
