import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { SidenavTreeComponent } from './sidenav-tree.component';
import { SidenavComponent } from './sidenav.component';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    SidenavTreeComponent,
    SidenavComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule
  ],
  exports: [
    SidenavTreeComponent,
    SidenavComponent
  ],
})
export class SidenavModule {
}
