import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLengthDirective } from './directives/custom-length.directive';
import { MathjaxComponent } from './mathjax/mathjax.component';
import { FormsModule } from '@angular/forms';
import { DisableCopyPasteDirective } from './directives/disable-copy-paste.directive';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NoAccessErrorPageComponent } from './no-access-error-page/no-access-error-page.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/mat-spinner-overlay.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    CustomLengthDirective,
    MathjaxComponent,
    InformationDialogComponent,
    DisableCopyPasteDirective,
    NoAccessErrorPageComponent,
    MatSpinnerOverlayComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    CustomLengthDirective,
    MathjaxComponent,
    InformationDialogComponent,
    DisableCopyPasteDirective,
    MatSpinnerOverlayComponent,
  ],
})
export class SharedModule {}
