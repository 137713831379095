import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BitvComponent } from './bitv.component';
import { BalmEvaluationComponent } from './balm-evaluation/balm-evaluation.component';
import { BitvPrintPreviewComponent } from './bitv-print-preview/bitv-print-preview.component';
import { EvaluationConfigurationComponent } from './evaluation-configuration/evaluation-configuration.component';
import { UnsavedChangesGuard } from '../guards/unsaved-changes.guard';
import { BalmEvaluationOverviewDashboardComponent } from './balm-evaluation/balm-evaluation-dashboard/balm-evaluation-dashboard.component';
import { BalmTestToolsComponent } from './balm-test-tools/balm-test-tools.component';
import { RoutingParams } from '../shared/global';

// TODO: Routing-Parameter glattziehen
const routes: Routes = [
  {path: '', component: BitvComponent},
  {path: 'new', component: EvaluationConfigurationComponent},
  {path: ':id', component: BalmEvaluationOverviewDashboardComponent},
  {path: ':id/configuration', component: EvaluationConfigurationComponent},
  {path: ':id/preview', component: BitvPrintPreviewComponent},
  {
    path: `:${RoutingParams.bitvIdParam}/test-tools`,
    component: BalmTestToolsComponent,
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: ':id/testobjects',
    component: BalmEvaluationComponent,
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: `:id/testobjects/:${RoutingParams.testobjectIdParam}`,
    component: BalmEvaluationComponent,
    canDeactivate: [UnsavedChangesGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BitvRoutingModule {
}
