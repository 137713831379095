import { Action } from '@ngrx/store';
import { Language } from '../../app-state/settings';
import { TestType } from '../adapter/testtype';
import { TestDesignTechnique } from '../adapter/testDesignTechnique';

export enum TestMethodsActions {
  LoadTestTypes = '[TestMethods] Load TestTypes',
  LoadTestTypesSuccess = '[TestMethods] Load TestTypes Success',
  LoadTestTypesFailure = '[TestMethods] Load TestTypes Failure',
  LoadTestDesignTechniques = '[TestMethods] Load TestDesignTechniques',
  LoadTestDesignTechniquesSuccess = '[TestMethods] Load TestDesignTechniques Success',
  LoadTestDesignTechniquesFailure = '[TestMethods] Load TestDesignTechniques Failure',
  LoadCancel = '[TestMethods] Load TestTypes Cancel'
}

export class LoadTestTypes implements Action {
  readonly type = TestMethodsActions.LoadTestTypes;

  constructor(public language: Language) {
  }
}

export class LoadTestTypesSuccess implements Action {
  readonly type = TestMethodsActions.LoadTestTypesSuccess;

  constructor(public testTypes: TestType[]) {
  }
}

export class LoadTestTypesFailure implements Action {
  readonly type = TestMethodsActions.LoadTestTypesFailure;

  constructor(public payload: { error: string }) {
  }
}

export class LoadTestDesignTechniques implements Action {
  readonly type = TestMethodsActions.LoadTestDesignTechniques;

  constructor(public language: Language) {
  }
}

export class LoadTestDesignTechniquesSuccess implements Action {
  readonly type = TestMethodsActions.LoadTestDesignTechniquesSuccess;

  constructor(public testDesignTechniques: TestDesignTechnique[]) {
  }
}

export class LoadCancel implements Action {
  readonly type = TestMethodsActions.LoadCancel;
}

export class LoadTestDesignTechniquesFailure implements Action {
  readonly type = TestMethodsActions.LoadTestDesignTechniquesFailure;

  constructor(public payload: { error: string }) {
  }
}

export type TestMethodsAction = LoadTestTypes
  | LoadTestTypesSuccess
  | LoadTestTypesFailure
  | LoadTestDesignTechniques
  | LoadTestDesignTechniquesSuccess
  | LoadTestDesignTechniquesFailure
  | LoadCancel;
