<div aria-hidden="true">
  <div class="mat-snack-bar-content-container">
    <div class="snackbar-message"
         data-cy="snackbar-message"
         aria-hidden="true">
      {{data.message}}
    </div>
    <mat-dialog-actions aria-hidden="true">
      <button id="closeButton" (click)="close()">
        <mat-icon id="save-confirm-snackbar-close-button-icon">close</mat-icon>
      </button>
    </mat-dialog-actions>
  </div>

  <mat-progress-bar class="_mat-animation-noopable" mode="determinate" role="none" [value]="progressValue"></mat-progress-bar>
</div>
