import { Component, Input, OnInit } from '@angular/core';
import { ApplicationsService } from '../applications.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationReceived } from '../model/applicationReceived';
import { ApplicationNfrReceived } from '../model/applicationNfrReceived';
import { LoadCatalog } from '../../catalog/catalog-state/catalog.actions';
import { Language } from '../../app-state/settings';
import { Store } from '@ngrx/store';
import { getFactorList } from '../../catalog/catalog-state/catalog.reducer';
import { Factor } from '../../catalog/adapter/factor';
import { Criterion } from '../../catalog/adapter/criterion';
import { Metric } from '../../catalog/adapter/metric';
import { TestDesignTechnique } from '../../test-methods/adapter/testDesignTechnique';
import { TestType } from '../../test-methods/adapter/testtype';
import { AboutService } from '../../about/about.service';
import { CustomApplicationNfr } from '../model/applicationNfr';
import { QualityProcessReceived } from '../model/qualityProcessReceived';
import { DocumentType } from '../model/requirementsDocument';
import { assertUnreachable } from '../../shared/utils/assert-unreachable';
import { HilfeFunktionen } from '../../shared/global';

@Component({
  selector: 'nfa-nfr-print-preview',
  templateUrl: './nfr-print-preview.component.html',
  styleUrls: ['./nfr-print-preview.component.scss'],
})
export class NfrPrintPreviewComponent implements OnInit {

  @Input()
  application: ApplicationReceived;
  relevantFactors: Set<Factor> = new Set<Factor>();
  relevantCriteria: Map<string, Criterion> = new Map<string, Criterion>();
  relevantMetricForCriterion: Map<string, Set<Metric>> = new Map<string, Set<Metric>>();
  relevantCriteriaForFactor: Map<string, Set<Criterion>> = new Map<string, Set<Criterion>>();
  loading = true;
  loadingAbout = true;
  about: string;

  constructor(
    private applicationService: ApplicationsService,
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store,
    private aboutService: AboutService,
  ) {
    this.store$.dispatch(new LoadCatalog(Language.DEUTSCH));
  }

  private readonly LANGUAGE: Language = Language.DEUTSCH;

  ngOnInit(): void {
    HilfeFunktionen.setApplicationTitle('TEN - PDF-Preview');
    this.applicationService.getApplicationById(this.route.snapshot.params.id, this.LANGUAGE)
      .subscribe( {
        next: application => {
          this.application = application;
          this.application.customNfrs = this.customNfrs.filter(customNfr => customNfr.active);
          this.createNFRTree(this.application.applicationNfrs, this.application.customNfrs);
          this.loading = false;
        },
        error: err => {
          if (err.status === 403) {
            this.router.navigateByUrl('/no-access-error-page');
          } else if (err.status === 404) {
            this.router.navigateByUrl('/empty');
          }
        }
      });
    this.aboutService.getAbout(this.LANGUAGE).subscribe(aboutJsonArray => {
      this.loadingAbout = false;
      this.about = this.transformImpressum(aboutJsonArray[0].aboutHtml);
    });
  }

  private transformImpressum(aboutHtml: string) {
    return aboutHtml.replace(/h2/gi, 'h3').replace('<h1>Impressum</h1>', '');
  }

  get loaded() {
    return !this.loading;
  }

  get nfrs(): ApplicationNfrReceived[] {
    return this.application !== undefined ? this.application.applicationNfrs : [];
  }

  get customNfrs(): CustomApplicationNfr[] {
    return  this.application !== undefined ? this.application.customNfrs : [];
  }

  get qualityProcess(): QualityProcessReceived {
    return this.application.qualityProcesses[0];
  }

  get documentTitle(): string {
    let ret;

    switch (this.LANGUAGE) {
      case Language.DEUTSCH:
        ret =
          `Nicht-funktionale Anforderungen ${ this.mapDocumentIdToTitleDomainTextGerman(
            this.qualityProcess.document.identifier) } der Anwendung ${ this.application.name }`;
        break;
      case Language.ENGLISH:
        ret = 'TODO: Enter English Text for Document Title here';
        break;
      default:
        assertUnreachable(this.LANGUAGE, 'Language');
    }

    return ret;
  }

  private mapDocumentIdToTitleDomainTextGerman(identifier: string): string {
    switch (identifier) {
      case DocumentType.PROJECT_INITIALIZATION_DOCUMENT:
        return 'des Projektinitialisierungsdokuments';
      case DocumentType.PROJECT_DEFINITION_DOCUMENT:
        return 'des Projektdefinitionsdokuments';
      case DocumentType.REQUIREMENTS_DOCUMENT:
        return 'des Anforderungsdokuments';
      case DocumentType.USER_STORY:
        return 'einer User Story';
      case DocumentType.USER_REQUIREMENT_SPECIFICATION:
        return 'des Lastenhefts';
      case DocumentType.FUNCTIONAL_SPECIFICATION:
        return 'des Pflichtenhefts';
      case DocumentType.RETROSPECTIVE:
        return 'einer Retrospektive';
      default:
        return 'Unknown Document Identifier: ' + identifier;
    }
  }

  get relevantTesttypes(): TestType[] {
    const testTypes = [];
    this.relevantCriteria.forEach((criteria, id) => {
      testTypes.push(...criteria.testTypes);
    });

    return Array.from(
      new Map<string, TestType>(testTypes.map(tt => [tt.identifier, tt])),
      ([id, value]) => value,
    );

  }

  get relevantTestDesignTechniques(): TestDesignTechnique[] {
    const testDesignTechniques = [];
    this.relevantCriteria.forEach((criteria, id) => {
      testDesignTechniques.push(...(criteria.testDesignTechniques as unknown) as TestDesignTechnique[]);
    });

    return Array.from(
      new Map<string, TestDesignTechnique>(testDesignTechniques.map(tdt => [tdt.identifier, tdt])),
      ([id, value]) => value,
    );
  }

  testDesingTechniquesForPrint(criterion: Criterion) {
    return (criterion.testDesignTechniques as unknown as TestDesignTechnique[]).map(tdt => tdt.labelKey).join(', ');
  }

  testTestTypesForPrint(criterion: Criterion) {
    return (criterion.testTypes).map(tdt => tdt.labelKey).join(', ');
  }

  nfrsForMetric(identifier: string): Set<ApplicationNfrReceived> {
    return new Set<ApplicationNfrReceived>(this.nfrs.filter(nfr => nfr.nfr.metric === identifier));
  }

  getDate() {
    return new Date();
  }

  getCriteriaAsList(criteria: Criterion[]): string {
    return criteria
      .filter(criterion => this.relevantCriteria.has(criterion.identifier))
      .map(c => c.description)
      .join(', ');
  }

  hasAdditionalData(nfr: ApplicationNfrReceived | CustomApplicationNfr) {
    return nfr.annotation !== '' ||
      nfr.priority !== '' ||
      nfr.criticality !== '' ||
      nfr.protectionNeeds !== '' ||
      nfr.basedOn !== '' ||
      nfr.version !== '';
  }

  getCustomNfrs(application: ApplicationReceived, metricId: string): CustomApplicationNfr[] {
    return application.customNfrs.filter((customNfr) => customNfr.metric === metricId);
  }

  print() {
    window.print();
  }

  back() {
    // TODO check if all use cases covered
    if(this.application.canUserEditApplication) {
      this.router.navigateByUrl(`/applications/${this.application.id}/ten`);
    } else {
      this.router.navigateByUrl(`/applications`);
    }
  }

  private createNFRTree(applicationNfrs: ApplicationNfrReceived[], customNfrs: CustomApplicationNfr[]) {
    const metricIds: Set<string> = new Set(applicationNfrs.map(nfr => nfr.nfr.metric));
    customNfrs.filter(customNfr => customNfr.active).map(customNfr => customNfr.metric).forEach(metricId => metricIds.add(metricId));

    this.store$.select(getFactorList).subscribe(
      factors => {
        this.relevantFactors = new Set<Factor>(factors.filter(factor => isFactorContainingMetric(factor, metricIds)));

        this.relevantFactors.forEach(factor => {
          this.relevantCriteriaForFactor.set(factor.identifier, new Set<Criterion>());
          factor.criteria
            .filter(criterion => isCriterionContainingMetric(criterion, metricIds))
            .forEach(criterion => {
              this.relevantCriteriaForFactor.get(factor.identifier).add(criterion);
              this.relevantCriteria.set(criterion.identifier, criterion);
            });
        });
        this.relevantCriteria.forEach((criterion, identifier) => {
          this.relevantMetricForCriterion.set(
            identifier,
            new Set<Metric>(criterion.metrics.filter(metric => metricIds.has(metric.identifier))),
          );
        });
      },
    );
  }
}

const isFactorContainingMetric = (factor: Factor, metricsIds: Set<string>) => {
  return factor.criteria.filter(criterion => criterion.metrics.find(metric => metricsIds.has(metric.identifier))).length > 0;
};

const isCriterionContainingMetric = (criterion: Criterion, metricsIds: Set<string>) => {
  return criterion.metrics.some(metric => metricsIds.has(metric.identifier));
};
