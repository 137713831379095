  <div class="page-header">
    <div class="page-header__heading">
      <h1 nfaDisableCopyPaste>Barrierefreiheitstest {{loading ? '' : (!!bitv ? 'bearbeiten' : 'anlegen')}}</h1>
    </div>
  </div>
 <div class="configuration-container">
  <section class="configuration__step-container margin--sides" data-cy="prüfgegenständeSection"
           *ngIf="!!configurationOptions">
    <h2 class="configuration__label-step">Schritt 1: Prüfgegenstandstyp auswählen</h2>
    <mat-card class="configuration__typ-testobject-card"
              tabindex="0"
              *ngFor="let objectType of configurationOptions.objectTypes"
              [ngClass]="{
            'configuration__typ-testobject-card--selected':objectType.key === selectedObjectType?.key,
            'configuration__typ-testobject-card--not-selected': !!selectedObjectType && objectType.key !== selectedObjectType?.key
            }">
      <mat-card-header>
        <div mat-card-avatar *ngIf="!!objectType.icon">
          <mat-icon>{{objectType.icon}}</mat-icon>
        </div>
        <mat-card-title>
          <h3 tabindex="0" [attr.aria-label]="areaLabelForConfigurationType(objectType)">{{objectType.label}}</h3>
        </mat-card-title>
        <mat-card-subtitle *ngIf="objectType.description">{{objectType.description}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content *ngIf="!!objectType.linkedOptions?.['evaluationTypes']">
        <ul *ngFor="let linkedOption of linkedOptionsCategory(objectType)">
          <li><span>{{getLinkedOptionText(objectType, linkedOption)}}</span></li>
        </ul>
      </mat-card-content>
      <mat-card-actions [align]="'end'">
        <button mat-flat-button
                class="mat-mdc-unelevated-button-secondary"
                (click)="selectObjectType(objectType)">
          Auswählen
        </button>
      </mat-card-actions>
    </mat-card>
  </section>

  <section class="configuration__step-container margin--sides" data-cy="prüfartSection"
           *ngIf="selectedObjectType !== undefined">
    <h2 class="configuration__label-step">Schritt 2: Prüfart auswählen</h2>
    <mat-card class="configuration__typ-testobject-card configuration__typ-testobject-card--evaluation-type"
              tabindex="0"
              [ngClass]="{
            'configuration__typ-testobject-card--selected':evaluationType.key === selectedEvaluationType?.key,
            'configuration__typ-testobject-card--not-selected': !!selectedEvaluationType && evaluationType.key !== selectedEvaluationType?.key
            }"
              *ngFor="let evaluationType of matchingEvaluationTypes | async">
      <mat-card-header>
        <mat-card-title>
          <h3 [attr.aria-label]="areaLabelForEvaluationType(evaluationType)">{{evaluationType.label}}</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul *ngFor="let line of evaluationTypeDescriptionLines(evaluationType.description)">
          <li><span>{{line}}</span></li>
        </ul>
      </mat-card-content>
      <mat-card-actions [align]="'end'">
        <button mat-flat-button
                class="mat-mdc-unelevated-button-secondary"
                (click)="selectEvaluationType(evaluationType)">
          <span>Auswählen</span>
        </button>
      </mat-card-actions>
    </mat-card>
  </section>

  <section class="configuration__step-container margin--sides" data-cy="testobjectSection"
           *ngIf="selectedObjectType !== undefined && selectedEvaluationType !== undefined">
    <h2 class="configuration__label-step">Schritt 3: {{step3header}}</h2>
    <div *ngIf="this.configurationObjectsByType.length === 0" class="error">
      <div class="noEvaluationObjectsError">
        <mat-icon>warning_amber</mat-icon>
        <span>{{noEvaluationObjectsError}}</span>
      </div>
    </div>

    <div *ngIf="this.configurationObjectsByType.length !== 0" class="configuration__flex">
      <ul class="configuration__test-object-list" data-cy="configuration-list">
        <li [ngClass]="{'highlight': selectedConfigurationObject === typedConfigurationObject}"
            *ngFor="let typedConfigurationObject of this.configurationObjectsByType">
          <div>
            <input id="addtobitv{{typedConfigurationObject.label}}" type="checkbox"
                   [(ngModel)]="typedConfigurationObject.checked"
                   (change)="showEvaluationObjectDetailsByCheckbox(typedConfigurationObject)">
            <label [ngClass]="{'checked': true === typedConfigurationObject.checked}"
                   for="addtobitv{{typedConfigurationObject.label}}">{{typedConfigurationObject.label}}</label>
          </div>
          <button mat-flat-button
                  class="mat-mdc-unelevated-button-secondary"
                  [ngClass]="{'highlight': selectedConfigurationObject === typedConfigurationObject}"
                  (click)="showEvaluationObjectDetails(typedConfigurationObject)"><span>Details</span></button>
        </li>
      </ul>

      <mat-card class="configuration__typ-testobject-card" tabindex="0" data-cy="configurationsettings">
        <mat-card-header>
          <mat-card-title>
            <h3>{{selectedConfigurationObject.label}}</h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="configuration__testobject_conformitylvl">
          <span class="column_title">Konformitätsstufe</span>
          <mat-radio-group disabled="{{!selectedConfigurationObject.checked}}"
                           [(ngModel)]="selectedConfigurationObject.conformityLevel" class="conformity-radio-group"
                           data-cy="conformityradiogroup">
            <mat-radio-button *ngFor="let conformityLevel of filterConformityLevelsByObjectType(configurationOptions.conformityLevels, selectedObjectType)"
                              value={{conformityLevel.key}} class="conformity-radio-button"
                              id="setconformitylvl{{conformityLevel.label}}">
              {{conformityLevel.label}}
            </mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>

    </div>
  </section>

  <section class="margin--sides page-actions">
    <button #createnewbalmtest
            mat-flat-button
            id="createnewbalmtest"
            class="mat-mdc-unelevated-button-primary"
            color="primary"
            [disabled]="notReadyToSave"
            (click)="save()">
      <mat-icon>save</mat-icon>
      Speichern
    </button>
    <button #cancel
            id="cancelbutton"
            mat-flat-button
            class="mat-mdc-unelevated-button-tertiary"
            [routerLink]="cancelConfiguration()">
      <mat-icon>close</mat-icon>
      Abbrechen
    </button>
  </section>
</div>
