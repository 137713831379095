import { QualityProcess } from './qualityProcess';
import { PersonsInChargeMSG } from './personsInChargeMSG';
import { SystemType } from './systemType';

export class Application {
  id?: number;
  keyCloakGroupIdentifier?: string;
  name: string;
  customerName?: string;
  organizationalUnit?: string;
  sectorIdentifier: string;
  contactsIdentifiers: number[];
  personsInChargeMSG: PersonsInChargeMSG;
  systemTypeId?: number;
  systemType?: SystemType;
  qualityProcesses: QualityProcess[];
  lastUpdate?: number;
  lastUpdateString?: string;  // only used in Frontend
}

export class ApplicationDTO {
  id?: number;
  name: string;
  customerName?: string;
  organizationalUnit?: string;
  sectorIdentifier: string;
  contactsIdentifiers: number[];
  personsInChargeMSG: PersonsInChargeMSG;
  systemType?: SystemType;
  lastUpdate?: number;

  constructor( application: Application) {
    this.id = application.id;
    this.name = application.name;
    this.customerName = application.customerName;
    this.lastUpdate = application.lastUpdate;
    this.contactsIdentifiers = application.contactsIdentifiers;
    this.organizationalUnit = application.organizationalUnit;
    this.personsInChargeMSG = application.personsInChargeMSG;
    this.systemType = application.systemType;
    this.sectorIdentifier = application.sectorIdentifier;
  }

}
