import { Component, Input } from '@angular/core';
import { EvaluationstepInfo, EvaluationstepMeta } from '../../../types/evaluationstep';

@Component({
  selector: 'balm-info',
  templateUrl: './balm-info.component.html',
  styleUrls: ['./balm-info.component.scss']
})
export class BalmInfoComponent {

  @Input() evaluationstepName: string;
  @Input() evaluationstepMeta: EvaluationstepMeta;
  @Input() set evaluationstepinfo(value: EvaluationstepInfo) {
    this.what = value?.what?.replace(/<h3/g, '<h4').replace(/<\/h3>/g, '</h4>');
    this.why = value?.why?.replace(/<h2/g, '<h4').replace(/<\/h2>/g, '</h4>');
    this.how = value?.how?.replace(/<h5/g, '<h6').replace(/<\/h5>/g, '</h6>')
      .replace(/<h4/g, '<h5').replace(/<\/h4>/g, '</h5>')
      .replace(/<h3/g, '<h4').replace(/<\/h3>/g, '</h4>')
  }

  showOverallDetails = false;
  showWhat = false;
  showWhy = false;
  showHow = false;

  what: string;
  why: string;
  how: string;

  toggleOverallDetails() {
    this.showOverallDetails = !this.showOverallDetails;
  }

  toggleWhat() {
    this.showWhat = !this.showWhat;
  }

  toggleWhy() {
    this.showWhy = !this.showWhy;
  }

  toggleHow() {
    this.showHow = !this.showHow;
  }
}
