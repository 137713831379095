import { Component, Input, OnInit } from '@angular/core';
import { BitvScreenshotService } from './bitv-add-screenshot/bitv-screenshot.service';
import { Evaluationstep, Screenshot } from '../../../types/evaluationstep';

@Component({
  selector: 'nfa-bitv-screenshot',
  templateUrl: './bitv-screenshot.component.html',
  styleUrls: ['./bitv-screenshot.component.scss']
})
export class BitvScreenshotComponent implements OnInit {

  @Input() bitvEvaluationStep: Evaluationstep;

  constructor(private screenshotService: BitvScreenshotService) {
  }

  ngOnInit() {
    if (!this.bitvEvaluationStep.screenshots) {
      this.initScreenshots(this.bitvEvaluationStep.id);
    }
  }

  deleteScreenshot(screenshot: Screenshot) {
    this.screenshotService.delete(screenshot.id).subscribe(() => {
      this.initScreenshots(this.bitvEvaluationStep.id);
    });
  }

  onScreenshotCreated(newScreenshot: Screenshot) {
    if (this.bitvEvaluationStep.screenshots) {
      this.bitvEvaluationStep.screenshots.push(newScreenshot);
    } else {
      this.bitvEvaluationStep.screenshots = [];
      this.bitvEvaluationStep.screenshots.push(newScreenshot);
    }
  }

  private initScreenshots(bitvEvaluationStepId: number): void {
    this.bitvEvaluationStep.screenshots = [];
    this.screenshotService.getScreenshots(bitvEvaluationStepId).subscribe(data => {
        this.bitvEvaluationStep.screenshots = data.sort((a, b) => a.id - b.id);
      }
    );
  }

}
