<header>
<mat-toolbar class="mat-app-background mat-toolbar--no-padding mat-toolbar--fixed mat-elevation-z0" color="primary">
  <div class="brand">
    <img class="msg-logo" src="assets/images/msg-logo.svg" alt="Logo msg - IT Solutions & IT Beratung" routerLink="/applications" tabindex="-1">
  </div>
  <nav role="navigation" class="list-horizontal" aria-label="Hauptnavigation">
    <div class="nav-list hide-on-small-screens">
      <ng-container *ngFor="let item of mainNavigationRoutes">
        <a id="{{item.id}}"
           class="hide-on-small-screens nav-item"
           [routerLink]="[item.route]"
           routerLinkActive="active"
           mat-button
           style="font-size: 20px">
          <mat-icon inline="true" class="menu-icon">{{item.icon}}</mat-icon>
          {{item.content}}
        </a>
      </ng-container>
    </div>
    <div class="nav-list--end hide-on-small-screens">
      <ng-container *ngFor="let item of serviceNavigationRoutes">
        <a id="{{item.id}}"
           class="hide-on-small-screens nav-item"
           [routerLink]="[item.route]"
           routerLinkActive="active"
           mat-button
           style="font-size: 20px">
          <mat-icon inline="true" class="menu-icon">{{item.icon}}</mat-icon>
          {{item.content}}
        </a>
      </ng-container>
        <button mat-button
                data-cy="konto"
                [matMenuTriggerFor]="menu"
                class="nav-item account-button"
                *ngIf="loggedIn">
          <mat-icon inline="true">account_box</mat-icon>
          Konto
        </button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item
             class="context-menu-item"
             title="Benutzerkontoverwaltung öffnen"
             href="/auth/realms/qumap/account/"
             target="_blank">
            <mat-icon>perm_identity</mat-icon>
            <span>{{userDetails.firstName}} {{userDetails.lastName}}</span>
          </a>
          <a mat-menu-item
             class="context-menu-item"
             (click)="logoutUser()"
             data-cy="logout-button">
            <mat-icon>power_settings_new</mat-icon>
            <span>Logout</span>
          </a>
        </mat-menu>
        <a *ngIf="!loggedIn"
           class="nav-item account-button"
           routerLink="/"
           mat-button
           style="font-size: 20px"
           (click)="loginUser()">
          <mat-icon inline="true">login</mat-icon>
          Login
        </a>
    </div>
    <button mat-button class="show-on-small-screens menu-button" (click)="sidenav.toggle()">
      <mat-icon>{{isMenuOpen ? 'clear' : 'menu'}}</mat-icon>
    </button>
  </nav>
</mat-toolbar>
</header>
<mat-sidenav-container class="sidenav-container" fullscreen>
  <mat-sidenav #sidenav autoFocus="false" [(opened)]="isMenuOpen" class="sidenav" (click)="onSidenavClick()" role="navigation" aria-label="Hauptnavigation">
    <h2>Navigation</h2>
    <ng-container *ngFor="let item of mainNavigationRoutes">
      <a *ngIf="(item.authorization)" mat-button [routerLink]="[item.route]">
        <mat-icon inline="true" class="menu-icon">{{item.icon}}</mat-icon>
        {{item.content}}
      </a>
    </ng-container>
    <ng-container *ngFor="let item of serviceNavigationRoutes">
      <a *ngIf="(item.authorization)" mat-button [routerLink]="[item.route]">
        <mat-icon inline="true" class="menu-icon">{{item.icon}}</mat-icon>
        {{item.content}}
      </a>
    </ng-container>
    <h2>Konto</h2>
    <a mat-button title="Benutzerkontoverwaltung öffnen" *ngIf="loggedIn" href="/auth/realms/qumap/account/"
      target="_blank" style="text-decoration: none; color: white; width: auto;">
      <mat-icon inline="true" class="menu-icon">perm_identity</mat-icon>
      {{userDetails.firstName}} {{userDetails.lastName}}
    </a>
    <a mat-button style="width: auto;" *ngIf="loggedIn" (click)="logoutUser()">
      <mat-icon inline="true" class="menu-icon">power_settings_new</mat-icon>
      Logout
    </a>
    <a mat-button style="width: auto;" *ngIf="!loggedIn" (click)="loginUser()">
      <mat-icon inline="true" class="menu-icon">login</mat-icon>
      Login
    </a>
  </mat-sidenav>
  <mat-sidenav-content class="mat-sidenav-container--lean">
    <main>
      <div class="content">
        <router-outlet></router-outlet>
      </div>

    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
<footer data-cy="footer">
  <div class="footercontent">
    <div>
      <span>&#169; {{currentYear | date : 'YYYY'}} <br>msg systems ag</span>
    </div>
    <nav role="navigation" class="links" aria-label="Footer-Navigation">
      <ul>
        <li><a routerLink="/privacy-policy" routerLinkActive="current">Datenschutz</a></li>
        <li class="impressum"><a routerLink="/about" routerLinkActive="current">Impressum</a></li>
        <li><a href="mailto: support.qumap@msg.group" data-cy=support>Kontakt</a></li>
      </ul>
    </nav>
    <div>
      <span class="Version">Version:&MediumSpace;<br>{{buildVersion}}</span>
    </div>
  </div>
</footer>
