import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class ConfirmationDialogData {
  dialogType: 'help' | 'warning' | 'error' = 'help';
  header = 'Warnung';
  confirmOptionText = 'Ja';
  confirmOptionIcon: string | undefined = undefined;
  rejectOptionText: string | undefined = undefined;
  rejectOptionIcon: string | undefined = undefined;
  cancelOptionText = 'Nein';
  message: string;
  confirmationQuestion = 'Sind Sie wirklich sicher?';
  confirmationProvider: ConfirmationProvider | undefined = undefined;
}

export interface ConfirmationProvider {
  triggerConfirmation?: () => void;
  triggerRejection?: () => void;
}

@Component({
  selector: 'nfa-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  data: ConfirmationDialogData;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ConfirmationDialogData
  ) {
    this.data = data;
  }

  ngOnInit() {
    this.setMessages();
  }

  setMessages() {
    const displayMessage = document.querySelector('.display-message');
    const confirmationQuestionMessage = document.querySelector('.confirmation-question-message');
    displayMessage.innerHTML = this.data.message;
    confirmationQuestionMessage.innerHTML = this.data.confirmationQuestion;
  }

  getDialogTypeHint() {
    switch (this.data.dialogType) {
      case 'error': {
        return 'Fehler Dialogfeld';
      }
      case 'warning': {
        return 'Warnung Dialogfeld';
      }
      case 'help': {
        return 'Bestätigung Dialogfeld';
      }
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  confirm() {
    if(this.data.confirmationProvider?.triggerConfirmation !== undefined) {
      this.data.confirmationProvider.triggerConfirmation();
    }
    this.dialogRef.close(true);
  }

  reject() {
    if(this.data.confirmationProvider?.triggerRejection !== undefined) {
      this.data.confirmationProvider.triggerRejection();
    }
    this.dialogRef.close(true);
  }

  getIcon() {
    return `${this.data.dialogType}_outline`;
  }
}
