import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Metric } from '../../catalog/adapter/metric';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomApplicationNfr } from '../model/applicationNfr';

@Component({
  selector: 'nfa-create-custom-nfr',
  templateUrl: './create-custom-nfr.component.html',
  styleUrls: ['./create-custom-nfr.component.scss']
})
export class CreateCustomNfrComponent {

  saving = false;

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    formulation: new UntypedFormControl('', [Validators.required]),
    priority: new UntypedFormControl(''),
    criticality: new UntypedFormControl(''),
    protectionNeeds: new UntypedFormControl(''),
    basedOn: new UntypedFormControl(''),
    version: new UntypedFormControl(''),
    annotation: new UntypedFormControl(''),
    projectPhase: new UntypedFormControl('')
  });

  constructor(
    public dialogRef: MatDialogRef<CreateCustomNfrComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selectedMetric: Metric, applicationId: string }
  ) {
  }

  submit() {
    this.saving = true;
    if (this.form.valid) {
      const customNfr: CustomApplicationNfr = {
        ...this.form.value,
        active: true,
        metric: this.data.selectedMetric.identifier,
        application: this.data.applicationId
      };
      this.dialogRef.close(customNfr);
    } else {
      this.saving = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
