import { Component, OnInit } from '@angular/core';
import { BitvService } from '../bitv.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { BalmTestConfigurationOptions, ConfigurationOption } from '../types/configuration-options';
import { Bitv, BitvType, isBitvCompleted } from '../types/bitv';
import {
  ConfigurationObject,
  ConfigurationObjectImpl,
  Pruefgegenstand,
  TestObject,
  TestObjectImpl
} from '../types/pruefgegenstand';
import { HilfeFunktionen } from '../../shared/global';
import { ApplicationsService } from 'src/app/applications/applications.service';
import { UserAccessRight, UserAccessRightsService } from '../user-access-rights.service';
import { ApplicationReceived } from 'src/app/applications/model/applicationReceived';

@Component({
  selector: 'nfa-new-evaluation',
  templateUrl: './evaluation-configuration.component.html',
  styleUrls: ['./evaluation-configuration.component.scss']
})
export class EvaluationConfigurationComponent implements OnInit {

  saveButtonActive = true;
  cancelButtonActive = true;
  loading = true;

  application: ApplicationReceived;
  userAccessRights: UserAccessRight = undefined;
  bitv: Bitv;
  configurationOptions: BalmTestConfigurationOptions;
  selectedObjectType: ConfigurationOption | undefined = undefined;
  selectedEvaluationType: ConfigurationOption | undefined = undefined;
  selectedTestObjectType: string;
  selectedConfigurationObject: ConfigurationObject;
  matchingEvaluationTypes: Observable<ConfigurationOption[]>;
  configurationObjects: ConfigurationObject[] = [];
  configurationObjectsByType: ConfigurationObject[] = [];
  testObjects: TestObject[] = [];
  noEvaluationObjectsError: string;
  step3header: string;

  get notReadyToSave(): boolean {
    return this.selectedEvaluationType === undefined || this.selectedObjectType === undefined ||
      this.configurationObjectsByType.find(object => object.checked === true) === undefined;
  }

  constructor(
    private bitvService: BitvService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private applicationsService: ApplicationsService,
    private userAccessRightsService: UserAccessRightsService) {
  }

  ngOnInit(): void {
    const applicationid = this.activatedRoute.snapshot.paramMap.get('applicationid');
    const bitvId = this.activatedRoute.snapshot.paramMap.get('id');
    this.applicationsService.getApplicationById(applicationid).subscribe({
      next: application => {
        this.application = application;
        this.userAccessRightsService.checkAccessRight(this.application).subscribe({
          next: userAccessRights => {
            this.userAccessRights = userAccessRights;
            if (userAccessRights === UserAccessRight.WRITEACCES) {
              if (bitvId) {
                this.loadAndInitForUpdate(bitvId);
              } else {
                this.loadAndInit();
              }
            } else {
              if (bitvId) {
                this.navigateToDashboard(this.application.id, bitvId);
              } else if (this.router.navigate(['../new'])) {
                this.router.navigate(['/no-access-error-page'], {queryParams: {mode: 'noWriteAccess'}});
              } else {
                this.router.navigateByUrl('/empty');
              }
            }
          }
        });
      },
      error: err => {
        if (err.status === 403) {
          this.router.navigateByUrl('/no-access-error-page');
        } else if (err.status === 404) {
          this.router.navigateByUrl('/empty');
        }
      }
    });

    HilfeFunktionen.setApplicationTitle('BALM - Barrierefreiheitstest');
  }

  save() {
    this.createTestobjectsForBitv(this.configurationObjects);
    const bitvId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.testObjects.length !== 0) {
      if (bitvId) {
        this.updateConfiguration(bitvId);
      } else {
        this.saveConfiguration();
      }
    }
    this.saveButtonActive = false;
    this.cancelButtonActive = false;
  }

  cancelConfiguration() {
    // deactivate buttons
    this.saveButtonActive = false;
    this.cancelButtonActive = false;
    const bitvId = this.activatedRoute.snapshot.paramMap.get('id');
    if (bitvId) {
      return [`/applications/${this.application?.id}/balm/${bitvId}`];
    } else {
      return [`/applications/${this.application?.id}/balm`];
    }
  }

  navigateToDashboard(applicationId: string | number, bitvId: string | number): void {
    this.router.navigateByUrl(`/applications/${applicationId}/balm/${bitvId}`);
  }

  selectObjectType(objectType: ConfigurationOption) {
    this.selectedObjectType = objectType;
    this.changeType();
    this.selectedConfigurationObject = this.configurationObjectsByType[0];
    const matchingEvaluationTypes = this.getLinkedOptions(objectType, 'evaluationTypes');
    this.matchingEvaluationTypes = of(matchingEvaluationTypes);
    if (matchingEvaluationTypes.length === 1) {
      this.selectedEvaluationType = matchingEvaluationTypes[0];
    }
  }

  selectEvaluationType(evaluationType: ConfigurationOption) {
    this.selectedEvaluationType = evaluationType;
  }

  evaluationTypeDescriptionLines(description: string): string[] {
    return description.split('\\n');
  }

  getLinkedOptionText(objectType: ConfigurationOption, linkedOption: string): string {
    const linkedOptions = this.getLinkedOptions(objectType, linkedOption);

    return this.getLinkedOptionLabel(linkedOption, linkedOptions.length > 1) +
      linkedOptions.map(option => option.label).join(', ');
  }

  areaLabelForConfigurationType(objectType: ConfigurationOption): string {
    return (this.selectedObjectType !== undefined) ?
      (objectType.key === this.selectedObjectType?.key) ? objectType.label + ': ausgewählt' : objectType.label + ': nicht ausgewählt' :
      objectType?.label;
  }

  areaLabelForEvaluationType(evaluationType: ConfigurationOption): string {
    return (this.selectedEvaluationType !== undefined) ?
      (evaluationType.key === this.selectedEvaluationType?.key) ? evaluationType.label + ': ausgewählt' : evaluationType.label + ': nicht ausgewählt' :
      evaluationType?.label;
  }

  linkedOptionsCategory(objectType: ConfigurationOption): string[] {
    return Object.keys(objectType.linkedOptions);
  }

  createTestobjectsForBitv(config: ConfigurationObject[]) {
    config.forEach(configuration => {
      if (configuration.checked === true && configuration.type === this.selectedTestObjectType) {
        if (this.testObjects.find(object => object.testobjectId === configuration.pruefgegenstandId) === undefined) {
          this.testObjects.push(new TestObjectImpl(configuration));
        }
      }
    });
  }

  changeType() {
    this.selectType();
    this.filterByType();
  }

  filterByType() {
    this.configurationObjectsByType = this.configurationObjects.filter(object => object.type === this.selectedTestObjectType);
  }

  selectType() {
    switch (this.selectedObjectType?.label) {
      case 'Web':
        this.step3header = 'Webseiten auswählen und konfigurieren';
        this.noEvaluationObjectsError = 'Bitte erfassen Sie mindestens eine Webseite und ordnen Sie diese entsprechend zu, um einen Barrierefreiheitstest durchführen zu können.';
        this.selectedTestObjectType = 'WEB';
        break;
      case 'Dokumente':
        this.step3header = 'Dokumente auswählen und konfigurieren';
        this.noEvaluationObjectsError = 'Bitte erfassen Sie mindestens ein Dokument und ordnen Sie dieses entsprechend zu, um einen Barrierefreiheitstest durchführen zu können.';
        this.selectedTestObjectType = 'DOCUMENT';
        break;
      case 'Mobile Anwendung':
        this.step3header = 'Mobile Seiten auswählen und konfigurieren';
        this.noEvaluationObjectsError = 'Bitte erfassen Sie mindestens eine Mobile Seite und ordnen Sie diese entsprechend zu, um einen Barrierefreiheitstest durchführen zu können.';
        this.selectedTestObjectType = 'MOBILE_SITE';
        break;
      case 'Software':
        this.step3header = 'Softwareoberflächen auswählen und konfigurieren';
        this.noEvaluationObjectsError = 'Bitte erfassen Sie mindestens eine Softwareoberfläche und ordnen Sie diese entsprechend zu, um einen Barrierefreiheitstest durchführen zu können.';
        this.selectedTestObjectType = 'SOFTWARE';
        break;
      default:
        this.step3header = '';
        this.noEvaluationObjectsError = '';
        this.selectedTestObjectType = '';
        break;
    }
  }

  initConfigurationObjects(evaluationObjects: Pruefgegenstand[]) {
    this.configurationObjects.length = evaluationObjects.length;
    for (let i = 0; i < this.configurationObjects.length; i++) {
      this.configurationObjects[i] = new ConfigurationObjectImpl();
      this.configurationObjects[i].conformityLevel = 'AA';
      this.configurationObjects[i].label = evaluationObjects[i].name;
      this.configurationObjects[i].pruefgegenstandId = evaluationObjects[i].id;
      this.configurationObjects[i].type = evaluationObjects[i].contentType;
    }
  }

  initConfigurationObjectsForUpdate(bitvtestobjects: TestObject[], evaluationObjects: Pruefgegenstand[]) {
    this.configurationObjects.length = evaluationObjects.length;
    for (let i = 0; i < this.configurationObjects.length; i++) {
      this.configurationObjects[i] = new ConfigurationObjectImpl();
      let testobject: TestObject;
      testobject = bitvtestobjects.find(object => object.testobjectId === evaluationObjects[i].id);
      if (testobject !== undefined) {
        this.configurationObjects[i].conformityLevel = testobject.conformityLevel;
        this.configurationObjects[i].label = evaluationObjects[i].name;
        this.configurationObjects[i].pruefgegenstandId = evaluationObjects[i].id;
        this.configurationObjects[i].type = evaluationObjects[i].contentType;
        this.configurationObjects[i].checked = true;
      } else {
        this.configurationObjects[i].conformityLevel = 'AA';
        this.configurationObjects[i].label = evaluationObjects[i].name;
        this.configurationObjects[i].pruefgegenstandId = evaluationObjects[i].id;
        this.configurationObjects[i].type = evaluationObjects[i].contentType;
      }
    }
  }

  showEvaluationObjectDetails(object: ConfigurationObject) {

    this.selectedConfigurationObject = object;

  }

  showEvaluationObjectDetailsByCheckbox(object: ConfigurationObject) {
    if (object.checked === true) {
      this.selectedConfigurationObject = object;
    }
  }

  filterConformityLevelsByObjectType(conformityLevels: ConfigurationOption[], selectedObjectType: ConfigurationOption) {
    return (selectedObjectType.key === 'WEB') ? conformityLevels : conformityLevels.filter(conformityLevel => conformityLevel.key === 'AA');
  }

  private updateConfiguration(bitvId: string) {
    const bitvToSave = {
      ...this.bitv,
      objectType: this.selectedObjectType.key as BitvType,
      evaluationType: this.selectedEvaluationType.key,
      testObjects: this.testObjects
    };
    this.bitvService.updateBitvConfiguration('de', bitvToSave).subscribe(bitv => {
        this.saveButtonActive = true;
        this.cancelButtonActive = true;
        this.router.navigate([`/applications/${this.application.id}/balm/${bitv.id}`]);
      },
      error => {
        // TODO error handling
        this.saveButtonActive = true;
        this.cancelButtonActive = true;
      });

  }

  private saveConfiguration() {
    this.bitvService.createNewBitv({
      applicationId: Number(this.application.id),
      objectType: this.selectedObjectType.key,
      evaluationType: this.selectedEvaluationType.key,
      testObjects: this.testObjects
    }).subscribe({
      next: bitv => {
        this.saveButtonActive = true;
        this.cancelButtonActive = true;
        this.router.navigate([`/applications/${this.application.id}/balm/${bitv.id}`]);
      },
      error: error => {
        // TODO error handling
        this.saveButtonActive = true;
        this.cancelButtonActive = true;
      }
    });
  }

  private loadAndInit() {
    forkJoin([
      this.bitvService.getConfigurationOptions('de'), // TODO language
      this.bitvService.getPruefgegenstandByApplication(this.application.id)
    ]).subscribe(value => {
      const [configurationOptions, evaluationObjects] = value;
      this.configurationOptions = configurationOptions;
      this.initConfigurationObjects(evaluationObjects);
      this.loading = false;
    });
  }

  private loadAndInitForUpdate(bitvId: string) {
    forkJoin([
      this.bitvService.getBitvById(bitvId, 'de'),
      this.bitvService.getConfigurationOptions('de'),
      this.bitvService.getPruefgegenstandByApplication(this.application.id)
    ]).subscribe(value => {
      const [bitv, configurationOptions, evaluationObjects] = value;
      this.bitv = bitv;
      if (isBitvCompleted(bitv)) {
        this.navigateToDashboard(this.application.id, bitvId);
      }
      this.configurationOptions = configurationOptions;
      this.loading = false;
      this.selectedObjectType = configurationOptions.objectTypes
        .filter(objectType => objectType.key === bitv.objectType).pop();
      this.initConfigurationObjectsForUpdate(bitv.testObjects, evaluationObjects);
      this.changeType();
      this.showEvaluationObjectDetails(this.configurationObjectsByType[0]);
      this.matchingEvaluationTypes = of(this.getLinkedOptions(this.selectedObjectType, 'evaluationTypes'));
      this.selectedEvaluationType = configurationOptions.evaluationTypes
        .filter(evaluationType => evaluationType.key === bitv.evaluationType).pop();
    });
  }

  private getLinkedOptions(objectType: ConfigurationOption, linkedOption: string): ConfigurationOption[] {
    const matchingLinkedOption: ConfigurationOption[] = [];

    objectType?.linkedOptions[linkedOption]?.forEach(
      linkedOptionKey => matchingLinkedOption.push(
        this.configurationOptions[linkedOption]
          .find(option => option.key === linkedOptionKey)
      )
    );

    return matchingLinkedOption;
  }

  // FIXME: workaround as long as we don't use ng-translate or don't get the LinkedOption-Name from Backend
  private getLinkedOptionLabel(linkedOption: string, multipleOptions: boolean): string {
    switch (linkedOption) {
      case 'objectTypes':
        return multipleOptions ? 'Prüfgegenstandstypen: ' : 'Prüfgegenstandstyp: ';
      case 'evaluationTypes':
        return multipleOptions ? 'Prüfarten: ' : 'Prüfart: ';
      default:
        return '';
    }
  }

}
