<ng-container *ngFor="let key of Object.keys(testTools)">
  <div
    id="test-tools-{{key}}"
  >
    <h2 [innerHTML]="texts.sectionTitles[key] + (isRequired(key) ? ' *' : '')"></h2>
    <section>{{testTools[key].length !== 0 ? texts.covered : texts.uncovered}}</section>
  </div>
</ng-container>

<button
  mat-flat-button
  class="mat-mdc-unelevated-button-secondary"
  data-cy="edit-test-tools"
  *ngIf="canEdit"
  [disabled]="isBitvCompleted(bitv)"
  (click)="switchToTestToolsPage()"
>
  <mat-icon>build</mat-icon>
  {{texts?.edit}}
</button>
