import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import { Bitv, BitvType, isBitvCompleted } from 'src/app/bitv/types/bitv';
import { BalmTestobjectsOverviewLangPack } from './balm-testobjects-overview.lang.de';
import { BitvService } from '../../../bitv.service';
import { Language } from '../../../../app-state/settings';
import { LockUi, UiLockable } from '../../../../shared/utils/ui-lockable';
import { firstValueFrom } from 'rxjs';
import {Pruefgegenstand} from "../../../types/pruefgegenstand";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from '@angular/material/table';
import { TestObject } from 'src/app/bitv/types/pruefgegenstand';
import { MatPaginator } from '@angular/material/paginator';
import { Evaluationstep, checkIfStillCompliant } from 'src/app/bitv/types/evaluationstep';
import { EvaluationCriteria } from '../../balm-evaluation-criteria.enum';
@Component({
  selector: 'nfa-balm-testobjects-overview',
  templateUrl: './balm-testobjects-overview.component.html',
  styleUrls: ['./balm-testobjects-overview.component.scss']
})
export class BalmTestobjectsOverviewComponent implements OnInit, UiLockable {
  readonly isBitvCompleted = isBitvCompleted;
  readonly BitvType = BitvType;

  @Input()
  canEdit: boolean;

  @Input()
  bitv: Bitv;

  @Output()
  testsCompleted = new EventEmitter<void>();

  @ViewChild('testObjectsPaginator') paginator: MatPaginator;

  texts: BalmTestobjectsOverviewLangPack;
  uiLocked = false;
  dataSource: MatTableDataSource<TestObject> = new MatTableDataSource<TestObject>();
  pageSizeOptions: number[] = [5, 10, 15, 20];
  displayedColumns: string[] = ['name', 'conformityLevel', 'progress', 'result'];
  isConform: boolean = false;
  testobjectProgress: number = 0;

  private language: Language;

  constructor(private bitvService: BitvService) {
  }

  async ngOnInit() {
    this.language = Language.DEUTSCH;
    this.texts = await import(`./balm-testobjects-overview.lang.${this.language}`).then(module => module.texts);
    this.dataSource.data = this.bitv.testObjects.sort((a, b) => a.id - b.id);
    this.initPaginator();
  }

  @LockUi()
  async launchAutomatedTests() {
    await firstValueFrom(this.bitvService.triggerAutomatedTests(this.language, this.bitv.id));
    this.testsCompleted.emit();
  }

  private initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Prüfgegenstände pro Seite: ';
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.firstPageLabel = 'Erste Seite';
    this.paginator._intl.lastPageLabel = 'Letzte Seite';
    this.paginator._intl.nextPageLabel = 'Nächste Seite';
    this.paginator._intl.previousPageLabel = 'Vorherige Seite';
    this.paginator._intl.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return '0 von ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' von ' + length;
    };
  }

  calculateProgress(steps: Evaluationstep[]) {
    const evaluatedSteps: number = steps.filter(s => s.evaluationresult === EvaluationCriteria.no || s.evaluationresult === EvaluationCriteria.fulfilled || s.evaluationresult === EvaluationCriteria.notApplicable).length;
    this.testobjectProgress = evaluatedSteps / steps.length;
    return true;
  }

  isTestobjectCompleted(steps: Evaluationstep[]) {
    return steps.every(x => x.evaluationresult !== undefined && x.evaluationresult !== null);
  }

  isTestobjectConform(steps: Evaluationstep[]) {
    return this.isConform = checkIfStillCompliant(steps);
  }
}
