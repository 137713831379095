import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestMethodsComponent } from './test-methods.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { TestMethodsAdapterService } from './adapter/test-methods-adapter.service';
import { RouterModule } from '@angular/router';
import { CatalogComponent } from '../catalog/catalog/catalog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FEATURE_NAME, testMethodsReducer } from './test-methods-state/test-methods.reducer';
import { TestMethodsEffects } from './test-methods-state/test-methods.effects';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    TestMethodsComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, testMethodsReducer),
    EffectsModule.forFeature([TestMethodsEffects]),
    MatTabsModule,
    RouterModule,
    MatExpansionModule,
    MatTooltipModule,
    SharedModule,
    FormsModule,
    MatButtonModule,
    MatIconModule
  ],
  providers: [
    TestMethodsAdapterService,
    CatalogComponent],
  exports: [TestMethodsComponent]
})
export class TestMethodsModule { }
