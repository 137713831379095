import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class UsernameInterceptor implements HttpInterceptor {

  constructor(private keycloak: KeycloakService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.keycloak.loadUserProfile()).pipe(mergeMap(profile => {
      req = req.clone({
        setHeaders: {
          'access-log': btoa(JSON.stringify({username: this.keycloak.getUsername()}))
        }
      });
      return next.handle(req).pipe(catchError(
        error => {
          return throwError(error);
        })
      );
    }));
  }
}
