<nfa-mat-spinner-overlay
  *ngIf="pdfLoading"
  loadingText="PDF wird geladen ..."
  overlay="true"
></nfa-mat-spinner-overlay>

<div nfaDisableCopyPaste class="balm-overview-container">
  <div class="balm-overview"
       *ngIf="!serverError">
    <div class="balm-overview-header-container">
      <h2 id="balm-evaluation-overview" class="balm-overview-label">Barrierefreiheitstests</h2>
      <a mat-flat-button class="balm-overview-button" color="primary" routerLink="new" *ngIf="canUserEdit">
        <mat-icon>add</mat-icon>
        <span>Barrierefreiheitstest hinzufügen</span>
      </a>
    </div>
    <mat-spinner id="loadingEvaluations" *ngIf="evaluationsLoading || userAccessRight === undefined"></mat-spinner>
    <table mat-table
           tabindex="0"
           class="mat-elevation-z0"
           aria-labelledby="balm-evaluation-overview"
           data-cy="evaluations-table"
           [dataSource]="evaluationsDataSource"
           *ngIf="!evaluationsLoading && userAccessRight !== undefined">

      <!-- Test object type column -->
      <ng-container matColumnDef="object_type">
        <th mat-header-cell *matHeaderCellDef class="data-col--object_type">Prüfgegenstandstyp</th>
        <td mat-cell *matCellDef="let evaluation, let i = index" class="table-cell--with-icon">
          <mat-icon
            aria-label="Icon für {{objectTypeLabel(evaluation.objectType)}}">{{objectTypeIcon(evaluation.objectType)}}</mat-icon>
          <span>{{objectTypeLabel(evaluation.objectType)}}</span>
        </td>
      </ng-container>

      <!-- Evaluation type column -->
      <ng-container matColumnDef="evaluation_type">
        <th mat-header-cell *matHeaderCellDef class="data-col--evaluation_type"> Prüfart</th>
        <td mat-cell *matCellDef="let evaluation">{{evaluationTypeLabel(evaluation.evaluationType)}}</td>
      </ng-container>

      <!-- progress column -->
      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef class="data-col--progress">Fortschritt</th>
        <td mat-cell *matCellDef="let evaluation">
          <div class="table-cell--with-icon">
            <span *ngIf="calculateProgressPercentage(evaluation)">{{ evaluation.progress.percentage | percent}}</span>
            <mat-icon *ngIf="isStatusClosed(evaluation)" aria-hidden="false" aria-label="Abgeschlossen">lock</mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- created at column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef class="data-col--created"> Angelegt</th>
        <td mat-cell *matCellDef="let evaluation">{{evaluation.createdAt | date:'dd.MM.yyyy'}}</td>
      </ng-container>
      <!-- last change column -->
      <ng-container matColumnDef="changed">
        <th mat-header-cell *matHeaderCellDef class="data-col--changed"> Letzte Änderung</th>
        <td mat-cell *matCellDef="let evaluation">{{changedAtLabel(evaluation)}}</td>
      </ng-container>

      <!-- result column -->
      <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef class="data-col--result"> Ergebnis</th>
        <td mat-cell *matCellDef="let evaluation">
          <div *ngIf="isPercentage100(evaluation)" class="table-cell--with-icon">
            <span class="data-col--result__text">{{conformityResult(evaluation)}}</span>
            <mat-icon *ngIf="isEvaluationConform(evaluation)" class="conformity-indicator" aria-label="Konform">check
            </mat-icon>
            <mat-icon *ngIf="!isEvaluationConform(evaluation)" class="non-conformity-indicator"
                      aria-label="Nicht konform">close
            </mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- Action column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="data-col--actions">Aktionen</th>
        <td mat-cell *matCellDef="let evaluation; let i = index;">
          <button mat-icon-button [matMenuTriggerFor]="menu"
                  aria-label="Aktionen"
                  class="action-button"
                  [attr.data-cy]="'evaluations-action-button-'+i">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="wide-menu">
            <a mat-menu-item
               class="context-menu-item"
               data-cy="link-open-bitv"
               [routerLink]="openBitv(evaluation)"
               [attr.aria-label]="'Barrierefreiheitstest öffnen'">
              <mat-icon>accessible_forward</mat-icon>
              <span>Barrierefreiheitstest öffnen</span>
            </a>
            <a mat-menu-item
               class="context-menu-item"
               data-cy="link-pdf2"
               [attr.data-bitvId]=evaluation.id
               (click)="openBitvPdf(evaluation.id, 'Pruefbericht')">
              <mat-icon>picture_as_pdf</mat-icon>
              <span>Prüfbericht öffnen</span>
            </a>
            <a mat-menu-item
               class="context-menu-item"
               data-cy="link-pdf-fb"
               [attr.data-bitvId]=evaluation.id
               (click)="openBitvPdf(evaluation.id, 'Fehlerbericht')">
              <mat-icon>picture_as_pdf</mat-icon>
              <span>Fehlerbericht öffnen</span>
            </a>
          </mat-menu>
        </td>
      </ng-container>

      <ng-container *ngIf="evaluationsDataSource.data.length === 0" matColumnDef="noTestItems">
        <td mat-footer-cell id="keinePruefgegenstaendeVorhanden" *matFooterCellDef colspan="6"><i>Es sind keine
          Barrierefreiheitstests vorhanden.</i></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsEvaluationTable"></tr>
      <tr mat-row tabindex="0" *matRowDef="let row; columns: displayedColumnsEvaluationTable;"></tr>
      <ng-container *ngIf="evaluationsDataSource.data.length === 0">
        <tr mat-footer-row *matFooterRowDef="['noTestItems']"></tr>
      </ng-container>
    </table>
    <mat-paginator #evaluationPaginator
                   [length]="evaluations?.length"
                   [pageSize]="5"
                   [pageSizeOptions]="[5, 10, 15, 20]"
                   [showFirstLastButtons]="true"
                   aria-label="Barrierefreiheitstest auswählen"
    ></mat-paginator>
  </div>
  <div id="serverError"
       role="alert"
       tabindex="0"
       *ngIf="serverError">
    <div class="serverError">
      <mat-icon>warning_amber</mat-icon>
      <span>Leider ist ein Fehler beim Laden der Tabelle mit den Barrierefreiheitstests aufgetreten.</span>
    </div>
  </div>
</div>
