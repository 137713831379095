import * as ApplicationActions from './application.actions';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Branch } from '../../nfa-packages/adapter/branch';
import { RequirementsDocument } from '../model/requirementsDocument';
import { SystemType } from '../model/systemType';

export const APPLICATION_STATE = 'applicationState';

// State
export interface ApplicationState {
  branches: Branch[];
  branchesLoaded: boolean;
  documents: RequirementsDocument[];
  documentsLoaded: boolean;
  systemTypes: SystemType[];
  systemTypesLoaded: boolean;
  errors: string[];
  isExportingToJira: boolean;
  allNfasExported: boolean;
}

// Initialisierung
export const initialState: ApplicationState = {
  branches: [],
  branchesLoaded: false,
  documents: [],
  documentsLoaded: false,
  systemTypes: [],
  systemTypesLoaded: false,
  errors: [],
  isExportingToJira: false,
  allNfasExported: false
};

// Reducer-Funktion
export const applicationReducer = createReducer<ApplicationState, Action>(
  initialState,
  on(ApplicationActions.loadBranchesSuccess, (state, {branches}) => ({
    ...state,
    branches,
    branchesLoaded: true
  })),
  on(ApplicationActions.loadDocumentsSuccess, (state, {documents}) => ({
    ...state,
    documents,
    documentsLoaded: true
  })),
  on(ApplicationActions.loadSystemTypesSuccess, (state, {systemTypes}) => ({
    ...state,
    systemTypes,
    systemTypesLoaded: true
  })),
  on(ApplicationActions.exportToJiraSuccess, (state => ({
    ...state,
    isExportingToJira: false,
    allNfasExported: true
  }))),
  on(ApplicationActions.exportToJira, (state => ({
    ...state,
    isExportingToJira: true
  }))),
  on(ApplicationActions.exportToJiraFailure, (state => ({
    ...state,
    isExportingToJira: false,
    allNfasExported: false
  }))),
  on(ApplicationActions.nfasReadyToExport, (state => ({
    ...state,
    isExportingToJira: false,
    allNfasExported: false
  }))),
  on(ApplicationActions.resetJiraState, (state => ({
    ...state,
    isExportingToJira: false,
    allNfasExported: false
  })))
);

export function reducer(state: ApplicationState | undefined, action: Action) {
  return applicationReducer(state, action);
}

// Selektoren
export const selectApplicationState = createFeatureSelector(APPLICATION_STATE);
export const getBranches = createSelector(
  selectApplicationState,
  (state: ApplicationState) => state.branches
);
export const areBranchesLoaded = createSelector(
  selectApplicationState,
  (state: ApplicationState) => state.branchesLoaded
);
export const getDocuments = createSelector(
  selectApplicationState,
  (state: ApplicationState) => state.documents
);
export const areDocumentsLoaded = createSelector(
  selectApplicationState,
  (state: ApplicationState) => state.documentsLoaded
);
export const getSystemTypes = createSelector(
  selectApplicationState,
  (state: ApplicationState) => state.systemTypes
);
export const areSystemTypesLoaded = createSelector(
  selectApplicationState,
  (state: ApplicationState) => state.systemTypesLoaded
);

export const isExportingToJira = createSelector(
  selectApplicationState,
  (state: ApplicationState) => state.isExportingToJira
);

export const allNfasExported = createSelector(
  selectApplicationState,
  (state: ApplicationState) => state.allNfasExported
);
