<div mat-dialog-title data-cy="dialog-header" [attr.aria-label]="'Eingabe Dialogfeld'">
  <h1 mat-dialog-title id="pruefgegenstandHinzufuegenBearbeitenDialog" class="header-message">{{title}}</h1>
</div>
<p id="pflichtfeld">* Pflichtfelder</p>

<div id="serverInfo" *ngIf="localData['isPruefgegenstandInUse']" role="alert" tabindex="0">
  <div class="serverInfo">
    <mat-icon>info_outline</mat-icon>
    <p><span>Sie können den Typ nicht ändern, da dieser Prüfgegenstand mindestens einem Barrierefreiheitstest zugeordnet ist. Um den Typ zu ändern, müssen Sie die Zuordnung in den Konfigurationen der jeweiligen Barrierefreiheitstests aufheben.<br>
       Achten sie beim Ändern der URL darauf, dass sich die geänderte URL weiterhin auf <span>{{localData['pruefgegenstand'].typ == 'Dokument' ? 'dasselbe' : 'dieselbe'}}</span> {{localData.pruefgegenstand['typ']}}
      bezieht.</span></p>
  </div>
</div>

<form [formGroup]="form" class="dialog-form">
  <div *ngIf="localData['isPruefgegenstandInUse']" id="pruefgegenstandTypInUse" tabindex="0">
    Typ: {{localData.pruefgegenstand['typ']}}</div>
  <div *ngIf="!localData['isPruefgegenstandInUse']" id="pruefgegenstandTyp" class="pruefgegenstandTyp"
       aria-live="assertive">
    <label id="pruefgegenstandTypLabel">Typ *</label>
    <mat-radio-group aria-labelledby="pruefgegenstandTypLabel" aria-label="Typ auswählen"
                     class="pruefgegenstandTypRadioGroup" required formControlName="typ">
      <mat-radio-button id="radioButtonWebseite" class="pruefgegenstandTypRadioButton" value='Webseite'>Webseite
      </mat-radio-button>
      <mat-radio-button id="radioButtonDokument" class="pruefgegenstandTypRadioButton" value='Dokument'>Dokument
      </mat-radio-button>
      <mat-radio-button id="radioButtonSoftware" class="pruefgegenstandTypRadioButton" value='Softwareoberfläche'>
        Softwareoberfläche
      </mat-radio-button>
      <mat-radio-button id="radioButtonMobil" class="pruefgegenstandTypRadioButton" value='Mobile Seite'>Mobile Seite
      </mat-radio-button>
    </mat-radio-group>
    <mat-error id="errorTyp" *ngIf="typFormControl.hasError('required') && typFormControl.touched === true"> Bitte
      wählen Sie einen Typ aus.
    </mat-error>
  </div>
  <div id="eingabefelderPruefgegenstand">
    <mat-form-field id="name" appearance="outline">
      <mat-label id="labelName">Name</mat-label>
      <input id="inputName" matInput placeholder="Name" required formControlName="name">
      <mat-error id="errorName">Bitte geben Sie einen Namen ein.</mat-error>
    </mat-form-field>
    <mat-form-field id="url" appearance="outline">
      <mat-label id="labelURL">URL</mat-label>
      <input id="inputURL" matInput placeholder="URL (z. B. https://qumap.gbg.msg.team)"
             [required]="typFormControl.value === 'Webseite'" formControlName="url">
      <mat-error id="errorURL">{{errorMessage}}</mat-error>
    </mat-form-field>
  </div>
</form>
<div id="serverErrorConstraintValidation" *ngIf="serverError && serverErrorStatus === 400">
  <div class="serverError">
    <mat-icon>warning_amber</mat-icon>
    <span>Bitte geben Sie eine korrekte URL ein.</span>
  </div>
</div>
<div id="serverError" *ngIf="serverError && serverErrorStatus !== 400">
  <div class="serverError">
    <mat-icon>warning_amber</mat-icon>
    <span>Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</span>
  </div>
</div>
<mat-dialog-actions align="end" id="pruefgegenstandHinzufuegenBearbeitenDialogButtons">
  <button id="speichernButton" mat-flat-button
          class="mat-mdc-unelevated-button-primary" type="button" color="primary" [disabled]="saving"
          (click)="onSavePruefgegenstand()">
    <mat-icon>save</mat-icon>
    <span>Speichern</span>
  </button>
  <button id="abbrechenButton" mat-flat-button
          class="mat-mdc-unelevated-button-tertiary" [disabled]="saving" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
    <span>Abbrechen</span>
  </button>
</mat-dialog-actions>
