import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TestType } from './testtype';
import { catchError } from 'rxjs/operators';
import { TestDesignTechnique } from './testDesignTechnique';

@Injectable()
export class TestMethodsAdapterService {

  private apiUrl = '/nfabackend/webapi';

  constructor(private http: HttpClient) {
  }

  getTestTypes(language: string): Observable<TestType[]> {
    return this.http.get<TestType[]>(`${this.apiUrl}/${language}/testtypes`).pipe(
      catchError(() => throwError('Not able to load test types'))
    );
  }

  getTestDesignTechniques(language: string): Observable<TestDesignTechnique[]> {
    return this.http.get<TestDesignTechnique[]>(`${this.apiUrl}/${language}/testdesigntechniques`).pipe(
      catchError(() => throwError('Not able to load test design techniques'))
    );
  }
}
