import { Criterion } from './criterion';
import { Document } from './document';
import { Treenode, TreenodeCategory } from '../../shared/sidenav/treenode';
import { CriterionWrapper } from './criterion-wrapper';
import { Factor } from './factor';

export class FactorWrapper implements Treenode, Factor {
  description?: string;
  explanation?: string;
  identifier: string;
  criteria: Criterion[];
  documents: Document[];
  expandedIntern = false;
  children: Treenode[];
  authorization?: boolean;

  constructor(factor: Factor) {
    this.description = factor.description;
    this.explanation = factor.explanation;
    this.identifier = factor.identifier;
    this.criteria = factor.criteria;
    this.children = factor.criteria.map(criterion => new CriterionWrapper(criterion, this));
    this.documents = factor.documents;
    this.authorization = true;
  }

  get name() {
    return `${this.identifier}  ${this.description}`;
  }

  get expanded() {
    return this.expandedIntern;
  }

  set expanded(expanded: boolean) {
    this.expandedIntern = expanded;
  }
  get category() {
    return TreenodeCategory.NODE;
  }
}



