import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BitvScreenshotService } from './bitv-screenshot.service';
import { Screenshot } from '../../../../types/evaluationstep';
import { BitvImageSelectionComponent } from '../bitv-image-selection/bitv-image-selection.component';

@Component({
  selector: 'nfa-bitv-add-screenshot',
  templateUrl: './bitv-add-screenshot.component.html',
  styleUrls: ['./bitv-add-screenshot.component.scss']
})
export class BitvAddScreenshotComponent {

  // tslint:disable-next-line:variable-name
  _screenshot: Screenshot;
  comment: string;
  createNew = true;

  @ViewChild(BitvImageSelectionComponent) imageSelection: BitvImageSelectionComponent;
  @Input() bitvEvaluationStepId: number;

  @Output() screenshotChange = new EventEmitter<Screenshot>();
  fileErrorMsg: string;
  files: File[];

  @Input() set screenshot(existingScreenshot: Screenshot) {
    if (existingScreenshot) {
      this.createNew = false;
      this._screenshot = existingScreenshot;
      this.comment = existingScreenshot.comment;
    }
  }

  constructor(
    private screenshotService: BitvScreenshotService) {
  }

  uploadScreenshot() {
    if (this.createNew) {
      this.screenshotService.create(this.bitvEvaluationStepId, this.imageSelection.file, this.comment).subscribe(
        (createdScreenshot: Screenshot) => {
          this.screenshotChange.emit(createdScreenshot);
          this.resetFields();
        }
      );
    } else {
      this.screenshotService.update(this._screenshot.id, this.imageSelection.file, this.comment).subscribe(
        (updatedScreenshot: Screenshot) => {
          this.screenshotChange.emit(updatedScreenshot);
        }
      );
    }
  }

  dismiss() {
    if (this._screenshot) {
      this._screenshot.editMode = false;
    } else {
      this.resetFields();
    }
  }

  resetFields() {
    this.imageSelection.file = null;
    this.comment = null;
  }
}
