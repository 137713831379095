<ng-container *ngIf="loading">
  <div style="display: grid; justify-content: center; margin-top: 10%;">
    <mat-spinner [diameter]="100"></mat-spinner>
    <span>wird geladen ...</span>
  </div>
</ng-container>

<ng-container *ngIf="pdfLoading">
  <div style="display: flex; flex-direction: column; align-items: center; position: absolute; top: 50%; left: 50%;">
    <mat-spinner [diameter]="100"></mat-spinner>
    <span>PDF wird geladen ...</span>
  </div>
</ng-container>

<ng-container *ngIf="!loading">
  <div class="page-header mobile">
    <div class="page-header__heading">
      <button mat-flat-button class="mat-mdc-unelevated-button-primary mobile" color="primary"
              (click)="navbarExpanderClick(sharedSidenav)"
              aria-label="Navigationsbereich ein/ausblenden">
        <mat-icon class="navbar__expand-toggle--expanded icon-no-margin" *ngIf="navbarExpanded">chevron_left</mat-icon>
        <mat-icon class="navbar__expand-toggle--collapsed icon-no-margin" *ngIf="!navbarExpanded">chevron_right</mat-icon>
      </button>
      <h1 data-cy="barrierefreiheitstestHeader" id="testtitle" *ngIf="!!bitv"
      matTooltip="{{evaluationTypeLabel(bitv?.evaluationType)}} {{bitv?.createdAt | date:'dd.MM.yyyy'}} {{application ? 'für' : ''}} {{application?.name}}">
        {{evaluationTypeLabel(bitv?.evaluationType)}}
      </h1>
      <button mat-icon-button
              aria-label="Hilfe"
              class="helpBtn helpIcon"
              (click)="onHelpButtonClick()">
        <mat-icon class="helpButtonIcon" matTooltip="Hilfe">
          help_outline
        </mat-icon>
      </button>
    </div>
    <div class="page-header__middle">
      <mat-form-field class="testobject"
                      appearance="outline">
        <mat-select data-cy="prüfgegenstandSelect"
                    name="prüfgegenstand"
                    (selectionChange)="save(); onPruefgegenstandChange($event)"
                    aria-label="Prüfgegenstand"
                    [value]="selected">
          <mat-option *ngFor="let evalObject of bitv?.bitvEvaluationStepsByTestobject"
                      id="prüfgegenstandSelect{{evalObject?.name}}" [value]="evalObject.testobjectid">
            {{evalObject?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="page-header__actions">
      <button mat-flat-button
              data-cy="back-to-dashboard-button"
              [disableRipple]="true"
              class="mat-mdc-unelevated-button-primary back-to-overview-button"
              (click)="back()">
        <mat-icon>arrow_left</mat-icon>
        Zurück zur Übersicht
      </button>
    </div>
  </div>

<!--  <div class="toggles">-->
<!--    <mat-slide-toggle labelPosition="before" disabled>Nur meine Prüfschritte anzeigen</mat-slide-toggle>-->
<!--    <mat-slide-toggle labelPosition="before" disabled>Nur bewertbare Prüfschritte anzeigen</mat-slide-toggle>-->
<!--  </div>-->

  <mat-sidenav-container class="mobile-scroll">
    <mat-sidenav #sharedSidenav mode="side" [opened]="navbarExpanded" class="mat-sidenav--evaluation-steps">

      <nfa-sidenav [treeRoots]="headers"
                   [documentIdentifier]="documentIdentifier"
                   [showStatus]="true"
                   [selectedItem]="selectedTreenode"
                   (itemSelected)="save(); initSelectedCategoriesAndEvaluationStepsByTreenode($event)">
      </nfa-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="mat-sidenav-container--leanbalm">
      <div>
        <div class="mobile_header mobile-only">
          <div class="mobile_header__title">
            <h1 data-cy="barrierefreiheitstestHeader" id="testtitle" *ngIf="!!bitv"
                matTooltip="{{evaluationTypeLabel(bitv?.evaluationType)}} {{bitv?.createdAt | date:'dd.MM.yyyy'}} {{application ? 'für' : ''}} {{application?.name}}">
              {{evaluationTypeLabel(bitv?.evaluationType)}}
            </h1>
            <button mat-icon-button
                    aria-label="Hilfe"
                    class="helpBtn helpIcon"
                    (click)="onHelpButtonClick()">
              <mat-icon class="helpButtonIcon" matTooltip="Hilfe">
                help_outline
              </mat-icon>
            </button>
          </div>
          <div class="mobile_header__action">
            <button mat-flat-button
                    data-cy="back-to-dashboard-button-mobile"
                    [disableRipple]="true"
                    class="mat-mdc-unelevated-button-primary back-to-overview-button"
                    (click)="back()">
              <mat-icon>arrow_left</mat-icon>
              Zurück zur Übersicht
            </button>
          </div>
          <div class="mobile_header__testobject">
            <mat-form-field class="testobject"
                            appearance="outline">
              <mat-select data-cy="prüfgegenstandSelect-mobile"
                          name="prüfgegenstand"
                          (selectionChange)="save(); onPruefgegenstandChange($event)"
                          aria-label="Prüfgegenstand"
                          [value]="selected">
                <mat-option *ngFor="let evalObject of bitv?.bitvEvaluationStepsByTestobject"
                            id="prüfgegenstandSelect{{evalObject?.name}}" [value]="evalObject.testobjectid">
                  {{evalObject?.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>

        <balm-evaluation-progression
          *ngIf="allSelectedEvaluationsteps"
          [bitv]="bitv"
          [compliant]="compliant"
          [evaluationstepsCount]="allSelectedEvaluationsteps.length"
          [notApplicableCount]="currentNotApplicableCount"
          [conformCount]="currentFulfilledCount"
          [notConformCount]="currentNoCount"
          [openCount]="currentOpenCount">
        </balm-evaluation-progression>
        <balm-content
          *ngIf="selectedCategory && selectedEvaluationsteps"
          [categoriesHeaders]="headers"
          [bitv]="bitv"
          [categoryName]="selectedCategory.name"
          [selectedevaluationstep]="selectedEvaluationstep"
          [allevaluationsteps]="allSelectedEvaluationsteps"
          (stepUpdated)="stepUpdated()"
          (nextOrBackClicked)="save(); initSelectedCategoriesAndEvaluationStepsByEvaluationStep($event);"
          (selectNextItem)="save(); initSelectedCategoriesAndEvaluationStepsByTreenode($event);"
          (emitUpdatedProgress)="updateProgress()">
        </balm-content>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<ng-template #noContent>Es können keine Daten geladen werden!</ng-template>
