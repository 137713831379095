<div class="page-header">
  <h1>{{texts?.pageTitle}}</h1>
</div>

<div class="content-container">
  <nfa-mat-spinner-overlay
    *ngIf="loading"
    [loadingText]="this.texts?.loading"
  >
  </nfa-mat-spinner-overlay>

  <form [formGroup]="formRoot" *ngIf="!loading">
    <section class="tools-section">
      <mat-card
        *ngFor="let type of Object.keys(formRoot.controls)"
        formGroupName="{{type}}"
        id="tool-{{type}}"
      >
        <mat-card-header>
          <h2 [innerHTML]="texts.cardTitles[type]"></h2>
        </mat-card-header>
        <mat-card-content>
          <div
            *ngFor="let tool of formRoot.controls[type].controls as toolSection; let i = index"
            formGroupName="{{i}}"
            class="tool-instance"
            id="tool-{{type}}-{{i}}"
          >
            <mat-form-field
              *ngFor="let control of [tool.controls.name]"
              appearance="outline"
              class="tool-name"
              [ngClass]="{'noHover':!this.canEdit}"
              subscriptSizing="dynamic"
            >
              <mat-label [innerHtml]="texts.inputPlaceholders[type]"></mat-label>
              <input
                matInput
                #toolName
                formControlName="name"
                id="tool-{{type}}-{{i}}-name"
                [readonly]="!this.canEdit"
                maxlength="{{nameMaxLength}}"
                [errorStateMatcher]="duplicateEntryErrorStateMatcher"
              />
              <mat-hint *ngIf="toolName.value.length > nameHintTriggerLength" align="end">
                {{toolName.value.length}} / {{nameMaxLength}}
              </mat-hint>
              <mat-error *ngFor="let error of Object.values(tool.errors || {})" [innerHtml]="error"></mat-error>
              <mat-error *ngFor="let error of Object.values(control.errors || {})" [innerHtml]="error"></mat-error>
            </mat-form-field>

            <mat-form-field
              *ngFor="let control of [tool.controls.version]"
              appearance="outline"
              class="tool-version"
              [ngClass]="{'noHover':!this.canEdit}"
              subscriptSizing="dynamic"
            >
              <mat-label [innerHtml]="texts.inputPlaceholders.version"></mat-label>
              <input
                matInput
                #toolVersion
                formControlName="version"
                id="tool-{{type}}-{{i}}-version"
                [readonly]="!this.canEdit"
                maxlength="{{versionMaxLength}}"
                [errorStateMatcher]="duplicateEntryErrorStateMatcher"
              />
              <mat-hint *ngIf="toolVersion.value.length > versionHintTriggerLength" align="end">
                {{toolVersion.value.length}} / {{versionMaxLength}}
              </mat-hint>
              <mat-error *ngFor="let error of Object.values(control.errors || {})" [innerHtml]="error"></mat-error>
            </mat-form-field>
            <button
              *ngIf="canEdit && toolSection.length !== (i + 1)"
              mat-icon-button
              title="{{getDeleteButtonTitle(type, i)}}"
              type="button"
              id="tool-{{type}}-{{i}}-loeschen"
              class="deleteButton mat-mdc-unelevated-button-secondary"
              [disabled]="!this.canEdit"
              (click)="deleteTool(type, i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </section>

    <section class="page-actions">
      <button
        type="submit"
        mat-flat-button
        id="save-button"
        class="mat-mdc-unelevated-button-primary"
        color="primary"
        [disabled]="!canSave()"
        (click)="triggerConfirmation()"
      >
        <mat-icon>save</mat-icon>
        {{texts.save}}
      </button>
      <button
        id="cancel-button"
        mat-flat-button
        class="mat-mdc-unelevated-button-tertiary"
        [disabled]="uiLocked"
        [routerLink]="[returnAddress]"
      >
        <mat-icon>close</mat-icon>
        {{texts.cancel}}
      </button>
    </section>

  </form>

</div>
