<div class="page-header">
  <div class="page-header__heading">
    <h1 nfaDisableCopyPaste>Testverfahren nach ISO/IEC 29119</h1>
    <button mat-icon-button aria-label="Hilfe" data-cy="helpBtn" class="helpBtn" (click)="onHelpButtonClick()">
      <mat-icon class="helpButtonIcon" matTooltip="Hilfe">
        help_outline
      </mat-icon>
    </button>
  </div>
</div>
<!--necessary for first initialization of MathJax-->
<mathjax [content]='' class="box"></mathjax>

<div class="testmethods-content-container">
  <mat-tab-group nfaDisableCopyPaste class="firstLevelTab" [(selectedIndex)]="selectedValue" mat-stretch-tabs>
    <div *ngIf="testTypesData$ | async as testTypesData">
      <mat-tab #testTypes label="Testarten">
        <mat-expansion-panel [id]="getTestTypeIdentifier(testType.identifier)" *ngFor="let testType of testTypesData">
          <mat-expansion-panel-header class="testMethodsPanelTitle">
            <mat-panel-title>
              <h3>{{ testType.labelKey }}</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="testMethodContent" nfaDisableCopyPaste>
            <div class="testMethodContentTitle">
              Definition:
            </div>
            <div class="testMethodContentDescription" [innerHTML]="testType.definitionKey"></div>
            <div class="testMethodContentTitle" *ngIf="testType.criteria.length === 1">Kriterium:</div>
            <div class="testMethodContentTitle" *ngIf="testType.criteria.length > 1">Kriterien:</div>
            <div *ngIf="testType.criteria.length === 1">
              <a *ngFor="
          let criterion of testType.criteria;
          let i = index
        " [routerLink]="['/catalog']" [queryParams]="{ criterion: criterion.identifier }">
                {{criterion.identifier}}
                {{criterion.description}}</a>
            </div>
            <div class="criteriaList" *ngIf="testType.criteria.length > 1">
              <li *ngFor="
              let criterion of testType.criteria;
              let i = index
            ">
                <a [routerLink]="['/catalog']"
                   [queryParams]="{ criterion: criterion.identifier }">{{criterion.identifier}}
                  {{criterion.description}}</a>
              </li>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-tab>
    </div>
    <div *ngIf="testDesignTechniqueData$ | async as testDesignTechniqueData">
      <mat-tab #testDesignTechniques label="Testentwurfsmethoden">
        <mat-expansion-panel *ngFor="let testDesignTechnique of testDesignTechniqueData">
          <mat-expansion-panel-header class="testMethodsPanelTitle">
            <mat-panel-title>
              <h3>{{ testDesignTechnique.labelKey }}</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="testMethodContent" nfaDisableCopyPaste>
            <div class="testMethodContentTitle">
              Art:
            </div>
            <div class="testMethodContentDescription">
              <!--additional span for beeter tooltip position-->
              <span matTooltip="{{testDesignTechnique.type.definitionKey}}">
              {{testDesignTechnique.type.labelKey}}
            </span>
            </div>
            <div class="testMethodContentTitle">
              Definition:
            </div>
            <div class="testMethodContentDescription">
              <div [innerHTML]="testDesignTechnique.definitionKey">
              </div>
              <mat-tab-group *ngIf="testDesignTechnique.identifier === '5'"
                             [(selectedIndex)]="combinatorialTestDesignTechniquesVariantValue" class="testdesignmethod-tab">
                <mat-tab *ngFor="let variant of testDesignTechnique.labelVariantKeys | keyvalue; let i = index"
                         [label]="testDesignTechnique.labelVariantKeys[i]">
                  <div [innerHTML]="testDesignTechnique.definitionVariantKeys[i]"></div>
                </mat-tab>
              </mat-tab-group>
              <mat-tab-group *ngIf="testDesignTechnique.identifier === '17'" [(selectedIndex)]="dataFlowTestVariantValue"
                             class="testdesignmethod-tab">
                <mat-tab *ngFor="let variant of testDesignTechnique.labelVariantKeys | keyvalue; let i = index"
                         [label]="testDesignTechnique.labelVariantKeys[i]">
                  <div [innerHTML]="testDesignTechnique.definitionVariantKeys[i]"></div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="testMethodContentTitle">Testabdeckungsmessung:</div>
            <div class="testMethodContentDescription">
              <mathjax [content]="testDesignTechnique.testCoverageMeasurementKey" class="box"></mathjax>
              <span *ngIf="testDesignTechnique.identifier === '5'"
                    [innerHTML]="testDesignTechnique.testCoverageVariantKeys[combinatorialTestDesignTechniquesVariantValue]"></span>
              <span *ngIf="testDesignTechnique.identifier === '17'"
                    [innerHTML]="testDesignTechnique.testCoverageVariantKeys[dataFlowTestVariantValue]"></span>
            </div>
            <div class="testMethodContentTitle" *ngIf="testDesignTechnique.criteria.length === 1">Kriterium:</div>
            <div class="testMethodContentTitle" *ngIf="testDesignTechnique.criteria.length > 1">Kriterien:</div>
            <div *ngIf="testDesignTechnique.criteria.length === 1">
              <a *ngFor="
              let criterion of testDesignTechnique.criteria;
              let i = index
            " [routerLink]="['/catalog']" [queryParams]="{ criterion: criterion.identifier }">
                {{criterion.identifier}}
                {{criterion.description}}</a>
            </div>
            <div class="criteriaList" *ngIf="testDesignTechnique.criteria.length > 1">
              <li *ngFor="
                  let criterion of testDesignTechnique.criteria;
                  let i = index
                ">
                <a [routerLink]="['/catalog']"
                   [queryParams]="{ criterion: criterion.identifier }">{{criterion.identifier}}
                  {{criterion.description}}</a>
              </li>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-tab>
    </div>
  </mat-tab-group>
</div>

