import { Language } from './settings';
import * as AppActions from './app.actions';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

export const APP_STATE = 'appState';

// State
export interface AppState {
  language: Language;
}

// Initialisierung
export const initialState: AppState = {
  language: Language.DEUTSCH,
};

// Reducer-Funktion
export const appReducer = createReducer<AppState, Action>(
  initialState,
  on(AppActions.changeLanguange, (state, {language}) => ({...state, language})),
);

export function reducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}

// Selektoren
export const selectAppState = createFeatureSelector(APP_STATE);
export const getCurrentLanguage = createSelector(selectAppState, (state: AppState) => state.language);
