import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable(
  {providedIn: 'root'}
)
export class BitvScreenshotPasteService {

  private screenshotPastedSource = new Subject<File>();
  screenshotPaste$ = this.screenshotPastedSource.asObservable();

  constructor() {
  }

  pasteScreenshot(file: File) {
    this.screenshotPastedSource.next(file);
  }
}
