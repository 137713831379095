import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BitvService } from '../bitv.service';
import { checkIfStillCompliant, Evaluationstep } from '../types/evaluationstep';
import { Bitv, BitvEvaluationStepsByTestobject, isBitvCompleted } from '../types/bitv';
import { EvaluationCriteria } from './balm-evaluation-criteria.enum';
import { forkJoin, Observable, tap } from 'rxjs';
import { Treenode, TreenodeCategory } from 'src/app/shared/sidenav/treenode';
import { HilfeFunktionen } from '../../shared/global';
import { ApplicationsService } from '../../applications/applications.service';
import { ApplicationReceived } from '../../applications/model/applicationReceived';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BalmTestConfigurationOptions, evaluationTypeLabel } from '../types/configuration-options';
import { QumapHelpService } from 'src/app/qumap-help/qumap-help.service';
import { MatDialog } from '@angular/material/dialog';
import { Pruefgegenstand } from '../types/pruefgegenstand';
import { UserAccessRight, UserAccessRightsService } from '../user-access-rights.service';
import { UnsavedChangesProvider } from '../../guards/unsaved-changes.guard';
import { ConfirmationProvider } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatSelectChange } from '@angular/material/select';
import { BalmStepsHelper } from '../balm-steps-helper/balm-steps-helper';
import { SaveConfSnackbarComponent } from "../../shared/save-confirm-snackbar/save-confirm-snackbar-component";

@Component({
  selector: 'balm-evaluation',
  styleUrls: ['./balm-evaluation.component.scss'],
  templateUrl: './balm-evaluation.component.html'
})
export class BalmEvaluationComponent implements OnInit, UnsavedChangesProvider, ConfirmationProvider {

  readonly LANGUAGE = 'de';
  readonly completionHint = '';

  bitv: Bitv;
  application: ApplicationReceived;
  userAccessRights: UserAccessRight = undefined;
  allSelectedEvaluationsteps: Evaluationstep[];
  selectedEvaluationsteps: Evaluationstep[];
  selectedEvaluationstep: Evaluationstep;
  bitvCategories: Treenode[];
  selectedCategory: Treenode;
  selectedCategorysParent: Treenode;
  selectedTreenode: Treenode | null = null;

  compliant;
  currentFulfilledCount: number;
  currentNoCount: number;
  currentNotApplicableCount: number;
  currentOpenCount: number;

  allOpenCount: number;
  allFulfilledCount: number;
  allNoCount: number;
  allNotApplicableCount: number;
  allEvaluationstepsCount: number;

  navbarExpanded: boolean;
  documentIdentifier: string;
  headers: Treenode[] = [];
  selectedTestObject: BitvEvaluationStepsByTestobject;

  selected: number;
  loading = true;
  pdfLoading = false;

  private configurationOptions: BalmTestConfigurationOptions;
  private unsavedChanges = false;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private bitvService: BitvService,
              private applicationsService: ApplicationsService,
              private userAccessRightsService: UserAccessRightsService,
              private snackBar: MatSnackBar,
              private qumapHelpService: QumapHelpService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    HilfeFunktionen.setApplicationTitle('BALM - Barrierefreiheitstest');
    const params = this.activatedRoute.snapshot.paramMap;

    // init bitv and allEvaluations
    const applicationID = params.get('applicationid');
    const bitvID = params.get('id');
    const bitvTestobjectID = params.get('testobjectid');
    this.applicationsService.getApplicationById(applicationID).subscribe({
      next: application => {
        this.application = application;
        this.userAccessRightsService.checkAccessRight(this.application).subscribe({
          next: userAccessRights => {
            this.userAccessRights = userAccessRights;
            if (userAccessRights === UserAccessRight.WRITEACCES) {
              this.handleWriteAccess(applicationID, bitvID, bitvTestobjectID);
            } else {
              this.handleReadAccess(bitvID);
            }
          }
        });
      },
      error: err => {
        if (err.status === 403) {
          this.router.navigateByUrl('/no-access-error-page');
        } else if (err.status === 404) {
          this.router.navigateByUrl('/empty');
        }
      }
    });
  }

  @HostListener('window:beforeunload')
  hasNoUnsavedChanges(): boolean {
    return !this.unsavedChanges;
  }

  triggerConfirmation() {
    this.save();
  }

  handleWriteAccess(applicationID, bitvID, testobjectID) {
    forkJoin([
      this.bitvService.getBitvCategories(this.LANGUAGE),
      this.bitvService.getBitvById(bitvID, this.LANGUAGE),
      this.bitvService.getConfigurationOptions(this.LANGUAGE),
      this.bitvService.getPruefgegenstandByApplication(applicationID)
    ]).subscribe({
      next: ([categories, bitv, configurationOptions, testObjects]) => {
        if (isBitvCompleted(bitv)) {
          this.navigateToDashboard(this.application.id, bitv.id);
          return;
        }
        this.navbarExpanded = HilfeFunktionen.initialNavbarExpanded();
        this.bitvCategories = categories;
        this.bitv = bitv;
        if (this.bitv.bitvEvaluationStepsByTestobject.map(to => to.testobjectid).includes(parseInt(testobjectID))) {
          this.selectedTestObject = this.bitv.bitvEvaluationStepsByTestobject.find(to => to.testobjectid === parseInt(testobjectID));
          this.selected = parseInt(testobjectID);
        } else if (isNaN(parseInt(testobjectID))) {
          let testObject = this.bitv.bitvEvaluationStepsByTestobject[0];
          this.selectedTestObject = testObject;
          this.selected = testObject.testobjectid;

          window.history.replaceState({}, '', `/applications/${this.bitv.applicationId}/balm/${this.bitv.id}/testobjects/${testObject.testobjectid}`);
        } else {
          this.router.navigateByUrl('/empty')
        }
        this.configurationOptions = configurationOptions;
        this.allSelectedEvaluationsteps = BalmStepsHelper.sortEvaluationStepsByNumber(this.selectedTestObject.bitvEvaluationSteps);
        this.mapNamesToTestObjects(testObjects);

        this.setProgressionInfo();
        this.headers = BalmStepsHelper.assignEvaluationStepsToCategories(this.allSelectedEvaluationsteps, this.bitvCategories);
        const rootParent: Treenode = {
          name: 'root',
          id: -1,
          expanded: true,
          category: TreenodeCategory.ROOT,
          authorization: true,
          children: this.headers
        };
        this.headers.forEach(header => header.parent = rootParent);

        this.initSelectedCategoriesAndEvaluationStepsByEvaluationStep(this.allSelectedEvaluationsteps[0]);
        this.loading = false;
      },
      error: err => {
        if (err.status === 403) {
          this.router.navigateByUrl('/no-access-error-page');
        } else if (err.status === 404) {
          this.router.navigateByUrl('/empty');
        }
      }
    });
  }

  handleReadAccess(bitvID) {
    this.navigateToDashboard(this.application.id, bitvID);
  }

  mapNamesToTestObjects(testObjects: Pruefgegenstand[]) {
    this.bitv.bitvEvaluationStepsByTestobject.forEach(testObject => {
      const temp = testObjects.find((obj) => {
        return obj.id === testObject.testobjectid;
      });
      testObject.name = temp?.name;
    });
  }

  onPruefgegenstandChange(event: MatSelectChange | undefined): void {
    if (event !== undefined) {
      window.history.replaceState({}, '', `/applications/${this.bitv.applicationId}/balm/${this.bitv.id}/testobjects/${event.value}`);
      this.selectedTestObject = this.bitv.bitvEvaluationStepsByTestobject.find(testObj => {
        return testObj.testobjectid == event.value;
      });
    }
    this.allSelectedEvaluationsteps = BalmStepsHelper.sortEvaluationStepsByNumber(this.selectedTestObject?.bitvEvaluationSteps);

    this.setProgressionInfo();

    if (this.selectedTestObject.lastEdit) {
      this.selectedEvaluationstep = this.selectedTestObject.lastEdit;
    } else {
      this.selectedEvaluationstep = this.allSelectedEvaluationsteps[0];
    }

    this.initSelectedCategoriesAndEvaluationStepsByEvaluationStep(this.selectedEvaluationstep);
    this.refreshSidenav();
  }

  refreshSidenav() {
    this.headers = BalmStepsHelper.assignEvaluationStepsToCategories(this.allSelectedEvaluationsteps, this.bitvCategories);
  }

  initSelectedCategoriesAndEvaluationStepsByTreenode(selectedItem: Treenode) {
    const selectedEvaluationStep = this.allSelectedEvaluationsteps
      .find(evalItem => evalItem.evaluationStep.id === selectedItem.id);
    this.initSelectedCategoriesAndEvaluationStepsByEvaluationStep(selectedEvaluationStep);
  }

  initSelectedCategoriesAndEvaluationStepsByEvaluationStep(evaluationStep: Evaluationstep) {
    this.selectedTestObject.lastEdit = evaluationStep;

    // init selected evaluation steps
    this.selectedEvaluationstep = evaluationStep;
    this.selectedEvaluationsteps = this.allSelectedEvaluationsteps
      .filter(evalItem => evalItem.evaluationStep.bitvCategorieId === evaluationStep.evaluationStep.bitvCategorieId);

    // init selected categories
    this.selectedCategory = this.bitvCategories.find(category => category.id === evaluationStep.evaluationStep.bitvCategorieId);
    this.selectedCategorysParent = this.bitvCategories.find(category => category.id === this.selectedCategory.parent_id);
    // init selected treenode
    this.selectedTreenode = this.selectedCategory.children.find(child => child.id === evaluationStep.evaluationStep.id);
  }

  updateProgress() {
    setTimeout(() => {
      this.setProgressionInfo();
    }, 0);
  }

  navbarExpanderClick(matSideNav: any) {
    this.navbarExpanded = !this.navbarExpanded;
    matSideNav.toggle();
  }

  save() {
    const stepToSave = this.selectedEvaluationstep;
    if (this.unsavedChanges) {
      this.saveSingleStep(stepToSave).subscribe({
        error: () => {
        }
      });
    }
  }

  back() {
    if (this.unsavedChanges) {
      this.saveSingleStep(this.selectedEvaluationstep).subscribe({
        next: () => this.navigateToDashboard(this.bitv.applicationId, this.bitv.id),
        error: () => {
        }
      });
    } else {
      this.navigateToDashboard(this.bitv.applicationId, this.bitv.id);
    }
  }

  evaluationTypeLabel(evaluationTypeKey: string): string {
    return evaluationTypeLabel(this.configurationOptions, evaluationTypeKey);
  }

  onHelpButtonClick() {
    this.qumapHelpService.getQumapHelpAndShowInDialog('balm-evaluate-steps', this.dialog);
  }

  stepUpdated() {
    this.unsavedChanges = true;
  }

  navigateToDashboard(applicationId: string | number, bitvId: string | number): void {
    this.router.navigateByUrl(`/applications/${applicationId}/balm/${bitvId}`);
  }

  private saveSingleStep(stepToSave: Evaluationstep): Observable<Evaluationstep> {
    return this.bitvService.saveEvaluationStep('de', stepToSave).pipe(
      tap({
        next: (savedStep) => {

          this.snackBar.openFromComponent(SaveConfSnackbarComponent, {
            data: {message: 'Der Prüfschritt ' + savedStep.evaluationStep.number + ' wurde erfolgreich gespeichert.'}
          });

          this.unsavedChanges = false;
        },
        error: () => {
          this.snackBar.openFromComponent(SaveConfSnackbarComponent, {
            data: {message:
                'Etwas ist schief gelaufen. Der Prüfschritt ' +
                stepToSave.evaluationStep.number +
                ' wurde nicht gespeichert.',
            }
          });
        }
      })
    );
  }

  private setProgressionInfo() {
    this.currentOpenCount = this.allSelectedEvaluationsteps
      .filter(x => x.evaluationresult === undefined || x.evaluationresult === null).length;
    this.currentNotApplicableCount = this.allSelectedEvaluationsteps.filter(x =>
      (x.evaluationresult === EvaluationCriteria.notApplicable)).length;
    this.currentFulfilledCount = this.allSelectedEvaluationsteps.filter(x =>
      (x.evaluationresult === EvaluationCriteria.fulfilled)).length;
    this.currentNoCount = this.allSelectedEvaluationsteps.filter(x => x.evaluationresult === EvaluationCriteria.no).length;

    this.compliant = checkIfStillCompliant(this.allSelectedEvaluationsteps);

    let allEvaluationSteps: Evaluationstep[] = [];
    this.bitv.bitvEvaluationStepsByTestobject.forEach(bitvEvaluationStepsByTestobject => {
      allEvaluationSteps = [...allEvaluationSteps, ...bitvEvaluationStepsByTestobject.bitvEvaluationSteps];
    });
    this.allOpenCount = allEvaluationSteps.filter(x =>
      x.evaluationresult === undefined || x.evaluationresult === null).length;
    this.allFulfilledCount = allEvaluationSteps.filter(x =>
      (x.evaluationresult === EvaluationCriteria.fulfilled)).length;
    this.allNoCount = allEvaluationSteps.filter(x =>
      (x.evaluationresult === EvaluationCriteria.no)).length;
    this.allNotApplicableCount = allEvaluationSteps.filter(x =>
      (x.evaluationresult === EvaluationCriteria.notApplicable)).length;
    this.allEvaluationstepsCount = allEvaluationSteps.length;
  }

}
