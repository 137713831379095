import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationsComponent } from './applications.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ApplicationsService } from './applications.service';
import { MatSelectModule } from '@angular/material/select';
import { ApplicationComponent } from './application/application.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { ContactsModule } from '../contacts/contacts.module';
import { ItSavePopUpComponent } from './save-pop-up/it-save-pop-up.component';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChooseNfrComponent } from './choose-nfr/choose-nfr.component';
import { MaterialModule } from '../material/material.module';
import { HighlightSearchPipe } from '../shared/pipes/highlightable-search.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from '../shared/shared.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SidenavModule } from '../shared/sidenav/sidenav.module';
import { InformationHeaderModule } from '../shared/information-header/information-header.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { StoreModule } from '@ngrx/store';
import * as ApplicationStore from './application-state/application.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationEffects } from './application-state/application.effects';
import { NfrPrintPreviewComponent } from './nfr-print-preview/nfr-print-preview.component';
import { CreateCustomNfrComponent } from './create-custom-nfr/create-custom-nfr.component';
import { JiraExportComponent } from './jira-export/jira-export.component';

@NgModule({
  declarations: [
    ApplicationsComponent,
    ApplicationComponent,
    ItSavePopUpComponent,
    ChooseNfrComponent,
    HighlightSearchPipe,
    NfrPrintPreviewComponent,
    CreateCustomNfrComponent,
    JiraExportComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatCardModule,
    ContactsModule,
    FormsModule,
    RouterModule,
    MatTooltipModule,
    MaterialModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    SharedModule,
    SidenavModule,
    InformationHeaderModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    StoreModule.forFeature(ApplicationStore.APPLICATION_STATE, ApplicationStore.reducer),
    EffectsModule.forFeature([ApplicationEffects]),
  ],
  providers: [
    ApplicationsService
  ],
  exports: [ApplicationsComponent]
})
export class ApplicationsModule {
}
