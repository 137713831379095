import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Treenode, TreenodeCategory } from './treenode';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'nfa-sidenav-tree',
  templateUrl: './sidenav-tree.component.html',
  styleUrls: ['./sidenav-tree.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('800ms cubic-bezier(0.2,0.2,0.2,1)')
      )
    ])
  ]
})
export class SidenavTreeComponent {

  @Output() itemSelected = new EventEmitter<Treenode>();
  @Input() item: Treenode;
  @Input() selected: Treenode | null = null;
  @Input() factors: Set<number>;
  @Input() documentIdentifier: string;
  @Input() showStatus: boolean = false;

  // @HostBinding('attr.aria-expanded') ariaExpanded = this.item.expanded;

  public constructor(private activatedRoute: ActivatedRoute, private router: Router) {
  }

  onItemSelected(item: Treenode) {

    if (this.item.children !== undefined) {

      if (this.item.children.length !== 0) {
        this.item.expanded = !this.item.expanded;
      } else {
        this.selected = item;
        this.itemSelected.emit(item);
      }
    } else {
      this.selected = item;
      this.itemSelected.emit(item);
    }
  }

  onSelectedItemIntern(item: Treenode) {
    this.selected = item;
    this.itemSelected.emit(item);
  }

  isSelected(): boolean {

    // Betrifft Sidenav im Bereich "Hilfe"
    if (this.router.url.indexOf('qumap-help') > -1) {

      const helpitemid = Number(this.activatedRoute.snapshot.paramMap.get('helpitemid')) + 5;
      return Number(helpitemid) === this.item.id;

    }
    // Betrifft Sidenav im Bereich Bitv-Evaluation
    else if (this.router.url.indexOf('bitv') > -1) {

      const selectedCategoryId = Number(this.activatedRoute.snapshot.paramMap.get('cid'));
      return Number(selectedCategoryId) === this.item.id;

    }
    // Betrifft sonstige Sidenav (Catalog und Choose-Nfr)
    else {

      return this.selected === this.item;

    }
  }

  isDisabledFactor(item): boolean {
    if (item.category.toString() === '1' && this.factors !== null) {
      if (this.factors.size === 0) {
        return false;
      }
      let notFound = true;
      if (this.factors !== null) {
        this.factors.forEach((factor) => {
          if ((factor.valueOf() + 1).toString() === item.identifier) {
            notFound = false;
          }
        });
        return notFound;
      }
    } else {
      return false;
    }
  }

  inDocuments(item): boolean {
    if (this.documentIdentifier === undefined) {
      return true;
    }
    if (item.category.toString() === '1') {
      return (item.documents.find(document =>
        document.identifier === this.documentIdentifier));
    }
    return true;
  }

  getStyleByCategory(): string {
    switch (this.item.category) {
      case TreenodeCategory.ROOT:
        return this.getRootStyle();
      case TreenodeCategory.NODE:
        return 'list-item list-item--node';
      case TreenodeCategory.LEAF:
        return 'list-item list-item--leaf';
    }
  }

  private getRootStyle(): string {
    if (this.item.parent !== undefined) {
      return 'list-item list-item--subroot';
    } else {
      return 'list-item list-item--root';
    }
  }

  shouldShowStatus() {
    return this.showStatus;
  }
}

