import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  myFactors$: Observable<any>;
  private factorSubject = new Subject<Set<number>>();

  constructor() {
    this.myFactors$ = this.factorSubject.asObservable();
  }

  setFactor(data) {
    this.factorSubject.next(data);
  }
}
