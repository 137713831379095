import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CatalogState, getCatalogState, getFactorList, getSectors } from '../catalog-state/catalog.reducer';
import {
  LoadCatalog,
  LoadNfrsForMetric,
  LoadSectors,
  LoadTemplateNames,
  LoadTemplates
} from '../catalog-state/catalog.actions';
import { HttpClient } from '@angular/common/http';
import { Language } from '../../app-state/settings';
import { Factor } from '../adapter/factor';
import { Metric } from '../adapter/metric';
import { MatDialog } from '@angular/material/dialog';
import { NfrCreateSimpleComponent } from '../nfr-create-simple/nfr-create-simple.component';
import { Criterion } from '../adapter/criterion';
import { ActivatedRoute } from '@angular/router';
import { Sector } from '../adapter/sector';
import { NfrReceived } from '../adapter/nfrReceived';
import { Treenode, TreenodeCategory } from '../../shared/sidenav/treenode';
import { FactorWrapper } from '../adapter/factor-wrapper';
import { CriterionWrapper } from '../adapter/criterion-wrapper';
import { filter } from 'rxjs/operators';
import { HilfeFunktionen } from '../../shared/global';
import { QumapHelpService } from 'src/app/qumap-help/qumap-help.service';

@Component({
  selector: 'nfa-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit {
  readonly LANGUAGE: Language = Language.DEUTSCH;

  identifierOfCriteriaPanelsInitiallyExpanded = '';

  catalogState$: Observable<CatalogState> = this.store$.pipe(
    select(getCatalogState)
  );
  factors$: Observable<Factor[]> = this.store$.pipe(
    select(getFactorList),
    filter(liste => liste.length > 0)); // so holt man sich ein Teil davon, z.b. die Liste der Faktoren
  // criteria$: Observable<Criterion[]> = this.store$.pipe(select(getCriterionList));
  branches$: Observable<Sector[]> = this.store$.pipe(select(getSectors));

  nfrsOfSystemType: number[] = [];

  selectedCriterionWrapper: CriterionWrapper | null = null;
  selectedFactorWrapper: FactorWrapper | null = null;
  selectedMetric: Metric | null = null;

  documentIdentifier: string;

  activeFactor = 0; // TODO entfernen
  navbarExpanded: boolean;
  openMenu: Record<string, boolean>;
  factorMarked = 0;
  headers: Treenode[] = [
    {
      name: 'Qualitätsanforderungen',
      expanded: true,
      category: TreenodeCategory.ROOT,
      authorization: true
    },
    {
      name: 'Rahmenbedingungen',
      expanded: true,
      category: TreenodeCategory.ROOT,
      authorization: true
    }
  ];
  headers2: Treenode[] = [
    {
      name: 'Qualität im Gebrauch',
      expanded: true,
      category: TreenodeCategory.ROOT,
      parent: this.headers[0],
      authorization: true
    },
    {
      name: 'Produktqualität',
      expanded: true,
      category: TreenodeCategory.ROOT,
      parent: this.headers[0],
      authorization: true
    }
  ];

  constructor(
    private store$: Store<CatalogState>,
    private http: HttpClient,
    public nfrDialog: MatDialog,
    private route: ActivatedRoute,
    private qumapHelpService: QumapHelpService,
    private dialog: MatDialog) {

    HilfeFunktionen.setApplicationTitle('NFA-Katalog');

    this.openMenu = {
      menuOpen: true,
      qualityRequirementsOpen: true,
      qualityInUseOpen: false,
      productQualityOpen: true,
      constraintsOpen: false
    };
  }

  ngOnInit() {
    this.store$.dispatch(new LoadCatalog(Language.DEUTSCH));
    this.store$.dispatch(new LoadTemplates(Language.DEUTSCH));
    this.store$.dispatch(new LoadTemplateNames(Language.DEUTSCH));
    this.store$.dispatch(new LoadSectors(Language.DEUTSCH));
    this.route.queryParams.subscribe(queryParams => {
      this.navbarExpanded = HilfeFunktionen.initialNavbarExpanded();
      if (queryParams && queryParams.criterion) {
        this.identifierOfCriteriaPanelsInitiallyExpanded = this.getCriterionPanelIdentifier(queryParams.criterion);
        this.scrollCriterionPanelIntoView();
      }
    });

    this.factors$.subscribe(factors => {
      const factorsWrapper = factors.map(factor => new FactorWrapper(factor));
      this.headers2[0].children = factorsWrapper.slice(0, 5);
      this.headers2[1].children = factorsWrapper.slice(5, 13);
      this.headers[0].children = this.headers2;
      this.headers[1].children = factorsWrapper.slice(13, 19);
      this.navigateToItem(factorsWrapper[0].children[0]);
    });
  }

  navbarExpanderClick(matSideNav: any) {
    this.navbarExpanded = !this.navbarExpanded;
    matSideNav.toggle();
  }

  scrollCriterionPanelIntoView() {
    const identifierOfCriteriaPanelScrolledIntoView = this.identifierOfCriteriaPanelsInitiallyExpanded;
    const observer = new MutationObserver((mutations, me) => {
      const criterionPanelByIdentifier = document.getElementById(identifierOfCriteriaPanelScrolledIntoView);
      if (criterionPanelByIdentifier) {
        criterionPanelByIdentifier.scrollIntoView();
        me.disconnect();
        return;
      }
    });
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  }

  getCriterionPanelIdentifier(identifier: string): string {
    return 'Criterion' + identifier;
  }

  isCriterionExpanded(criterion: Criterion) {
    return this.identifierOfCriteriaPanelsInitiallyExpanded.includes(criterion.identifier);
  }

  isFactorExpanded(criteria: Criterion[]) {
    let factorContainsExpandedCritria = false;
    for (const criterion of criteria) {
      if (this.identifierOfCriteriaPanelsInitiallyExpanded.includes(criterion.identifier)) {
        factorContainsExpandedCritria = true;
      }
    }
    return factorContainsExpandedCritria;
  }

  getMyNfrs(metric: string) {
    if (metric !== null && metric !== undefined) {
      this.store$.dispatch(
        new LoadNfrsForMetric({metricId: metric})
      );
    }
  }

  getNFAs(language: string, identifier: string): Observable<NfrReceived[]> {
    return this.http.get<NfrReceived[]>(
      `/nfabackend/webapi/${language}/nfas/metric?metric=${identifier}`
    ); // mock: /api +
  }

  getAllNFAs(language: string): Observable<NfrReceived[]> {
    return this.http.get<NfrReceived[]>(
      `/nfabackend/webapi/${language}/nfas`
    );
  }

  openCreateNfr(metric: Metric) {
    this.nfrDialog.open(NfrCreateSimpleComponent, {
      data: {activeMetric: metric}
    });
  }

  changeActiveFactor(fi: number) {
    this.activeFactor = fi;
  }

  selectedMenu(open: string) {
    switch (open) {
      case 'menu': {
        this.openMenu.menuOpen = !this.openMenu.menuOpen;
        break;
      }
      case 'qualityRequirements': {
        this.openMenu.qualityRequirementsOpen = !this.openMenu.qualityRequirementsOpen;
        this.openMenu.qualityInUseOpen = false;
        this.openMenu.productQualityOpen = false;
        break;
      }
      case 'qualityInUse': {
        this.openMenu.qualityInUseOpen = !this.openMenu.qualityInUseOpen;
        break;
      }
      case 'productQuality': {
        this.openMenu.productQualityOpen = !this.openMenu.productQualityOpen;
        break;
      }
      case 'constraints': {
        this.openMenu.constraintsOpen = !this.openMenu.constraintsOpen;
        break;
      }
    }
  }

  selectedFactor(fi: number) {
    this.factorMarked = fi;
  }

  navigateToItem(treenode: Treenode | undefined) {
    if (treenode !== undefined) {
      this.selectedCriterionWrapper = treenode as CriterionWrapper;
      this.selectedFactorWrapper = treenode.parent as FactorWrapper;
      if (this.selectedCriterionWrapper.metrics !== undefined) {
        this.selectedMetric = this.selectedCriterionWrapper.metrics[0];
        this.getMyNfrs(this.selectedMetric.identifier);
      }
    }
  }

  changeSelectedMetric(metric: Metric) {
    this.selectedMetric = metric;
    this.getMyNfrs(this.selectedMetric.identifier);
  }

  onHelpButtonClick() {
    this.qumapHelpService.getQumapHelpAndShowInDialog('ten-catalog',this.dialog);
  }
}
