<div mat-dialog-title>
  <div *ngIf="contact.gender || contact.title || contact.firstName || contact.nobilityTitle || contact.lastName">
    <h2>{{contact.gender}} {{contact.title}} {{contact.firstName}} {{contact.nobilityTitle}} {{contact.lastName}}</h2>
  </div>
</div>
<mat-dialog-content>
  <div>
    <h3>Position</h3>
    <div>{{contact.position}}</div>
  </div>
  <div>
    <h3>Faktoren</h3>
  </div>
  <div *ngFor="let factor of this.contact.factors">
    {{factor.identifier}} {{factor.description}}
  </div>
  <div>
    <h3>Kontakt</h3>
  </div>
  <div *ngIf="!contact.businessAddress.poBox">
    <div
      *ngIf="contact.businessAddress.street || contact.businessAddress.houseNumber || contact.businessAddress.additionalAddress || contact.businessAddress.zipCode || contact.businessAddress.city">
      <div *ngIf="contact.businessAddress.street || contact.businessAddress.houseNumber">
        <span>{{contact.businessAddress.street}}
          {{contact.businessAddress.houseNumber}}</span>
      </div>
      <div *ngIf="contact.businessAddress.additionalAddress">
        <span>{{contact.businessAddress.additionalAddress}}</span>
      </div>
      <div *ngIf="contact.businessAddress.zipCode || contact.businessAddress.city">
        <span>{{contact.businessAddress.zipCode}} {{contact.businessAddress.city}}</span>
      </div>
      <br>
    </div>
  </div>
  <div *ngIf="contact.businessAddress.poBox">
    <span>{{contact.businessAddress.poBox}} {{contact.businessAddress.city}} </span>
    <br>
    <br>
  </div>
  <div *ngIf="contact.businessAddress.floor || contact.businessAddress.roomNumber">
    <div *ngIf="contact.businessAddress.floor">
      <span>Stockwerk: </span>
      <span>{{contact.businessAddress.floor}}</span>
    </div>
    <div *ngIf="contact.businessAddress.roomNumber">
      <span>Zimmernummer: </span>
      <span>{{contact.businessAddress.roomNumber}}</span>
    </div>
    <br>
  </div>
  <div *ngIf="contact.eMailPrimary || contact.eMailSecondary">
    <div *ngIf="contact.eMailPrimary">
      <span>E-Mail 1: </span>
      <span>{{contact.eMailPrimary}}</span>
    </div>
    <div *ngIf="contact.eMailSecondary">
      <span>E-Mail 2: </span>
      <span>{{contact.eMailSecondary}}</span>
    </div>
    <br>
  </div>
  <div *ngIf="contact.phoneNumber || contact.cellPhoneNumber">
    <div *ngIf="contact.phoneNumber">
      <span>Telefon: </span>
      <span>{{contact.phoneNumber}}</span>
    </div>
    <div *ngIf="contact.cellPhoneNumber">
      <span>Mobil: </span>
      <span>{{contact.cellPhoneNumber}}</span>
    </div>
    <br>
  </div>

</mat-dialog-content>
<mat-dialog-actions class="buttonRight">
  <button mat-raised-button mat-dialog-close>Ok</button>
  <!--
      TODO nach dem Workshop
      <button mat-raised-button>Bearbeiten</button>
    -->
</mat-dialog-actions>
