import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { environment } from '../environments/environment';

@Component({
  selector: 'nfa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isMenuOpen = false;
  loggedIn = false;
  userDetails: KeycloakProfile = {};

  authorizationTen = this.keycloak.isUserInRole('TEN');
  buildVersion = environment.buildVersion;
  currentYear = new Date();

  mainNavigationRoutes = [
    { id: 'nav_applications', icon: 'dvr', route: '/applications', content: 'Anwendungen', authorization: true },
    { id: 'nav_catalog', icon: 'local_library', route: '/catalog', content: 'NFA-Katalog', authorization: this.authorizationTen },
    { id: 'nav_testmethods', icon: 'fact_check', route: '/testmethods', content: 'Testverfahren', authorization: this.authorizationTen }
  ];
  serviceNavigationRoutes = [
    { id: 'nav_qumaphelp', icon: 'help_outline', route: '/qumap-help', content: 'Hilfe', authorization: true }
  ];

  static entferneNGVersion() {
    document.getElementsByTagName('nfa-root')[0].removeAttribute('ng-version');
  }

  constructor(protected keycloak: KeycloakService) {
  }

  async ngOnInit() {
    if (await this.keycloak.isLoggedIn()) {
      this.userDetails = await this.keycloak.loadUserProfile();
      this.loggedIn = true;
    }
    AppComponent.entferneNGVersion();
  }

  onSidenavClick(): void {
    this.isMenuOpen = false;
  }

  logoutUser() {
    this.keycloak.logout();
    this.keycloak.clearToken();
    this.loggedIn = false;
  }

  loginUser() {
    this.keycloak.login();
  }

}
