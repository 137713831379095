<div class="test-item-header">
  <h2 id="uebersichtPruefgegenstaendeUeberschrift">{{texts?.title}}</h2>
</div>
<div>
  <button
    *ngIf="bitv.objectType == BitvType.WEB"
    mat-flat-button
    class="mat-mdc-unelevated-button-secondary auto-test-button"
    data-cy="launch-automated-tests"
    [disabled]="isBitvCompleted(bitv) || !canEdit || uiLocked"
    (click)="launchAutomatedTests()"
  >
    <mat-icon>settings</mat-icon>
    {{texts?.launchTests}}
  </button>
</div>
<div id="pruefgegenstaende" class="table-Desktop">
  <table mat-table
         id="uebersichtTabellePruefgegenstaende"
         tabindex="0"
         aria-labelledby="uebersichtPruefgegenstaendeUeberschrift"
         [dataSource]="dataSource"
         class="mat-elevation-z0">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef id="nameColumn"> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!--  Conformity Level Column -->
    <ng-container matColumnDef="conformityLevel">
      <th mat-header-cell *matHeaderCellDef id="conformityLevelColumn"> Konformitätsstufe </th>
      <td mat-cell *matCellDef="let element"> {{element.conformityLevel}} </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef id="progressColumn"> Fortschritt </th>
      <td mat-cell *matCellDef="let element">
        <div class="table-cell--with-icon">
          <span *ngIf="calculateProgress(element.bitvEvaluationSteps)">{{ testobjectProgress | percent}}</span>
        </div>
      </td>
    </ng-container>

    <!-- Result column -->
    <ng-container matColumnDef="result">
      <th mat-header-cell *matHeaderCellDef id="resultColumn">{{ isBitvCompleted(bitv) ? 'Ergebnis' : 'Aktuelles Ergebnis' }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="table-cell--with-icon">
          <span class="data-col--result__text">{{isTestobjectConform(element.bitvEvaluationSteps) ? 'Konform' : 'Nicht konform'}}</span>
          <mat-icon *ngIf="isConform" class="conformity-indicator" aria-label="Konform">check
          </mat-icon>
          <mat-icon *ngIf="!isConform" class="non-conformity-indicator"
                    aria-label="Nicht konform">close
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container *ngIf="bitv.testObjects.length === 0" matColumnDef="noTestItems">
      <td mat-footer-cell id="keinePruefgegenstaendeVorhanden" *matFooterCellDef colspan="6"><i>Es sind keine Prüfgegenstände vorhanden.</i></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr tabindex="0" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator id="pruefgegenstaendeTabellePaginator"
                 #testObjectsPaginator
                 [length]="dataSource.data.length"
                 [pageSize]="5"
                 [pageSizeOptions]="pageSizeOptions"
                 [showFirstLastButtons]="true"
                 aria-label="Mehr Prüfgegenstände anzeigen">
  </mat-paginator>
</div>
<div class="mobile-view-cards">
  <!--<button
    mat-flat-button
    class="mat-mdc-unelevated-button-secondary left-aligned-button order-button">
    <mat-icon>apps</mat-icon>
    Reihenfolge ändern
  </button>-->

  <mat-card *ngFor="let testObject of this.bitv.testObjects" class="mobile-cards">
    <mat-card-content>
      <div>
        <mat-card-subtitle class = "mobile-card-subtitle">Name</mat-card-subtitle>
        <span><b class="mobile-card-text">{{testObject.name}}</b></span>
      </div>
      <div class="mobile-card-flex">
        <div>
          <mat-card-subtitle class = "mobile-card-subtitle">Konformitätsstufe:</mat-card-subtitle>
          <span class="mobile-card-text"> {{testObject.conformityLevel}} </span>
        </div>
        <div>
          <mat-card-subtitle class = "mobile-card-subtitle">Fortschritt:</mat-card-subtitle>
          <span class="mobile-card-text" *ngIf="calculateProgress(testObject.bitvEvaluationSteps)">{{ testobjectProgress | percent}}</span>
        </div>
        <div>
          <mat-card-subtitle class = "mobile-card-subtitle">{{ isBitvCompleted(bitv) ? 'Ergebnis:' : 'Aktuelles Ergebnis:' }}</mat-card-subtitle>
          <span class="data-col--result__text" class="mobile-card-text">{{isTestobjectConform(testObject.bitvEvaluationSteps) ? 'Konform' : 'Nicht konform'}} </span>
          <mat-icon *ngIf="isConform" class="conformity-indicator" aria-label="Konform">check
          </mat-icon>
          <mat-icon *ngIf="!isConform" class="non-conformity-indicator"
                    aria-label="Nicht konform">close
          </mat-icon>
        </div>
        <!--<div>
          <mat-card-subtitle>Automatischer Test:</mat-card-subtitle>
          <span>noTest</span>
        </div>-->
      </div>
    </mat-card-content>
  </mat-card>
</div>

