export enum DocumentType {
  PROJECT_INITIALIZATION_DOCUMENT = '1',
  PROJECT_DEFINITION_DOCUMENT = '2',
  REQUIREMENTS_DOCUMENT = '3',
  USER_STORY = '4',
  USER_REQUIREMENT_SPECIFICATION = '5',
  FUNCTIONAL_SPECIFICATION = '6',
  RETROSPECTIVE = '7',
}

export interface RequirementsDocument {
  identifier: string;
  description: string;
}
