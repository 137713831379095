export interface ConfigurationOption {
  key: string;
  label: string;
  selected?: boolean;
  icon?: string;
  description?: string;
  linkedOptions: { [key: string]: string[] };
}

export interface BalmTestConfigurationOptions {
  objectTypes: ConfigurationOption[];
  evaluationTypes: ConfigurationOption[];
  conformityLevels: ConfigurationOption[];
}

export const objectTypeLabel = (configurationOptions: BalmTestConfigurationOptions, objectTypeKey: string): string => {
  return configurationOptions?.objectTypes
    .find(objectType => objectType.key === objectTypeKey)?.label;
};

export const objectTypeIcon = (configurationOptions: BalmTestConfigurationOptions, objectTypeKey: string): string => {
  return configurationOptions?.objectTypes
    .find(objectType => objectType.key === objectTypeKey)?.icon;
};

export const objectTypeDescription = (configurationOptions: BalmTestConfigurationOptions, objectTypeKey: string): string => {
  return configurationOptions?.objectTypes
    .find(objectType => objectType.key === objectTypeKey)?.description;
};

export const evaluationTypeLabel = (configurationOptions: BalmTestConfigurationOptions, evaluationTypeKey: string): string => {
  return configurationOptions?.evaluationTypes
    .find(evaluationType => evaluationType.key === evaluationTypeKey)?.label;
};

export const evaluationTypeDescription = (configurationOptions: BalmTestConfigurationOptions, evaluationTypeKey: string): string => {
  return configurationOptions?.evaluationTypes
    .find(evaluationType => evaluationType.key === evaluationTypeKey)?.description;
};
