import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Treenode } from './treenode';
import { SidenavService } from './sidenav.service';

@Component({
  selector: 'nfa-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {

  navbarExpanded = false;

  selected: Treenode | null = null;
  factors: Set<number> = null;

  @Input() treeRoots: Treenode[];
  @Input() documentIdentifier: string;
  @Input() showStatus: boolean = false;
  @Output() itemSelected = new EventEmitter<Treenode>();

  public constructor(private sidenavService: SidenavService) {
    this.sidenavService.myFactors$.subscribe((data) => {
      this.factors = data;
    });
  }

  navbarExpanderClick() {
    this.navbarExpanded = !this.navbarExpanded;
  }

  onItemSelect(item: Treenode) {
    this.selected = item;
    this.itemSelected.emit(item);
  }
  @Input()
  set selectedItem(item: Treenode) {
    this.selected = item;
  }
}

