import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Language } from '../../app-state/settings';
import { Bitv, isBitvCompleted } from '../types/bitv';
import { MatTableDataSource } from '@angular/material/table';
import {
  BalmTestConfigurationOptions,
  evaluationTypeLabel,
  objectTypeIcon,
  objectTypeLabel
} from '../types/configuration-options';
import { forkJoin } from 'rxjs';
import { BitvService } from '../bitv.service';
import { BalmEvluationsPaginatorIntl } from './balm-evaluations-paginator-intl';
import { CustomDateFormatter } from '../../shared/utils/custom-date-formatter';
import { UserAccessRight } from '../user-access-rights.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'nfa-balm-evaluation-overview',
  templateUrl: './balm-evaluation-overview.component.html',
  styleUrls: ['./balm-evaluation-overview.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: BalmEvluationsPaginatorIntl}]
})
export class BalmEvaluationOverviewComponent implements OnInit {

  readonly LANGUAGE: Language = Language.DEUTSCH;

  @Input() userAccessRight: UserAccessRight;
  @Input() applicationId: number | string;

  displayedColumnsEvaluationTable: string[] = ['object_type', 'evaluation_type', 'progress', 'created', 'changed', 'result', 'actions'];

  evaluations: Bitv[];
  evaluationsDataSource: MatTableDataSource<Bitv> = new MatTableDataSource([]);
  evaluationsLoading = true;
  pdfLoading = false;
  serverError = false;
  configurationOptions: BalmTestConfigurationOptions;

  @ViewChild('evaluationPaginator') evaluationPaginator: MatPaginator;
  @Output() bitvs: EventEmitter <Bitv[]> = new EventEmitter();

  get canUserEdit() {
    return this.userAccessRight === UserAccessRight.WRITEACCES;
  }

  authorizationPreview = false;

  constructor(private bitvService: BitvService, private keycloak: KeycloakService) {
    this.authorizationPreview = this.keycloak.isUserInRole('PREVIEW');
  }

  ngOnInit(): void {
    this.loadEvaluations(this.applicationId);
  }

  openBitvPdf(bitvId: string | number, pdfReport: string): void {
    this.pdfLoading = true;
    this.bitvService.getBitvPdf('de', bitvId, pdfReport).subscribe(pdf => {
      const blob = new Blob([pdf], {type: 'application/pdf'});
      const fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL);
      this.pdfLoading = false;
    });
  }

  calculateProgressPercentage(evaluation: Bitv): boolean {
    if (evaluation.progress !== null && evaluation.progress !== undefined) {
      evaluation.progress.percentage = evaluation.progress.evaluatedSteps / evaluation.progress.allSteps;
      return true;
    }

    return false;
  }

  isPercentage100(evaluation: Bitv): boolean {
    if (evaluation.progress !== null && evaluation.progress !== undefined) {
      return evaluation.progress.percentage === 1;
    }

    return false;
  }

  conformityResult(evaluation: Bitv): string {
    return evaluation.succesfull !== undefined ? (evaluation.succesfull ? 'Konform' : 'Nicht konform') : '';
  }

  isStatusClosed(evaluation: Bitv): boolean {
    return evaluation.status === 'COMPLETED';
  }

  isEvaluationConform(evaluation: Bitv): boolean {
    return evaluation.succesfull !== undefined ? evaluation.succesfull : false;
  }

  openBitv(bitv: Bitv) {
    return [bitv.id];
  }

  changedAtLabel(bitv: Bitv): string {
    return CustomDateFormatter.setChangedAtString(bitv.changedAt);
  }

  objectTypeLabel(objectTypeKey: string): string {
    return objectTypeLabel(this.configurationOptions, objectTypeKey);
  }

  objectTypeIcon(objectTypeKey: string): string {
    return objectTypeIcon(this.configurationOptions, objectTypeKey);
  }

  evaluationTypeLabel(evaluationTypeKey: string): string {
    return evaluationTypeLabel(this.configurationOptions, evaluationTypeKey);
  }

  evaluationCompleted(evaluation: Bitv): boolean {
    return isBitvCompleted(evaluation);
  }

  private loadEvaluations(applicationId: string | number) {
    forkJoin([
      this.bitvService.getBitvsByApplication(applicationId, this.LANGUAGE),
      this.bitvService.getConfigurationOptions(this.LANGUAGE)
    ]).subscribe({
      next: ([evaluations, configurations]) => {
        this.evaluationsLoading = false;
        this.evaluations = evaluations.sort((x, y) => y.changedAt - x.changedAt);
        this.bitvs.next(this.evaluations);
        this.configurationOptions = configurations;
        this.evaluationsDataSource.data = this.evaluations;
        this.evaluationsDataSource.paginator = this.evaluationPaginator;
      },
      error: () => {
        this.evaluationsLoading = false;
        this.serverError = true;
      }
    });
  }
}
