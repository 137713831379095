<div class="stakeholderTitleBox">
  <div class="stakeholderHeadlineLeft">
    <h1>Stakeholder-Übersicht</h1>
  </div>
  <div class="stakeholderHeadlineRight">
    <mat-form-field class="searchInput">
      <input matInput (keyup)="applyFilter($event.target.value)">
      <mat-icon matPrefix inline="true">search</mat-icon>
      <span matPrefix>&nbsp;</span>
    </mat-form-field>
  </div>
</div>
<div>
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortContact($event)">
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let contact"> {{contact.lastName}} </td>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Vorname </th>
      <td mat-cell *matCellDef="let contact"> {{contact.firstName}} </td>
    </ng-container>
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Position </th>
      <td mat-cell *matCellDef="let contact"> {{contact.position}} </td>
    </ng-container>
    <ng-container matColumnDef="eMailPrimary">
      <th mat-header-cell *matHeaderCellDef> E-Mail </th>
      <td mat-cell *matCellDef="let contact"> {{contact.eMailPrimary}} </td>
    </ng-container>
    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef> Telefon </th>
      <td mat-cell *matCellDef="let contact"> {{contact.phoneNumber}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <!--Anzeigen-->
      <td mat-cell *matCellDef="let contact" class="icon-table"> <button mat-icon-button
          (click)="onShowContactDetail(contact)">
          <mat-icon matTooltip="Anzeigen" class="icon-table">remove_red_eye</mat-icon>
        </button> </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="resultsLength" [pageSize]="10"></mat-paginator>
</div>
