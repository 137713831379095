import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationsService } from '../applications.service';
import { ApplicationReceived } from '../model/applicationReceived';
import { forkJoin, Observable } from 'rxjs';
import {
  CatalogState,
  getAllNfrs,
  getCatalogState,
  getFactorList,
  getTemplateNodes
} from 'src/app/catalog/catalog-state/catalog.reducer';
import { select, Store } from '@ngrx/store';
import { Factor } from 'src/app/catalog/adapter/factor';
import { Criterion } from 'src/app/catalog/adapter/criterion';
import {
  LoadCatalog,
  LoadNfrs,
  LoadNfrsForMetric,
  LoadNfrsForMultipleMetric,
  LoadSectors,
  LoadTemplateNames,
  LoadTemplates
} from 'src/app/catalog/catalog-state/catalog.actions';
import { Language } from 'src/app/app-state/settings';
import { Criticality, CustomApplicationNfr, ApplicationNfr, Priority, ProtectionNeeds } from '../model/applicationNfr';
import { MatOption } from '@angular/material/core';
import { TemplateNode } from 'src/app/catalog/adapter/templateNode';
import { NfrReceived } from 'src/app/catalog/adapter/nfrReceived';
import { QualityProcessReceived } from '../model/qualityProcessReceived';
import { ApplicationNfrReceived } from '../model/applicationNfrReceived';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sorter } from 'src/app/shared/utils/sorter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Treenode, TreenodeCategory } from 'src/app/shared/sidenav/treenode';
import { FactorWrapper } from 'src/app/catalog/adapter/factor-wrapper';
import { CriterionWrapper } from 'src/app/catalog/adapter/criterion-wrapper';
import { filter } from 'rxjs/operators';
import { Metric } from 'src/app/catalog/adapter/metric';
import { SidenavService } from 'src/app/shared/sidenav/sidenav.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateCustomNfrComponent } from '../create-custom-nfr/create-custom-nfr.component';
import { assertUnreachable } from '../../shared/utils/assert-unreachable';
import { JiraExportService } from '../jira-export/jira-export.service';
import { InformationDialogComponent } from '../../shared/information-dialog/information-dialog.component';
import { ApplicationState } from '../application-state/application.reducer';
import { nfasReadyToExport } from '../application-state/application.actions';
import { HilfeFunktionen } from '../../shared/global';
import { QumapHelpService } from 'src/app/qumap-help/qumap-help.service';
import {UserAccessRight, UserAccessRightsService} from "../../bitv/user-access-rights.service";
import {SaveConfSnackbarComponent} from "../../shared/save-confirm-snackbar/save-confirm-snackbar-component";



@Component({
  selector: 'nfa-choose-nfr',
  templateUrl: './choose-nfr.component.html',
  styleUrls: ['./choose-nfr.component.scss']
})
export class ChooseNfrComponent implements OnInit {
  readonly LANGUAGE: Language = Language.DEUTSCH;

  catalogState$: Observable<CatalogState> = this.store$.pipe(
    select(getCatalogState)
  );
  factors$: Observable<Factor[]> = this.store$.pipe(
    select(getFactorList),
    filter((liste) => liste.length > 0)
  );
  nfrs: NfrReceived[];

  isLoading = true;
  application: ApplicationReceived;
  nfrsOfSystemType: number[] = [];

  navbarExpanded: boolean;
  userAccessRights: UserAccessRight = undefined;

  get activeQualityProcess(): QualityProcessReceived {
    return this.application.qualityProcesses[
    this.application.qualityProcesses.length - 1
      ];
  }

  applicationNfrs: Record<number, ApplicationNfr> = {};

  get applicationNfrsReceived(): ApplicationNfrReceived[] {
    return this.application.applicationNfrs;
  }

  readonly criticalityOptions = Object.values(Criticality);
  readonly priorityOptions = Object.values(Priority);
  readonly protectionNeedOptions = Object.values(ProtectionNeeds);

  splitFormulation: Record<number, string[]> = {};
  templateNodes$: Observable<TemplateNode[]> = this.store$.pipe(
    select(getTemplateNodes)
  );
  templateNodes: TemplateNode[];

  nfridcounter: number;
  nfrnamecounter: number[] = [];

  identifierOfCriteriaPanelsInitiallyExpanded = '';

  stakeholderForm: UntypedFormGroup;

  factorsToConsider = new Set();

  activeFactor = 0;
  openMenu: Record<string, boolean>;
  factorMarked = 0;

  @ViewChild('allSelected') private allSelected: MatOption;

  selectedCriterionWrapper: CriterionWrapper | null = null;
  selectedFactorWrapper: FactorWrapper | null = null;
  selectedMetric: Metric | null = null;

  documentIdentifier: string;

  showRecommendations = false;

  jiraUrl: string = null;

  headers: Treenode[] = [
    {
      name: 'Qualitätsanforderungen',
      expanded: true,
      category: TreenodeCategory.ROOT,
      authorization: true
    },
    {
      name: 'Rahmenbedingungen',
      expanded: true,
      category: TreenodeCategory.ROOT,
      authorization: true
    }
  ];
  headers2: Treenode[] = [
    {
      name: 'Qualität im Gebrauch',
      expanded: true,
      category: TreenodeCategory.ROOT,
      parent: this.headers[0],
      authorization: true
    },
    {
      name: 'Produktqualität',
      expanded: true,
      category: TreenodeCategory.ROOT,
      parent: this.headers[0],
      authorization: true
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationsService,
    private jiraExportService: JiraExportService,
    private store$: Store<CatalogState>,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private sidenavService: SidenavService,
    private router: Router,
    private store: Store<ApplicationState>,
    private qumapHelpService: QumapHelpService,
    private userAccessRightsService: UserAccessRightsService
  ) {
    this.navbarExpanded = HilfeFunktionen.initialNavbarExpanded();
    this.openMenu = {
      menuOpen: true,
      qualityRequirementsOpen: true,
      qualityInUseOpen: false,
      productQualityOpen: true,
      constraintsOpen: false
    };
  }

  ngOnInit() {
    HilfeFunktionen.setApplicationTitle('TEN');
    this.applicationNfrs = [];
    this.initApplication();
    this.store$.dispatch(new LoadCatalog(Language.DEUTSCH));
    this.store$.dispatch(new LoadTemplates(Language.DEUTSCH));
    this.store$.dispatch(new LoadTemplateNames(Language.DEUTSCH));
    this.store$.dispatch(new LoadSectors(Language.DEUTSCH));
    this.store$.dispatch(new LoadNfrs(Language.DEUTSCH));
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams && queryParams.criterion) {
        this.identifierOfCriteriaPanelsInitiallyExpanded =
          this.getCriterionPanelIdentifier(queryParams.criterion);
        this.scrollCriterionPanelIntoView();
      }
    });

    this.templateNodes$.subscribe((data: TemplateNode[]) => {
      this.templateNodes = data;
    });

    this.stakeholderForm = this.fb.group({
      stakeholders: new UntypedFormControl('')
    });
    this.factors$.subscribe((factors) => {
      const factorsWrapper = factors.map((factor) => new FactorWrapper(factor));
      this.headers2[0].children = factorsWrapper.slice(0, 5);
      this.headers2[1].children = factorsWrapper.slice(5, 13);
      this.headers[0].children = this.headers2;
      this.headers[1].children = factorsWrapper.slice(13, 19);
      this.navigateToItem(factorsWrapper[0].children[0]);
    });
  }

  async initApplication() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.applicationService
      .getApplicationById(id)
      .subscribe({
        next: application => {
          this.application = application;
          this.userAccessRightsService
            .checkAccessRight(this.application)
            .subscribe({
              next: userAccessRights => {
                this.userAccessRights = userAccessRights;
                if (userAccessRights === UserAccessRight.READACCESS) {
                  this.router.navigateByUrl(`/applications/${this.application.id}/preview`);
                } else if (userAccessRights === UserAccessRight.NOACCESS){
                  this.router.navigateByUrl('/no-access-error-page');
                }
                this.isLoading = false;
              }
            });
          this.getNfrs();
          this.documentIdentifier = this.activeQualityProcess.document.identifier;
          if (this.application.systemType) {
            this.applicationService
              .getNfrOfSystemType(this.application.systemType.id)
              .subscribe(nfrsOfSystemType => {
                this.nfrsOfSystemType = nfrsOfSystemType;
              });
          }
        },
          error: err => {
            if (err.status === 403) {
              this.router.navigateByUrl('/no-access-error-page');
            } else if (err.status === 404) {
              this.router.navigateByUrl('/empty');
            }
          }
        });
    this.jiraExportService
      .getJiraInstanceUrl(id)
      .subscribe((jiraInstance) => {
        this.jiraUrl = jiraInstance.jiraUrl;
      });
  }

  getNfrs() {
    this.store$.pipe(select(getAllNfrs)).subscribe((nfrs: NfrReceived[]) => {
      this.nfrs = nfrs;
      this.nfridcounter = this.nfrs.length + 1;
      for (const nfr of this.nfrs) {
        this.applicationNfrs[nfr.id] = this.createDefaultNfr(nfr);
      }
      for (const applicationNfrRecieved of this.applicationNfrsReceived) {
        this.applicationNfrs[applicationNfrRecieved.nfr.id] = {
          ...applicationNfrRecieved,
          active: true,
          edited: false,
          existing: true,
          application: +this.route.snapshot.paramMap.get('id'),
          nfr: applicationNfrRecieved.nfr.id,
          index: applicationNfrRecieved.nfr.id,
          metric: applicationNfrRecieved.nfr.metric,
          nameGerman: applicationNfrRecieved.nfr.nameGerman
        };
        // TODO: Einfärben (addHighlights)
        // TODO: Wenn nfr.id mehrfach => catalogstate anpassen (addNfrToCatalogState)
      }
    });
  }

  navbarExpanderClick(matSideNav: any) {
    this.navbarExpanded = !this.navbarExpanded;
    matSideNav.toggle();
  }

  private createDefaultNfr(nfr: NfrReceived): ApplicationNfr {
    this.changeFormulation(nfr.formulationGerman, nfr.id);
    this.nfrnamecounter.splice(nfr.id, 0, 2);
    return {
      edited: false,
      existing: false,
      active: false,
      priority: '',
      criticality: '',
      protectionNeeds: '',
      basedOn: '',
      version: '',
      annotation: '',
      nfr: nfr.id,
      formulation: '',
      application: +this.route.snapshot.paramMap.get('id'),
      index: nfr.id,
      projectPhase: '',
      rv: '',
      vb: '',
      kw: '',
      vw: '',
      wert: '',
      ow: '',
      wertebereich: '',
      metric: nfr.metric,
      nameGerman: nfr.nameGerman
    };
  }

  // TODO immutability of state violated
  resetCatalogState() {
    this.catalogState$
      .subscribe((catalogState) => {
        for (const metricId of Object.keys(catalogState.nfrs)) {
          const elements: NfrReceived[] = [];
          catalogState.nfrs[metricId].forEach((element) => {
            if (element.id > this.nfrs.length) {
              elements.push(element);
            }
          });
          elements.forEach((element2) => {
            catalogState.nfrs[metricId].splice(
              catalogState.nfrs[metricId].indexOf(element2),
              1
            );
          });
        }
      })
      .unsubscribe();
  }

  async saveNfr() {
    const createdApplicationNfrs: ApplicationNfr[] = [];
    const editedApplicationNfrs: ApplicationNfr[] = [];
    const deletedApplicationNfrs: ApplicationNfr[] = [];

    for (const applicationNfr of Object.values(this.applicationNfrs)) {
      applicationNfr.formulation = this.getFormulation(applicationNfr.index);
      applicationNfr.projectPhase = this.activeQualityProcess.phase;

      const isCreatedApplication = !applicationNfr.existing && applicationNfr.active;
      const isEditedApplication =
        applicationNfr.existing && applicationNfr.active && applicationNfr.edited;
      const itDeletedApplication = applicationNfr.existing && !applicationNfr.active;

      if (isCreatedApplication) {
        createdApplicationNfrs.push(
          this.convertApplicationNfrClientToServerModel(applicationNfr)
        );
      } else if (isEditedApplication) {
        editedApplicationNfrs.push(
          this.convertApplicationNfrClientToServerModel(applicationNfr)
        );
      } else if (itDeletedApplication) {
        deletedApplicationNfrs.push(applicationNfr);
      }
    }

    const saveObservables: Observable<unknown>[] = [];
    if (createdApplicationNfrs.length > 0) {
      saveObservables.push(
        this.applicationService.saveMultipleApplicationNfrs(createdApplicationNfrs)
      );
    }
    if (editedApplicationNfrs.length > 0) {
      saveObservables.push(
        this.applicationService.editMultipleApplicationNfrs(editedApplicationNfrs)
      );
    }
    if (deletedApplicationNfrs.length > 0) {
      saveObservables.push(
        this.applicationService.deleteMultipleApplicationNfrsNfr(
          deletedApplicationNfrs
        )
      );
    }

    // Update Custom Nfrs
    const editedCustomNfrs: CustomApplicationNfr[] = [];
    for (const customNfr of this.application.customNfrs) {
      if (customNfr.edited) {
        editedCustomNfrs.push(
          this.convertCustomApplicationNfrClientToServerModel(customNfr)
        );
      }
    }

    if (editedCustomNfrs.length > 0) {
      saveObservables.push(
        this.applicationService.editMultipleCustomNfrs(editedCustomNfrs)
      );
    }

    const [nfrSaveSuccessMessage, nfrSaveFailureMessage] =
      this.getNfrSaveMessages();
    let saveMessage;
    return !saveObservables.length
      ? Promise.resolve()
      : forkJoin(saveObservables)
        .toPromise()
        .then(() => {
          saveMessage = nfrSaveSuccessMessage;
          this.store.dispatch(nfasReadyToExport());
        })
        .catch(() => (saveMessage = nfrSaveFailureMessage))
        .finally(() => {
          this.snackBar.openFromComponent(SaveConfSnackbarComponent, {
            data: { message: saveMessage}});
          this.initApplication();
        });
  }

  private getNfrSaveMessages(): [string, string] {
    let nfrSaveSuccessMessage;
    let nfrSaveFailureMessage;
    switch (this.LANGUAGE) {
      case Language.DEUTSCH:
        nfrSaveSuccessMessage = `Die NFA der Anwendung ${this.application.name} wurden erfolgreich gespeichert.`;
        nfrSaveFailureMessage = `Etwas ist schief gelaufen. Die NFA der Anwendung ${this.application.name} wurden nicht gespeichert.`;
        break;
      case Language.ENGLISH:
        nfrSaveSuccessMessage = `The NFR of the Application ${this.application.name} have been saved successfully.`;
        nfrSaveFailureMessage = `Something went wrong. The NFR of the Application ${this.application.name} have not been saved.`;
        break;
      default:
        assertUnreachable(this.LANGUAGE, 'Language');
    }
    return [nfrSaveSuccessMessage, nfrSaveFailureMessage];
  }

  private convertApplicationNfrClientToServerModel(applicationNfr: ApplicationNfr) {
    const ret = {...applicationNfr};
    delete ret.active;
    delete ret.existing;
    delete ret.edited;
    delete ret.index;
    return ret;
  }

  private convertCustomApplicationNfrClientToServerModel(
    customApplicationNfr: CustomApplicationNfr
  ) {
    const ret = {...customApplicationNfr};
    delete ret.edited;
    return ret;
  }

  editInput(input: string, id: number) {
    for (const applicationNfr of this.applicationNfrsReceived) {
      if (applicationNfr.nfr.id === id) {
        if (
          applicationNfr.priority !== input ||
          applicationNfr.criticality !== input ||
          applicationNfr.protectionNeeds !== input ||
          applicationNfr.basedOn !== input ||
          applicationNfr.version !== input ||
          applicationNfr.annotation !== input ||
          applicationNfr.rv !== input ||
          applicationNfr.vb !== input ||
          applicationNfr.kw !== input ||
          applicationNfr.vw !== input ||
          applicationNfr.wert !== input ||
          applicationNfr.ow !== input ||
          applicationNfr.wertebereich !== input
        ) {
          this.applicationNfrs[id].edited = true;
        } else {
          // todo: else wird nie aufgerufen
          this.applicationNfrs[id].edited = false;
        }
      }
    }
  }

  setEditedFlag(id: number) {
    this.applicationNfrs[id].edited = true;
    // todo: Set edited state based on whether nfr-subform is dirty
  }

  editInputCustomNfr(id: number) {
    for (const index in this.application.customNfrs) {
      if (this.application.customNfrs[index].id === id) {
        this.application.customNfrs[index].edited = true;
        if (this.application.customNfrs[index].active === false) {
          this.application.customNfrs[index] = this.clearCustomNfr(
            this.application.customNfrs[index]
          );
        }
      }
    }
  }

  clearCustomNfr(customNfr: CustomApplicationNfr) {
    const clearedCustomNfr: CustomApplicationNfr = customNfr;
    clearedCustomNfr.priority = '';
    clearedCustomNfr.criticality = '';
    clearedCustomNfr.protectionNeeds = '';
    clearedCustomNfr.basedOn = '';
    clearedCustomNfr.version = '';
    clearedCustomNfr.annotation = '';
    return clearedCustomNfr;
  }

  goBack() {
    this.resetCatalogState();
    this.router.navigateByUrl(`/applications`);
  }

  getFormulation(id: number): string {
    let reforgedString: string = this.splitFormulation[id].join(' ');
    reforgedString = reforgedString.replace(new RegExp('> ,', 'g'), '>,');
    reforgedString = reforgedString.replace('<RV>', this.applicationNfrs[id].rv);
    reforgedString = reforgedString.replace(
      '<WERT>',
      this.applicationNfrs[id].wert
    );
    reforgedString = reforgedString.replace('<VW>', this.applicationNfrs[id].vw);
    reforgedString = reforgedString.replace('<KW>', this.applicationNfrs[id].kw);
    reforgedString = reforgedString.replace('<VB>', this.applicationNfrs[id].vb);
    reforgedString = reforgedString.replace('<OW>', this.applicationNfrs[id].ow);
    reforgedString = reforgedString.replace(
      '<WERTEBEREICH>',
      this.applicationNfrs[id].wertebereich
    );
    return reforgedString;
  }

  scrollCriterionPanelIntoView() {
    const identifierOfCriteriaPanelScrolledIntoView =
      this.identifierOfCriteriaPanelsInitiallyExpanded;
    const observer = new MutationObserver((mutations, me) => {
      const criterionPanelByIdentifier = document.getElementById(
        identifierOfCriteriaPanelScrolledIntoView
      );
      if (criterionPanelByIdentifier) {
        criterionPanelByIdentifier.scrollIntoView();
        me.disconnect();
        return;
      }
    });
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  }

  sortTestMethods(criterion: Criterion) {
    criterion.testTypes.sort((a, b) =>
      Sorter.compare(a.labelKey, b.labelKey, true)
    );
    criterion.testDesignTechniques.sort((a, b) => Sorter.compare(a, b, true));
  }

  getMyNfrs(metricId: string) {
    if (metricId) {
      this.store$.dispatch(new LoadNfrsForMetric({metricId}));
    }
  }

  loadNfrsForMetrics(metricIds: string[]) {
    if (metricIds.length > 0) {
      this.store$.dispatch(new LoadNfrsForMultipleMetric({metricIds}));
    }
  }

  getCriterionPanelIdentifier(identifier: string): string {
    return 'Criterion' + identifier;
  }

  isCriterionExpanded(criterion: Criterion) {
    return this.identifierOfCriteriaPanelsInitiallyExpanded.includes(
      criterion.identifier
    );
  }

  isFactorExpanded(criteria: Criterion[]) {
    let factorContainsExpandedCritria = false;
    for (const criterion of criteria) {
      if (
        this.identifierOfCriteriaPanelsInitiallyExpanded.includes(
          criterion.identifier
        )
      ) {
        factorContainsExpandedCritria = true;
      }
    }
    return factorContainsExpandedCritria;
  }

  changeFormulation(formulation: string, id: number) {
    let formulationChange: string = formulation.replace(
      '<RECHTLICHE VERBINDLICHKEIT>',
      '<RV>'
    );
    formulationChange = formulationChange.replace('VARIABLER WERT', 'VW');
    formulationChange = formulationChange.replace(
      'KONKRETISIERENDER WERT',
      'KW'
    );
    formulationChange = formulationChange.replace(
      'VARIABLER BETRACHTUNGSGEGENSTAND',
      'VB'
    );
    formulationChange = formulationChange.replace('OBERER WERT', 'OW');
    // this.formulationChange = this.formulationChange.replace('WERTEBEREICH', 'WERTEBEREICH');
    formulationChange = formulationChange.replace(new RegExp('>,', 'g'), '> ,');
    this.splitFormulation[id] = formulationChange.split(' ');
  }

  sharesSameSectorAsApplication(nfr: NfrReceived): boolean {
    return Boolean(
      nfr.sectors.find(
        (sector) => sector.id === this.application.sector.id || sector.id === 'AL'
      )
    );
  }

  tosslePerOne() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      this.selectFactors();
      return false;
    }
    if (
      this.stakeholderForm.controls.stakeholders.value.length ===
      this.application.contacts.length
    ) {
      this.allSelected.select();
    }
    this.selectFactors();
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.stakeholderForm.controls.stakeholders.patchValue([
        ...this.application.contacts.map((item) => item.identifier),
        0
      ]);
      this.selectFactors();
    } else {
      this.stakeholderForm.controls.stakeholders.patchValue([]);
      this.factorsToConsider.clear();
    }
  }

  private selectFactors() {
    this.factorsToConsider.clear();
    for (const stakeholderIdentifier of this.stakeholderForm.get('stakeholders')
      .value) {
      for (const stakeholder of this.application.contacts) {
        if (stakeholderIdentifier === stakeholder.identifier) {
          for (const factor of stakeholder.factors) {
            this.factorsToConsider.add(Number(factor.identifier) - 1);
          }
          this.sidenavService.setFactor(this.factorsToConsider);
        }
      }
    }
  }

  changeActiveFactor(fi: number) {
    this.activeFactor = fi;
  }

  selectedMenu(open: string) {
    switch (open) {
      case 'menu': {
        this.openMenu.menuOpen = !this.openMenu.menuOpen;
        break;
      }
      case 'qualityRequirements': {
        this.openMenu.qualityRequirementsOpen =
          !this.openMenu.qualityRequirementsOpen;
        this.openMenu.qualityInUseOpen = false;
        this.openMenu.productQualityOpen = false;
        break;
      }
      case 'qualityInUse': {
        this.openMenu.qualityInUseOpen = !this.openMenu.qualityInUseOpen;
        break;
      }
      case 'productQuality': {
        this.openMenu.productQualityOpen = !this.openMenu.productQualityOpen;
        break;
      }
      case 'constraints': {
        this.openMenu.constraintsOpen = !this.openMenu.constraintsOpen;
        break;
      }
    }
  }

  selectedFactor(fi: number) {
    this.factorMarked = fi;
  }

  navigateToItem(treenode: Treenode | undefined) {
    if (treenode !== undefined) {
      this.selectedCriterionWrapper = treenode as CriterionWrapper;
      this.selectedFactorWrapper = treenode.parent as FactorWrapper;
      if (this.selectedCriterionWrapper.metrics !== undefined) {
        this.selectedMetric = this.selectedCriterionWrapper.metrics[0];
        // this.getMyNfrs(this.selectedCriterionWrapper.metrics[0].identifier);
        this.loadNfrsForMetrics(
          this.selectedCriterionWrapper.metrics.map(
            (metric) => metric.identifier
          )
        );
      }
    }
  }

  changeSelectedMetric(metric: Metric) {
    this.selectedMetric = metric;
    this.getMyNfrs(this.selectedMetric.identifier);
  }

  isRecommendation(nfrId: number): boolean {
    return this.nfrsOfSystemType.includes(nfrId) && this.showRecommendations;
  }

  toggleShowRecommendations() {
    this.showRecommendations = !this.showRecommendations;
  }

  getCustomNfrs(
    application: ApplicationReceived,
    metricId: string
  ): CustomApplicationNfr[] {
    return application.customNfrs.filter(
      (customNfr) => customNfr.metric === metricId
    );
  }

  openCreateNewCustomNfr(selectedMetric: Metric) {
    const dialogRef = this.dialog.open(CreateCustomNfrComponent, {
      width: '500px',
      data: {
        selectedMetric,
        applicationId: this.application.id
      }
    });
    dialogRef.afterClosed().subscribe((customNfr) => {
      if (customNfr !== undefined) {
        this.applicationService.saveCustomNfr(customNfr).subscribe(
          // workaround for showing the saved entity without reloading the application
          (savedCustomNfr) => this.application.customNfrs.push(savedCustomNfr)
        );
      } else {
        // do nothing
      }
    });
  }

  onHelpButtonClick() {
    this.qumapHelpService.getQumapHelpAndShowInDialog('ten-edit-nfr',this.dialog);
  }

  async onPdfPreviewButtonClick() {
    await this.saveNfr();
    this.router.navigateByUrl(`/applications/${this.application.id}/preview`);
  }

  async onJiraExportButtonClick() {
    if (!this.jiraUrl) {
      this.dialog.open(InformationDialogComponent, {
        panelClass: 'information-dialog-container',
        data: {
          informationType: 'info',
          header: 'Keine Jira-Instanz registriert',
          message: `Für Ihre Organisation ist keine URL für eine Jira-Instanz registriert. Um eine URL zu registrieren,
          schreiben Sie bitte eine E-Mail an <a href='mailto:support.qumap.msg.group'> support.qumap.msg.group</a> mit
          der Angabe, wie die URL Ihrer Jira-Instanz lautet und in welchem Land diese gehostet ist.`
        },
        autoFocus: "dialog"
      });
    } else {
      this.router.navigateByUrl(`/applications/${this.application.id}/jiraExport`);
    }
  }

  onApplicationNfrActiveStateToggle(applicationNfr: NfrReceived) {
    this.setEditedFlag(applicationNfr.id);
    const oldApplicationNfr = this.applicationNfrs[applicationNfr.id];
    if (!oldApplicationNfr.active) {
      const newApplicationNfr = this.createDefaultNfr(applicationNfr);
      newApplicationNfr.existing = oldApplicationNfr.existing;
      newApplicationNfr.id = oldApplicationNfr.id;
      this.applicationNfrs[applicationNfr.id] = newApplicationNfr;
    }
  }

  nameVB(nfr: string): string {
    return nfr;
  }
}
