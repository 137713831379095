import { Component, OnInit } from '@angular/core';
import { Bitv, isBitvCompleted } from '../../types/bitv';
import { ApplicationReceived } from '../../../applications/model/applicationReceived';
import {
  BalmTestConfigurationOptions,
  evaluationTypeDescription,
  evaluationTypeLabel,
  objectTypeDescription,
  objectTypeIcon,
  objectTypeLabel
} from '../../types/configuration-options';
import { ActivatedRoute, Router } from '@angular/router';
import { BitvService } from '../../bitv.service';
import { ApplicationsService } from '../../../applications/applications.service';
import { UserAccessRight, UserAccessRightsService } from '../../user-access-rights.service';
import { HilfeFunktionen } from '../../../shared/global';
import { forkJoin } from 'rxjs';
import { EvaluationCriteria } from '../balm-evaluation-criteria.enum';
import { checkIfStillCompliant, Evaluationstep } from '../../types/evaluationstep';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData
} from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { SaveConfSnackbarComponent } from 'src/app/shared/save-confirm-snackbar/save-confirm-snackbar-component';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'nfa-balm-evaluation-dashboard',
  templateUrl: './balm-evaluation-dashboard.component.html',
  styleUrls: ['./balm-evaluation-dashboard.component.scss']
})
export class BalmEvaluationOverviewDashboardComponent implements OnInit {
  readonly LANGUAGE = 'de';
  readonly completionMessage = `Wenn Sie den Barrierefreiheitstest abgeschlossen haben,
  können Sie ihn nicht mehr nachträglich bearbeiten.<br>
  Nach dem Abschließen werden Sie zum Prüfbericht weitergeleitet.`;

  bitv: Bitv;
  application: ApplicationReceived;
  userAccessRights: UserAccessRight = undefined;

  compliant: boolean;

  allOpenCount: number;
  allConformCount: number;
  allNotConformCount: number;
  allNotApplicableCount: number;
  allEvaluationstepsCount: number;
  dialogRef: MatDialogRef<ConfirmationDialogComponent, any>;

  loading = true;
  pdfLoading = false;

  get canUserEdit() {
    return this.userAccessRights === UserAccessRight.WRITEACCES;
  }

  private configurationOptions: BalmTestConfigurationOptions;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private bitvService: BitvService,
    private applicationsService: ApplicationsService,
    private userAccessRightsService: UserAccessRightsService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public keycloakService: KeycloakService, // Entfernen, sobald Automatisierte Tests offiziell released sind
  ) {
  }

  ngOnInit() {
    HilfeFunktionen.setApplicationTitle('BALM - Barrierefreiheitstest');
    const params = this.activatedRoute.snapshot.paramMap;

    // init bitv and allEvaluations
    const applicationid = params.get('applicationid');
    const bitvID = params.get('id');
    this.applicationsService.getApplicationById(applicationid).subscribe({
      next: application => {
        this.application = application;
        this.userAccessRightsService.checkAccessRight(this.application).subscribe({
          next: userAccessRights => {
            this.userAccessRights = userAccessRights;
            forkJoin([
              this.bitvService.getBitvById(bitvID, this.LANGUAGE),
              this.bitvService.getConfigurationOptions(this.LANGUAGE),
              this.bitvService.getPruefgegenstandByApplication(applicationid)
            ]).subscribe({
              next: ([bitv, configurationOptions, testObjects]) => {
                this.bitv = bitv;
                this.setProgressionInfo();
                this.configurationOptions = configurationOptions;
                this.loading = false;
              },
              error: err => {
                if (err.status === 403) {
                  this.router.navigateByUrl('/no-access-error-page');
                } else if (err.status === 404) {
                  this.router.navigateByUrl('/empty');
                }
              }
            });
          }
        });
      },
      error: err => {
        if (err.status === 403) {
          this.router.navigateByUrl('/no-access-error-page');
        } else if (err.status === 404) {
          this.router.navigateByUrl('/empty');
        }
      }
    });
  }

  back() {
    this.router.navigateByUrl(`/applications/${this.bitv.applicationId}/balm`);
  }

  openBitvEvaluation() {
    if (this.canUserEdit)
      this.router.navigateByUrl(`/applications/${this.bitv.applicationId}/balm/${this.bitv.id}/testobjects/`);
  }

  openBitvConfiguration() {
    if (this.canUserEdit)
      this.router.navigateByUrl(`/applications/${this.bitv.applicationId}/balm/${this.bitv.id}/configuration`);
  }

  openBitvPdf(pdfReport: string): void {
    this.pdfLoading = true;
    this.bitvService.getBitvPdf('de', this.bitv.id, pdfReport).subscribe(pdf => {
      const blob = new Blob([pdf], {type: 'application/pdf'});
      const fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL);
      this.pdfLoading = false;
    });
  }

  objectTypeLabel(objectTypeKey: string): string {
    return objectTypeLabel(this.configurationOptions, objectTypeKey);
  }

  objectTypeIcon(objectTypeKey: string): string {
    return objectTypeIcon(this.configurationOptions, objectTypeKey);
  }

  objectTypeDescription(objectTypeKey: string): string {
    return objectTypeDescription(this.configurationOptions, objectTypeKey);
  }

  evaluationTypeLabel(evaluationTypeKey: string): string {
    return evaluationTypeLabel(this.configurationOptions, evaluationTypeKey);
  }

  evaluationTypeDescriptionLines(evaluationTypeKey: string): string[] {
    return evaluationTypeDescription(this.configurationOptions, evaluationTypeKey).split('\\n');
  }

  evaluationCompleted(evaluation: Bitv): boolean {
    return isBitvCompleted(evaluation);
  }

  getEvaluationResult(): string {
    return this.compliant ? 'Konform' : 'Nicht konform';
  }

  getResultIcon(): string {
    return this.compliant ? 'check' : 'close';
  }

  private setProgressionInfo() {
    let allEvaluationSteps: Evaluationstep[] = [];
    this.bitv.bitvEvaluationStepsByTestobject.forEach(bitvEvaluationStepsByTestobject => {
      allEvaluationSteps = [...allEvaluationSteps, ...bitvEvaluationStepsByTestobject.bitvEvaluationSteps];
    });
    this.compliant = checkIfStillCompliant(allEvaluationSteps);

    this.allOpenCount = allEvaluationSteps.filter(x =>
      x.evaluationresult === undefined || x.evaluationresult === null).length;
    this.allConformCount = allEvaluationSteps.filter(x =>
      (x.evaluationresult === EvaluationCriteria.fulfilled)).length;
    this.allNotConformCount = allEvaluationSteps.filter(x =>
      (x.evaluationresult === EvaluationCriteria.no)).length;
    this.allNotApplicableCount = allEvaluationSteps.filter(x =>
      (x.evaluationresult === EvaluationCriteria.notApplicable)).length;
    this.allEvaluationstepsCount = allEvaluationSteps.length;
  }

  calculateArcAngle(count: number): number {
    return (count / this.allEvaluationstepsCount) * 440;
  }

  private openBitvPdfFinish(bitvId: string | number, pdfReport: string): void {
    this.pdfLoading = true;
    this.bitvService.getBitvPdf('de', bitvId, pdfReport).subscribe(pdf => {
      const blob = new Blob([pdf], {type: 'application/pdf'});
      const fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL);
      this.pdfLoading = false;
    });
  }

  finish() {
    const data: ConfirmationDialogData = {
      ...new ConfirmationDialogData(),
      header: 'Möchten Sie den Barrierefreiheitstest wirklich abschließen?',
      cancelOptionText: 'Abbrechen',
      confirmOptionIcon: 'lock_outline',
      confirmOptionText: 'Abschließen',
      message: this.completionMessage,
      confirmationQuestion: ''
    };
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'confirmation-dialog-container',
      disableClose: true,
      data,
      autoFocus: 'dialog',
      role: 'dialog',
      maxHeight: '90vh',
      maxWidth: '90vh'
    });
    this.dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.bitv.status = 'COMPLETED';
        this.bitvService.updateStatusBitv('de', this.bitv).subscribe({
          next: () => {
            this.snackBar.openFromComponent(SaveConfSnackbarComponent, {
              data: {
                message: 'Die abgeschlossene ' + evaluationTypeLabel(this.configurationOptions, this.bitv.evaluationType) +
                  'wurde erfolgreich gespeichert.'
              }
            });
            //this.navigateToPrintPreview(this.bitv.applicationId, this.bitv.id);
            this.openBitvPdfFinish(this.bitv.id, 'Pruefbericht');
          },
          error: () => {
            this.snackBar.openFromComponent(SaveConfSnackbarComponent, {
              data: {
                message: 'Etwas ist schief gelaufen. Die abgeschlossene ' +
                  evaluationTypeLabel(this.configurationOptions, this.bitv.evaluationType) +
                  ' wurde nicht gespeichert.'
              }
            });
          }
        });
      }
    });
  }
}


