import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxDropzoneModule } from 'ngx-dropzone';

import { BitvRoutingModule } from './bitv-routing.module';
import { BitvComponent } from './bitv.component';
import { BalmEvaluationComponent } from './balm-evaluation/balm-evaluation.component';
import { BalmContentComponent } from './balm-evaluation/balm-content/balm-content.component';
import { BalmInfoComponent } from './balm-evaluation/balm-content/balm-info/balm-info.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BitvScreenshotComponent } from './balm-evaluation/balm-content/balm-screenshot/bitv-screenshot.component';
import { BitvAddScreenshotComponent } from './balm-evaluation/balm-content/balm-screenshot/bitv-add-screenshot/bitv-add-screenshot.component';
import { BitvImageSelectionComponent } from './balm-evaluation/balm-content/balm-screenshot/bitv-image-selection/bitv-image-selection.component';
import {
  BitvScreenshotItemComponent,
  ScreenshotDialogComponent
} from './balm-evaluation/balm-content/balm-screenshot/bitv-screenshot-item/bitv-screenshot-item.component';
import { SharedModule } from '../shared/shared.module';
import { BitvPrintPreviewComponent } from './bitv-print-preview/bitv-print-preview.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { SidenavModule } from '../shared/sidenav/sidenav.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EvaluationConfigurationComponent } from './evaluation-configuration/evaluation-configuration.component';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PruefgegenstandDialogComponent } from './pruefgegenstand-dialoge/create-edit-pruefgegenstand-dialog/pruefgegenstand-dialog.component';
import { MaterialModule } from '../material/material.module';
import { DeletePruefgegenstandDialogComponent } from './pruefgegenstand-dialoge/delete-pruefgegenstand-dialog/delete-pruefgegenstand-dialog.component';
import { BalmEvaluationOverviewComponent } from './balm-evaluation-overview/balm-evaluation-overview.component';
import { BalmEvaluationProgressionComponent } from './balm-evaluation/balm-evaluation-progression/balm-evaluation-progression.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BalmEvaluationOverviewDashboardComponent } from './balm-evaluation/balm-evaluation-dashboard/balm-evaluation-dashboard.component';
import { BalmTestToolsComponent } from './balm-test-tools/balm-test-tools.component';
import { BalmTestToolsOverviewComponent } from './balm-evaluation/balm-evaluation-dashboard/balm-test-tools-overview/balm-test-tools-overview.component';
import { BalmTestobjectsOverviewComponent } from './balm-evaluation/balm-evaluation-dashboard/balm-testobjects-overview/balm-testobjects-overview.component';
import { EditorComponent } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    BitvComponent,
    BalmEvaluationComponent,
    BalmContentComponent,
    BalmInfoComponent,
    BitvScreenshotComponent,
    BitvAddScreenshotComponent,
    ScreenshotDialogComponent,
    BitvImageSelectionComponent,
    BitvScreenshotItemComponent,
    BitvPrintPreviewComponent,
    EvaluationConfigurationComponent,
    PruefgegenstandDialogComponent,
    DeletePruefgegenstandDialogComponent,
    BalmEvaluationOverviewComponent,
    BalmEvaluationProgressionComponent,
    BalmEvaluationOverviewDashboardComponent,
    BalmTestToolsComponent,
    BalmTestToolsOverviewComponent,
    BalmTestobjectsOverviewComponent
  ],
  imports: [
    CommonModule,
    BitvRoutingModule,
    MatCardModule,
    MatListModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatRadioModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSnackBarModule,
    NgxDropzoneModule,
    SharedModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSelectModule,
    SidenavModule,
    MatTooltipModule,
    MatTableModule,
    MaterialModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    EditorComponent
  ]
})
export class BitvModule {
}
