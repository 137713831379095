<div mat-dialog-title data-cy="dialog-header" [attr.aria-label]="getDialogTypeHint()">
  <mat-icon class="confirmation-dialog-icon" id="confirmation-dialog-icon" aria-hidden="true">{{getIcon()}}</mat-icon>
  <h1 mat-dialog-title class="header-message">{{data.header}}</h1>
</div>

<div mat-dialog-content
     class="display-message"
     data-cy="dialog-message"
     tabindex="0"
     role="document">
</div>
<br>
<div mat-dialog-content
     class="confirmation-question-message"
     data-cy="dialog-confirmation-question-message"
     tabindex="0"
     role="document">
</div>

<mat-dialog-actions [align]="'end'">
  <button id="confirmButton" mat-flat-button class="mat-mdc-unelevated-button-primary" type="button" color="primary"
          (click)="confirm()">
    <mat-icon *ngIf="!!data.confirmOptionIcon"
              id="confirmation-dialog-button-icon">{{data.confirmOptionIcon}}</mat-icon>
    <span>{{data.confirmOptionText}}</span>
  </button>
  <button id="rejectButton" *ngIf="!!data.rejectOptionText" mat-flat-button class="mat-mdc-unelevated-button-secondary"
          type="button"
          (click)="reject()">
    <mat-icon *ngIf="!!data.rejectOptionIcon" id="reject-button-icon">{{data.rejectOptionIcon}}</mat-icon>
    <span>{{data.rejectOptionText}}</span>
  </button>
  <button id="abbrechenButton" mat-flat-button class="mat-mdc-unelevated-button-tertiary" (click)="close()">
    <mat-icon id="confirmation-dialog-cancel-button-icon">close</mat-icon>
    <span>{{data.cancelOptionText}}</span>
  </button>
</mat-dialog-actions>
