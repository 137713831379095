import { Action } from '@ngrx/store';
import { Language } from '../../app-state/settings';
import { Factor } from '../adapter/factor';
import { ActivatedRoute } from '@angular/router';
import { Metric } from '../adapter/metric';
import { TemplateNode } from '../adapter/templateNode';
import { TemplateName } from '../adapter/templateName';
import { Sector } from '../adapter/sector';
import { NfrReceived } from '../adapter/nfrReceived';
import { Nfr } from '../adapter/nfr';
import { MultiNfrRecieved } from '../adapter/multiNfrRecieved';

export enum CatalogActions {
  LoadCatalog = '[Catalog] Load',
  LoadCatalogSuccess = '[Catalog] Load Success',
  LoadCatalogFailure = '[Catalog] Load Failure',
  LoadCanceled = '[Catalog] Load Canceled',
  LoadTemplates = '[Catalog] Load Templates',
  LoadTemplatesSuccess = '[Catalog] Load Templates Success',
  LoadTemplatesFailure = '[Catalog] Load Templates Failure',
  LoadSectors = '[Catalog] Load Sectors',
  LoadSectorsSuccess = '[Catalog] Load Sectors Success',
  LoadSectorsFailure = '[Catalog] Load Sectors Failure',
  LoadTemplateNames = '[Catalog] Load Template Names',
  LoadTemplateNamesSuccess = '[Catalog] Load Template Names Success',
  LoadTemplateNamesFailure = '[Catalog] Load Template Names Failure',
  LoadNfrs = '[Catalog] Load NFRs',
  LoadNfrsSuccess = '[Catalog] Load NFRs Success',
  LoadNfrsFailure = '[Catalog] Load NFRs Failure',
  LoadNfrsForMetric = '[Catalog] Load NFRs for Metric',
  LoadNfrsForMultipleMetric = '[Catalog] Load NFRs for Multiple Metric',
  LoadNfrsForMetricSuccess = '[Catalog] Load NFRs for Metric Success',
  LoadNfrsForMetricFailure = '[Catalog] Load NFRs for Metric Failure',
  LoadNfrsForMultipleMetricSuccess = '[Catalog] Load NFRs for Multiple Metric Success',
  BackToCatalog = '[Catalog] Back To Catalog',
  CreateNewNfr = '[Catalog] Create New NFR',
  CreateNewNfrSuccess = '[Catalog] Create New NFR Success',
  CreateNewNfrFailure = '[Catalog] Create New NFR Failure',
  NavigateToNfr = '[Catalog] Navigate To NFR'

}

export class LoadCatalog implements Action {
  readonly type = CatalogActions.LoadCatalog;

  constructor(public language: Language) {
  }
}

export class LoadCatalogSuccess implements Action {
  readonly type = CatalogActions.LoadCatalogSuccess;

  constructor(public factors: Factor[]) {
  }
}

export class LoadCatalogFailure implements Action {
  readonly type = CatalogActions.LoadCatalogFailure;

  constructor(public payload: { error: string }) {
  }
}

export class LoadCanceled implements Action {
  readonly type = CatalogActions.LoadCanceled;
}

export class LoadSectors implements Action {
  readonly type = CatalogActions.LoadSectors;

  constructor(public language: Language) {
  }
}

export class LoadSectorsSuccess implements Action {
  readonly type = CatalogActions.LoadSectorsSuccess;

  constructor(public sectors: Sector[]) {
  }
}

export class LoadSectorsFailure implements Action {
  readonly type = CatalogActions.LoadSectorsFailure;

  constructor(public payload: { error: string }) {
  }
}

export class LoadTemplates implements Action {
  readonly type = CatalogActions.LoadTemplates;

  constructor(public language: Language) {
  }
}

export class LoadTemplatesSuccess implements Action {
  readonly type = CatalogActions.LoadTemplatesSuccess;

  constructor(public templateNodes: TemplateNode[]) {
  }
}

export class LoadTemplatesFailure implements Action {
  readonly type = CatalogActions.LoadTemplatesFailure;

  constructor(public payload: { error: string }) {
  }
}

export class LoadTemplateNames implements Action {
  readonly type = CatalogActions.LoadTemplateNames;

  constructor(public language: Language) {
  }
}

export class LoadTemplateNamesSuccess implements Action {
  readonly type = CatalogActions.LoadTemplateNamesSuccess;

  constructor(public templateNames: TemplateName[]) {
  }
}

export class LoadTemplateNamesFailure implements Action {
  readonly type = CatalogActions.LoadTemplateNamesFailure;

  constructor(public payload: { error: string }) {
  }
}

export class LoadNfrs implements Action {
  readonly type = CatalogActions.LoadNfrs;

  constructor(public payload: {}) {
  }
}

export class LoadNfrsSuccess implements Action {
  readonly type = CatalogActions.LoadNfrsSuccess;

  constructor(public payload: { allNfrs: NfrReceived[] }) {
  }
}

export class LoadNfrsFailure implements Action {
  readonly type = CatalogActions.LoadNfrsFailure;

  constructor(public payload: { error: string }) {
  }
}

export class LoadNfrsForMetric implements Action {
  readonly type = CatalogActions.LoadNfrsForMetric;

  constructor(public payload: { metricId: string }) {
  }
}

export class LoadNfrsForMultipleMetric implements Action {
  readonly type = CatalogActions.LoadNfrsForMultipleMetric;

  constructor(public payload: { metricIds: string[] }) {
  }
}

export class LoadNfrsForMultipleMetricSuccess implements Action {
  readonly type = CatalogActions.LoadNfrsForMultipleMetricSuccess;

  constructor(public payload: MultiNfrRecieved[]) {
  }
}

export class LoadNfrsForMetricSuccess implements Action {
  readonly type = CatalogActions.LoadNfrsForMetricSuccess;

  constructor(public payload: { nfrs: NfrReceived[], metricId: string }) {
  }
}

export class LoadNfrsForMetricFailure implements Action {
  readonly type = CatalogActions.LoadNfrsForMetricFailure;

  constructor(public payload: { error: string }) {
  }
}

export class NavigateToNfr implements Action {
  readonly type = CatalogActions.NavigateToNfr;

  constructor(public payload: { activatedRoute: ActivatedRoute, metric: Metric, nfrId?: string }) {
  }
}

export class BackToCatalog implements Action {
  readonly type = CatalogActions.BackToCatalog;

  constructor(public payload: ActivatedRoute) {
  }
}

export class CreateNewNfr implements Action {
  readonly type = CatalogActions.CreateNewNfr;

  constructor(public payload: { nfr: Nfr }) {
  }
}

export class CreateNewNfrSuccess implements Action {
  readonly type = CatalogActions.CreateNewNfrSuccess;

  constructor(public payload: { metricId: string }) {
  }
}

export class CreateNewNfrFailure implements Action {
  readonly type = CatalogActions.CreateNewNfrFailure;

  constructor(public payload: { error: string }) {
  }
}

export type CatalogAction = LoadCatalog
  | LoadCatalogSuccess
  | LoadCatalogFailure
  | LoadCanceled
  | LoadTemplates
  | LoadTemplatesSuccess
  | LoadTemplatesFailure
  | LoadSectors
  | LoadSectorsSuccess
  | LoadSectorsFailure
  | LoadTemplateNames
  | LoadTemplateNamesSuccess
  | LoadTemplateNamesFailure
  | LoadNfrsForMetric
  | LoadNfrsForMultipleMetric
  | LoadNfrsForMetricSuccess
  | LoadNfrsForMetricFailure
  | LoadNfrsForMultipleMetricSuccess
  | LoadNfrs
  | LoadNfrsSuccess
  | LoadNfrsFailure
  | CreateNewNfr
  | CreateNewNfrSuccess
  | CreateNewNfrFailure
  | NavigateToNfr
  | BackToCatalog;
