<h1 mat-dialog-title>NFA erstellen für {{data.selectedMetric.label}} ({{data.selectedMetric.identifier}})</h1>

<div mat-dialog-content class="custom-nfr-dialog-content">
  <form [formGroup]="form"
        class="custom-nfr-form"
        autocomplete="off">
    <mat-form-field class="custom-nfr-form__single-row" appearance="outline">
      <mat-label>Name</mat-label>
      <input formControlName="name"
             matInput
             required>
    </mat-form-field>
    <mat-form-field class="custom-nfr-form__single-row" appearance="outline">
      <mat-label>Formulierung</mat-label>
      <textarea matInput
                formControlName="formulation"
                required>
      </textarea>
    </mat-form-field>
    <mat-form-field class="custom-nfr-form__half-row" appearance="outline">
      <mat-label>Priorität</mat-label>
      <mat-select class="form-control"
                  formControlName="priority"
                  placeholder="Priorität">
        <mat-option value="Blocker">Blocker</mat-option>
        <mat-option value="Kritisch">Kritisch</mat-option>
        <mat-option value="Major">Major</mat-option>
        <mat-option value="Normal">Normal</mat-option>
        <mat-option value="Minor">Minor</mat-option>
        <mat-option value="Trivial">Trivial</mat-option>
        <mat-option value="Verbesserung">Verbesserung</mat-option>
        <mat-option value="Bug">Bug</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="custom-nfr-form__half-row" appearance="outline">
      <mat-label>Basiert auf</mat-label>
      <input formControlName="basedOn"
             autocomplete="off"
             matInput>
    </mat-form-field>
    <mat-form-field class="custom-nfr-form__half-row" appearance="outline">
      <mat-label>Kritikalität</mat-label>
      <mat-select formControlName="criticality"
                  class="form-control"
                  placeholder="Kritikalität">
        <mat-option value="hoch">hoch</mat-option>
        <mat-option value="mittel">mittel</mat-option>
        <mat-option value="niedrig">niedrig</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="custom-nfr-form__half-row" appearance="outline">
      <mat-label>Version</mat-label>
      <input formControlName="version"
             matInput>
    </mat-form-field>

    <mat-form-field class="custom-nfr-form__half-row" appearance="outline">
      <mat-label>Schutzbedarf</mat-label>
      <mat-select formControlName="protectionNeeds"
                  class="form-control"
                  placeholder="Schutzbedarf">
        <mat-option value="Streng vertraulich">Streng vertraulich
        </mat-option>
        <mat-option value="Vertraulich">Vertraulich</mat-option>
        <mat-option value="Intern">Intern</mat-option>
        <mat-option value="Öffentlich">Öffentlich</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="custom-nfr-form__single-row" appearance="outline">
      <mat-label>Anmerkung</mat-label>
      <textarea matInput
                formControlName="annotation">
      </textarea>
    </mat-form-field>
  </form>
</div>
<div class="buttonRight">
  <button mat-flat-button class="mat-mdc-unelevated-button-primary" color="primary" (click)="submit()"
  [disabled]="form.invalid">
  <!--TODO Icons sind noch zu groß!!!-->
  <mat-icon>save</mat-icon>
  Speichern
  </button>
  <button mat-flat-button class="mat-mdc-unelevated-button-tertiary " (click)="cancel()">
    <!--TODO Icons sind noch zu groß!!!-->
    <mat-icon>close</mat-icon>
    Abbrechen
  </button>
</div>
