<nfa-mat-spinner-overlay *ngIf="loading"></nfa-mat-spinner-overlay>

<ng-container *ngIf="!loading">
  <div class="page-header">
    <div class="page-header__heading">
      <h1>{{headline}}</h1>
      <button mat-icon-button class="helpBtn" (click)="onHelpButtonClick()" aria-label="Hilfe" data-cy="helpBtn">
        <mat-icon class="helpButtonIcon" matTooltip="Hilfe">help_outline</mat-icon>
      </button>
    </div>
    <div class="page-header__actions">
      <button
        *ngIf="canEdit" mat-flat-button [disabled]="saving" type="button" id="speichern"
        class="mat-mdc-unelevated-button-primary" (click)="onSaveApplication()"
      >
        <!--Primäre Anwendung anlegen/bearbeiten-->
        <mat-icon>save</mat-icon>
        Speichern
      </button>
      <button
        mat-flat-button type="button" id="abbrechen" class="mat-mdc-unelevated-button-tertiary" (click)="cancel()"
        [disabled]="saving"
      >
        <mat-icon>{{ canEdit ? 'close' : 'arrow_left'}}</mat-icon>
        {{ canEdit ? 'Abbrechen' : 'Zurück'}}
      </button>
    </div>
  </div>
  <div class="scrollable-container">
    <div class="page-header__detail">
      <div *ngIf="isExistingApplication">
        <p>Angelegt von {{queryUserNameForId(this.application?.personsInChargeMSG?.creator)}}</p>
        <p>{{lastUpdateString()}}</p>
      </div>
      <p class="page-header__required" *ngIf="canEdit">* Pflichtfelder</p>
    </div>
    <form [formGroup]="form">
      <div>
        <div style="display: flex">
          <mat-card class="application__card application__card--halfway">
            <h3>Metadaten Anwendung</h3>
            <mat-form-field
              [appearance]="appearance" id="application-name" class="inputFieldLong" [class.noHover]="!canEdit"
            >
              <mat-label>Name der Anwendung</mat-label>
              <input
                #applicationName matInput [readonly]="!canEdit" [required]="canEdit" id="name" formControlName="name"
                placeholder="Name der Anwendung" data-cy="applicationName" maxlength="255"
              >
              <mat-hint *ngIf="applicationName.value.length > 200">
                {{applicationName.value.length}} / {{nameMaxLength}}</mat-hint>
              <mat-error>
                {{this.currentError.name}}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              *ngIf="authorizationTen" [appearance]="appearance" id="systemType" class="inputFieldLong"
              [class.noHover]="!canEdit"
            >
              <mat-label>IT-Systemart</mat-label>
              <input
                *ngIf="!canEdit" matInput readonly type="text" [value]="systemTypeAsTextValue"
                [class.noValueInput]="application.systemType === null" data-cy="readOnlySystemType"
              >
              <mat-select
                *ngIf="canEdit" id="systemTypeOptions" formControlName="systemTypeId"
                [disabled]="!this.authorizationTen" data-cy="systemType"
              >
                <mat-option *ngFor="let systemType of systemTypes" [value]="systemType.id" data-cy="namesSystemtypes">
                  {{systemType.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-card>
          <mat-card class="application__card application__card--halfway">
            <h3>Zuordnung Branche und Kunde</h3>
            <mat-form-field
              [appearance]="appearance" id="sector" class="inputFieldLong" [class.noHover]="!canEdit"
            >
              <mat-label>Branche</mat-label>
              <input
                *ngIf="!canEdit" matInput readonly type="text" [value]="sectorAsTextValue" data-cy="sector"
              >
              <mat-select *ngIf="canEdit" required formControlName="sectorIdentifier" data-cy="sector">
                <mat-option *ngFor="let sector of sectors" [value]="sector.id" data-cy="namesSectors">
                  {{sector.name}}
                </mat-option>
              </mat-select>
              <mat-error>
                {{this.currentError.sector}}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              [appearance]="appearance" class="inputFieldLong" [class.noHover]="!canEdit" data-cy="customerName"
            >
              <mat-label>Kundenname</mat-label>
              <input
                #customer matInput [readonly]="!canEdit" [required]="canEdit" id="customer" placeholder="Kundenname"
                formControlName="customerName" data-cy="customerNameValue" maxlength="255"
              >
              <mat-hint *ngIf="canEdit && customer.value.length > 200">
                {{customer.value.length}} / {{customerMaxLength}}
              </mat-hint>
              <mat-error>{{this.currentError.customerName}}</mat-error>
            </mat-form-field>

            <mat-form-field
              [appearance]="appearance"
              class="inputFieldLong"
              [class.noHover]="!canEdit"
            >
              <mat-label>Organisatorische Einheit</mat-label>
              <input
                #orgUnit matInput [readonly]="!canEdit" [value]="canEdit ? null : organizationalUnitAsTextValue"
                maxlength="255" [formControlName]="canEdit ? 'organizationalUnit' : null"
                [class.noValueInput]="!canEdit && application.organizationalUnit === null" data-cy="organizationalUnit"
              >
              <mat-hint *ngIf="orgUnit.value.length > 200" align="end">
                {{orgUnit.value.length}} / {{orgUnitMaxLength}}
              </mat-hint>
            </mat-form-field>
          </mat-card>
        </div>
        <div style="display: flex">
          <mat-card class="application__card application__card--halfway">
            <h3>Mitarbeiter</h3>
            <div [formGroup]="formGroupPersonsInCharge">
              <mat-form-field
                *ngIf="this.isMsgEmployee" [appearance]="appearance" class="inputFieldLong"
                [class.form-field--with-margin]="hasMultipleErrors(formControlOrganizationIdentifier)"
                [class.noHover]="!canEdit"
              >
                <mat-label>Organisation</mat-label>
                <input
                  #organisation matInput [matAutocomplete]="auto" [readonly]="!canEdit" [required]="canEdit"
                  type="text" id="organisation" [formControl]="formControlOrganizationIdentifier"
                  (change)="enableEmployeeInputFields(organisation.value)" data-cy="organization"
                >
                <mat-autocomplete
                  #auto="matAutocomplete" autoActiveFirstOption
                  (optionSelected)="enableEmployeeInputFields($event.option.value)"
                >
                  <mat-option
                    *ngFor="let organization of filteredOrganizations | async" [value]="organization"
                    data-cy="organizationList"
                  >
                    {{organization}}
                  </mat-option>
                </mat-autocomplete>
                <button
                  *ngIf="canEdit" mat-icon-button matSuffix
                  [disabled]="formControlOrganizationIdentifier.value === null" type="button" class="deleteButton"
                  (click)="deleteOrganisation()"
                  title="Organisation löschen"
                  data-cy="deleteOrganization"
                >
                  <mat-icon>delete</mat-icon>
                </button>
                <mat-error *ngIf="formControlOrganizationIdentifier.hasError('required')">
                  {{this.currentError.organizationRequired}}
                </mat-error>
                <mat-error *ngIf="formControlOrganizationIdentifier.hasError('invalid')">
                  {{this.currentError.organizationInvalid}}
                </mat-error>
              </mat-form-field>

              <mat-form-field
                [appearance]="appearance" class="inputFieldLong" [class.noHover]="!canEdit"
                [class.form-field--with-margin]="hasMultipleErrors(formControlIdentifierOfPersonInChargeMSG)"
                data-cy="personInCharge"
              >
                <mat-label>Verantwortlicher Mitarbeiter</mat-label>
                <input
                  matInput
                  [matAutocomplete]="canEdit ? autoPIC : null"
                  type="text"
                  [required]="canEdit"
                  [readonly]="!canEdit"
                  id="personincharge"
                  [value]="canEdit ? null : personInChargeAsTextValue"
                  [formControl]="formControlIdentifierOfPersonInChargeMSG"
                  (focusout)="clearPersonInChargeIfEmpty()"
                  (keyup)="checkDeletedUserFlags.personInChargeCheck = false"
                  data-cy="personInChargeField"
                >
                <mat-autocomplete
                  #autoPIC="matAutocomplete" autoActiveFirstOption id="personinchargeoptions"
                  [displayWith]="queryUserNameForId.bind(this)"
                >
                  <mat-option
                    *ngFor="let person of getUsersExcept(formControlIdentifierOfPersonInChargeMSG.value)"
                    [value]="person.id" data-cy="personInChargeList"
                  >
                    {{person.firstName}} {{person.lastName}} ({{person.email}})
                  </mat-option>
                </mat-autocomplete>
                <button
                  *ngIf="canEdit" mat-icon-button matSuffix
                  [disabled]="formControlIdentifierOfPersonInChargeMSG.value === null" type="button"
                  class="deleteButton" (click)="deletePersonInCharge(); checkDeletedUserFlags.personInChargeCheck = false"
                  title="Verantwortlichen Mitarbeiter löschen"
                  data-cy="deletePersonInCharge"
                >
                  <mat-icon>delete</mat-icon>
                </button>
                <mat-error *ngIf="personInChargeRequiredError()">
                  {{this.currentError.personInChargeRequiredError}}
                </mat-error>
                <mat-error *ngIf="formControlIdentifierOfPersonInChargeMSG.hasError('invalid') && !checkDeletedUserFlags.personInChargeCheck">
                  {{this.currentError.personInChargeInvalid}}
                </mat-error>
                <mat-error *ngIf="formControlIdentifierOfPersonInChargeMSG.hasError('invalid') && checkDeletedUserFlags.personInChargeCheck">
                  {{this.currentError.personInChargeDeleted}}
                </mat-error>
                <mat-error *ngIf="personInChargeAndDeputySameError()">
                  {{this.currentError.personInChargeAndDeputySameError}}
                </mat-error>
                <mat-error *ngIf="personInChargeAsMsgEmployeeError()">
                  {{this.currentError.personInChargeAsMsgEmployeeError}}
                </mat-error>
              </mat-form-field>

              <mat-form-field
                [appearance]="appearance"
                class="inputFieldLong"
                [class.noHover]="!canEdit"
                [class.form-field--with-margin]="hasMultipleErrors(formControlIdentifierOfDeputyPersonInChargeMSG)"
                data-cy="deputyPersonInCharge"
              >
                <mat-label>Stellvertretender verantwortlicher Mitarbeiter</mat-label>
                <input
                  matInput
                  [matAutocomplete]="canEdit ? autoDPIC : null"
                  type="text"
                  [readonly]="!canEdit"
                  [value]="canEdit ? null : deputyPersonInChargeAsTextValue"
                  [formControlName]="canEdit ? 'identifierOfDeputyPersonInChargeMSG' : null"
                  id="deputypersonincharge"
                  [class.noValueInput]="formControlIdentifierOfDeputyPersonInChargeMSG.value === null"
                  (focusout)="clearDeputyPersonInChargeIfEmpty()"
                  (keyup) ="checkDeletedUserFlags.deputyCheck = false"
                  data-cy="deputyPersonInChargeField"
                >
                <mat-autocomplete
                  autoActiveFirstOption #autoDPIC="matAutocomplete" [displayWith]="queryUserNameForId.bind(this)"
                >
                  <mat-option
                    *ngFor="let person of getUsersExcept(formControlIdentifierOfDeputyPersonInChargeMSG.value)"
                    [value]="person.id" data-cy="deputyPersonInChargeList">
                    {{person.firstName}} {{person.lastName}} ({{person.email}})
                  </mat-option>
                </mat-autocomplete>
                <button
                  *ngIf="canEdit" mat-icon-button matSuffix
                  [disabled]="formControlIdentifierOfDeputyPersonInChargeMSG.value === null" type="button"
                  class="deleteButton" (click)="deleteDeputyPersonInCharge(); checkDeletedUserFlags.deputyCheck = false"
                  title="Stellvertretenden verantwortlichen Mitarbeiter löschen" data-cy="deleteDeputyPersonInCharge"
                >
                  <mat-icon>delete</mat-icon>
                </button>
                <mat-error *ngIf="formControlIdentifierOfDeputyPersonInChargeMSG.hasError('invalid') && !checkDeletedUserFlags.deputyCheck">
                  {{this.currentError.deputyInvalid}}
                </mat-error>
                <mat-error *ngIf="formControlIdentifierOfDeputyPersonInChargeMSG.hasError('invalid') && checkDeletedUserFlags.deputyCheck">
                  {{this.currentError.deputyDeleted}}
                </mat-error>
                <mat-error *ngIf="personInChargeAndDeputySameError()">
                  {{this.currentError.deputyAndPersonInChargeSameError}}
                </mat-error>
                <mat-error *ngIf="deputyAsMsgEmployeeError()">
                  {{this.currentError.deputyAsMsgEmployeeError}}
                </mat-error>
              </mat-form-field>

              <mat-form-field
                [appearance]="appearance" subscriptSizing="dynamic" id="employee-input" class="inputFieldLong"
                [class.noHover]="!canEdit" data-cy="employees"
              >
                <mat-label>Weitere Mitarbeiter</mat-label>
                <input
                  *ngIf="!canEdit" matInput readonly type="text" [value]="employeeListAsTextValue"
                  [class.noValueInput]="formControlEmployeesIdentifier.value?.length === 0" data-cy="readOnlyEmployees"
                >

                <mat-select
                  *ngIf="canEdit" multiple disabled id="employeeoptions" formControlName="employeesIdentifier"
                  data-cy="employeesField"
                >
                  <mat-option
                    *ngFor="let user of furtherEmployeeIds"
                    [value]="user"
                    data-cy="employeesList"
                  >
                    {{getUserForId(user)}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formControlEmployeesIdentifier.hasError('deletedEmployees')">
                  {{this.currentError.employeeDeleted}}
                </mat-error>
                <mat-error *ngIf="personInChargeAsMsgEmployeeError()">
                  {{this.currentError.employeeAsPersonInCharge}}
                </mat-error>
                <mat-error *ngIf="deputyAsMsgEmployeeError()">
                  {{this.currentError.employeeAsDeputy}}
                </mat-error>
              </mat-form-field>
            </div>
          </mat-card>
          <mat-card
            *ngIf="authorizationTen else platzhalter"
            class="application__card application__card--halfway"
          >
            <h3>Phase Anwendung</h3>
            <mat-form-field [appearance]="appearance" id="phase" class="inputFieldLong" [class.noHover]="!canEdit">
              <!-- Anwendungsphase -->
              <mat-label>Name</mat-label>
              <input
                *ngIf="!canEdit" matInput readonly type="text" [value]="namePhaseAsTextValue"
                [class.noValueInput]="application.qualityProcesses.length === 0" data-cy="readOnlyNamePhase"
              >
              <mat-select
                *ngIf="canEdit" required formControlName="phase" (selectionChange)="changeProcessPhase($event.value)"
                data-cy="namePhase"
              >
                <mat-option value='Initialisierung'>
                  Initialisierung
                </mat-option>
                <mat-option value='Definition'>
                  Definition
                </mat-option>
                <mat-option value='Realisierung'>
                  Realisierung
                </mat-option>
              </mat-select>
              <mat-error>
                {{this.currentError.phase}}
              </mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="appearance" id="phase" class="inputFieldLong" [class.noHover]="!canEdit">
              <mat-label>Anforderungsdokument</mat-label>
              <input
                *ngIf="!canEdit" matInput readonly type="text" [value]="documentAsTextValue"
                [class.noValueInput]="application.qualityProcesses.length === 0" data-cy="readOnlyDocument"
              >
              <mat-select
                *ngIf="canEdit" [required]="phase !== null" [disabled]="phase===null"
                id="document" formControlName="document" data-cy="document"
              >
                <span *ngFor="let doc of allDocuments">
                  <mat-option
                    disabled *ngIf="phase === 'Initialisierung' && (doc.identifier === '1')"
                    [value]="doc.identifier"
                  >
                    {{doc.description}}
                  </mat-option>
                  <mat-option
                    disabled *ngIf="phase === 'Definition' && (doc.identifier === '2')"
                    [value]="doc.identifier"
                  >
                    {{doc.description}}
                  </mat-option>
                  <mat-option
                    *ngIf="phase === 'Realisierung' && (doc.identifier === '3' || doc.identifier === '4' || doc.identifier === '5' || doc.identifier === '6')"
                    [value]="doc.identifier" data-cy="namesDocuments"
                  >
                    {{doc.description}}
                  </mat-option>
                </span>
              </mat-select>
              <mat-error>
                {{this.currentError.document}}
              </mat-error>
            </mat-form-field>

          </mat-card>
          <ng-template #platzhalter>
            <div class="application__card application__card--halfway"></div>
          </ng-template>
        </div>
      </div>
    </form>
  </div>
</ng-container>
