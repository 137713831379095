import { createAction, props } from '@ngrx/store';
import { Branch } from '../../nfa-packages/adapter/branch';
import { RequirementsDocument } from '../model/requirementsDocument';
import { SystemType } from '../model/systemType';

export const loadBranches = createAction('[Application] Load Branches');
export const loadBranchesSuccess = createAction('[Application] Load Branches Success',
  props<{ branches: Branch[] }>());
export const loadDocuments = createAction('[Application] Load Documents Success');
export const loadDocumentsSuccess = createAction('[Application] Load Documents',
  props<{ documents: RequirementsDocument[] }>());
export const loadSystemTypes = createAction('[Application] Load Documents Success');
export const loadSystemTypesSuccess = createAction('[Application] Load System Types',
  props<{ systemTypes: SystemType[] }>());
export const loadFailure = createAction('[Application] Load Failure',
  props<{ error: string[] | string }>());
export const exportToJira = createAction('[Application] Export To Jira');
export const exportToJiraSuccess = createAction('[Application] Export To Jira Successful');
export const exportToJiraFailure = createAction('[Application] Export To Jira Failure');
export const nfasReadyToExport = createAction('[Application] NFAs Ready To Export To Jira');
export const resetJiraState = createAction('[Application] Reset Jira State');
