import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BitvService } from '../../bitv.service';
import { Pruefgegenstand } from '../../types/pruefgegenstand';

@Component({
  selector: 'nfa-delete-pruefgegenstand-dialog',
  templateUrl: './delete-pruefgegenstand-dialog.component.html',
  styleUrls: ['./delete-pruefgegenstand-dialog.component.scss']
})
export class DeletePruefgegenstandDialogComponent {

  deleting: boolean = false;
  pruefgegenstand: {pruefgegenstand: Pruefgegenstand, edit: boolean, applicationId: number};
  serverError: boolean = false;
  serverErrorStatus: number;

  constructor(
    public dialogRef: MatDialogRef<DeletePruefgegenstandDialogComponent>,
    public bitvService: BitvService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {pruefgegenstand: Pruefgegenstand, edit: boolean, applicationId: number}
  ) {
    this.pruefgegenstand = {...data};
  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }

  deletePruefgegenstand() {
    this.serverError = false;
    this.deleting = true
    this.bitvService.deletePruefgegenstand(this.pruefgegenstand.pruefgegenstand.id).subscribe({
      next: () => {
        this.deleting = false;
        this.dialogRef.close({event: 'Delete'})
      },
      error: (error) => {
        this.deleting = false;
        this.serverError = true;
        this.serverErrorStatus = error.error.status;
      }
    })
  }
}
