import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'nfa-no-access-error-page',
  templateUrl: './no-access-error-page.component.html',
  styleUrls: ['./no-access-error-page.component.scss']
})
export class NoAccessErrorPageComponent implements OnInit {

  mode: string;
  noWriteAccess: boolean;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.mode = params.mode;
      this.noWriteAccess = this.mode === "noWriteAccess";
    });
  }
}
