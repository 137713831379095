export const nfrForSystemTypes = {

  1: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    571, 572, 573, 574],  //  Systeme mit einer Benutzeroberfläche

  2: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 239, 240, 241, 242, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    494, 495, 496, 497,
    571, 572, 573, 574], //  Informationssystem
// NFA IDs, die durch das Informationssystem hinzukommen
//    89, 90,
// , 239, 240, 241, 242, 243, 244, 245, 248, 249, 250, 251
// , 494, 495, 496, 497

  3: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    101, 102, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 239, 240, 241, 242, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    421, 422, 494, 495, 496, 497,
    571, 572, 573, 574], //  Management Informationssystem

  // 101, 102,
  // 421,422,

  4: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 239, 240, 241, 242, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    494, 495, 496, 497,
    571, 572, 573, 574], //  Führungsinformationssystem

  5: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 239, 240, 241, 242, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    494, 495, 496, 497,
    571, 572, 573, 574], //  Personalinformationssystem

  6: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 239, 240, 241, 242, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    494, 495, 496, 497,
    571, 572, 573, 574], //  Marketinginformationssystem

  7: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 239, 240, 241, 242, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    494, 495, 496, 497,
    571, 572, 573, 574], //  Buchhaltungsinformationssystem

  8: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 239, 240, 241, 242, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    494, 495, 496, 497,
    571, 572, 573, 574], //  Strategisches Informationssystem

  9: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 239, 240, 241, 242, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    494, 495, 496, 497,
    571, 572, 573, 574], //  Lokale Datenbank

  10: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    571, 572, 573, 574], //  Anwendungssystem

// 89, 90
// 243, 244, 245, 248, 249, 250, 251

  11: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 161,
    234, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331, 392, 393, 394, 395, 396,
    400,
    571, 572, 573, 574], // Transaktionsverarbeitungssystem

  // 161,
  // 392, 393, 394, 395, 396
  // 400

  12: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    441, 490, 491, 492, 493,
    571, 572, 573, 574], //  Prozessleitsystem

  // 26
  // 441, 490,491, 492, 493

  13: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258, 275, 276,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    400,
    571, 572, 573, 574], // Büroautomatisierungssystem

// 275, 276
// 400

  14: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    571, 572, 573, 574], // Management Unterstützungssystem

  15: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    571, 572, 573, 574], // Entscheidungsunterstützungssystem

  16: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    571, 572, 573, 574], // Experten Systeme

  17: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    571, 572, 573, 574], // Wissensmanagementsystem

  18: [3, 4, 5, 6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 89, 90,
    121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
    234, 243, 244, 245, 248, 249, 250, 251, 254, 255, 256, 257, 258,
    301, 302, 303, 304, 314, 315, 316, 317, 318, 319, 320, 321, 325, 326, 327, 328, 329, 330, 331,
    571, 572, 573, 574] // Funktionales Geschäftssystem
};

