<ng-container *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
</ng-container>
<div class="scrollable-container">
  <div *ngIf="!loading" class="print-container">
  <button class="print-container__print-button mat-mdc-unelevated-button-primary" mat-flat-button color="primary"
          (click)="print()">
    <mat-icon>print</mat-icon>
    Drucken
  </button>
  <button class="print-container__back-button mat-mdc-unelevated-button-tertiary" mat-flat-button
          routerLink="/applications/{{application.id}}/balm">
    <mat-icon>arrow_left</mat-icon>
    Zurück
  </button>

  <div class="print-document">
    <section class="title-page">
      <div class="title-page__msg-logo">
        <img src="../../../assets/images/msg-logo.png" alt="msg Logo">
        <span class="title-page__msg-mission-statement">.consulting .solutions .partnership</span>
      </div>
      <img class="title-page__msg-hq" src="../../../assets/images/headerBITV.png" alt="msg Kugel">
      <h1>
        Prüfbericht zur {{documentHeaderInformation}} {{application.name}}
      </h1>
      <p>Stand: {{getDate() | date:'dd.MM.yyyy'}}</p>
    </section>

    <section class="toc-page">
      <h2>Inhaltsverzeichnis</h2>
      <ul class="toc">
        <li class="toc-entry">
          <a href="#summary" class="toc-entry__item--print">I Zusammenfassung der Prüfgegenstände</a>
          <span class="toc-entry__item">I Zusammenfassung</span>
        </li>
        <li class="toc-entry">
          <a href="#testResults" class="toc-entry__item--print">II Testergebnisse</a>
          <span class="toc-entry__item">II Testergebnisse</span>
        </li>
        <li class="toc-entry">
          <a href="#impressum" class="toc-entry__item--print">III Impressum</a>
          <span class="toc-entry__item">III Impressum</span>
        </li>
      </ul>
    </section>

    <section>
      <h2 id="summary">I Zusammenfassung der Prüfgegenstände</h2>
      <div *ngFor="let testobject of testobjects">
        <div id="summary{{testobject.name}}">
          <h3 id="summaryName{{testobject.name}}">{{testobject.name}}</h3>
          <p id="summaryURL{{testobject.name}}">URL: {{testobject.url}}</p>
          <p id="summaryConf{{testobject.name}}">Konformitätsstufe: {{testobject.conformityLevel}}</p>
        </div>
        <div class="print-box">
          <table class="print-table-balm print-table-balm--summary">
            <thead>
            <tr>
              <th class="print-table-balm--summary__header">
                <strong>Nicht getestet</strong>
              </th>
              <th class="print-table-balm--summary__header">
                <strong>Nicht anwendbar</strong>
              </th>
              <th class="print-table-balm--summary__header">
                <strong>Erfüllt</strong>
              </th>
              <th class="print-table-balm--summary__header">
                <strong>Nicht erfüllt</strong>
              </th>
              <th class="print-table-balm--summary__header">
                <strong>Gesamtbewertung</strong>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td id="notTested{{testobject.name}}" class="print-table-balm--summary__step-result">
                {{notTestedSteps(testobject)}}
              </td>
              <td id="notApplicable{{testobject.name}}" class="print-table-balm--summary__step-result">
                {{notApplicableSteps(testobject)}}
              </td>
              <td id="fulfilled{{testobject.name}}" class="print-table-balm--summary__step-result">
                {{fulfilledSteps(testobject)}}
              </td>
              <td id="notFulfilled{{testobject.name}}" class="print-table-balm--summary__step-result">
                {{notFulfilledSteps(testobject)}}
              </td>
              <td id="overallSummary{{testobject.name}}" class="print-table-balm--summary__step-result">
                {{getSummary(testobject)}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <section *ngIf="screeshotsMap.size === 0 || (screenshots | async) as allScreenshots">
      <h2 id="testResults"> II Testergebnisse</h2>
      <ng-container *ngFor="let testobject of testobjects">
        <h3>{{testobject.name}}</h3>

        <table class="print-table-balm print-table-balm--summary print-table-balm--with-padding">
          <thead>
          <tr>
            <th class="print-table-balm--summary__header">
              <strong>Prüfschritt</strong>
            </th>
            <th class="print-table-balm--summary__header">
              <strong>Ergebnis</strong>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let evaluationstep of testobject.bitvEvaluationSteps">
            <td class="print-table-balm--summary__step-result">
              {{evaluationstep.evaluationStep.number}}: {{evaluationstep.evaluationStep.name}}
            </td>
            <td class="print-table-balm--summary__step-result">
              {{evaluationstep.evaluationresult || 'Nicht bewertet'}}
            </td>
          </tr>
          </tbody>
        </table>

        <ng-container *ngFor="let category of categoryMapByObjectId.get(testobject.testobjectId)">
          <ng-container *ngFor="let evaluationStep of category.children">
            <h4 class="evaluation-step--detail">
              <span class="sub-heading">{{category.name}}</span>
              {{evaluationStep.number}}: {{evaluationStep.name}}
            </h4>
            <div>
              <p>
                <strong>Bewertung: </strong>  {{getEvaluationStepObjectFromTreenode(evaluationStep).evaluationresult || 'Nicht bewertet'}}
              </p>
              <p><strong>Zusammenfassung: </strong>
                <ng-container
                  *ngIf="getEvaluationStepObjectFromTreenode(evaluationStep).comment"> {{getEvaluationStepObjectFromTreenode(evaluationStep).comment}}</ng-container>
                <i *ngIf="!getEvaluationStepObjectFromTreenode(evaluationStep).comment">Keine Zusammenfassung</i>
              </p>
              <strong>Screenshots: </strong>
              <i>{{hasEvaluationStepScreenshots(evaluationStep) ? '' : 'Keine Screenshots'}}</i>
              <figure
                *ngFor="let screenshot of getScreenshotsForEvaluationStep(evaluationStep, allScreenshots); let i = index"
                class="screenshot-container">
                <img alt="Screenshot: {{screenshot.comment}}"
                     class="screenshot"
                     src="{{screenshot.dataUrl}}">
                <figcaption class="screenshot-text" aria-hidden="true">
                  {{screenshot.comment}}
                </figcaption>
              </figure>
            </div>
            <mat-divider style="margin: 8px 0 16px 0;"></mat-divider>
          </ng-container>
        </ng-container>

      </ng-container>
    </section>

    <section *ngIf="!loadingAbout" class="impressum">
      <h2 id="impressum">III Impressum</h2>
      <div class="about" [innerHTML]="about">
      </div>
    </section>

  </div>
</div>
</div>
