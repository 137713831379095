import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Factor } from '../../catalog/adapter/factor';
import { Criterion } from '../../catalog/adapter/criterion';
import { Metric } from '../../catalog/adapter/metric';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NfrReceived } from 'src/app/catalog/adapter/nfrReceived';

@Component({
  selector: 'nfa-information-header',
  templateUrl: './information-header.component.html',
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(180deg)'})),
      state('expanded', style({transform: 'rotate(0deg)'})),
      transition('expanded <=> collapsed',
        animate('800ms cubic-bezier(0.2,0.2,0.2,1)')
      )
    ])
  ]
})
export class InformationHeaderComponent {

  @Input() factor: Factor | null = null;
  @Input() criterion: Criterion | null = null;
  @Input() metrics: Metric[] = [];
  @Input() selectedMetric: Metric | null = null;
  @Input() nfrRecommendations: number[];
  @Input() nfrsOfMetrics: { [metricId: string]: NfrReceived[] | undefined };
  @Input() showRecommendations = false;
  @Output() selectedMetricChanged = new EventEmitter<Metric>();

  hideFactor = true;
  hideCriterion = true;
  hideMetric = true;

  toggleFactor(): void {
    this.hideFactor = !this.hideFactor;
  }

  toggleCriterion(): void {
    this.hideCriterion = !this.hideCriterion;
  }

  toggleMetric(): void {
    this.hideMetric = !this.hideMetric;
  }

  selectMetric(metric: Metric) {
    if (this.selectedMetric !== metric) {
      this.selectedMetricChanged.emit(metric);
      this.selectedMetric = metric;
    }
  }

  hasCriterionUnitTestRecommendation(criterion: Criterion): boolean {
    // tslint:disable-next-line:radix
    return Number.parseInt(criterion.identifier) < 6;
  }

  containsRecommendations(metric: Metric): boolean {
    let contains = false;
    const nfrIdForMetric: number[] = (this.nfrsOfMetrics[metric.identifier] !== undefined ?
      this.nfrsOfMetrics[metric.identifier] :
      []).map(nfr => nfr.id);
    for (const id of nfrIdForMetric) {
      if (this.nfrRecommendations.includes(id)) {
        contains = true;
        break;
      }
    }
    return contains && this.showRecommendations;
  }

  /*  downloadPDF(id: number, language: string) {
     this.applicationService.requestPDFs('de', id).subscribe();
   toggleShowRecommendations() {
     this.showRecommendations = !this.showRecommendations;
   } */
}
