<form>
  <div class="screenshot-form">
    <nfa-bitv-image-selection></nfa-bitv-image-selection>
    <mat-form-field class="comment-field comment-screenshot" appearance="outline">
      <mat-label>Beschreibung</mat-label>
      <textarea matInput name="comment" rows="6" data-cy="screenshot-comment-field" placeholder="Dieser Screenshot zeigt..."
                [(ngModel)]="comment"></textarea>
    </mat-form-field>
    <div class="screenshot-buttons">
      <button mat-icon-button  data-cy="screenshot-check-button" (click)="uploadScreenshot()">
        <mat-icon>check_circle</mat-icon>
      </button>
      <br>
      <button mat-icon-button (click)="dismiss()">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</form>
