<div mat-dialog-title data-cy="dialog-header" [attr.aria-label]="'Löschen Dialogfeld'">
  <h1 mat-dialog-title id="pruefgegenstandLoeschenDialog" class="header-message">Prüfgegenstand löschen</h1>
</div>
  <mat-dialog-content>
    <p id="anzeige-pruefgegenstand">
      Möchten Sie den Prüfgegenstand unwiderruflich löschen?
      <br> <br>
        <strong>Name: </strong><strong id="name">{{pruefgegenstand.pruefgegenstand.name}}</strong><br>
        <strong>Typ: </strong><strong id="typ">{{pruefgegenstand.pruefgegenstand.typ}}</strong> <br>
        <strong>URL: </strong><strong id="url">{{pruefgegenstand.pruefgegenstand.url}}</strong> <br>
    </p>

    <div id="serverError" *ngIf="serverError">
      <div class="serverError">
        <mat-icon>warning_amber</mat-icon> <span>Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</span>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" id="pruefgegenstandLoeschenDialogButtons">
    <button id="loeschenButton" mat-flat-button [disabled]="deleting" class="mat-mdc-unelevated-button-primary" color="primary"
      (click)="deletePruefgegenstand()">
      <mat-icon>delete</mat-icon>
      <span>Löschen</span>
    </button>
    <button id="abbrechenButton" mat-flat-button class="mat-mdc-unelevated-button-tertiary" [disabled]="deleting"
      (click)="closeDialog()">
      <mat-icon>close</mat-icon>
      <span>Abbrechen</span>
    </button>
  </mat-dialog-actions>
