<div class="balm-focus">
  <button mat-button (click)="toggleHow()" data-cy="showHow" [attr.aria-label]="showHow ? 'zuklappen' : 'aufklappen'">
    <h3>
      Wie wird geprüft?
      <mat-icon>{{showHow ? 'expand_less' : 'expand_more'}}</mat-icon>
    </h3>
  </button>
</div>
<div class='mobile'  [innerHTML]="how || '<p><em>Aktuell keine Beschreibung verfügbar</em></p>'" *ngIf="showHow" data-cy="how"></div>

<div class="balm-focus">
  <button mat-button (click)="toggleWhat()" data-cy="showWhat"  [attr.aria-label]="showWhat ? 'zuklappen' : 'aufklappen'">
    <h3>
      Was wird geprüft?
      <mat-icon>{{showWhat ? 'expand_less' : 'expand_more'}}</mat-icon>
    </h3>
  </button>
</div>

<div class='mobile' [innerHTML]="what || '<p><em>Aktuell keine Beschreibung verfügbar</em></p>'" *ngIf="showWhat" data-cy="what"></div>

<div class="balm-focus">
  <button mat-button (click)="toggleWhy()" data-cy="showWhy" [attr.aria-label]="showWhy ? 'zuklappen' : 'aufklappen'">
    <h3>
      Warum wird das geprüft?
      <mat-icon>{{showWhy ? 'expand_less' : 'expand_more'}}</mat-icon>
    </h3>
  </button>
</div>

<div class='mobile' [innerHTML]="why || '<p><em>Aktuell keine Beschreibung verfügbar</em></p>'" *ngIf="showWhy" data-cy="why"></div>

<div class="balm-focus">
  <button mat-button (click)="toggleOverallDetails()"
          [attr.aria-label]="showOverallDetails ? 'zuklappen' : 'aufklappen'">
    <h3 class="section-header">Allgemeine Informationen
      <mat-icon>{{showOverallDetails ? 'expand_less' : 'expand_more'}}</mat-icon>
    </h3>
  </button>
</div>

<div *ngIf="showOverallDetails"
     class="bitv-content-section balm-information"
     nfaDisableCopyPaste>
  <div class="grid-container">
    <div>Prüfschritt erfüllt:</div>
    <div [innerHTML]="evaluationstepMeta.fullfillmentcriteria || '-'"></div>
    <div>Prüfschritt nicht anwendbar:</div>
    <div [innerHTML]="evaluationstepMeta.unapplicablecriteria || '-'"></div>
    <div>Bezieht sich auf:</div>
    <div [innerHTML]="evaluationstepMeta.refersto || '-'"></div>
  </div>
  <div class="bitv-content-section" nfaDisableCopyPaste>
    <div class="grid-container" *ngIf="evaluationstepMeta.accessibilityRequirements">
      <div>Barrierefreiheits&shy;erfordernisse:</div>
      <div *ngIf="evaluationstepMeta.accessibilityRequirements.length > 1">
        <ul>
          <li *ngFor="let requirement of evaluationstepMeta.accessibilityRequirements">
            {{requirement.name}}
          </li>
        </ul>
      </div>
      <div *ngIf="evaluationstepMeta.accessibilityRequirements.length === 1">
        {{evaluationstepMeta.accessibilityRequirements[0].name}}
      </div>
    </div>
  </div>
  <div class="bitv-content-section" nfaDisableCopyPaste>
    <div class="grid-container">
      <ng-container *ngIf="evaluationstepMeta.wcag_principles">
        <div>WCAG-Prinzipien:</div>
        <div>{{evaluationstepMeta.wcag_principles}}</div>
      </ng-container>
      <ng-container *ngIf="evaluationstepMeta.wcag_successcriteria">
        <div>WCAG-Erfolgskriterium:</div>
        <div>{{evaluationstepMeta.wcag_successcriteria}}</div>
      </ng-container>
      <ng-container *ngIf="evaluationstepMeta.wcag_conformityLevel">
        <div>WCAG-Konformitätslevel:</div>
        <div>{{evaluationstepMeta.wcag_conformityLevel}}</div>
      </ng-container>
    </div>
  </div>
</div>

