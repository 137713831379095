import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { AppState } from './app.reducer';

@Injectable()
export class CatalogEffects {

  constructor(
    private readonly store$: Store<AppState>,
    private readonly actions$: Actions,
    private readonly keycloak: KeycloakService) {
  }

}
