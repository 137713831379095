<div class="page-header">
  <div class="page-header__heading">
    <button mat-flat-button
            class = "mat-mdc-unelevated-button-primary"
            color="primary"
            (click)="navbarExpanderClick(sharedSidenav)"
            aria-label="Navigationsbereich ein/ausblenden">
      <mat-icon class="navbar__expand-toggle--expanded icon-no-margin" *ngIf="navbarExpanded">chevron_left</mat-icon>
      <mat-icon class="navbar__expand-toggle--collapsed icon-no-margin" *ngIf="!navbarExpanded">chevron_right</mat-icon>
    </button>
    <h1>QuMaP-Hilfebereich</h1>
  </div>
</div>

<mat-sidenav-container>

    <mat-sidenav #sharedSidenav mode="side" [opened]="navbarExpanded" class="mat-sidenav--fixed-height" data-cy="help-sidenav">
      <nfa-sidenav [treeRoots]="headers"
                   [documentIdentifier]="documentIdentifier"
                   (itemSelected)="loadHelpArticle($event.textId)">
      </nfa-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="mat-sidenav-container--leanhelp">
      <mat-card class="mat-card--no-padding-top mat-elevation-z0">

        <mat-card-content data-cy="help-content">
          <div>
            <div class="qumap-help" #helpContent>
            </div>
            <div *ngIf="(authorizationTen)" class="tenonly" #tenonly>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
