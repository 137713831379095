import { BalmEvaluationStepNode, Evaluationstep } from '../types/evaluationstep';
import { Treenode, TreenodeCategory } from '../../shared/sidenav/treenode';

export class BalmStepsHelper {

  static assignEvaluationStepsToCategories = (
    allEvaluationsteps: Evaluationstep[],
    bitvCategoryTree: Treenode[]): Treenode[] => {
    const tempHeaders = BalmStepsHelper.buildBitvCategoryTree(bitvCategoryTree);
    BalmStepsHelper.sortEvaluationStepsByNumber(allEvaluationsteps)
      .forEach(evaluationStep => {
        const categoryNode: Treenode = bitvCategoryTree.find(category =>
          category.id === evaluationStep.evaluationStep.bitvCategorieId
        );
        const evaluationStepNode: Treenode = new BalmEvaluationStepNode(
          evaluationStep.evaluationStep.id,
          evaluationStep.evaluationStep.name,
          TreenodeCategory.LEAF,
          evaluationStep,
          evaluationStep.evaluationStep.number,
          categoryNode
        );
        evaluationStepNode.parent_id = categoryNode.id;
        categoryNode.children.push(evaluationStepNode);
      });

    return tempHeaders.filter(header => header.children !== undefined && header.children.length > 0);
  };

  static buildBitvCategoryTree = (bitvCategories: Treenode[]): Treenode [] => {
    const headers: Treenode[] = [];

    bitvCategories
      .filter(category => category.parent_id === 0)
      .forEach(parentCategory => {

        const parentCategoryIndex = headers.push({
          name: parentCategory.name,
          designation: parentCategory.designation,
          expanded: true,
          category: TreenodeCategory.ROOT,
          authorization: true,
          id: parentCategory.id
        });

        parentCategory.children = bitvCategories
          .filter(category => category.parent_id !== 0).filter(childCategory => childCategory.parent_id === parentCategory.id);
        parentCategory.children.forEach(childCategory => {
          childCategory.category = TreenodeCategory.NODE;
          childCategory.authorization = true;
          childCategory.expanded = false;
          childCategory.parent = parentCategory;
        });
        headers[parentCategoryIndex - 1].children = parentCategory.children;
      });

    return headers;
  };

  static sortEvaluationStepsByNumber = (steps: Evaluationstep[]): Evaluationstep[] => {

    return [...steps].map(BalmStepsHelper.formatEvaluationStepsForSorting)
      .sort(BalmStepsHelper.compareEvaluationSteps)
      .map(BalmStepsHelper.reformatEvaluationStepsForDisplaying);
  };

  static compareEvaluationSteps = (a: Evaluationstep, b: Evaluationstep) => a.evaluationStep.number > b.evaluationStep.number ? 1 : -1;

  static formatEvaluationStepsForSorting = (step: Evaluationstep): Evaluationstep => {
    step.evaluationStep.number = step.evaluationStep.number.split('.')
      .map(n => (/^[0-9]+$/g.test(n)) ? +n + 100 : `10${n}`).join('.');

    return step;
  };

  static reformatEvaluationStepsForDisplaying = (step: Evaluationstep): Evaluationstep => {
    step.evaluationStep.number = step.evaluationStep.number.split('.')
      .map(n => (/^[0-9]+$/g.test(n)) ? +n - 100 : n.substring(2)).join('.');

    return step;
  };
}
