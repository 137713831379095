import { Injectable } from '@angular/core';
import { Application, ApplicationDTO } from './model/application';
import { QualityProcess } from './model/qualityProcess';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Sector } from './model/sector';
import { RequirementsDocument } from './model/requirementsDocument';
import { User } from './model/user';
import { ApplicationReceived } from './model/applicationReceived';
import { QualityProcessReceived } from './model/qualityProcessReceived';
import { CustomApplicationNfr, ApplicationNfr } from './model/applicationNfr';
import { SystemType } from './model/systemType';
import { nfrForSystemTypes } from './mock';
import { Store } from '@ngrx/store';
import { AppState, getCurrentLanguage } from '../app-state/app.reducer';

@Injectable()
export class ApplicationsService {

  private apiUrl = '/nfabackend/webapi';
  private language?: string;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(getCurrentLanguage).subscribe(
      language => this.language = language,
    );
  }

  createApplication(application: ApplicationDTO, language: string): Observable<Application | ApplicationDTO> {
    return this.http.post<ApplicationDTO>(`${ this.apiUrl }/${ language }/applications`, application).pipe(
      catchError(() => throwError('Anwendung konnte nicht gespeichert werden')),
    );
  }

  updateApplication(applicationIdentifier: number, application: ApplicationDTO, language: string = this.language): Observable<void> {
    return this.http.put<void>(`${ this.apiUrl }/${ language }/applications/${ applicationIdentifier }`, application).pipe(
      catchError(() => throwError('Anwendung konnte nicht aktualisiert werden.')),
    );
  }

  saveQualityProcess(qualityProcess: QualityProcess, language: string = this.language): Observable<QualityProcess> {
    return this.http.post<QualityProcess>(`${ this.apiUrl }/${ language }/qualityprocesses`, qualityProcess).pipe(
      catchError(() => throwError('Qualitätsprozess konnte nicht gespeichert werden.')),
    );
  }

  updateQualityProcess(applicationId: number, qualityProcess: QualityProcess, language: string = this.language): Observable<QualityProcess> {
    return this.http.put<QualityProcess>(`${ this.apiUrl }/${ language }/qualityprocesses/${ applicationId }`, qualityProcess).pipe(
      catchError(() => throwError('Qualitätsprozess konnte nicht aktualisiert werden.')),
    );
  }

  saveApplicationNfr(applicationNfr: ApplicationNfr, language: string = this.language): Observable<ApplicationNfr> {
    return this.http.post<ApplicationNfr>(`${ this.apiUrl }/${ language }/applicationnfrs`, applicationNfr).pipe(
      catchError(() => throwError('')),
    );
  }

  saveMultipleApplicationNfrs(applicationNfrs: ApplicationNfr[], language: string = this.language): Observable<ApplicationNfr[]> {
    return this.http.post<ApplicationNfr[]>(`${ this.apiUrl }/${ language }/applicationnfrs/multiple`, applicationNfrs).pipe(
      catchError(() => throwError('')),
    );
  }

  editMultipleApplicationNfrs(applicationNfrs: ApplicationNfr[], language: string = this.language): Observable<ApplicationNfr[]> {
    return this.http.put<ApplicationNfr[]>(`${ this.apiUrl }/${ language }/applicationnfrs/multiple/edit`, applicationNfrs).pipe(
      catchError(() => throwError('')),
    );
  }

  deleteMultipleApplicationNfrsNfr(deletedApplicationNfrs: ApplicationNfr[], language = this.language): Observable<unknown> {
    return forkJoin(deletedApplicationNfrs.map(process => this.deleteNfrInApplication(process.id, language)));
  }

  // triggers the backend to generate new pdf files.
  generatePpdf(applicationNfrs: ApplicationNfr[], language: string = this.language): Observable<ApplicationNfr[]> {
    return this.http.post<ApplicationNfr[]>(`${ this.apiUrl }/${ language }/applicationnfrs/pdf`, applicationNfrs).pipe(
      catchError(() => throwError('')),
    );
  }

  requestPDFs(id: number, language: string = this.language): Observable<Blob> {
    return this.http
      .get(`${ this.apiUrl }/${ language }/applicationnfrs/pdf/request/${ id }`, { responseType: 'blob' }).pipe(
        catchError((err) => throwError(err)),
      );
  }

  deleteNfrInApplication(applicationNfrId: number, language: string = this.language): Observable<{}> {
    return this.http.delete<number>(`${ this.apiUrl }/${ language }/applicationnfrs/delete/${ applicationNfrId }`).pipe(
      catchError(() => throwError('Not able to delete Application NFR: ' + applicationNfrId)),
    );
  }

  getAllApplications(language: string = this.language): Observable<ApplicationReceived[]> {
    return this.http.get<ApplicationReceived[]>(`${ this.apiUrl }/${ language }/applications`).pipe(
      catchError(() => throwError('')),
    );
  }

  getApplicationById(id: number | string, language: string = this.language): Observable<ApplicationReceived> {
    return this.http.get<ApplicationReceived>(`${ this.apiUrl }/${ language }/applications/${ id }`);
  }

  getAllApplicationNfrs(language: string = this.language): Observable<ApplicationNfr[]> {
    return this.http.get<ApplicationNfr[]>(`${ this.apiUrl }/${ language }/applicationnfrs`);
  }

  getAllQualityProcesses(language: string = this.language): Observable<QualityProcessReceived[]> {
    return this.http.get<QualityProcessReceived[]>(`${ this.apiUrl }/${ language }/qualityprocesses`);
  }

  getAllSectors(language: string = this.language): Observable<Sector[]> {
    return this.http.get<Sector[]>(`${ this.apiUrl }/${ language }/branches`);
  }

  getDocuments(language: string = this.language): Observable<RequirementsDocument[]> {
    return this.http.get<RequirementsDocument[]>(`${ this.apiUrl }/${ language }/documents`).pipe(
      catchError(() => throwError('Dokumente konnten nicht geladen werden.')),
    );
  }

  getAllSystemTypes(language: string = this.language): Observable<SystemType[]> {
    return this.http.get<SystemType[]>(`${ this.apiUrl }/${ language }/systemtypes`).pipe(
      catchError(() => throwError('Not able to get all systemtypes')),
    );
  }

  getNfrOfSystemType(systemTypeId: number): Observable<number[]> {
    // return this.http.get<number[]>(`${this.apiUrl}/${language}/systemtypes/nfr?systemtypeid=${systemTypeId}`).pipe(
    //   catchError(() => throwError('Not able to get nfrs for given systemtype'))
    // );
    // temporärer mock
    return of(nfrForSystemTypes[systemTypeId]); // TODO:backend für den Aufruf implementieren
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${ this.apiUrl }/administration/users`).pipe(
      catchError(() => throwError('Not able to get all users')),
    );
  }

  getAllUsersOfOrganisation(organisationName: string): Observable<User[]> {
    return this.http.get<User[]>(`${ this.apiUrl }/administration/users?groupName=${ organisationName }`).pipe(
      catchError(() => throwError('Not able to get users for ' + organisationName)),
    );
  }

  saveCustomNfr(customNfr: CustomApplicationNfr, language: string = this.language): Observable<CustomApplicationNfr> {
    return this.http.post<CustomApplicationNfr>(`${ this.apiUrl }/${ language }/customnfr`, customNfr).pipe(
      catchError((err) => throwError('Not able to save custom nfr')),
    );
  }

  editMultipleCustomNfrs(customNfrs: CustomApplicationNfr[], language: string = this.language): Observable<CustomApplicationNfr[]> {
    return this.http.put<CustomApplicationNfr[]>(`${this.apiUrl}/${language}/customnfr/multiple/edit`, customNfrs).pipe(
      catchError(() => throwError('Not able to edit custom nfr'))
    );
  }
}
