<div>
  <h1>Keine Rechte</h1>
  <p>Sie besitzen keine Rechte zum Anzeigen dieser Seite. Bitte überprüfen Sie die URL und laden die Seite neu oder gehen Sie zur
    <a href="">Startseite</a>.
  </p>
  <p *ngIf="noWriteAccess">Falls Sie Rechte zum Bearbeiten benötigen, wenden Sie sich bitte an
    den verantwortlichen oder stellvertretenden verantwortlichen Mitarbeiter der Anwendung, damit dieser Sie als Mitarbeiter zuordnet.
  </p>
  <p *ngIf="!noWriteAccess">Falls Sie für die Organisation arbeiten, zu der die Anwendung gehört, deren Daten Sie ansehen möchte, wenden Sie sich bitte an
    <a href="mailto:personendaten.qumap@msg.group">personendaten.qumap@msg.group</a>.
</p>
</div>
