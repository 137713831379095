import { KeycloakService } from 'keycloak-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Application, ApplicationDTO } from '../model/application';
import { ApplicationsService } from '../applications.service';
import { Sector } from '../model/sector';
import { DatePipe, registerLocaleData } from '@angular/common';
import { User } from '../model/user';
import { QualityProcess } from '../model/qualityProcess';
import { RequirementsDocument } from '../model/requirementsDocument';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HilfeFunktionen, MaxLengths } from '../../shared/global';
import { ApplicationReceived } from '../model/applicationReceived';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Language } from '../../app-state/settings';
import { SystemType } from '../model/systemType';
import {
  validateDeletedEmployees,
  validateEmployeesDep,
  validateEmployeesPiC,
  validateOrga,
  validatePersonInChargeDeputee,
  validateUser
} from './employees.validator';
import { AppState } from '../../app-state/app.reducer';
import { Store } from '@ngrx/store';
import { ApplicationState, getBranches, getDocuments, getSystemTypes } from '../application-state/application.reducer';
import { loadBranches, loadDocuments, loadSystemTypes } from '../application-state/application.actions';
import { assertUnreachable } from '../../shared/utils/assert-unreachable';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData
} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { QumapHelpService } from 'src/app/qumap-help/qumap-help.service';
import { SaveConfSnackbarComponent } from '../../shared/save-confirm-snackbar/save-confirm-snackbar-component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export const germanError: Record<string, string> = {
  name: 'Bitte geben Sie einen Namen der Anwendung ein.',
  sector: 'Bitte wählen Sie eine Branche aus.',
  personInChargeMSG: 'Bitte wählen Sie einen msg-Mitarbeiter aus, der die Bearbeitung dieser Anwendung verantwortet.',
  phase: 'Bitte wählen Sie den Namen der Phase aus, in der sich die Anwendung befindet.',
  document: 'Bitte wählen Sie das Dokument aus, für das Sie nicht-funktionale Anforderungen auswählen möchten.',
  organizationRequired: 'Bitte wählen Sie eine Organisation aus.',
  organizationInvalid: 'Bitte wählen Sie eine gültige Organisation aus.',
  personInChargeRequiredError: 'Bitte wählen Sie einen verantwortlichen Mitarbeiter aus.',
  personInChargeInvalid: 'Bitte wählen Sie einen gültigen verantwortlichen Mitarbeiter aus.',
  personInChargeDeleted: 'Bitte wählen Sie einen neuen verantwortlichen Mitarbeiter aus.',
  personInChargeAndDeputySameError: 'Der verantwortliche Mitarbeiter kann nicht derselbe wie der stellvertretende verantwortliche sein.',
  personInChargeAsMsgEmployeeError: 'Der verantwortliche Mitarbeiter ist in der Liste der weiteren Mitarbeiter vorhanden.',
  employeeAsPersonInCharge: 'Einer der weiteren Mitarbeiter ist bereits verantwortlicher Mitarbeiter.',
  deputyInvalid: 'Bitte wählen Sie einen gültigen stellvertretenden verantwortlichen Mitarbeiter aus.',
  deputyDeleted: 'Bitte aktualisieren oder löschen Sie den stellvertretenden verantwortlichen Mitarbeiter.',
  deputyAndPersonInChargeSameError: 'Der stellvertretende verantwortliche Mitarbeiter kann nicht derselbe wie der verantwortliche sein.',
  deputyAsMsgEmployeeError: 'Der stellvertretende verantwortliche Mitarbeiter ist in der Liste der weiteren Mitarbeiter vorhanden.',
  employeeAsDeputy: 'Einer der weiteren Mitarbeiter ist bereits stellvertretender verantwortlicher Mitarbeiter.',
  employeeDeleted: 'Bitte aktualisieren oder löschen Sie die nicht mehr existierenden weiteren Mitarbeiter.',
  customerName: 'Bitte geben Sie einen Kundennamen ein.'
};
const englishError: Record<string, string> = {
  name: 'Please enter an Application name.',
  sector: 'Please choose a sector.',
  personInChargeMSG: 'Please choose an msg employee who is responsible for processing this Application.',
  phase: 'Please choose the name of the Application phase in which this Application is.',
  document: 'Please choose the document for which you want to choose the NFR.',
  organizationRequired: 'Please choose an organization.',
  organizationInvalid: 'Please choose a valid organization.',
  personInChargeRequiredError: 'Please choose a person in charge.',
  personInChargeInvalid: 'Please choose a valid person in charge.',
  personInChargeAndDeputySameError: 'Please choose another person in charge.',
  personInChargeAsMsgEmployeeError: 'The person in charge is included in the employee list.',

  deputyInvalid: 'Please choose a valid deputy.',
  deputyAndPersonInChargeSameError: 'Please choose another deputy.',
  deputyAsMsgEmployeeError: 'The deputy is included in the employee list.'
};

@Component({
  selector: 'nfa-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit, OnDestroy {

  // Settings
  readonly LANGUAGE: Language = Language.DEUTSCH;
  currentError: Record<string, string>;
  datepipe = new DatePipe('de-DE');

  // Metadata
  readonly nameMaxLength = MaxLengths.applicationName;
  readonly customerMaxLength = MaxLengths.applicationcustomerName;
  readonly orgUnitMaxLength = MaxLengths.applicationorgUnit;
  // TODO -> in state auslagern
  loading = true;
  saving = false;
  errorMessage: Error;
  currentUserKeycloakId: string;

  isExistingApplication: boolean;
  initialOrganization: string;

  authorizationBalm = false;
  authorizationTen = false;

  // data
  applicationModel: Application;
  application: ApplicationReceived;

  form: UntypedFormGroup;

  organizations: string[];
  filteredOrganizations: Observable<string[]>;
  selectedOrganization: string;
  currentEmployeeSelectedOrganizationUsers: User[] = [];
  furtherEmployeeIds: string[] = [];
  sectors: Sector[];
  allDocuments: RequirementsDocument[];
  systemTypes: SystemType[];
  userRightsForOneApplication: Record<string, boolean> = {canEdit: false};
  checkDeletedUserFlags: { personInChargeCheck: boolean; deputyCheck: boolean } = {
    personInChargeCheck: false,
    deputyCheck: false
  };
  flag: boolean;

  protected onDestroy = new Subject<void>();

  get canEdit(): boolean {
    return this.userRightsForOneApplication.canEdit;
  }

  get headline(): string {
    let verb = !this.application ? 'anlegen' : this.canEdit ? 'bearbeiten' : 'anzeigen';
    return 'Anwendung ' + verb;
  }

  get isMsgEmployee(): boolean {
    return this.organizations.includes('msg');
  }

  get formGroupPersonsInCharge(): UntypedFormGroup {
    return this.form.controls.personsInChargeMSG as UntypedFormGroup;
  }

  get formControlOrganizationIdentifier(): UntypedFormControl {
    return this.formGroupPersonsInCharge.controls.organizationIdentifier as UntypedFormControl;
  }

  get formControlIdentifierOfPersonInChargeMSG(): UntypedFormControl {
    return this.formGroupPersonsInCharge.controls.identifierOfPersonInChargeMSG as UntypedFormControl;
  }

  get formControlIdentifierOfDeputyPersonInChargeMSG(): UntypedFormControl {
    return this.formGroupPersonsInCharge.controls.identifierOfDeputyPersonInChargeMSG as UntypedFormControl;
  }

  get formControlEmployeesIdentifier(): UntypedFormControl {
    return this.formGroupPersonsInCharge.controls.employeesIdentifier as UntypedFormControl;
  }

  get employeeListAsTextValue(): string {
    const ids = this.formControlEmployeesIdentifier.value || [];
    if (ids.length === 0) {
      return 'Keine weiteren Mitarbeiter ausgewählt';
    }
    return ids?.map(employeeId => this.queryUserNameForId(employeeId)).join(', ');
  }

  get sectorAsTextValue(): string {
    return this.application.sector.name;
  }

  get organizationalUnitAsTextValue(): string {
    if (this.application.organizationalUnit === null) {
      return 'Keine organisatorische Einheit eingetragen';
    }
    return this.application.organizationalUnit;
  }

  get systemTypeAsTextValue(): string {
    if (this.application.systemType === null) {
      return 'Keine IT-Systemart ausgewählt';
    }
    return this.application.systemType.name;
  }

  get personInChargeAsTextValue(): string {
    return this.queryUserNameForId(this.formControlIdentifierOfPersonInChargeMSG.value);
  }

  get deputyPersonInChargeAsTextValue(): string {
    if (this.formControlIdentifierOfDeputyPersonInChargeMSG.value === null) {
      return 'Kein stellvertretender verantwortlicher Mitarbeiter ausgewählt';
    }

    return this.queryUserNameForId(this.formControlIdentifierOfDeputyPersonInChargeMSG.value);
  }

  get namePhaseAsTextValue(): string {
    if (this.application.qualityProcesses.length === 0) {
      return 'Keine Phase ausgewählt';
    }
    return this.application.qualityProcesses[0].phase;
  }

  get documentAsTextValue(): string {
    if (this.application.qualityProcesses.length === 0) {
      return 'Kein Anforderungsdokument ausgewählt';
    }
    return this.application.qualityProcesses[0].document.description;
  }

  get phase(): string {
    return this.form.value.phase;
  }

  get appearance(): string {
    return this.canEdit ? 'outline' : 'fill';
  }

  hasMultipleErrors(control: UntypedFormControl): boolean {
    return control.errors !== null && Object.keys(control?.errors).length > 1;
  }

  personInChargeRequiredError(): boolean {
    return this.formControlIdentifierOfPersonInChargeMSG.hasError('required');
  }

  personInChargeAndDeputySameError(): boolean {
    return this.formControlIdentifierOfPersonInChargeMSG.hasError('personInChargeAndDeputySame');
  }

  personInChargeAsMsgEmployeeError(): boolean {
    return this.formControlEmployeesIdentifier.hasError('employeesPiCError');
  }

  deputyAsMsgEmployeeError(): boolean {
    return this.formControlEmployeesIdentifier.hasError('employeesDepError');
  }

  clearPersonInChargeIfEmpty() {
    if (
      !(this.formControlIdentifierOfPersonInChargeMSG.value === null) &&
      this.formControlIdentifierOfPersonInChargeMSG.value.length === 0
    ) {
      this.deletePersonInCharge();
    }
  }

  clearDeputyPersonInChargeIfEmpty() {
    if (
      !(this.formControlIdentifierOfDeputyPersonInChargeMSG.value === null) &&
      this.formControlIdentifierOfDeputyPersonInChargeMSG.value.length === 0
    ) {
      this.deleteDeputyPersonInCharge();
    }
  }

  constructor(
    private applicationService: ApplicationsService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private store: Store<AppState | ApplicationState>,
    private keycloak: KeycloakService,
    private dialog: MatDialog,
    private qumapHelpService: QumapHelpService
  ) {
    const tokenInstance: any = this.keycloak.getKeycloakInstance().tokenParsed;
    this.organizations = tokenInstance.organization;
    this.currentUserKeycloakId = tokenInstance.sub;

    this.store.dispatch(loadBranches());
    this.store.select(getBranches).pipe(takeUntil(this.onDestroy)).subscribe(
      branches => this.sectors = branches
    );

    this.store.dispatch(loadDocuments());
    this.store.select(getDocuments).pipe(takeUntil(this.onDestroy)).subscribe(
      documents => this.allDocuments = documents
    );

    this.store.dispatch(loadSystemTypes());
    this.store.select(getSystemTypes).pipe(takeUntil(this.onDestroy)).subscribe(
      systemTypes => this.systemTypes = systemTypes
    );

    this.authorizationBalm = this.keycloak.isUserInRole('BALM');
    this.authorizationTen = this.keycloak.isUserInRole('TEN');

    switch (this.LANGUAGE) {
      case Language.DEUTSCH:
        this.currentError = germanError;
        break;
      case Language.ENGLISH:
        this.currentError = englishError;
        break;
      default:
        assertUnreachable(this.LANGUAGE, 'Language');
    }
  }

  async ngOnInit() {
    HilfeFunktionen.setApplicationTitle('Anwendung');
    this.initForm();
    this.initAutoLoadUsersForSelectedOrganization();
    await this.initApplication();
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(this.nameMaxLength).bind(this)]),
      sectorIdentifier: new UntypedFormControl(null, Validators.required),
      customerName: new UntypedFormControl(null, [Validators.required, Validators.maxLength(this.customerMaxLength).bind(this)]),
      organizationalUnit: new UntypedFormControl(null, Validators.maxLength(this.orgUnitMaxLength).bind(this)),
      phase: new UntypedFormControl(null, Validators.required),
      document: new UntypedFormControl(null),
      systemTypeId: new UntypedFormControl(),
      personsInChargeMSG: new UntypedFormGroup({
        organizationIdentifier: new UntypedFormControl(
          null, [Validators.required, validateOrga.bind(this)]),
        identifierOfPersonInChargeMSG: new UntypedFormControl(
          {value: null, disabled: this.isMsgEmployee},
          [Validators.required, validateUser.bind(this)]
        ),
        identifierOfDeputyPersonInChargeMSG: new UntypedFormControl({
          value: null,
          disabled: this.isMsgEmployee,
        }, [validateUser.bind(this)]),
        employeesIdentifier: new UntypedFormControl(
          {value: null, disabled: this.isMsgEmployee},
          [validateDeletedEmployees.bind(this)]
        )
      }, {
        validators: [
          validatePersonInChargeDeputee(),
          validateEmployeesPiC(),
          validateEmployeesDep()
        ]
      })
    });
    if (!this.authorizationTen) {
      this.form.controls.phase.disable();
      this.form.controls.document.disable();
    }
  }

  private initAutoLoadUsersForSelectedOrganization() {
    this.filteredOrganizations = of(this.organizations);
    if (this.isMsgEmployee) {
      this.formControlOrganizationIdentifier.valueChanges.pipe(
        takeUntil(this.onDestroy),
        map((value: string) => {
          this.loadUsersOfOrganization(value);
          if (value != null) {
            this.filteredOrganizations = of(this.organizations.filter(org => org.toLowerCase().includes(value.toLowerCase())));
          } else {
            this.filteredOrganizations = of(null);
          }
        })
      ).subscribe();
    } else if (this.organizations.length === 1) {
      const organization = this.organizations[0];
      this.loadUsersOfOrganization(organization);
    }
  }

  private loadUsersOfOrganization(organizationName?: string) {
    if (organizationName !== undefined && organizationName !== '') {
      this.applicationService.getAllUsersOfOrganisation(organizationName).pipe(
        take(1),
        tap(users => this.currentEmployeeSelectedOrganizationUsers = users),
        tap(users => this.furtherEmployeeIds = this.isExistingApplication && this.initialOrganization === organizationName ? this.furtherEmployeeIds : users.map(user => user.id))
      ).subscribe();
    } else {
      this.currentEmployeeSelectedOrganizationUsers = [];
      this.furtherEmployeeIds = [];
    }
  }

  async initApplication() {

    const id = +this.route.snapshot.paramMap.get('id');
    this.isExistingApplication = Boolean(id);
    if (this.isExistingApplication) {
      await this.loadExistingApplication(id);
    } else {
      this.userRightsForOneApplication = {canEdit: true};

      if (!this.isMsgEmployee) {
        this.form.controls.customerName.setValue(this.organizations[0]);
        this.formControlOrganizationIdentifier.setValue(this.organizations[0]);
      }
    }

    this.loading = false;

    if (this.authorizationTen) {
      this.form.controls.phase.enable();
      this.form.controls.document.enable();
      this.form.controls.systemTypeId.enable();
    } else {
      this.form.controls.phase.disable();
      this.form.controls.document.disable();
      this.form.controls.systemTypeId.disable();
    }
  }

  getUserForId(userId: string) {
    const userForId = this.currentEmployeeSelectedOrganizationUsers.find(user => user.id === userId);
    let displayName: string;
    if (userForId !== undefined) {
      displayName = `${userForId.firstName} ${userForId.lastName} (${userForId.email || ''})`;
    } else {
      displayName = this.deletedUserText;
      this.formControlEmployeesIdentifier.markAllAsTouched();
    }
    return displayName;
  }

  private async loadExistingApplication(id: number) {
    try {
      this.application = await firstValueFrom(this.applicationService.getApplicationById(id));
      this.initialOrganization = this.application.personsInChargeMSG.organizationIdentifier;
      this.userRightsForOneApplication = {canEdit: this.application.canUserEditApplication};
      this.currentEmployeeSelectedOrganizationUsers = await firstValueFrom(this.applicationService.getAllUsersOfOrganisation(this.application.personsInChargeMSG.organizationIdentifier));
      this.applicationModel = this.convertApplicationReceivedToApplication(this.application);
      this.setPersonsInCharge();

      if (this.authorizationTen) {
        this.setQualityProcess();
      }

      this.form.patchValue(this.applicationModel);
      const employeeSet: Set<string> = new Set([
        ...this.currentEmployeeSelectedOrganizationUsers.map(user => user.id),
        ...this.applicationModel.personsInChargeMSG.employeesIdentifier
      ]);

      this.furtherEmployeeIds = Array.from(employeeSet);
      this.loading = false;
      this.checkDeletedUserFlags = {
        deputyCheck: true,
        personInChargeCheck: true
      }
      if (this.application.canUserEditApplication && !this.form.valid) {
        this.form.markAllAsTouched();
      }

    } catch (e) {
      this.errorMessage = e;
      this.setPersonsInCharge();
    }
  }

  convertApplicationReceivedToApplication(applicationModelToDisplay: ApplicationReceived): Application {
    const applicationModel = applicationModelToDisplay as unknown as Application;
    applicationModel.id = applicationModelToDisplay.id;
    applicationModel.systemTypeId = applicationModelToDisplay.systemType?.id;
    applicationModel.sectorIdentifier = applicationModelToDisplay.sector?.id;
    return applicationModel;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  cancel() {
    this.router.navigate(['/applications']);
  }

  onSaveApplication() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
    }
    if (this.form.valid) {
      this.saving = true;
      const dataToSave: [Application, QualityProcess] = this.prepareDataForSaving(this.applicationModel, this.form);
      if (dataToSave[0].id) {

        const currentUserId = this.currentUserKeycloakId;
        const creatorId = this.application.personsInChargeMSG.creator;
        const personInChargeId = this.formControlIdentifierOfPersonInChargeMSG.value;
        const deputyId = this.formControlIdentifierOfDeputyPersonInChargeMSG.value;

        if ([creatorId, personInChargeId, deputyId].includes(currentUserId)) {
          this.updateProcess(dataToSave, this.authorizationTen, this.LANGUAGE);

        } else {
          const data: ConfirmationDialogData = {
            ...new ConfirmationDialogData(),
            dialogType: 'error',
            header: 'Entfernen der eigenen Bearbeitungsrechte',
            message: `Sie haben sich als ${(this.application.personsInChargeMSG.identifierOfDeputyPersonInChargeMSG === currentUserId) ? 'stellvertretender verantwortlicher' : 'verantwortlicher'}
            Mitarbeiter entfernt. Dadurch können Sie die Anwendung nicht mehr bearbeiten, nur noch anschauen.`,
            confirmationQuestion: 'Möchten Sie trotzdem speichern?',
            confirmOptionText: 'Trotzdem Speichern',
            confirmOptionIcon: 'save_outline',
            cancelOptionText: 'Abbrechen'
          };
          this.dialog
            .open(ConfirmationDialogComponent, {
              disableClose: true,
              autoFocus: 'dialog',
              role: 'dialog',
              panelClass: 'confirmation-dialog-container',
              data
            })
            .afterClosed().subscribe(confirmed => {
            if (confirmed) {
              this.updateProcess(dataToSave, this.authorizationTen, this.LANGUAGE);
            } else {
              this.saving = false;
            }
          });
        }
      } else {
        this.createProcess(dataToSave, this.authorizationTen, this.LANGUAGE);
      }
    }
  }

  private prepareDataForSaving(oldModel: Application, form: UntypedFormGroup): [Application, QualityProcess] {
    const formValue = {...form.value};
    delete formValue.phase;
    delete formValue.document;
    const application: Application = {...oldModel, ...formValue};
    application.lastUpdate = Date.now();
    application.systemType = formValue.systemTypeId !== undefined ?
      this.systemTypes.filter(st => st.id === formValue.systemTypeId)[0] :
      undefined;
    delete application.systemTypeId;
    const qualityProcess: QualityProcess = {
      document: form.value.document,
      phase: form.value.phase
    };
    return [application, qualityProcess];
  }

  private updateProcess(dataToSave: [Application, QualityProcess], qualityProcessAllowed: boolean, language: Language) {
    let updateCall$: Observable<any>;
    if (qualityProcessAllowed) {
      dataToSave[1].applicationId = dataToSave[0].id;
      updateCall$ = this.application.qualityProcesses.length > 0 ?
        this.applicationService.updateQualityProcess(dataToSave[0].id, dataToSave[1], language) :
        this.applicationService.saveQualityProcess(dataToSave[1]);
      updateCall$ = updateCall$.pipe(mergeMap(
        () => this.applicationService.updateApplication(dataToSave[0].id, new ApplicationDTO(dataToSave[0]))
      ));
    } else {
      updateCall$ = this.applicationService.updateApplication(dataToSave[0].id, new ApplicationDTO(dataToSave[0]));
    }
    this.handleSaveOrUpdateServerCall(updateCall$, dataToSave[0].name, language);
  }

  private createProcess(dataToSave: [Application, QualityProcess], qualityProcessAllowed: boolean, language: Language) {
    const saveCall$: Observable<any> = qualityProcessAllowed ?
      this.applicationService.createApplication(dataToSave[0], this.LANGUAGE).pipe(
        tap((createdApplication: Application) => dataToSave[1].applicationId = createdApplication.id),
        mergeMap(() => this.applicationService.saveQualityProcess(dataToSave[1]))
      ) :
      this.applicationService.createApplication(dataToSave[0], language);
    this.handleSaveOrUpdateServerCall(saveCall$, dataToSave[0].name, language);
  }

  private handleSaveOrUpdateServerCall(call$: Observable<any>, name: string, language: Language) {
    call$.subscribe({
      next: () => {
        const message = language === Language.DEUTSCH
          ? `Die Anwendung ${name} wurde erfolgreich gespeichert.`
          : `The Application ${name} has been saved successfully.`;
        this.snackBar.openFromComponent(SaveConfSnackbarComponent, {
          data: {message: message}
        });
        this.router.navigate(['/applications']);
      },
      error: () => {
        const message = language === Language.DEUTSCH
          ? `Die Anwendung ${name} wurde nicht gespeichert.`
          : `The Application ${name} has not been saved.`;
        this.snackBar.openFromComponent(SaveConfSnackbarComponent, {
          data: {message: message}
        });
      }
    }).add(() => this.saving = false);
  }

  setQualityProcess() {
    const qualityProcess = this.application.qualityProcesses[0];
    if (qualityProcess !== undefined) {
      this.form.patchValue({
        phase: qualityProcess.phase,
        document: qualityProcess.document.identifier
      });
    }
  }

  setPersonsInCharge() {
    let personsInChargeMSG = this.applicationModel?.personsInChargeMSG;

    let organizationIdentifier = personsInChargeMSG?.organizationIdentifier || null;
    if (organizationIdentifier != null) {
      this.formControlOrganizationIdentifier.patchValue(organizationIdentifier);
      this.enableEmployeeInputFields(organizationIdentifier);
    }

    let identifierOfPersonInChargeMSG = personsInChargeMSG?.identifierOfPersonInChargeMSG || null;
    if (identifierOfPersonInChargeMSG != null) {
      this.formControlIdentifierOfPersonInChargeMSG.patchValue(identifierOfPersonInChargeMSG);
    }

    let identifierOfDeputyPersonInChargeMSG = personsInChargeMSG?.identifierOfDeputyPersonInChargeMSG || null;
    if (identifierOfDeputyPersonInChargeMSG != null) {
      this.formControlIdentifierOfDeputyPersonInChargeMSG.patchValue(identifierOfDeputyPersonInChargeMSG);
    }
  }

  changeProcessPhase(phase: string) {
    if (phase === 'Initialisierung') {
      this.form.patchValue({document: this.allDocuments[0].identifier});
    } else if (phase === 'Definition') {
      this.form.patchValue({document: this.allDocuments[1].identifier});
    }
  }

  enableEmployeeInputFields(organization: string) {
    if(this.initialOrganization !== organization) {
      this.initialOrganization = undefined;
    }
    if (this.selectedOrganization !== organization) {
      this.deleteDeputyPersonInCharge();
      this.deletePersonInCharge();
      this.deleteMoreEmployees();
      if (this.formControlOrganizationIdentifier.invalid) {
        this.formControlIdentifierOfPersonInChargeMSG.disable();
        this.formControlIdentifierOfDeputyPersonInChargeMSG.disable();
        this.formControlEmployeesIdentifier.disable();
      }
    }

    if (organization === null) {
      this.initAutoLoadUsersForSelectedOrganization();
    }
    if (this.isMsgEmployee && this.formControlOrganizationIdentifier.valid) {
      this.formControlIdentifierOfPersonInChargeMSG.enable();
      this.formControlIdentifierOfDeputyPersonInChargeMSG.enable();
      this.formControlEmployeesIdentifier.enable();
      this.form.controls.customerName.setValue(organization);
    }
  }

  readonly deletedUserText = 'Gelöschter Anwender';

  queryUserNameForId(id: string): string {
    if (!id) {
      return '';
    }
    const matchingUser = this.currentEmployeeSelectedOrganizationUsers.find(user => user.id === id);
    return matchingUser !== undefined ? `${matchingUser.firstName} ${matchingUser.lastName}` : this.deletedUserText;
  }

  getUsersExcept(searchWord: string) {
    if (searchWord !== null && searchWord !== '') {
      return this.currentEmployeeSelectedOrganizationUsers.filter(user =>
        user.firstName?.toLowerCase().includes(searchWord.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(searchWord.toLowerCase()) ||
        user.username?.toLowerCase().includes(searchWord.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchWord.toLowerCase()) ||
        user.firstName?.toLowerCase().concat(' ').concat(user.lastName?.toLowerCase()).includes(searchWord.toLowerCase()) ||
        user.lastName?.toLowerCase().concat(' ').concat(user.firstName?.toLowerCase()).includes(searchWord.toLowerCase())
      );
    } else {
      return this.currentEmployeeSelectedOrganizationUsers;
    }
  }

  deleteDeputyPersonInCharge() {
    this.formControlIdentifierOfDeputyPersonInChargeMSG.setValue(null);
  }

  deletePersonInCharge() {
    this.formControlIdentifierOfPersonInChargeMSG.setValue(null);
  }

  deleteMoreEmployees() {
    this.formControlEmployeesIdentifier.reset();
  }

  deleteOrganisation() {
    this.initialOrganization = undefined;
    this.formControlOrganizationIdentifier.setValue(null);
    this.enableEmployeeInputFields(null);
  }

  onHelpButtonClick() {
    this.qumapHelpService.getQumapHelpAndShowInDialog('qumap-edit-application', this.dialog);
  }

  lastUpdateString(): string {
    if (!this.application?.lastUpdate) {
      return '';
    }
    const updateDate = new Date(this.application.lastUpdate);
    return 'Zuletzt geändert am ' + this.datepipe.transform(updateDate, 'dd.MM.yyyy') + ' um ' + updateDate.toLocaleTimeString(
      navigator.language, {hour: '2-digit', minute: '2-digit', hour12: false}) + ' Uhr';
  }

  getUsersById() {
    return this.currentEmployeeSelectedOrganizationUsers.map(u => u.id);
  }
}
