import { Component, OnInit, ViewChild } from '@angular/core';
import { BitvService } from './bitv.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HilfeFunktionen } from '../shared/global';
import { ApplicationsService } from '../applications/applications.service';
import { ApplicationReceived } from '../applications/model/applicationReceived';
import { QumapHelpService } from '../qumap-help/qumap-help.service';
import { Language } from '../app-state/settings';
import { InformationDialogComponent } from '../shared/information-dialog/information-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {
  PruefgegenstandDialogComponent
} from './pruefgegenstand-dialoge/create-edit-pruefgegenstand-dialog/pruefgegenstand-dialog.component';
import { Pruefgegenstand } from './types/pruefgegenstand';
import {
  DeletePruefgegenstandDialogComponent
} from './pruefgegenstand-dialoge/delete-pruefgegenstand-dialog/delete-pruefgegenstand-dialog.component';
import { PruefgegenstandTyp } from './pruefgegenstand-dialoge/pruefgegenstandTyp.enum';
import { Bitv } from './types/bitv';
import { UserAccessRight, UserAccessRightsService } from './user-access-rights.service';

@Component({
  selector: 'nfa-bitv',
  templateUrl: './bitv.component.html',
  styleUrls: ['./bitv.component.scss']
})
export class BitvComponent implements OnInit {

  readonly LANGUAGE: Language = Language.DEUTSCH;
  readonly deletionHint = 'Sie können diesen Prüfgegenstand nicht löschen, da er mindestens einem Barrierefreiheitstest zugeordnet ist. Um den Prüfgegenstand zu löschen, müssen Sie die Zuordnungen in den Konfigurationen der jeweiligen Barrierefreiheitstests aufheben.';
  readonly deletionHintHeader = 'Löschen nicht möglich';

  application: ApplicationReceived;
  applicationId: string;

  url: string;
  name: string;
  dataSource: MatTableDataSource<Pruefgegenstand> = new MatTableDataSource<Pruefgegenstand>();
  dialogRef: MatDialogRef<PruefgegenstandDialogComponent, any>;
  deleteDialogRef: MatDialogRef<DeletePruefgegenstandDialogComponent, any>;
  infoDialogRef: MatDialogRef<InformationDialogComponent, any>;
  isLoadingDataSource = false;
  serverError = false;
  serverErrorStatus: number;
  displayedColumns: string[] = ['name', 'id', 'typ', 'url', 'actions'];
  displayedColumnsReadOnly: string[] = ['name', 'id', 'typ', 'url'];
  pageSizeOptions: number[] = [5, 10, 15, 20];

  bitvs: Bitv[] = [];
  userAccessRights: UserAccessRight = undefined;

  @ViewChild('evaluationObjectsPaginator') paginator: MatPaginator;

  constructor(private bitvService: BitvService,
              private applicationsService: ApplicationsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              private qumapHelpService: QumapHelpService,
              private userAccessRightsService: UserAccessRightsService) {
  }

  ngOnInit() {
    HilfeFunktionen.setApplicationTitle('BALM');
    this.activatedRoute.paramMap.subscribe(params => {
      this.applicationId = params.get('applicationid');
      this.applicationsService.getApplicationById(this.applicationId).subscribe({
        next: application => {
          this.application = application;
          this.userAccessRightsService.checkAccessRight(this.application).subscribe({
            next: userAccessRights => this.userAccessRights = userAccessRights
          });
        },
        error: err => {
          if (err.status === 403){
            this.router.navigate(['../no-access-error-page']);
          }
        }
      });
      this.getPruefgegenstandByApplication(this.applicationId);
    });
  }

  getPruefgegenstandByApplication(applicationId: string | number) {
    this.serverError = false;
    this.isLoadingDataSource = true;
    this.bitvService.getPruefgegenstandByApplication(applicationId).subscribe({
      next: (res: Pruefgegenstand[]) => {
        res.map((data) => data.typ = PruefgegenstandTyp[data.contentType]);
        this.dataSource.data = res.sort((a, b) => a.id - b.id);
        this.isLoadingDataSource = false;
        this.initPaginator();
      },
      error: (error) => {
        this.serverError = true;
        this.serverErrorStatus = error.error.status;
        this.isLoadingDataSource = false;
      }
    });
  }

  openDialog(edit: boolean, pruefgegenstand: Pruefgegenstand): void {
    const isPruefgegenstandInUse = this.bitvs
      .flatMap(bitv => bitv.testObjects)
      .map(testObject => testObject.testobjectId)
      .includes(pruefgegenstand.id);
    this.dialogRef = this.dialog.open(PruefgegenstandDialogComponent, {
      disableClose: true,
      data: { pruefgegenstand, edit, applicationId: Number(this.application.id), isPruefgegenstandInUse },
      autoFocus: 'dialog',
      maxHeight: '90vh',
      role: 'dialog',
      panelClass: 'pruefgegenstand-dialog-container'
    });
    this.dialogRef.afterClosed().subscribe((res) => {
      if (res.event !== 'Cancel') {
        this.getPruefgegenstandByApplication(this.application.id);
      }
    });
  }

  openDeleteDialog(pruefgegenstand: Pruefgegenstand): void {
    const isPruefgegenstandInUse = this.bitvs
      .flatMap(bitv => bitv.testObjects)
      .map(testObject => testObject.testobjectId)
      .includes(pruefgegenstand.id);
    if (isPruefgegenstandInUse) {
      this.dialog.open(InformationDialogComponent, {
        panelClass: 'information-dialog-container',
        data: {
          informationType: 'info',
          header: this.deletionHintHeader,
          message: this.deletionHint,
          closingDialog: 'OK'
        },
        autoFocus: "dialog",
        maxHeight: '90vh',
        maxWidth: '90vh'
      });
    } else {
      this.deleteDialogRef = this.dialog.open(DeletePruefgegenstandDialogComponent, {
        disableClose: true,
        data: {pruefgegenstand},
        autoFocus: 'dialog',
        maxHeight: '90vh',
        role: 'dialog',
        panelClass: 'pruefgegenstand-dialog-container'
      });
      this.deleteDialogRef.afterClosed().subscribe((res) => {
        if (res.event !== 'Cancel') {
          this.getPruefgegenstandByApplication(this.application.id);
        }
      });
    }
  }

  onKey(event: KeyboardEvent) {
    console.log(event);
  }

  onHelpButtonClick() {
    this.qumapHelpService.getQumapHelpAndShowInDialog('balm',this.dialog);
  }

  canUserEdit(userAccessRight: UserAccessRight): boolean {
   return userAccessRight === UserAccessRight.WRITEACCES;
  }

  private initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Prüfgegenstände pro Seite: ';
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.firstPageLabel = 'Erste Seite';
    this.paginator._intl.lastPageLabel = 'Letzte Seite';
    this.paginator._intl.nextPageLabel = 'Nächste Seite';
    this.paginator._intl.previousPageLabel = 'Vorherige Seite';
    this.paginator._intl.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return '0 von ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' von ' + length;
    };
  }
}
