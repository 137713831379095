<nav aria-label="Seitennavigation" role="navigation" tabindex="-1">
  <ul class="sidenav__tree-container" role="tree" aria-label="Seitennavigation">
    <li *ngFor="let item of treeRoots" role="none">
      <nfa-sidenav-tree *ngIf="(item.authorization)"
                        [item]="item"
                        [selected]="selected"
                        [factors]="factors"
                        [documentIdentifier]="documentIdentifier"
                        [showStatus] = "showStatus"
                        (itemSelected)="onItemSelect($event)">
      </nfa-sidenav-tree>
    </li>
  </ul>
</nav>
