import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { AppAuthGuard } from './app.authguard';

@Injectable({
  providedIn: 'root'
})
export class BalmAuthorizationGuard extends AppAuthGuard  {

  constructor(protected router: Router,
              protected keycloak: KeycloakService) {
    super(router, keycloak);
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const result = await super.canActivate(route, state);

    if (result === true) {
      return this.keycloak.isUserInRole('BALM') ? true : this.router.parseUrl('/no-access-error-page');
    }

    return false;
  }

}
