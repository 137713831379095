import { createAction, props } from '@ngrx/store';
import { Language } from './settings';

export const changeLanguange = createAction('[App-Core] Change Language', props<{ language: Language }>());
export const resetAuthenticatedUser = createAction('[App-Core] Reset Authenticated User');
export const setAuthenticatedUser = createAction('[App-Core] Set Authenticated User', props<{ authenticatedUser: string }>());
export const setAuthorizationFlags = createAction('[App-Core] Set Authorization Flags',
  props<{ isAuthorizedForBALM: boolean, isAuthorizedForTEN: boolean }>());

export const noopAction = createAction('[App-Core] Noop Action');
