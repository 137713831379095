import { Evaluationstep } from './evaluationstep';
import { TestObject } from './pruefgegenstand';

export enum BitvType {
  WEB = 'WEB',
  DOCUMENT = 'DOCUMENT',
  SOFTWARE = 'SOFTWARE',
  MOBILE_APP = 'MOBILE_APP',
}

export interface Bitv {
  id?: number;
  applicationId: number;
  createdAt?: number;
  finishedAt?: number;
  changedAt?: number;
  succesfull?: boolean;
  status?: string;
  objectType: BitvType;
  evaluationType: string;
  bitvEvaluationSteps?: Evaluationstep[];
  testObjects?: TestObject[];
  bitvEvaluationStepsByTestobject?: BitvEvaluationStepsByTestobject[];
  progress?: {
    allSteps: number;
    evaluatedSteps: number;
    percentage?: number
  };
}

export interface AccessibilityTest {
  id?: number;
  applicationId: number;
  objectType: string;
  evaluationType: string;
  status?: string;
  succesfull?: boolean;
  testObjects?: TestObject[];
}

export interface BitvEvaluationStepsByTestobject {
  bitvEvaluationSteps: Evaluationstep[];
  testobjectid: number;
  name?: string;
  lastEdit?: Evaluationstep;
}

export const isBitvCompleted = (bitv: Bitv) => bitv.status === 'COMPLETED';

