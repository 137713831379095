import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {LiveAnnouncerService} from "../live-announcer-service/live-announcer.service";

@Component({
  selector: 'nfa-save-confirm-snackbar',
  templateUrl: './save-confirm-snackbar-component.html'
})
export class SaveConfSnackbarComponent implements OnInit {

  progressValue: number = 100;
  progressDuration: number = 5000; //5000 milliseconds = 5 seconds
  progressInterval: number = 10;
  mouseover: boolean = false;

  constructor(
    private snackBarRef: MatSnackBarRef<SaveConfSnackbarComponent>,
    private liveAnnouncer: LiveAnnouncerService,
    private elRef: ElementRef,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.data = data;
  }

  ngOnInit() {
    this.liveAnnouncer.announce(this.data.message, true);

    this.startProgressBar();

    if (this.elRef.nativeElement.closest('.mdc-snackbar')) {
      const snackbarContainer = this.elRef.nativeElement.closest('.mdc-snackbar');
      snackbarContainer.addEventListener("mouseenter", () => this.break());
      snackbarContainer.addEventListener("mouseleave", () => this.continue());
    } else {
      const snackbarContainer = document.querySelector(".mdc-snackbar");
      snackbarContainer.addEventListener("mouseenter", () => this.break());
      snackbarContainer.addEventListener("mouseleave", () => this.continue());
    }
  }

  startProgressBar() {

    let elapsed = 0;

    const intervalId = window.setInterval(() => {
      if (!this.mouseover) {
        elapsed += this.progressInterval;
        this.progressValue = 100 - Math.round((elapsed / this.progressDuration) * 100);

        if (elapsed >= this.progressDuration) {
          clearInterval(intervalId);
          this.close();
        }
      }
    }, this.progressInterval);
  }

  close() {
    this.snackBarRef.dismiss();
  }

  break() {
    this.mouseover = true;
  }

  continue() {
    this.mouseover = false;
  }
}
