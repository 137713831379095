import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Branch } from './branch';
import { Phase } from './phases';
import { Package } from './package';

@Injectable()
export class NfaPackagesAdapterService {

  private apiUrl = '/nfabackend/webapi';

  constructor(private http: HttpClient) {
  }

  getBranches(language: string): Observable<Branch[]> {
    return this.http.get<Branch[]>(`${this.apiUrl}/${language}/branches`).pipe(
      catchError(() => throwError('Branchen konnten nicht geladen werden'))
    );
  }

  getPhases(language: string): Observable<Phase[]> {
    return this.http.get<Phase[]>(`${this.apiUrl}/${language}/phases`).pipe(
      catchError(() => throwError('Phasen konnten nicht geladen werden'))
    );
  }

  savePackage(language: string, newPackage12: Package): Observable<void | Observable<never>> {
    return this.http.post<void>(`${this.apiUrl}/${language}/nfrPackages/createNewPackage`, newPackage12).pipe(
      catchError(() => throwError('Paket konnte nicht gespeichert werden.'))
    );
  }

}
