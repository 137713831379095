export interface Treenode {
  id?: number;
  textId?: string; // string id, to be used additionally or instead of the numerical id
  parent_id?: number;
  parent?: Treenode;
  number?: string;
  designation?: string;
  children?: Treenode[];
  authorization?: boolean;
  name: string;
  expanded: boolean;
  category: TreenodeCategory;
  object?: any;
  getStatus?(): string;
  getStatusIcon?(): string;
  getStatusStyle?(): string;
}

export enum TreenodeCategory {

  ROOT, NODE, LEAF
}


