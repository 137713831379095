import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QumapHelpComponent } from './qumap-help.component';
import { MaterialModule } from '../material/material.module';
import { AppRoutingModule } from '../app-routing.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { InformationHeaderModule } from '../shared/information-header/information-header.module';
import { MatCardModule } from '@angular/material/card';
import { SidenavModule } from '../shared/sidenav/sidenav.module';

@NgModule({
  declarations: [
    QumapHelpComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    RouterModule,
    MatTooltipModule,
    SharedModule,
    MatSidenavModule,
    InformationHeaderModule,
    MatCardModule,
    SidenavModule
  ]
})
export class QumapHelpModule {
}
