import { Component, Input, OnInit } from '@angular/core';
import { TestToolCategory, TestTools } from '../../../types/test-tools';
import { Bitv, isBitvCompleted } from '../../../types/bitv';
import { BitvService } from '../../../bitv.service';
import { firstValueFrom } from 'rxjs';
import { Language } from '../../../../app-state/settings';
import { BalmTestToolsOverviewLangPack } from './balm-test-tools-overview.lang.de';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'nfa-balm-test-tools-overview',
  templateUrl: './balm-test-tools-overview.component.html',
  styleUrls: ['./balm-test-tools-overview.component.scss']
})
export class BalmTestToolsOverviewComponent implements OnInit {
  readonly Object = Object;
  readonly isBitvCompleted = isBitvCompleted;

  @Input()
  canEdit: boolean;

  @Input()
  bitv: Bitv;

  testTools: TestTools = {};
  requiredSectionKeys = [];
  texts: BalmTestToolsOverviewLangPack;

  constructor(private bitvService: BitvService, private router: Router, private route: ActivatedRoute) {
  }

  async ngOnInit() {
    let language = Language.DEUTSCH;
    this.texts = await import(`./balm-test-tools-overview.lang.${language}`).then(module => module.texts);

    this.testTools = await firstValueFrom(this.bitvService.getTestToolsForBalm(this.bitv.id));
    this.requiredSectionKeys = await firstValueFrom(this.bitvService.getRequiredTestToolSectionsForTestObjectType(this.bitv.objectType));
  }

  async switchToTestToolsPage() {
    return this.router.navigate(['test-tools'], {relativeTo: this.route});
  }

  isRequired(key: TestToolCategory): boolean {
    return this.requiredSectionKeys.includes(key);
  }
}
