import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CatalogState,
  getCatalogState,
  getSectors,
  getTemplateNames,
  getTemplateNodes
} from '../catalog-state/catalog.reducer';
import { select, Store } from '@ngrx/store';
import { CreateNewNfr } from '../catalog-state/catalog.actions';
import { Nfr } from '../adapter/nfr';
import { Metric } from '../adapter/metric';
import { ActivatedRoute } from '@angular/router';
import { TemplateNode } from '../adapter/templateNode';
import { TemplateName } from '../adapter/templateName';
import { NfrTemplate } from '../adapter/nfrTemplate';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { TemplateText } from '../adapter/templateText';
import { Sector } from '../adapter/sector';

@Component({
  selector: 'nfa-nfr-create-simple',
  templateUrl: './nfr-create-simple.component.html',
  styleUrls: ['./nfr-create-simple.component.scss']
})
export class NfrCreateSimpleComponent implements OnInit {
  catalogState$: Observable<CatalogState> = this.store$.pipe(select(getCatalogState));
  metric: Metric;
  plural = false;
  concreteValue = false;
  differentValues = false;
  variableValue = false;
  variableObject = false;
  obligatoryValues = false;
  conditionValues = false;
  valueRange = false;
  valueRange2 = false;
  operator1: string;
  operator2: string;
  operator3: string;
  operator4: string;
  enOperator1: string;
  enOperator2: string;
  enOperator3: string;
  enOperator4: string;
  template = -1;
  templateNodes$: Observable<TemplateNode[]> = this.store$.pipe(select(getTemplateNodes));
  templateNames$: Observable<TemplateName[]> = this.store$.pipe(select(getTemplateNames));
  branches$: Observable<Sector[]> = this.store$.pipe(select(getSectors));
  nfrTemplate: NfrTemplate;
  formulation: string;
  enFormulation: string;
  type: string;
  newNfr: Nfr;
  sectors: any[];
  templateNodes: TemplateNode[];
  branches: Sector[];

  constructor(
    public dialogRef: MatDialogRef<NfrCreateSimpleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store$: Store<CatalogState>,
    private route: ActivatedRoute
  ) {
    this.resetTemplate();
    this.newNfr = {
      id: null,
      concretizingValue: false,
      formulationEnglish: '',
      formulationGerman: '',
      liable: false,
      metric: '',
      nameEnglish: '',
      nameGerman: '',
      reference: '',
      sectors: [],
      type: '',
      valueVariable: false,
      variablePartMatter: false,
      variableValue: false,
      questionGerman: '',
      questionEnglish: '',
      plural: false
    };
  }

  ngOnInit() {
    this.metric = this.data.activeMetric;
    this.newNfr.metric = this.metric.identifier;
    this.branches$.subscribe((data: Sector[]) => {
      this.branches = data;
    });
    this.sectors = [
      {name: this.branches[0].name, value: 'PUBLIC_SECTOR'},
      {name: this.branches[1].name, value: 'AUTOMOTIVE'},
      {name: this.branches[2].name, value: 'BANKING'},
      {name: this.branches[3].name, value: 'FOOD'},
      {name: this.branches[4].name, value: 'INSURANCE'},
      {name: this.branches[5].name, value: 'LIFE'},
      {name: this.branches[6].name, value: 'TELECOMMUNICATION'},
      {name: this.branches[7].name, value: 'TRAVEL_LOGISTICS'},
      {name: this.branches[8].name, value: 'UTILITIES'}
    ];
    for (const sector of this.sectors) {
      this.newNfr.sectors.push(sector.value);
    }
    this.templateNodes$.subscribe((data: TemplateNode[]) => {
      this.templateNodes = data;
    });
  }

  resetTemplate() {
    this.nfrTemplate = {
      characteristic: '',
      characteristic2: '',
      component: '',
      property: '',
      property2: '',
      propertyVariable: '',
      requirement_level: null,
      concretizing_value: '',
      qualifying_expression: null,
      qualifying_expression2: null,
      value_1: '',
      value_range_1_1: '',
      value_range_1_2: '',
      be: '',
      conditional: null,
      condition: '',
      second_part_condition: '',
      value_2: '',
      value_range_2_1: '',
      value_range_2_2: '',
      actor: '',
      details: '',
      object: '',
      details0: '',
      process_verb: '',
      en_characteristic: '',
      en_characteristic2: '',
      en_component: '',
      en_property: '',
      en_property2: '',
      en_propertyVariable: '',
      en_concretizing_value: '',
      en_be: '',
      en_condition: '',
      en_second_part_condition: '',
      en_actor: '',
      en_details: '',
      en_object: '',
      en_details0: '',
      en_process_verb: ''
    };
  }

  isValid(): boolean {
    return true; // TODO return true if every value is valid
  }

  saveDisabled(): boolean {
    return false; // TODO return false only when form filled out and valid
  }

  changeValueRange(value: TemplateText) {
    console.log(value);
    this.operator1 = value.description;
    if (value.description === 'zwischen / und') {
      this.operator1 = 'zwischen';
      this.enOperator1 = 'between';
      this.operator2 = 'und';
      this.enOperator2 = 'and';
      this.valueRange = true;
    } else if (value.description === 'von / bis') {
      this.operator1 = 'von';
      this.enOperator1 = 'from';
      this.operator2 = 'bis';
      this.enOperator2 = 'to';
      this.valueRange = true;
    } else {
      this.valueRange = false;
    }
  }

  changeValueRange2(value: TemplateText) {
    console.log(value);
    this.operator3 = value.description;
    if (value.description === 'zwischen / und') {
      this.operator3 = 'zwischen';
      this.enOperator3 = 'between';
      this.operator4 = 'und';
      this.enOperator4 = 'and';
      this.valueRange2 = true;
    } else if (value.description === 'von / bis') {
      this.operator3 = 'von';
      this.enOperator3 = 'from';
      this.operator4 = 'bis';
      this.enOperator4 = 'to';
      this.valueRange2 = true;
    } else {
      this.valueRange2 = false;
    }
  }

  changeTemplate(value: string) {
    console.log(value);
    this.template = +value;
    console.log(this.template);
    this.valueRange = false;
    this.valueRange2 = false;
    this.operator1 = null;
    this.operator2 = null;
    this.operator3 = null;
    this.operator4 = null;
    this.enOperator1 = null;
    this.enOperator2 = null;
    this.enOperator3 = null;
    this.enOperator4 = null;
    this.resetTemplate();
  }

  setObligatoryValues() {
    if (this.obligatoryValues) {
      this.variableObject = false;
      this.variableValue = false;
      this.differentValues = false;
    } else {
      this.nfrTemplate.value_1 = null;
      this.nfrTemplate.requirement_level = null;
      this.nfrTemplate.value_2 = null;
      this.nfrTemplate.value_range_1_1 = null;
      this.nfrTemplate.value_range_1_2 = null;
      this.nfrTemplate.value_range_2_1 = null;
      this.nfrTemplate.value_range_2_2 = null;
      this.plural = false;
      this.nfrTemplate.concretizing_value = null;
    }
  }

  setVariableValues() {
    this.nfrTemplate.property = null;
    this.nfrTemplate.property2 = null;
    this.nfrTemplate.propertyVariable = null;
    this.nfrTemplate.en_property = null;
    this.nfrTemplate.en_property2 = null;
    this.nfrTemplate.en_propertyVariable = null;
  }

  submit() {
    if (this.isValid()) {
      // Formulations erzeugen
      if (this.template === 1) {
        this.newNfr.type = 'EIGENSCHAFT';
        this.buildTemplate1();
        this.buildEnglishTemplate1();
      }
      if (this.template === 2) {
        this.newNfr.type = 'EIGENSCHAFT_MIT_BEDINGUNG';
        this.buildTemplate2();
        this.buildEnglishTemplate2();
      }
      if (this.template === 3) {
        this.newNfr.type = 'PROZESS';
        this.buildTemplate3();
        this.buildEnglishTemplate3();
      }
      if (this.template === 4) {
        this.newNfr.type = 'PROZESS_MIT_BEDINGUNG';
        this.buildTemplate4();
        this.buildEnglishTemplate4();
      }
      if (this.template === 5) {
        this.newNfr.type = 'UMGEBUNG';
        this.buildTemplate5();
        this.buildEnglishTemplate5();
      }
      if (this.template === 6) {
        this.newNfr.type = 'UMGEBUNG_MIT_BEDINGUNG';
        this.buildTemplate6();
        this.buildEnglishTemplate6();
      }
      // Checkboxen
      this.newNfr.concretizingValue = this.concreteValue;
      this.newNfr.liable = this.obligatoryValues;
      this.newNfr.variablePartMatter = this.variableObject;
      this.newNfr.variableValue = this.variableValue;
      this.newNfr.valueVariable = this.differentValues;
      this.newNfr.plural = this.plural;
      // TODO: Werte für Bedingungen (conditionValues)

      // Formulations eintragen
      this.newNfr.formulationGerman = this.formulation;
      this.newNfr.formulationEnglish = this.enFormulation;

      this.checkSelectedSectors();
      console.log(this.nfrTemplate);
      console.log(this.newNfr);
      this.store$.dispatch(new CreateNewNfr({nfr: this.newNfr}));
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  checkSelectedSectors() {
    if (this.newNfr.sectors.length === this.sectors.length) {
      this.newNfr.sectors = ['ALL'];
    }
  }

  addComponentProperty() {
    if (!this.variableObject) {
      if (this.nfrTemplate.component !== '') {
        this.formulation += ' ' + this.nfrTemplate.component;
      }
      if (this.nfrTemplate.property !== '') {
        this.formulation += ' ' + this.nfrTemplate.property;
      }
      if (this.variableValue && (this.isTemplateActive(1) || this.isTemplateActive(2))) {
        this.formulation += ' ' + '<VARIABLER WERT>';
        if (this.nfrTemplate.propertyVariable !== '') {
          this.formulation += ' ' + this.nfrTemplate.propertyVariable;
        }
      }
    } else {
      if (this.isTemplateActive(3) || this.isTemplateActive(4)) {
        this.formulation += ' ' + '<' + this.templateNodes[9].description.toUpperCase() + '>';
      } else {
        this.formulation += ' ' + '<' + this.templateNodes[1].description.toUpperCase() + '>';
      }
    }
  }

  addEnglishComponentProperty() {
    if (!this.variableObject) {
      if (this.nfrTemplate.en_component !== '') {
        this.enFormulation += ' ' + this.nfrTemplate.en_component;
      }
      if (this.nfrTemplate.en_property !== '') {
        this.enFormulation += ' ' + this.nfrTemplate.en_property;
      }
      if (this.variableValue && (this.isTemplateActive(1) || this.isTemplateActive(2))) {
        this.enFormulation += ' ' + '<VARIABLE VALUE>';
        if (this.nfrTemplate.en_propertyVariable !== '') {
          this.enFormulation += ' ' + this.nfrTemplate.en_propertyVariable;
        }
      }
    } else {
      if (this.isTemplateActive(3) || this.isTemplateActive(4)) {
        this.enFormulation += ' ' + '<' + this.templateNodes[9].descriptionEnglish.toUpperCase() + '>';
      } else {
        this.enFormulation += ' ' + '<' + this.templateNodes[1].descriptionEnglish.toUpperCase() + '>';
      }
    }
  }

  addProperty2() {
    if (!this.variableObject) {
      this.formulation += ' ' + this.nfrTemplate.property2;
    } else {
      if (this.isTemplateActive(3) || this.isTemplateActive(4)) {
        this.formulation += ' ' + '<' + this.templateNodes[10].description.toUpperCase() + '>';
      } else {
        this.formulation += ' ' + '<' + this.templateNodes[1].description.toUpperCase() + '>';
      }
    }
  }

  addEnglishProperty2() {
    if (!this.variableObject) {
      this.enFormulation += ' ' + this.nfrTemplate.en_property2;
    } else {
      if (this.isTemplateActive(3) || this.isTemplateActive(4)) {
        this.enFormulation += ' ' + '<' + this.templateNodes[10].descriptionEnglish.toUpperCase() + '>';
      } else {
        this.enFormulation += ' ' + '<' + this.templateNodes[1].descriptionEnglish.toUpperCase() + '>';
      }
    }
  }

  addValueRange1() {
    if (!this.valueRange) {
      if (!this.obligatoryValues) {
        this.formulation += ' ' + '<' + this.templateNodes[4].description.toUpperCase() + '>';
      } else {
        this.formulation += ' ' + this.nfrTemplate.value_1;
      }
    } else {
      if (!this.obligatoryValues) {
        // TODO: Enum
        this.formulation += ' ' + '<UNTERE GRENZE DES WERTES>' + ' ' + this.operator2 + ' ' + '<OBERE GRENZE DES WERTES>';
      } else {
        this.formulation += ' ' + this.nfrTemplate.value_range_1_1 + ' ' + this.operator2 + ' ' + this.nfrTemplate.value_range_1_2;
      }
    }
  }

  addEnglishValueRange1() {
    if (!this.valueRange) {
      if (!this.obligatoryValues) {
        this.enFormulation += ' ' + '<' + this.templateNodes[4].descriptionEnglish.toUpperCase() + '>';
      } else {
        this.enFormulation += ' ' + this.nfrTemplate.value_1;
      }
    } else {
      if (!this.obligatoryValues) {
        // TODO: Enum
        this.enFormulation += ' ' + '<LOWER BOUND OF THE VALUE>' + ' ' + this.enOperator2 + ' ' + '<UPPER BOUND OF THE VALUE>';
      } else {
        this.enFormulation += ' ' + this.nfrTemplate.value_range_1_1 + ' ' + this.enOperator2 + ' ' + this.nfrTemplate.value_range_1_2;
      }
    }
  }

  addValueRange2() {
    if (!this.valueRange2) {
      if (!this.obligatoryValues) {
        this.formulation += ' ' + '<' + this.templateNodes[4].description.toUpperCase() + '>';
      } else {
        this.formulation += ' ' + this.nfrTemplate.value_2;
      }
    } else {
      if (!this.obligatoryValues) {
        // TODO: Enum
        this.formulation += ' ' + '<UNTERE GRENZE DES WERTES>' + ' ' + this.operator4 + ' ' + '<OBERE GRENZE DES WERTES>';
      } else {
        this.formulation += ' ' + this.nfrTemplate.value_range_2_1 + ' ' + this.operator4 + ' ' + this.nfrTemplate.value_range_2_2;
      }
    }
  }

  addEnglishValueRange2() {
    if (!this.valueRange2) {
      if (!this.obligatoryValues) {
        this.enFormulation += ' ' + '<' + this.templateNodes[4].descriptionEnglish.toUpperCase() + '>';
      } else {
        this.enFormulation += ' ' + this.nfrTemplate.value_2;
      }
    } else {
      if (!this.obligatoryValues) {
        // TODO: Enum
        this.enFormulation += ' ' + '<LOWER BOUND OF THE VALUE>' + ' ' + this.enOperator4 + ' ' + '<UPPER BOUND OF THE VALUE>';
      } else {
        this.enFormulation += ' ' + this.nfrTemplate.value_range_2_1 + ' ' + this.enOperator4 + ' ' + this.nfrTemplate.value_range_2_2;
      }
    }
  }

  addRequirementLevel() {
    if (!this.obligatoryValues) {
      this.formulation += ' ' + '<' + this.templateNodes[2].description.toUpperCase() + '>';
    } else {
      this.formulation += ' ' + this.nfrTemplate.requirement_level.description;
    }
  }

  addEnglishRequirementLevel() {
    if (!this.obligatoryValues) {
      this.enFormulation += ' ' + '<' + this.templateNodes[2].descriptionEnglish.toUpperCase() + '>';
    } else {
      this.enFormulation += ' ' + this.nfrTemplate.requirement_level.descriptionEnglish;
    }
  }

  addConcreteValue() {
    if (this.concreteValue) {
      if (!this.obligatoryValues) {
        // TODO: Enum
        this.formulation += ' ' + '<KONKRETISIERENDER WERT>';
      } else {
        this.formulation += ' ' +
          this.nfrTemplate.concretizing_value;
      }
    }
  }

  addEnglishConcreteValue() {
    if (this.concreteValue) {
      if (!this.obligatoryValues) {
        // TODO: Enum
        this.enFormulation += ' ' + '<CONCRETIZING VALUE>';
      } else {
        this.enFormulation += ' ' +
          this.nfrTemplate.en_concretizing_value;
      }
    }
  }

  addQualifyingExpression() {
    if (this.nfrTemplate.qualifying_expression !== null) {
      if (this.valueRange) {
        this.formulation += ' ' + this.operator1;
      } else {
        this.formulation += ' ' +
          this.nfrTemplate.qualifying_expression.description;
      }
    }
  }

  addEnglishQualifyingExpression() {
    if (this.nfrTemplate.qualifying_expression !== null) {
      if (this.valueRange) {
        this.enFormulation += ' ' + this.enOperator1;
      } else {
        this.enFormulation += ' ' +
          this.nfrTemplate.qualifying_expression.descriptionEnglish;
      }
    }
  }

  addQualifyingExpression2() {
    if (this.nfrTemplate.qualifying_expression2 !== null) {
      if (this.valueRange2) {
        this.formulation += ' ' + this.operator3;
      } else {
        this.formulation += ' ' + this.nfrTemplate.qualifying_expression2.description;
      }
    }
  }

  addEnglishQualifyingExpression2() {
    if (this.nfrTemplate.qualifying_expression2 !== null) {
      if (this.valueRange2) {
        this.enFormulation += ' ' + this.enOperator3;
      } else {
        this.enFormulation += ' ' + this.nfrTemplate.qualifying_expression2.descriptionEnglish;
      }
    }
  }

  addSecondCondition() {
    if (this.nfrTemplate.second_part_condition !== '') {
      this.formulation += ' ' + this.nfrTemplate.second_part_condition;
    }
  }

  addEnglishSecondCondition() {
    if (this.nfrTemplate.en_second_part_condition !== '') {
      this.enFormulation += ' ' + this.nfrTemplate.en_second_part_condition;
    }
  }

  addObject() {
    if (this.nfrTemplate.details !== '') {
      this.formulation += ' ' + this.nfrTemplate.details;
    }
    this.formulation += ' ' + this.nfrTemplate.object;
    if (this.nfrTemplate.details0 !== '') {
      this.formulation += ' ' + this.nfrTemplate.details0;
    }
  }

  addEnglishObject() {
    if (this.nfrTemplate.en_details !== '') {
      this.enFormulation += ' ' + this.nfrTemplate.en_details;
    }
    this.enFormulation += ' ' + this.nfrTemplate.en_object;
    if (this.nfrTemplate.en_details0 !== '') {
      this.enFormulation += ' ' + this.nfrTemplate.en_details0;
    }
  }

  addCharacteristic() {
    if (this.nfrTemplate.characteristic !== '') {
      this.formulation += ' ' + this.nfrTemplate.characteristic;
    }
    if (this.variableValue) {
      this.formulation += ' ' + '<VARIABLER WERT>';
      if (this.nfrTemplate.characteristic2 !== '') {
        this.formulation += ' ' + this.nfrTemplate.characteristic2;
      }
    }
  }

  addEnglishCharacteristic() {
    if (this.nfrTemplate.en_characteristic !== '') {
      this.enFormulation += ' ' + this.nfrTemplate.en_characteristic;
    }
    if (this.variableValue) {
      this.enFormulation += ' ' + '<VARIABLE VALUE>';
      if (this.nfrTemplate.en_characteristic2 !== '') {
        this.enFormulation += ' ' + this.nfrTemplate.en_characteristic2;
      }
    }
  }

  buildTemplate1() {
    this.formulation =
      this.nfrTemplate.characteristic;
    this.addComponentProperty();
    this.addRequirementLevel();
    this.addConcreteValue();
    this.addQualifyingExpression();
    this.addValueRange1();
    this.formulation += ' ' +
      this.nfrTemplate.be + '.';
  }

  buildEnglishTemplate1() {
    this.enFormulation = this.nfrTemplate.en_characteristic;
    this.addEnglishComponentProperty();
    this.addEnglishRequirementLevel();
    this.enFormulation += ' be';
    this.addEnglishQualifyingExpression();
    this.addEnglishValueRange1();
    this.addEnglishConcreteValue();
    this.enFormulation += '.';
  }

  buildTemplate2() {
    this.formulation = this.nfrTemplate.conditional.description + ' ' + this.nfrTemplate.condition;
    if (this.conditionValues) {
      this.addQualifyingExpression2();
      this.addValueRange2();
      this.addSecondCondition();
    }
    this.formulation += ',';
    this.addRequirementLevel();
    this.addCharacteristic();
    this.addComponentProperty();
    this.addConcreteValue();
    this.addQualifyingExpression();
    this.addValueRange1();
    this.formulation += ' ' +
      this.nfrTemplate.be + '.';
  }

  buildEnglishTemplate2() {
    this.enFormulation = this.nfrTemplate.conditional.descriptionEnglish + ' ' + this.nfrTemplate.en_condition;
    if (this.conditionValues) {
      this.addEnglishQualifyingExpression2();
      this.addEnglishValueRange2();
      this.addEnglishSecondCondition();
    }
    this.enFormulation += ',';
    this.addEnglishCharacteristic();
    this.addEnglishComponentProperty();
    this.addEnglishRequirementLevel();
    this.enFormulation += ' be';
    this.addEnglishQualifyingExpression();
    this.addEnglishValueRange1();
    this.addEnglishConcreteValue();
    this.enFormulation += '.';
  }

  buildTemplate3() {
    this.formulation = '';
    this.addComponentProperty();
    this.addRequirementLevel();
    this.formulation += ' ' + this.templateNodes[16].templateTexts[4].description;
    this.addProperty2();
    this.addConcreteValue();
    this.addCharacteristic();
    this.addQualifyingExpression();
    this.addValueRange1();
    this.formulation += ' ' + this.templateNodes[16].templateTexts[5].description + '.';
  }

  buildEnglishTemplate3() {
    this.enFormulation = '';
    this.addEnglishComponentProperty();
    this.addEnglishRequirementLevel();
    this.enFormulation += ' ' + this.templateNodes[16].templateTexts[4].descriptionEnglish;
    this.addEnglishProperty2();
    this.enFormulation += ' ' + this.templateNodes[16].templateTexts[5].descriptionEnglish;
    this.addEnglishCharacteristic();
    this.addEnglishQualifyingExpression();
    this.addEnglishValueRange1();
    this.addConcreteValue();
    this.enFormulation += '.';
  }

  buildTemplate4() {
    this.formulation = '';
    this.addComponentProperty();
    this.addRequirementLevel();
    this.formulation += ' ' + this.templateNodes[16].templateTexts[4].description;
    this.formulation += ' ' + this.nfrTemplate.conditional.description.toLowerCase() + ' ' + this.nfrTemplate.condition;
    if (this.conditionValues) {
      this.addQualifyingExpression2();
      this.addValueRange2();
      this.addSecondCondition();
    }
    this.formulation += ',';
    this.addProperty2();
    this.addConcreteValue();
    this.addCharacteristic();
    this.addQualifyingExpression();
    this.addValueRange1();
    this.formulation += ' ' + this.templateNodes[16].templateTexts[5].description + '.';
  }

  buildEnglishTemplate4() {
    this.enFormulation = '';
    this.addEnglishComponentProperty();
    this.addEnglishRequirementLevel();
    this.enFormulation += ' ' + this.templateNodes[16].templateTexts[4].descriptionEnglish;
    this.enFormulation += ' ' + this.nfrTemplate.conditional.descriptionEnglish.toLowerCase() + ' ' + this.nfrTemplate.en_condition;
    if (this.conditionValues) {
      this.addEnglishQualifyingExpression2();
      this.addEnglishValueRange2();
      this.addEnglishSecondCondition();
    }
    this.enFormulation += ',';
    this.addEnglishProperty2();
    this.enFormulation += ' ' + this.templateNodes[16].templateTexts[5].descriptionEnglish;
    this.addEnglishCharacteristic();
    this.addEnglishQualifyingExpression();
    this.addEnglishValueRange1();
    this.addConcreteValue();
    this.enFormulation += '.';
  }

  buildTemplate5() {
    this.formulation = this.nfrTemplate.actor;
    this.addRequirementLevel();
    this.addObject();
    this.addConcreteValue();
    this.addQualifyingExpression();
    this.addValueRange1();
    this.formulation += ' ' + this.nfrTemplate.process_verb + '.';
  }

  buildEnglishTemplate5() {
    this.enFormulation = '';
    this.addEnglishConcreteValue();
    this.enFormulation += ' ' + this.nfrTemplate.en_actor;
    this.addEnglishRequirementLevel();
    this.enFormulation += ' ' + this.nfrTemplate.en_process_verb;
    this.addEnglishObject();
    this.addEnglishQualifyingExpression();
    this.addEnglishValueRange1();
    this.enFormulation += '.';
  }

  buildTemplate6() {
    this.formulation = this.nfrTemplate.conditional.description + ' ' + this.nfrTemplate.condition;
    if (this.conditionValues) {
      this.addQualifyingExpression2();
      this.addValueRange2();
      this.addSecondCondition();
    }
    this.formulation += ',';
    this.addRequirementLevel();
    this.formulation += ' ' + this.nfrTemplate.actor;
    this.addObject();
    this.addConcreteValue();
    this.addQualifyingExpression();
    this.addValueRange1();
    this.formulation += ' ' + this.nfrTemplate.process_verb + '.';
  }

  buildEnglishTemplate6() {
    this.enFormulation = this.nfrTemplate.conditional.descriptionEnglish + ' ' + this.nfrTemplate.en_condition;
    if (this.conditionValues) {
      this.addEnglishQualifyingExpression2();
      this.addEnglishValueRange2();
      this.addEnglishSecondCondition();
    }
    this.addEnglishConcreteValue();
    this.enFormulation += ',';
    this.enFormulation += ' ' + this.nfrTemplate.en_actor;
    this.addEnglishRequirementLevel();
    this.enFormulation += ' ' + this.nfrTemplate.en_process_verb;
    this.addEnglishObject();
    this.addEnglishQualifyingExpression();
    this.addEnglishValueRange1();
    this.enFormulation += '.';
  }

  isTemplateActive(id: number) {
    return this.template === id;
  }

  isFactor(id: string) {
    return this.metric.identifier.split('.')[0] === id;
  }

  isPublicSector() {
    return (this.newNfr.sectors.includes('PUBLIC_SECTOR') && this.newNfr.sectors.length === 1);
  }
}
