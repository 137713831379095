import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { Screenshot } from 'src/app/bitv/types/evaluationstep';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BitvImageSelectionComponent } from '../bitv-image-selection/bitv-image-selection.component';

@Component({
  selector: 'nfa-screenshot-dialog',
  template: `
    <div mat-dialog-content><img [src]="data.dataUrl" alt="Screenshot"></div>`
})
export class ScreenshotDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

@Component({
  selector: 'nfa-bitv-screenshot-item',
  templateUrl: './bitv-screenshot-item.component.html',
  styleUrls: ['./bitv-screenshot-item.component.scss']
})
export class BitvScreenshotItemComponent {

  @Input() screenshot: Screenshot;
  @Output() deleteScreenshot = new EventEmitter<Screenshot>();
  @ViewChild(BitvImageSelectionComponent) imageSelection;
  filePath: string;

  constructor(private dialog: MatDialog) {
  }

  showImageEdit(screenshot: Screenshot) {
    screenshot.editMode = true;
  }

  cancelImageEdit(screenshot: Screenshot) {
    screenshot.editMode = false;
    this.imageSelection.reset();
  }

  exitImageEdit(screenshot: Screenshot) {
    screenshot.editMode = false;
    this.imageSelection.emitChanges();
  }

  emitDelete() {
    this.deleteScreenshot.emit(this.screenshot);
  }

  openScreenshot(dataUrl: any) {
    this.dialog.open(ScreenshotDialogComponent, {
      data: {dataUrl}
    });
  }


}
