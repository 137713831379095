export class MaxLengths {

  static applicationName = 255;
  static applicationcustomerName = 255;
  static applicationorgUnit = 255;

  static contactTitel = 45;
  static contactNobilityTitel = 45;
  static contactFirstName = 45;
  static contactLastName = 45;
  static contactPosition = 45;
  static contactEmailPrimary = 45;
  static contactEmailSecondary = 45;
  static contactPhone = 45;
  static contactPhoneMobile = 45;

  static businessAdressStreet = 200;
  static businessAdressAdditional = 200;
  static businessAdressHousenumber = 45;
  static businessAdressFloor = 45;
  static businessAdressRoomNumber = 45;
  static businessAdressZipCode = 45;
  static businessAdressCity = 45;
  static businessAdressPoBox = 200;

  // muessen noch ergaenzt und in die html eingefuegt werden
  static nfrNameEN = 200;
  static nfrNameDE = 200;
  static nfrReference = 40;
  static nfrQuestionEN = 2000;
  static nfrQuestionDE = 2000;
  // ...

  static packageNameEN = 100;
  static packageNameDE = 100;
  static packageDescriptionEN = 500;
  static packageDescriptionDE = 500;

}

export class HilfeFunktionen {
  static initialNavbarExpanded = () => window.innerWidth > 1280;
  static setApplicationTitle = (titleSuffix?: string): string => {
    return document.getElementById('app-title').innerText = !!titleSuffix ? `QuMaP - ${titleSuffix}` : 'QuMaP';
  };
}

export class RoutingParams {
  static readonly appIdParam = 'applicationid';
  static readonly bitvIdParam = 'bitvid';
  static readonly testobjectIdParam = 'testobjectid';
}

