import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { catalogReducer } from './catalog-state/catalog.reducer';
import { CatalogComponent } from './catalog/catalog.component';
import { EffectsModule } from '@ngrx/effects';
import { CatalogEffects } from './catalog-state/catalog.effects';
import { CatalogAdapterService } from './adapter/catalog.service';
import { MaterialModule } from '../material/material.module';
import { NfrCreateSimpleComponent } from './nfr-create-simple/nfr-create-simple.component';
import { AppRoutingModule } from '../app-routing.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidenavModule } from '../shared/sidenav/sidenav.module';
import { InformationHeaderModule } from '../shared/information-header/information-header.module';

@NgModule({
  declarations: [
    CatalogComponent,
    NfrCreateSimpleComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature('catalog', catalogReducer),
    EffectsModule.forFeature([CatalogEffects]),
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    RouterModule,
    MatTooltipModule,
    SharedModule,
    MatSidenavModule,
    SidenavModule,
    InformationHeaderModule
  ],
  providers: [CatalogAdapterService],
  exports: [
    CatalogComponent,
  ]
})
export class CatalogModule {
}
