import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';

export const initKeycloak = (keycloakService: KeycloakService): () => Promise<boolean> => {
  return () => keycloakService.init({
    config: environment.keycloak,
    bearerPrefix: 'Bearer',
    initOptions: {
      onLoad: 'check-sso',
      checkLoginIframe: false
    },
    bearerExcludedUrls: []
  });
};
