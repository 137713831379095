<div class="screenshot-item">
  <div class="screenshot-delete-action">
    <button mat-icon-button data-cy="screenshotDelete" (click)="emitDelete()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div class="screenshot-form">
    <div *ngIf="!screenshot.editMode; else pictureUpload">
      <div class="img-container" (click)="openScreenshot(screenshot.dataUrl)">
        <img [src]="screenshot.dataUrl" style=" max-width: 100%; max-height: 100%;">
      </div>
    </div>
    <button mat-icon-button *ngIf="!screenshot.editMode" (click)="showImageEdit(screenshot)">
      <mat-icon>edit</mat-icon>
    </button>
    <ng-template #pictureUpload>
      <nfa-bitv-image-selection [(dataUrl)]="screenshot.dataUrl">
      </nfa-bitv-image-selection>
      <div class="screenshot-actions">
        <button mat-icon-button (click)="exitImageEdit(screenshot)">
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button (click)="cancelImageEdit(screenshot)">
          <mat-icon>undo</mat-icon>
        </button>
      </div>
    </ng-template>
    <mat-form-field class="comment-field" appearance="outline">
      <mat-label>Beschreibung</mat-label>
      <textarea matInput style="resize: none; height: 104px; margin-top: 0.3em" name="comment"
                [(ngModel)]="screenshot.comment" required></textarea>
      <mat-error>
        <p> Bitte geben Sie eine Bescheibung ein.</p>
      </mat-error>
    </mat-form-field>
  </div>
</div>
