import { Component, OnDestroy, OnInit } from '@angular/core';
import { BitvService } from '../bitv.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccessRight, UserAccessRightsService } from '../user-access-rights.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { isToolSection, TestToolCategory, TestTools } from '../types/test-tools';
import { UnsavedChangesProvider } from '../../guards/unsaved-changes.guard';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors
} from '@angular/forms';
import { Language } from '../../app-state/settings';
import { BalmTestToolsLangPack } from './balm-test-tools.lang.de';
import { ConfirmationProvider } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { RoutingParams } from '../../shared/global';
import { LockUi, UiLockable } from '../../shared/utils/ui-lockable';

@Component({
  selector: 'nfa-balm-test-tool',
  templateUrl: './balm-test-tools.component.html',
  styleUrls: ['./balm-test-tools.component.scss'],
})
export class BalmTestToolsComponent implements OnInit, OnDestroy, UnsavedChangesProvider, ConfirmationProvider, UiLockable {
  readonly Object = Object;

  readonly nameHintTriggerLength = 200;
  readonly nameMaxLength = 255;
  readonly versionHintTriggerLength = 40;
  readonly versionMaxLength = 55;

  texts: BalmTestToolsLangPack;
  formRoot: FormGroup<Partial<{ [K in TestToolCategory]: FormArray<FormGroup> }>>;
  uiLocked = false;
  loading = true;

  private userAccessRight: UserAccessRight = UserAccessRight.NOACCESS;
  private initialContent: TestTools;
  private readonly subscriptions: Subscription[] = [];
  readonly duplicateEntryErrorStateMatcher: ErrorStateMatcher = {
    isErrorState(control: AbstractControl | null, _: FormGroupDirective | NgForm | null): boolean {
      return control?.touched && (control?.invalid || control.parent?.errors?.duplicateEntry);
    }
  };

  get returnAddress() {
    return `/applications/${this.getRouteParam(RoutingParams.appIdParam)}/balm/${this.getRouteParam(RoutingParams.bitvIdParam)}`;
  };

  get canEdit(): boolean {
    return this.userAccessRight === UserAccessRight.WRITEACCES;
  }

  constructor(
    private bitvService: BitvService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userAccessRightsService: UserAccessRightsService,
  ) {
  }

  async ngOnInit() {
    try {
      let language = Language.DEUTSCH;
      this.texts = await import(`./balm-test-tools.lang.${language}`).then(module => module.texts);
      const appId = Number(this.getRouteParam(RoutingParams.appIdParam));
      this.userAccessRight = await this.userAccessRightsService.checkAccessRightById(appId);
      const balmId = Number(this.getRouteParam(RoutingParams.bitvIdParam));
      this.initialContent = await firstValueFrom(this.bitvService.getTestToolsForBalm(balmId));

      this.initForm();
    } finally {
      this.loading = false;
    }
  }

  private initForm() {
    this.formRoot = new FormGroup({});
    for (let sectionKey in this.initialContent) {
      if (isToolSection(sectionKey)) {
        this.formRoot.setControl(sectionKey, this.createToolSectionForm(sectionKey));
      } else {
        console.error(`"${sectionKey}" is not a valid Test Tool section key!`);
      }
    }
  }

  private createToolSectionForm(sectionKey: string) {
    let ret: FormArray<FormGroup> = new FormArray<FormGroup>([] as FormGroup[]);

    for (let tool of this.initialContent[sectionKey]) {
      ret.push(this.createToolForm(sectionKey, tool.name, tool.version));
    }

    if (this.canEdit) {
      ret.push(this.createToolForm(sectionKey));
      this.subscriptions.push(ret.valueChanges.subscribe(_ => {
        this.validateToolSectionForm(sectionKey, ret);

        const lastToolControls = ret.at(-1).controls;
        if (lastToolControls.name.value.length !== 0 && lastToolControls.version.value.length !== 0) {
          ret.push(this.createToolForm(sectionKey));
        }
      }));
    }

    return ret;
  }

  private createToolForm(sectionKey: string, initialName?: string, initialVersion?: string) {
    const fb = new FormBuilder();
    const ret = fb.group({
      name: fb.control(initialName || ''),
      version: fb.control(initialVersion || '')
    });
    this.subscriptions.push(ret.valueChanges.subscribe(_ => {
      this.validateToolForm(sectionKey, ret);
    }));
    return ret;
  }

  private validateToolForm(sectionKey: string, toolGroup: FormGroup) {
    const newName = toolGroup.controls.name.value;
    const newVersion = toolGroup.controls.version.value;

    const nameErrors: ValidationErrors = {};
    const nameMissing = newName.length === 0 && newVersion.length !== 0;
    if (nameMissing) {
      nameErrors.nameMissing = this.texts.validation[sectionKey].nameMissing;
    }

    toolGroup.controls.name.setErrors(Object.keys(nameErrors).length !== 0 ? nameErrors : null);

    const versionErrors: ValidationErrors = {};
    const versionMissing = newName.length !== 0 && newVersion.length === 0;
    if (versionMissing) {
      versionErrors.versionMissing = this.texts.validation[sectionKey].versionMissing;
    }
    toolGroup.controls.version.setErrors(Object.keys(versionErrors).length !== 0 ? versionErrors : null);
  }

  private validateToolSectionForm(sectionKey: string, formArray: FormArray<FormGroup>) {
    const formGroupErrors = {duplicateEntry: this.texts.validation[sectionKey].duplicateEntry};
    const exclusionKey = '';
    this.aggregateDuplicates(formArray, exclusionKey)
      .forEach((value, key) => value
        .forEach(control => {
          if (key != exclusionKey && value.length > 1) {
            control.setErrors(formGroupErrors);
            control.markAllAsTouched();
          } else {
            control.setErrors(null);
          }
        }));
  }

  private aggregateDuplicates(formArray: FormArray<FormGroup>, keyForExcludedControls: string) {
    const variations: Map<string, FormGroup[]> = new Map();
    formArray.controls.forEach((group: FormGroup) => {
      const name = group.controls.name.value;
      const value = group.controls.version.value;
      const key: string = name.length === 0 || value.length === 0 ? keyForExcludedControls : name.trim() + value.trim();
      if (!variations.has(key)) {
        variations.set(key, []);
      }
      variations.get(key).push(group);
    });
    return variations;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  hasNoUnsavedChanges(): boolean {
    return this.formRoot.pristine;
  }

  async triggerConfirmation() {
    await this.save();
  }

  getDeleteButtonTitle(sectionKey: TestToolCategory, index: number): string {
    const toolControls = this.formRoot.controls[sectionKey].controls[index].controls;
    return `${this.texts.toolNames[sectionKey]} ${toolControls.name.value} (Version ${toolControls.version.value}) löschen`;
  }

  deleteTool(sectionKey: TestToolCategory, index: number) {
    const formArray = this.formRoot.controls[sectionKey] as FormArray<FormGroup>;
    formArray.removeAt(index);
    formArray.markAsDirty();
  }

  canSave() {
    return this.canEdit && !this.hasNoUnsavedChanges() && this.formRoot.valid && !this.uiLocked;
  }

  @LockUi()
  async save() {
    const balmId = Number(this.getRouteParam(RoutingParams.bitvIdParam));
    await firstValueFrom(this.bitvService.updateTestToolsForBalm(balmId, this.prepareNetworkPayload()));
    this.formRoot.markAsPristine();
    await this.router.navigate([this.returnAddress]);
  }

  private prepareNetworkPayload(): TestTools {
    const formValue = this.formRoot.value;

    // Entfernt die leeren Einträge für neue Tools am Ende
    for (let key in formValue) {
      formValue[key].pop();
    }

    return formValue;
  }

  private getRouteParam(key: string): string {
    return this.activatedRoute.snapshot.paramMap.get(key);
  }

}
