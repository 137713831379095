import { Evaluationstep } from './evaluationstep';

export interface Pruefgegenstand {
  name?: string;
  url?: string | null;
  applicationId?: number;
  id?: number;
  typedUrl?: string;
  typ?: string;
  contentType?: string;
}

export interface ConfigurationObject {
  label?: string;
  pruefgegenstandId?: number;
  type?: string;
  checked?: boolean;
  conformityLevel?: string;
  // Prüfer
  // Medien
}
export interface TestObject {
  testobjectId?: number;
  conformityLevel?: string;
  evaluationSteps?: Evaluationstep[];
  [key: string]: any;
  }

export class ConfigurationObjectImpl implements ConfigurationObject {

}
export class TestObjectImpl implements TestObject {
  testobjectId?: number;
  conformityLevel?: string;

  constructor(config: ConfigurationObject) {
    this.testobjectId = config.pruefgegenstandId;
    this.conformityLevel = config.conformityLevel;
  }

}
