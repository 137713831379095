import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  loadBranches,
  loadBranchesSuccess,
  loadDocuments,
  loadDocumentsSuccess,
  loadFailure,
  loadSystemTypes,
  loadSystemTypesSuccess
} from './application.actions';
import { areBranchesLoaded, areDocumentsLoaded, areSystemTypesLoaded, ApplicationState } from './application.reducer';
import { LoadCanceled } from '../../catalog/catalog-state/catalog.actions';
import { ApplicationsService } from '../applications.service';

@Injectable()
export class ApplicationEffects {

  loadBranches$ = createEffect(() => this.actions$.pipe(
    ofType(loadBranches.type),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(areBranchesLoaded))
    )),
    switchMap(([, loaded]) => {
      if (loaded) {
        return of(new LoadCanceled());
      }
      return this.adapter.getAllSectors().pipe(
        map(branches => loadBranchesSuccess({branches})),
        catchError(error => of(loadFailure({error})))
      );
    }))
  );

  loadDocuments$ = createEffect(() => this.actions$.pipe(
    ofType(loadDocuments.type),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(areDocumentsLoaded))
    )),
    switchMap(([, loaded]) => {
      if (loaded) {
        return of(new LoadCanceled());
      }
      return this.adapter.getDocuments().pipe(
        map(documents => loadDocumentsSuccess({documents})),
        catchError(error => of(loadFailure({error})))
      );
    }))
  );

  loadSystemTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadSystemTypes.type),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(areSystemTypesLoaded))
    )),
    switchMap(([, loaded]) => {
      if (loaded) {
        return of(new LoadCanceled());
      }
      return this.adapter.getAllSystemTypes().pipe(
        map(systemTypes => loadSystemTypesSuccess({systemTypes})),
        catchError(error => of(loadFailure({error})))
      );
    }))
  );

  constructor(
    private readonly store$: Store<ApplicationState>,
    private readonly actions$: Actions,
    private readonly adapter: ApplicationsService) {
  }

}
