import { NgModule } from '@angular/core';
import { NfaPackagesComponent } from './nfa-packages.component';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MaterialModule } from '../material/material.module';
import { NfaPackagesAdapterService } from './adapter/nfa-packages-adapter.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { CreatePackageComponent } from './create-package/create-package.component';
import { SavePopUpComponent } from './create-package/save-pop-up/save-pop-up.component';

@NgModule({
  declarations: [NfaPackagesComponent, CreatePackageComponent, SavePopUpComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MaterialModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule
  ],
  providers: [NfaPackagesAdapterService],
  exports: [
    NfaPackagesComponent
  ]
})
export class NfaPackagesModule { }
