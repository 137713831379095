import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  BackToCatalog,
  CatalogActions,
  CreateNewNfr,
  CreateNewNfrFailure,
  CreateNewNfrSuccess,
  LoadCanceled,
  LoadCatalog,
  LoadCatalogFailure,
  LoadCatalogSuccess,
  LoadNfrs,
  LoadNfrsFailure,
  LoadNfrsForMetric,
  LoadNfrsForMetricFailure,
  LoadNfrsForMetricSuccess,
  LoadNfrsForMultipleMetric,
  LoadNfrsForMultipleMetricSuccess,
  LoadNfrsSuccess,
  LoadSectors,
  LoadSectorsFailure,
  LoadSectorsSuccess,
  LoadTemplateNames,
  LoadTemplateNamesFailure,
  LoadTemplateNamesSuccess,
  LoadTemplates,
  LoadTemplatesFailure,
  LoadTemplatesSuccess,
  NavigateToNfr
} from './catalog.actions';
import { CatalogAdapterService } from '../adapter/catalog.service';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, getCurrentLanguage } from '../../app-state/app.reducer';
import { Router } from '@angular/router';
import {
  areSectorsLoaded,
  areTemplateNamesLoaded,
  areTemplateNodesLoaded,
  getFactorsLoadedAt
} from './catalog.reducer';
import * as dateFns from 'date-fns';
import { noopAction } from '../../app-state/app.actions';

@Injectable()
export class CatalogEffects {

  constructor(
    private readonly store$: Store<AppState>,
    private readonly actions$: Actions,
    private readonly adapter: CatalogAdapterService,
    private readonly router: Router) {
  }

  //
   loadCatalog$: Observable<LoadCatalogSuccess | LoadCatalogFailure | LoadCanceled> = createEffect(() => this.actions$.pipe(
    ofType<LoadCatalog>(CatalogActions.LoadCatalog),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(getFactorsLoadedAt))
    )),
    switchMap(([action, factorsLoadedAt]) => {
      if (factorsLoadedAt !== null && dateFns.differenceInMinutes(new Date(), factorsLoadedAt) < 20) {
        return of(new LoadCanceled());
      } else if (action.language !== null && action.language.toString() !== null) {
        return this.adapter.getFactors(action.language.toString()).pipe(
          map(data => new LoadCatalogSuccess(data)),
          catchError(error => of(new LoadCatalogFailure({error})))
        );
      } else {
        return of(new LoadCatalogFailure({error: 'language not set'}));
      }
    })
  ));

   loadTemplates$: Observable<LoadTemplatesSuccess | LoadTemplatesFailure | LoadCanceled> = createEffect(() => this.actions$.pipe(
    ofType<LoadTemplates>(CatalogActions.LoadTemplates),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(areTemplateNodesLoaded))
    )),
    switchMap(([action, templateNodesLoaded]) => {
      if (templateNodesLoaded) {
        return of(new LoadCanceled());
      }
      if (action.language !== null && action.language.toString() !== null) {
        return this.adapter.getTemplateNodes(action.language.toString()).pipe(
          map(data => new LoadTemplatesSuccess(data)),
          catchError(error => of(new LoadTemplatesFailure({error})))
        );
      }
    })
  ));

   loadSectors$: Observable<LoadSectorsSuccess | LoadSectorsFailure | LoadCanceled> = createEffect(() => this.actions$.pipe(
    ofType<LoadSectors>(CatalogActions.LoadSectors),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(areSectorsLoaded))
    )),
    switchMap(([action, sectorsLoaded]) => {
      if (sectorsLoaded) {
        return of(new LoadCanceled());
      }
      if (action.language !== null && action.language.toString() !== null) {
        return this.adapter.getSectors(action.language.toString()).pipe(
          map(data => new LoadSectorsSuccess(data)),
          catchError(error => of(new LoadSectorsFailure({error})))
        );
      }
    })
  ));

   loadTemplateNames$: Observable<LoadTemplateNamesSuccess | LoadTemplateNamesFailure | LoadCanceled>
    = createEffect(() => this.actions$.pipe(
    ofType<LoadTemplateNames>(CatalogActions.LoadTemplateNames),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(areTemplateNamesLoaded))
    )),
    switchMap(([action, templateNamesLoaded]) => {
      if (templateNamesLoaded) {
        return of(new LoadCanceled());
      }
      if (action.language !== null && action.language.toString() !== null) {
        return this.adapter.getTemplateNames(action.language.toString()).pipe(
          map(data => new LoadTemplateNamesSuccess(data)),
          catchError(error => of(new LoadTemplateNamesFailure({error})))
        );
      }
    })
  ));

   loadNfrs$: Observable<LoadNfrsSuccess | LoadNfrsFailure> = createEffect(() => this.actions$.pipe(
    ofType<LoadNfrs>(CatalogActions.LoadNfrs),
    withLatestFrom(this.store$.pipe(select(getCurrentLanguage))),
    switchMap(([, language]) => {
      return this.adapter.getAllNfrs(language.toString()).pipe(
        map(allNfrs => new LoadNfrsSuccess({allNfrs})),
        catchError(error => of(new LoadNfrsFailure({error})))
      );
    })
  ));

   loadNfrsForMetric$: Observable<LoadNfrsForMetricSuccess | LoadNfrsForMetricFailure> = createEffect(() => this.actions$.pipe(
    ofType<LoadNfrsForMetric>(CatalogActions.LoadNfrsForMetric),
    withLatestFrom(this.store$.pipe(select(getCurrentLanguage))),
    switchMap(([action, language]) => {
      return this.adapter.getNfrListForMetric(language.toString(), action.payload.metricId).pipe(
        map(nfrs => new LoadNfrsForMetricSuccess({nfrs, metricId: action.payload.metricId})),
        catchError(error => of(new LoadNfrsForMetricFailure({error})))
      );
    })
  ));

   loadNfrsForMultipleMetric$: Observable<LoadNfrsForMultipleMetricSuccess | LoadNfrsForMetricFailure> = createEffect(() => this.actions$.pipe(
    ofType<LoadNfrsForMultipleMetric>(CatalogActions.LoadNfrsForMultipleMetric),
    withLatestFrom(this.store$.pipe(select(getCurrentLanguage))),
    switchMap(([action, language]) => {
      return this.adapter.getNfrListForMultipleMetric(language.toString(), action.payload.metricIds).pipe(
        map(nfrs => new LoadNfrsForMultipleMetricSuccess(nfrs)),
        catchError(error => of(new LoadNfrsForMetricFailure({error})))
      );
    })
  ));

   navigateToNfr$: Actions = createEffect(() => this.actions$.pipe(
    ofType<NavigateToNfr>(CatalogActions.NavigateToNfr),
    switchMap((action) => {
      if (action.payload.nfrId === undefined) {
        this.router.navigate(['new'], {relativeTo: action.payload.activatedRoute});
      } else {
        this.router.navigate([action.payload.nfrId], {relativeTo: action.payload.activatedRoute});
      }
      return of(noopAction());
    })
  ));

   backToCatalog$: Actions = createEffect(() => this.actions$.pipe(
    ofType<BackToCatalog>(CatalogActions.BackToCatalog),
    switchMap((action) => {
      this.router.navigate(['../'], {relativeTo: action.payload});
      return of(noopAction());
    })
  ));

   createNewNfr$: Observable<CreateNewNfrSuccess | CreateNewNfrFailure> = createEffect(() => this.actions$.pipe(
    ofType<CreateNewNfr>(CatalogActions.CreateNewNfr),
    switchMap((action) => {
      return this.adapter.saveNfr('de', action.payload.nfr).pipe(
        map(() => new CreateNewNfrSuccess({metricId: action.payload.nfr.metric})),
        catchError(error => of(new CreateNewNfrFailure({error})))
      );
    })
  ));

   createNewNfrSuccess$: Observable<LoadNfrsForMetric> = createEffect(() => this.actions$.pipe(
    ofType<CreateNewNfrSuccess>(CatalogActions.CreateNewNfrSuccess),
    switchMap((action) => {
      return of(new LoadNfrsForMetric({metricId: action.payload.metricId}));
    })
  ));

}
