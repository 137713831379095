import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'qumap-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit {

  closingText = 'OK';
  dialogTypeHint: string;

  constructor(
    public dialogRef: MatDialogRef<InformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { informationType: string, header: string, message: string, closingDialog?: string}) {
    }

  ngOnInit() {
    this.setIcon();
    this.setMessages();
    this.setClosingText();
    this.setDialogTypeHint();
    this.dialogRef.disableClose = true;
  }

  setMessages() {
    const displayMessage = document.querySelector('.display-message');
    const headerMessage = document.querySelector('.header-message');
    displayMessage.innerHTML = this.data.message;
    headerMessage.innerHTML = this.data.header;
  }

  setIcon() {
    const icon = document.querySelector('#information-dialog-icon');
    switch (this.data.informationType) {
      case 'error': {
        icon.innerHTML = 'highlight_off';
        break;
      }
      case 'info': {
        icon.innerHTML = 'info_outline';
        break;
      }
      case 'help': {
        icon.innerHTML = 'help_outline';
        break;
      }
      default: {
        icon.innerHTML = 'info_outline';
      }
    }
  }

  setDialogTypeHint() {
    switch (this.data.informationType) {
      case 'error': {
        this.dialogTypeHint = 'Fehler Dialogfeld';
        break;
      }
      case 'info': {
        this.dialogTypeHint = 'Hinweis Dialogfeld';
        break;
      }
      case 'help': {
        this.dialogTypeHint = 'Hilfe Dialogfeld';
        break;
      }
      default: {
        this.dialogTypeHint = 'Hinweis Dialogfeld';
      }
    }
  }

  setClosingText() {
    if (typeof this.data.closingDialog !== 'undefined') {
      this.closingText = this.data.closingDialog;
    }
  }

  save() {
    this.dialogRef.close(true);
  }
}
