import { format } from 'date-fns';

export class CustomDateFormatter {
  static setChangedAtString = (dateToCompare: number): string => {
    if (dateToCompare === undefined) {
      return '';
    }

    const timeDiffMinutes = (new Date().getTime() - dateToCompare) / 60000;

    if (timeDiffMinutes <= 1) {
      return 'vor weniger als 1 Minute';
    } else if (timeDiffMinutes > 1 && timeDiffMinutes < 2) {
      return 'vor ' + Math.floor(timeDiffMinutes) + ' Minute';
    } else if (timeDiffMinutes >= 2 && timeDiffMinutes < 60) {
      return 'vor ' + Math.floor(timeDiffMinutes) + ' Minuten';
    } else if (timeDiffMinutes >= 60 && timeDiffMinutes < 60 * 2) {
      return 'vor ' + Math.floor(timeDiffMinutes / 60) + ' Stunde';
    } else if (timeDiffMinutes >= 60 * 2 && timeDiffMinutes < 60 * 24) {
      return 'vor ' + Math.floor(timeDiffMinutes / 60) + ' Stunden';
    } else {
      return format(new Date(dateToCompare), 'dd.MM.yyyy, hh:mm') + ' Uhr';
    }
  }
}
