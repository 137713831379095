export interface UiLockable {
  uiLocked: boolean;
}

/**
 * Decorating async methods of UiLockables with this ensures that the 'uiLocked' flag
 * is set at the start and unset at the end of their processing
 */
export function LockUi() {
  return (target: UiLockable, propertyKey: string, descriptor: TypedPropertyDescriptor<(...params: unknown[]) => Promise<any>>) => {
    const baseFunc = descriptor.value;
    descriptor.value = function (this: UiLockable, ...args: unknown[]) {
      this.uiLocked = true;
      return baseFunc.call(this, args).finally(() => this.uiLocked = false);
    };
    return descriptor;
  };
}

