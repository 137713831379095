import { Component, OnInit } from '@angular/core';

import { Language } from '../app-state/settings';
import { AboutService } from './about.service';
import { HilfeFunktionen } from '../shared/global';

@Component({
  selector: 'nfa-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  language = Language.DEUTSCH;

  constructor(private aboutService: AboutService) {
  }

  ngOnInit() {
    HilfeFunktionen.setApplicationTitle('Impressum');
    this.loadAbout();
  }

  loadAbout() {
    const about = document.querySelector('.about');
    this.aboutService.getAbout(this.language).subscribe(aboutJsonArray => {
      about.innerHTML = aboutJsonArray[0].aboutHtml;
    });
  }
}
