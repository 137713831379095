import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { BitvService } from '../../bitv.service';
import { Pruefgegenstand } from '../../types/pruefgegenstand';
import { getEnumKeyByEnumValue, PruefgegenstandTyp } from '../pruefgegenstandTyp.enum';


@Component({
  selector: 'nfa-pruefgegenstand-dialog',
  templateUrl: './pruefgegenstand-dialog.component.html',
  styleUrls: ['./pruefgegenstand-dialog.component.scss']
})
export class PruefgegenstandDialogComponent implements OnInit {

  url = '';
  name = '';
  form: FormGroup<{
    typ: FormControl<string>,
    name: FormControl<string>,
    url: FormControl<string | null>
  }>;
  saving = false;
  localData: any;
  result: Pruefgegenstand[] = [];
  Pruefobjekte: Pruefgegenstand[];
  title: string = 'Prüfgegenstand hinzufügen';
  serverErrorMessage: string = '';
  serverError: boolean = false;
  serverErrorStatus: number;
  hasTypRequiredError: false;
  PruefgegenstandTyp = PruefgegenstandTyp;

  pruefgegenstand: Pruefgegenstand;
  urlRegex = new RegExp(/^https?:\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]\.[a-zA-Z]{2,}[-/a-zA-Z0-9+&@$#/%?=~_|!:,.;]*$/);

  constructor(
    private bitvService: BitvService,
    public dialogRef: MatDialogRef<PruefgegenstandDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Object) {
    this.localData = {...data};
  }

  ngOnInit(): void {
    this.title = this.localData['edit'] ? 'Prüfgegenstand bearbeiten' : 'Prüfgegenstand hinzufügen';

    this.initForm();
  }


  private initForm() {
    this.bitvService.getPruefgegenstandByApplication(this.localData['applicationId']).subscribe(value => {
      const evaluationObjects = value;
      this.Pruefobjekte = evaluationObjects;
    });
    this.pruefgegenstand = {...this.localData.pruefgegenstand};
    this.form = new FormGroup<{typ: FormControl<string>; name: FormControl<string>; url: FormControl<string | null>}>({
      typ: new FormControl<string>({value: this.localData.pruefgegenstand['typ'], disabled: this.localData['edit'] && this.localData['isPruefgegenstandInUse']}, Validators.required),
      name: new FormControl<string>(this.localData.pruefgegenstand['name'], Validators.required),
      url: new FormControl<string|null>(this.localData.pruefgegenstand['url'], [])
    });

    this.onChanges();

    this.form.controls['typ'].valueChanges.subscribe(
      () => this.changeValidators()
    );
    setTimeout(() => {
      this.changeValidators();
    }, 0);
  }

  private onChanges() {
    //remove serverError, when changes in url
    this.form.get('url').valueChanges.subscribe(val => {
      if (this.serverError) {
        this.serverError = false;
      }
    });
  }

  private changeValidators() {

    this.form.controls['url'].clearValidators();
    if (this.form.controls['typ'].value === 'Webseite') {

      this.form.controls['url'].setValidators([Validators.required, Validators.pattern(this.urlRegex), this.duplicateValidator()]);

    } else {

      this.form.controls['url'].setValidators([Validators.pattern(this.urlRegex), this.duplicateValidator()]);

    }
    this.form.updateValueAndValidity();
  }

  onSavePruefgegenstand() {
    if (this.form.valid) {
      this.serverError = false;
      this.saving = true;
      this.pruefgegenstand['name'] = this.form.controls['name'].value;
      this.pruefgegenstand['url'] = this.form.controls['url'].value === '' ? null : this.form.controls['url'].value;
      this.pruefgegenstand['typ'] = this.form.controls['typ'].value;
      this.pruefgegenstand['contentType'] = getEnumKeyByEnumValue(PruefgegenstandTyp, this.form.controls['typ'].value);
      if (this.localData['edit'] === false) {
        this.pruefgegenstand['applicationId'] = this.localData['applicationId'];
        this.bitvService.createPruefgegenstand(this.pruefgegenstand).subscribe({
          next: () => {
            this.saving = false;
            this.dialogRef.close({event: 'Save'});
          },
          error: (error) => {
            this.saving = false;
            this.serverError = true;
            this.serverErrorStatus = error.error?.status;
          }
        });
      }
      if (this.localData['edit'] === true) {
        this.bitvService.updatePruefgegenstand(this.pruefgegenstand).subscribe({
          next: () => {
            this.saving = false;
            this.dialogRef.close({event: 'Update'});
          },
          error: (error) => {
            this.saving = false;
            this.serverError = true;
            this.serverErrorStatus = error.error.status;
          }
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }

  duplicateValidator(){
    return (control: FormControl<string | null>): ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            return null;
        }
        if (this.Pruefobjekte.find(object => object.url === value) !== undefined
            && this.Pruefobjekte.find(object => object.url === value).url !== this.localData.pruefgegenstand.url){

          return {duplicate: true};
        }
        return null;
    };
  }

  get errorMessage(): string {
    const formControl = this.form.controls['url'];
    return formControl.hasError('required') ?
      'Bitte geben Sie eine URL ein, z. B. https://qumap.gbg.msg.team .' :
      formControl.hasError('pattern') ?
      'Bitte geben Sie eine korrekte URL ein, z. B. https://qumap.gbg.msg.team .' :
      formControl.hasError('duplicate') ?
      'Sie können diese URL nicht speichern, da diese bereits existiert.' :
      'Leider ist ein Fehler aufgetreten. Versuchen Sie es erneut.';
  }

  get typFormControl(): FormControl<string> {
    return this.form.controls.typ as FormControl<string>;
  }
}
