import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Evaluationstep, EvaluationstepInfo} from '../../types/evaluationstep';
import {Bitv} from '../../types/bitv';
import {BitvService} from '../../bitv.service';
import {allEvaluationOptions, EvaluationCriteria} from '../balm-evaluation-criteria.enum';
import {BitvScreenshotPasteService} from './balm-screenshot/bitv-screenshot-paste.service';
import {take} from 'rxjs/operators';
import {Treenode} from '../../../shared/sidenav/treenode';
import {LiveAnnouncerService} from '../../../shared/live-announcer-service/live-announcer.service';
import {TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import tinymce, {Editor} from 'tinymce';

@Component({
  selector: 'balm-content',
  templateUrl: './balm-content.component.html',
  styleUrls: ['./balm-content.component.scss'],
  providers: [
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}
  ]
})
export class BalmContentComponent {
  evaluationstepinfo: EvaluationstepInfo;
  screenshotsExpanded = false;

  // tslint:disable-next-line:variable-name
  _bitv: Bitv;
  // tslint:disable-next-line:variable-name
  _allevaluationsteps: Evaluationstep[];
  // tslint:disable-next-line:variable-name
  _selectedevaluationstep: Evaluationstep;
  // tslint:disable-next-line:variable-name
  _categoryName: string;
  showDetails = false;

  @Input() categoriesHeaders: Treenode[] = [];

  @Input() set bitv(value: Bitv) {
    this._bitv = value;
  }

  @Input() set allevaluationsteps(value: Evaluationstep[]) {
    this._allevaluationsteps = value;
  }

  @Input() set selectedevaluationstep(value: Evaluationstep) {
    this._selectedevaluationstep = value;
    this.openInfo(value);
    this.liveAnnouncer.announce(`Prüfschritt ${value.evaluationStep.number} ${value.evaluationStep.name}. Status: ${value.evaluationresult || 'Offen'}`)
  }

  @Input() set categoryName(value: string) {
    this._categoryName = value;
  }

  get selectedevaluationstep() {
    return this._selectedevaluationstep;
  }

  @Output() emitUpdatedProgress = new EventEmitter();

  @Output() nextOrBackClicked = new EventEmitter<Evaluationstep>();

  @Output() selectNextItem = new EventEmitter<Treenode>();

  @Output() stepUpdated = new EventEmitter();

  constructor(
    private bitvService: BitvService,
    private screenshotPasteService: BitvScreenshotPasteService,
    private liveAnnouncer: LiveAnnouncerService) {
  }

  initEditor() {
    return {
      base_url: '/tinymce',
      suffix: '.min',
      skin_url: '/assets/tiny_mce/skins/oxide_msg',
      language_url: '/assets/tiny_mce/langs/de.js',
      language: 'de',
      entity_encoding: 'raw',
      menubar: false,
      promotion: false,
      branding: false,
      statusbar: true,
      resize: true,
      height: 300,
      help_tabs: ['shortcuts', 'keyboardnav', 'versions',],
      plugins: "lists image wordcount fullscreen help",
      toolbar: "fullscreen | bold italic underline | bullist numlist | outdent indent | alignleft aligncenter alignright alignjustify |" +
        "forecolor backcolor | subscript superscript | help",
      toolbar_mode: 'sliding',
      file_picker_callback: this.filePickerCallback,
      setup : (editor: Editor) => {
        editor.on('PostProcess', (ed) => {
          // Replace <br> tag causing problem generating pdf report
          ed.content = ed.content.replace(/<br>/gi,'<br/>');
          // Use german quotation marks
          var bookmark = editor.selection.getBookmark(2, true);
          editor.setContent(ed.content.replace(/(?<=>|\s)"/g, '„').replace(/"(?=<|\s)/g, '“'));
          editor.selection.moveToBookmark(bookmark);
        });
      }
    };
  }

  filePickerCallback(cb, value, meta) {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = function () {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const id = "blobid" + new Date().getTime();
        const blobCache = tinymce.activeEditor.editorUpload.blobCache;
        const base64 = (<string>reader.result).split(",")[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        cb(blobInfo.blobUri());
        console.log(file, "blob", blobInfo.blobUri());
      };
      reader.readAsDataURL(file);
    };
    input.click();
  }

  // todo zu oberen todo bei progress
  evaluationClicked(evaluationOption: EvaluationCriteria) {
    this._selectedevaluationstep.evaluationresult = evaluationOption;
    this.emitUpdatedProgress.emit();
  }

  resetEvaluation() {
    this._selectedevaluationstep.evaluationresult = undefined;
    this.emitUpdatedProgress.emit();
  }

  openInfo(item: Evaluationstep) {
    this.bitvService.getEvaluationStepInfo('de', item.evaluationStep.id).pipe(take(1)).subscribe(data => {
      this.evaluationstepinfo = data;
    });
  }

  nextClick(item: Evaluationstep) {
    if (!this.isLastStep(item)) {
      this.nextOrBackClicked.emit(this._allevaluationsteps[this._allevaluationsteps.map(e => e.id).indexOf(item.id) + 1]);
    } else {
      this.nextOrBackClicked.emit(item);
    }
  }

  nextClickLabel(selectedevaluationstep: Evaluationstep) {
    if (!this.isLastStep(selectedevaluationstep)) {
      return 'Weiter';
    } else {
      return 'Speichern';
    }
  }

  backClick(item: Evaluationstep) {
    this.nextOrBackClicked.emit(this._allevaluationsteps[this._allevaluationsteps.map(e => e.id).indexOf(item.id) - 1]);
  }

  selectItem(item: Treenode) {
    this.selectNextItem.emit(item);
  }

  @HostListener('document:paste', ['$event'])
  paste(event: ClipboardEvent) {
    const file = Array.from(event.clipboardData.files).pop();
    if (file != null && file.type.includes('image')) {
      this.screenshotPasteService.pasteScreenshot(file);
    }
  }

  toggleExpandScreenshotSection() {
    this.screenshotsExpanded = !this.screenshotsExpanded;
  }

  inputChanged(): void {
    this.stepUpdated.emit();
  }

  get allEvaluationOptions() {
    return allEvaluationOptions;
  }

  toggleDetails(): void {
    this.showDetails = !this.showDetails;
  }

  getEnumStringForOption(evalautionOption: EvaluationCriteria) {
    const index = Object.values(EvaluationCriteria).indexOf(evalautionOption);
    return Object.keys(EvaluationCriteria)[index];
  }

  public isLastStep(selectedevaluationstep: Evaluationstep) {
    return selectedevaluationstep.id === this._allevaluationsteps[this._allevaluationsteps.length - 1].id
  }
}
