import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform {

  transform(value: string, search: string): string {
    const valueStr = value + ''; // Ensure numeric values are converted to strings
    if (search === undefined) {
      return valueStr;
    } else {
      return valueStr.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)('
        + search + ')(?![^<>]*>)(?![^&;]+;)', 'gi'), '<b class="highlight-search">$1</b>');
    }
  }
}
