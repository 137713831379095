import { Metric } from './metric';
import { TestType } from 'src/app/test-methods/adapter/testtype';
import { Treenode, TreenodeCategory } from '../../shared/sidenav/treenode';
import { Criterion } from './criterion';
import { FactorWrapper } from './factor-wrapper';

export class CriterionWrapper implements Treenode, Criterion {
  description?: string;
  definition?: string;
  identifier: string;
  testTypes?: TestType[];
  testDesignTechniques?: string[];
  metrics?: Metric[];
  children: Treenode[];
  private expandedState = false;
  authorization?: boolean;

  constructor(criterion: Criterion, public factorParent: FactorWrapper) {
    this.description = criterion.description;
    this.definition = criterion.definition;
    this.identifier = criterion.identifier;
    this.testTypes = criterion.testTypes;
    this.testDesignTechniques = criterion.testDesignTechniques;
    this.metrics = criterion.metrics;
    this.authorization = true;
  }

  get name() {
    return `${this.identifier} ${this.description}`;
  }

  get expanded() {
    return this.expandedState;
  }

  set expanded(expanded: boolean) {
    this.expandedState = expanded;
  }

  get category() {
    return TreenodeCategory.LEAF;
  }

  get parent(): Treenode {
    return this.factorParent;
  }
}
