<ng-container *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
</ng-container>
<div class="scrollable-container">
  <div *ngIf="loaded" class="print-container">
  <button class="print-container__print-button mat-mdc-unelevated-button-primary" mat-flat-button (click)="print()" color="primary">
    <mat-icon>print</mat-icon>
    Drucken
  </button>
  <button class="print-container__back-button mat-mdc-unelevated-button-tertiary" mat-flat-button (click)="back()">
     <mat-icon>arrow_left</mat-icon>
    Zurück
  </button>

  <div class="print-document">
    <section class="title-page">
      <div class="title-page__msg-logo">
        <img src="../../../assets/images/msg-logo.png" alt="msg Logo">
        <span class="title-page__msg-mission-statement">.consulting .solutions .partnership</span>
      </div>
      <img class="title-page__msg-hq" src="../../../assets/images/headquarter.jpg" alt="msg Headquarter">
      <h1>{{documentTitle}}</h1>
      <p>Stand: {{getDate() | date:'dd.MM.yyyy'}}</p>
    </section>

    <section *ngIf="nfrs.length===0 && customNfrs.length===0">
      <p>Aktuell sind keine nicht funktionale Anforderungen ausgewählt.</p>
    </section>
    <table class="print-layout__print-layout-table">
      <thead>
      <tr class="print-layout__print-layout-table__header-row">
        <td>
          <div class="print-layout__header-space">
              <img src="../../../assets/images/msg-logo.png" alt="msg Logo">
              <span class="title-page__msg-mission-statement">.consulting .solutions .partnership</span>
          </div>
        </td>
      </tr>
      </thead>
      <tbody><tr><td>
        <section *ngIf="nfrs.length>0 || customNfrs.length>0" class="toc-page">
          <h2>I Inhaltsverzeichnis</h2>
          <ul class="toc">
            <li class="toc-entry">
              <a href="#nfaID" class="toc-entry__item--print">II NFA</a>
              <span class="toc-entry__item">II NFA</span>
            </li>
            <li class="toc-entry">
              <a href="#testtypes" class="toc-entry__item--print">III Testarten</a>
              <span class="toc-entry__item">III Testarten</span>
            </li>
            <li class="toc-entry">
              <a href="#testdesigntechniques" class="toc-entry__item--print">IV Testentwurfsmethoden</a>
              <span class="toc-entry__item">IV Testentwurfsmethoden</span>
            </li>
            <li class="toc-entry">
              <a href="#impressum" class="toc-entry__item--print">V Impressum</a>
              <span class="toc-entry__item">V Impressum</span>
            </li>
          </ul>
        </section>
        <section *ngIf="nfrs.length>0 || customNfrs.length>0" class="nfas">
          <h2 id="nfaID">II NFA</h2>
          <ng-container *ngFor="let factor of relevantFactors">
            <h3>{{factor.identifier}} {{factor.description}}</h3>
            <p>{{factor.explanation}}</p>
            <ng-container *ngFor="let criterion of relevantCriteriaForFactor.get(factor.identifier)">
              <h4>{{criterion.identifier}} {{criterion.description}}</h4>
              <p>{{criterion.definition}}</p>
              <mat-divider class="print-table__divider"></mat-divider>
              <table class="print-table">
                <tr>
                  <td><strong>Testentwurfsmethoden:</strong></td>
                  <td>{{testDesingTechniquesForPrint(criterion)}}</td>
                </tr>
                <tr>
                  <td><strong>Testmethoden:</strong></td>
                  <td>{{testTestTypesForPrint(criterion)}}</td>
                </tr>
              </table>
              <ng-container *ngFor="let metric of relevantMetricForCriterion.get(criterion.identifier)">
                <h5>{{metric.identifier}} {{metric.label}}</h5>
                <p>{{metric.explanation}}</p>
                <mat-divider class="print-table__divider"></mat-divider>
                <table class="print-table">
                  <tr>
                    <td><strong>Formel:</strong></td>
                    <td>
                      <mathjax [content]="metric.formula" class="box"></mathjax>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Interpretation:</strong></td>
                    <td>{{metric.interpretation}}</td>
                  </tr>
                  <tr>
                    <td><strong>Referenz:</strong></td>
                    <td>{{metric.reference}}</td>
                  </tr>
                </table>
                <ng-container *ngFor="let nfr of nfrsForMetric(metric.identifier)">
                  <div class="print-nfr-box">
                    <h6>{{nfr.nfr.nameGerman}}</h6>
                    <p>{{nfr.formulation}}</p>
                    <ng-container *ngIf="hasAdditionalData(nfr)">
                      <mat-divider class="print-table__divider"></mat-divider>
                      <table class="print-table">
                        <tr *ngIf="nfr.priority !== ''">
                          <td><strong>Priorität:</strong></td>
                          <td>{{nfr.priority}}</td>
                        </tr>
                        <tr *ngIf="nfr.criticality !== ''">
                          <td><strong>Kritikalität:</strong></td>
                          <td>{{nfr.criticality}}</td>
                        </tr>
                        <tr *ngIf="nfr.protectionNeeds !== ''">
                          <td><strong>Schutzbedarf:</strong></td>
                          <td>{{nfr.protectionNeeds}}</td>
                        </tr>
                        <tr *ngIf="nfr.basedOn !== ''">
                          <td><strong>Basiert auf:</strong></td>
                          <td>{{nfr.basedOn}}</td>
                        </tr>
                        <tr *ngIf="nfr.version !== ''">
                          <td><strong>Version:</strong></td>
                          <td>{{nfr.version}}</td>
                        </tr>
                        <tr *ngIf="nfr.annotation!==''">
                          <td><strong>Annotation:</strong></td>
                          <td>{{nfr.annotation}}</td>
                        </tr>
                      </table>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngFor="let customNfr of getCustomNfrs(application, metric.identifier)">
                  <div class="print-nfr-box">
                    <h6>{{customNfr.name}}</h6>
                    <p>{{customNfr.formulation}}</p>
                    <ng-container *ngIf="hasAdditionalData(customNfr)">
                      <mat-divider class="print-table__divider"></mat-divider>
                      <table class="print-table">
                        <tr *ngIf="customNfr.priority !== ''">
                          <td><strong>Priorität:</strong></td>
                          <td>{{customNfr.priority}}</td>
                        </tr>
                        <tr *ngIf="customNfr.criticality !== ''">
                          <td><strong>Kritikalität:</strong></td>
                          <td>{{customNfr.criticality}}</td>
                        </tr>
                        <tr *ngIf="customNfr.protectionNeeds !== ''">
                          <td><strong>Schutzbedarf:</strong></td>
                          <td>{{customNfr.protectionNeeds}}</td>
                        </tr>
                        <tr *ngIf="customNfr.basedOn !== ''">
                          <td><strong>Basiert auf:</strong></td>
                          <td>{{customNfr.basedOn}}</td>
                        </tr>
                        <tr *ngIf="customNfr.version !== ''">
                          <td><strong>Version:</strong></td>
                          <td>{{customNfr.version}}</td>
                        </tr>
                        <tr *ngIf="customNfr.annotation!==''">
                          <td><strong>Annotation:</strong></td>
                          <td>{{customNfr.annotation}}</td>
                        </tr>
                      </table>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </section>
        <section *ngIf="nfrs.length>0 || customNfrs.length>0" class="testtypes">
          <h2 id="testtypes">III Testarten</h2>
          <ng-container *ngFor="let testtype of relevantTesttypes">
            <h3>{{testtype.labelKey}}</h3>
            <h4>Definition</h4>
            <div class="testMethodContentDescription" [innerHTML]="testtype.definitionKey"></div>
            <mat-divider class="print-table__divider"></mat-divider>
            <table class="print-table">
              <tr>
                <td><strong>Kriterien:</strong></td>
                <td>{{getCriteriaAsList(testtype.criteria)}}</td>
              </tr>
            </table>
          </ng-container>
        </section>
        <section *ngIf="nfrs.length>0 || customNfrs.length>0" class="testdesignmethod">
          <h2 id="testdesigntechniques">IV Testentwurfsmethoden</h2>
          <ng-container *ngFor="let testDesignTechnique of relevantTestDesignTechniques">
            <h3>{{testDesignTechnique.labelKey}}</h3>
            <h4>Definition</h4>
            <div class="testMethodContentDescription" [innerHTML]="testDesignTechnique.definitionKey"></div>
            <mat-divider class="print-table__divider"></mat-divider>
            <table class="print-table">
              <tr>
                <td><strong>Testabdeckungsmessung:</strong></td>
                <td>
                  <mathjax [content]="testDesignTechnique.testCoverageMeasurementKey" class="box"></mathjax>
                </td>
              </tr>
              <tr>
                <td><strong>Kriterien:</strong></td>
                <td>{{getCriteriaAsList(testDesignTechnique.criteria)}}</td>
              </tr>
            </table>
          </ng-container>
        </section>

        <section *ngIf="(nfrs.length>0 || customNfrs.length>0) && !loadingAbout" class="impressum">
      <h2 id="impressum">V Impressum</h2>
      <div class="about" [innerHTML]="about">
      </div>
    </section>
      </td></tr></tbody>
      <tfoot>
      <tr>
        <td>
          <div class="print-layout__footer-space"></div>
          <div class="print-layout__footer">
            <div>NFA für {{application.customerName}} - {{application.name}}</div>
            <div>Stand: {{getDate() | date:'dd.MM.yyyy'}}</div>
          </div>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</div>
</div>
