import {LiveAnnouncer} from "@angular/cdk/a11y";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class LiveAnnouncerService {
  private active = false;

  private elements: Record<number, string> = {};
  private head: number = 0;
  private tail: number = 0;

  constructor(private announcer: LiveAnnouncer) {
  }

  announce(message: string, highPriority = false): void {
    if (!highPriority) {
      this.enqueue(message);
    } else {
      this.enqueueInFirst(message);
    }
    if (!this.active) {
      this.active = true;
      setTimeout(() => {
        this.announceFromQueue();
      }, 300);
    }
  }

  private announceFromQueue() {
    const message = this.dequeue();
    this.announcer.announce(message, "polite", 200);

    setTimeout(() => {
      if (!this.isEmpty) {
        this.announceFromQueue();
      } else {
        this.active = false;
      }
    }, 300);
  }

  private enqueue(element: any): void {
    this.elements[this.tail] = element;
    this.tail++;
  }

  private enqueueInFirst(element: any): void {
    this.elements[--this.head] = element;
  }

  private dequeue(): any {
    const item = this.elements[this.head];
    delete this.elements[this.head];
    this.head++;

    return item;
  }

  private get length(): number {
    return this.tail - this.head;
  }

  private get isEmpty(): boolean {
    return this.length === 0;
  }

}
