<nfa-mat-spinner-overlay *ngIf="loading"></nfa-mat-spinner-overlay>

<nfa-mat-spinner-overlay
  *ngIf="pdfLoading"
  loadingText="PDF wird geladen ..."
  overlay="true"
></nfa-mat-spinner-overlay>


<ng-container *ngIf="!loading">

  <div class="page-header">
    <div class="page-header__heading">
      <h1
        data-cy="balmOverviewHeader"
        id="testTitle"
        *ngIf="!!bitv"
        matTooltip="{{evaluationTypeLabel(bitv?.evaluationType)}} {{bitv?.createdAt | date:'dd.MM.yyyy'}} {{application ? 'für' : ''}} {{application?.name}}"
      >
        {{evaluationTypeLabel(bitv?.evaluationType)}}
      </h1>
    </div>
    <div class="page-header__actions">
      <button
        mat-flat-button
        data-cy="back-to-overview-button"
        class="mat-mdc-unelevated-button-tertiary desktop-button"
        (click)="back()"
      >
        <mat-icon>arrow_left</mat-icon>
        Zurück
      </button>
      <button mat-flat-button
              class="mat-mdc-unelevated-button-primary finish-button desktop-button"
              data-cy="finish-button"
              color="primary"
              *ngIf="canUserEdit"
              [disabled]="!(allOpenCount === 0) || evaluationCompleted(this.bitv)"
              (click)="finish()">
        <mat-icon>lock</mat-icon>
        Abschließen
      </button>
      <button mat-flat-button
              class="mat-mdc-unelevated-button-primary desktop-button"
              data-cy="evaluation"
              *ngIf="canUserEdit"
              [disabled]="evaluationCompleted(this.bitv)"
              (click)="openBitvEvaluation()">
        <mat-icon>accessible_forward</mat-icon>
        Barrierefreiheitstest fortsetzen
      </button>
    </div>
  </div>

  <div class="dashboard-container">
    <div class="page-header__breadcrumbs mobile-back">
      <button
        mat-flat-button
        data-cy="back-to-overview-button-mobile"
        class="mat-mdc-unelevated-button-tertiary mobile-button-back"
        (click)="back()"
      >
        <mat-icon>arrow_left</mat-icon>
        Zurück
      </button>
    </div>
    <div class="mobile_header">
      <div class="page-header__heading">
        <h1
          data-cy="balmOverviewHeaderMobile"
          id="testTitle"
          *ngIf="!!bitv"
          matTooltip="{{evaluationTypeLabel(bitv?.evaluationType)}} {{bitv?.createdAt | date:'dd.MM.yyyy'}} {{application ? 'für' : ''}} {{application?.name}}"
        >
          {{evaluationTypeLabel(bitv?.evaluationType)}}
        </h1>
      </div>
    </div>
    <div class="dashboard-row">
      <div class="dashboard-left-column">
        <div class="button-container">
          <button mat-flat-button
                  class="mat-mdc-unelevated-button-primary mobile-button"
                  data-cy="evaluation-mobile"
                  *ngIf="canUserEdit"
                  [disabled]="evaluationCompleted(this.bitv)"
                  (click)="openBitvEvaluation()">
            <mat-icon>accessible_forward</mat-icon>
            Barrierefreiheitstest fortsetzen
          </button>
          <button
            mat-flat-button
            class="mat-mdc-unelevated-button-secondary left-aligned-button configure-button"
            data-cy="configure"
            *ngIf="canUserEdit"
            [disabled]="evaluationCompleted(this.bitv)"
            (click)="openBitvConfiguration()"
          >
            <mat-icon>edit</mat-icon>
            Konfiguration bearbeiten
          </button>
          <button
            mat-flat-button
            class="mat-mdc-unelevated-button-secondary left-aligned-button open-pdf-button"
            data-cy="open-pdf"
            (click)="openBitvPdf('Pruefbericht')"
          >
            <mat-icon>picture_as_pdf</mat-icon>
            Prüfbericht öffnen
          </button>
          <button
            mat-flat-button
            class="mat-mdc-unelevated-button-secondary left-aligned-button open-pdf-button"
            data-cy="link-pdf-fb"
            (click)="openBitvPdf('Fehlerbericht')"
          >
            <mat-icon>picture_as_pdf</mat-icon>
            Fehlerbericht öffnen
          </button>
          <button mat-flat-button
                  class="mat-mdc-unelevated-button-primary finish-button mobile-button"
                  data-cy="finish-button-mobile"
                  color="primary"
                  *ngIf="canUserEdit"
                  [disabled]="!(allOpenCount === 0) || evaluationCompleted(this.bitv)"
                  (click)="finish()">
            <mat-icon>lock</mat-icon>
            Abschließen
          </button>
        </div>
        <mat-card class="dashboard-object-type-card"
                  [ngClass]="canUserEdit ? 'dashboard-object-type-card-height' : 'dashboard-object-type-card-height-read-only'">
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon>{{objectTypeIcon(this.bitv.objectType)}}</mat-icon>
            </div>
            <mat-card-title>
              <h3 data-cy="objectTypeLabel">{{objectTypeLabel(this.bitv.objectType)}}</h3>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
              {{objectTypeDescription(this.bitv.objectType)}}
            </p>
            <mat-card-title>
              <h3 data-cy="evaluationTypeLabel">{{evaluationTypeLabel(this.bitv.evaluationType)}}</h3>
            </mat-card-title>
            <ul *ngFor="let line of evaluationTypeDescriptionLines(this.bitv.evaluationType)">
              <li><span>{{line}}</span></li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>

      <mat-card class="dashboard-chart-card">
        <h1 style="margin-left: 20px !important;">Prüffortschritt</h1>
        <div class="dashboard-chart-card-body">

          <div class="progress-chart">
            <svg viewBox="0 0 200 200">
              <circle cx="100" cy="100" r="70" fill="none" stroke="#D8D8D8" stroke-width="30"/>
              <circle cx="100" cy="100" r="70" fill="none" stroke="#595959" stroke-width="30"
                      transform="rotate(-90, 100, 100)"
                      [attr.stroke-dasharray]="calculateArcAngle(allConformCount + allNotConformCount + allNotApplicableCount) + ', 440'"/>
              <circle cx="100" cy="100" r="70" fill="none" stroke="#FF0000" stroke-width="30"
                      transform="rotate(-90, 100, 100)"
                      [attr.stroke-dasharray]="calculateArcAngle(allConformCount + allNotConformCount) + ', 440'"/>
              <circle cx="100" cy="100" r="70" fill="none" stroke="#008000" stroke-width="30"
                      transform="rotate(-90, 100, 100)"
                      [attr.stroke-dasharray]="calculateArcAngle(allConformCount) + ', 440'"/>
            </svg>
            <div class="progress-legend-percentage">
              {{ allEvaluationstepsCount ? (((allEvaluationstepsCount - allOpenCount) / allEvaluationstepsCount) * 100).toFixed(0) : 0 }}&nbsp;%<br/>bearbeitet
            </div>
            <div class="progress-legend-percentage-mobile-view">
              {{ allEvaluationstepsCount ? (((allEvaluationstepsCount - allOpenCount) / allEvaluationstepsCount) * 100).toFixed(0) : 0 }}&nbsp;%
            </div>
          </div>

          <div class="progress-legend">
            <div class="progress-legend-title">{{allEvaluationstepsCount - allOpenCount}}
              von {{allEvaluationstepsCount}} Prüfschritten bearbeitet
            </div>
            <div
              class="progress-legend-entry"
              data-cy="fulfilledSteps"
            >
              <div class="progress-legend-conform-icon"></div>
              {{allConformCount}} / {{allEvaluationstepsCount}} Erfüllt
            </div>
            <div
              class="progress-legend-entry"
              data-cy="notConformSteps"
            >
              <div class="progress-legend-not-conform-icon" data-cy="not-conform-count"></div>
              {{allNotConformCount}} / {{allEvaluationstepsCount}} Nicht erfüllt
            </div>
            <div
              class="progress-legend-entry"
              data-cy="notApplicableSteps"
            >
              <div class="progress-legend-not-applicable-icon" data-cy="not-applicable-count"></div>
              {{allNotApplicableCount}} / {{allEvaluationstepsCount}} Nicht anwendbar
            </div>
            <div
              class="progress-legend-entry"
              data-cy="openSteps"
            >
              <div class="progress-legend-open-icon" data-cy="open-count"></div>
              {{allOpenCount}} / {{allEvaluationstepsCount}} Offen
            </div>
            <div class="progress-legend-result">
              <span data-cy="result-type">{{evaluationCompleted(bitv) ? 'Ergebnis:' : 'Aktuelles Ergebnis:'}}</span>
              <span  style="font-family: Akkurat regular, sans-serif;" data-cy="result-value">{{getEvaluationResult()}}</span>
              <mat-icon
                [ngClass]="compliant?'conformity-indicator':'non-conformity-indicator'">{{getResultIcon()}}</mat-icon>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card class="dashboard-object-type-card-mobile-view"
                [ngClass]="canUserEdit ? 'dashboard-object-type-card-height' : 'dashboard-object-type-card-height-read-only'">
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon>{{objectTypeIcon(this.bitv.objectType)}}</mat-icon>
          </div>
          <mat-card-title>
            <h3 data-cy="objectTypeLabel">{{objectTypeLabel(this.bitv.objectType)}}</h3>
          </mat-card-title>

        </mat-card-header>
        <mat-card-content>
          <p>
            {{objectTypeDescription(this.bitv.objectType)}}
          </p>
          <mat-card-title>
            <h3 data-cy="evaluationTypeLabel">{{evaluationTypeLabel(this.bitv.evaluationType)}}</h3>
          </mat-card-title>
          <ul *ngFor="let line of evaluationTypeDescriptionLines(this.bitv.evaluationType)">
            <li><span>{{line}}</span></li>
          </ul>
        </mat-card-content>
      </mat-card>

      <mat-card class="dashboard-chart-card">
        <nfa-balm-test-tools-overview
          [bitv]="bitv"
          [canEdit]="canUserEdit"
        ></nfa-balm-test-tools-overview>
      </mat-card>

    </div>
    <div class="dashboard-row">
      <nfa-balm-testobjects-overview
        [bitv]="bitv"
        [canEdit]="canUserEdit"
        (testsCompleted)="ngOnInit()"
      ></nfa-balm-testobjects-overview>
    </div>
  </div>
</ng-container>

<ng-template #noContent>Es können keine Daten geladen werden!</ng-template>
