import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { MaterialModule } from '../../material/material.module';
import { InformationHeaderComponent } from './information-header.component';
import { RouterModule } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
  declarations: [
    InformationHeaderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    SharedModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatButtonToggleModule,
  ],
  exports: [
    InformationHeaderComponent,
  ],
})
export class InformationHeaderModule {
}
