import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AccessibilityTest, Bitv, BitvType } from './types/bitv';
import { Evaluationstep, EvaluationstepInfo } from './types/evaluationstep';
import { catchError } from 'rxjs/operators';
import { Treenode } from '../shared/sidenav/treenode';
import { BalmTestConfigurationOptions } from './types/configuration-options';
import { Pruefgegenstand } from './types/pruefgegenstand';
import { TestToolCategory, TestTools } from './types/test-tools';
import { Language } from '../app-state/settings';

@Injectable({
  providedIn: 'root'
})
export class BitvService {

  private readonly apiUrl = '/nfabackend/webapi';

  constructor(private http: HttpClient) {
  }

  getBitvById(bitvid: string, language: string): Observable<Bitv> {
    return this.http.get<Bitv>(`${this.apiUrl}/${language}/bitv/${bitvid}`).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  getBitvsByApplication(applicationid: string | number, language: string): Observable<Bitv[]> {
    return this.http.get<Bitv[]>(`${this.apiUrl}/${language}/bitv`, {params: {applicationId: applicationid}})
      .pipe(
        catchError((error) => throwError(() => error))
      );
  }

  createNewBitv(bitv: AccessibilityTest): Observable<AccessibilityTest> {
    return this.http.post<AccessibilityTest>(`${this.apiUrl}/de/bitv`, bitv).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  updateBitvConfiguration(language: string, bitv: Bitv): Observable<Bitv> {
    return this.http.put<Bitv>(`${this.apiUrl}/${language}/bitv/${bitv.id}/configuration`, bitv).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  updateStatusBitv(language: string, bitv: Bitv): Observable<Bitv> {
    return this.http.put<Bitv>(`${this.apiUrl}/${language}/bitv/${bitv.id}/status`, bitv).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  getBitvPdf(language: string, bitvId: string | number, pdfReport: string): Observable<ArrayBuffer> {
    const apiLink = `${this.apiUrl}/pdf/${language}/${bitvId}/${pdfReport}/balm/`;

    return this.http.get(apiLink, {responseType: 'arraybuffer'}).pipe(
      //return this.http.get(`${this.apiUrl}/pdf/balm`, { responseType: 'arraybuffer' }).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  getBitvCategories(language: string): Observable<Treenode[]> {
    return this.http.get<Treenode[]>(`${this.apiUrl}/${language}/bitvcategories`).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  getEvaluationStepInfo(language: string, evaluationstepId: number): Observable<EvaluationstepInfo> {
    return this.http.get<EvaluationstepInfo>(`${this.apiUrl}/${language}/evaluationstep/${evaluationstepId}/info`).pipe(
      catchError((error) => {
        if (error.status === 404) {
          return of({id: evaluationstepId, why: null, how: null, what: null} as EvaluationstepInfo);
        }
        return throwError(() => error);
      })
    );
  }

  getConfigurationOptions(language: string): Observable<BalmTestConfigurationOptions> {
    return this.http.get<BalmTestConfigurationOptions>(`${this.apiUrl}/${language}/bitv/configoptions`).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  saveEvaluationStep(language: string, evaluationstep: Evaluationstep): Observable<Evaluationstep> {
    return this.http.put<Evaluationstep>(`${this.apiUrl}/${language}/bitvEvaluationstep`, evaluationstep).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  getPruefgegenstand(id: number): Observable<Pruefgegenstand> {
    return this.http.get<Pruefgegenstand>(`${this.apiUrl}/balm/testobjects/${id}`).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  getPruefgegenstandByApplication(applicationId: string | number): Observable<Pruefgegenstand[]> {
    return this.http.get<Pruefgegenstand[]>(`${this.apiUrl}/balm/testobjects?applicationId=${applicationId}`).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  createPruefgegenstand(pruefgegenstand: Pruefgegenstand): Observable<Pruefgegenstand> {
    return this.http.post<Pruefgegenstand>(`${this.apiUrl}/balm/testobjects`, pruefgegenstand).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  updatePruefgegenstand(pruefgegenstand: Pruefgegenstand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/balm/testobjects/${pruefgegenstand.id}`, pruefgegenstand).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  deletePruefgegenstand(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/balm/testobjects/${id}`).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  getTestToolsForBalm(balmId: number): Observable<TestTools> {
    return this.http.get<Partial<TestTools>>(`${this.apiUrl}/balm/${balmId}/test-tools`).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  getRequiredTestToolSectionsForTestObjectType(type: BitvType): Observable<TestToolCategory[]> {
    return this.http.get<TestToolCategory[]>(`${this.apiUrl}/balm/test-tools/required-categories/${type}`).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  updateTestToolsForBalm(balmId: number, testTools: TestTools): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/balm/${balmId}/test-tools`, testTools).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  triggerAutomatedTests(language: Language, balmId: number): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${language}/bitv/${balmId}/automated-tests`, null)
      .pipe(catchError((error) => throwError(() => error)));
  }
}
