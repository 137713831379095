import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BitvService } from '../bitv.service';
import { Bitv, BitvType } from '../types/bitv';
import { Evaluationstep, Screenshot } from '../types/evaluationstep';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { ApplicationsService } from '../../applications/applications.service';
import { ApplicationReceived } from '../../applications/model/applicationReceived';
import { BitvScreenshotService } from '../balm-evaluation/balm-content/balm-screenshot/bitv-add-screenshot/bitv-screenshot.service';
import { AboutService } from 'src/app/about/about.service';
import { EvaluationCriteria } from '../balm-evaluation/balm-evaluation-criteria.enum';
import { HilfeFunktionen } from '../../shared/global';
import { Treenode } from '../../shared/sidenav/treenode';
import { BalmStepsHelper } from '../balm-steps-helper/balm-steps-helper';
import { switchMap, takeUntil } from 'rxjs/operators';
import { TestObject } from '../types/pruefgegenstand';

@Component({
  selector: 'nfa-bitv-print-preview',
  templateUrl: './bitv-print-preview.component.html',
  styleUrls: ['./bitv-print-preview.component.scss']
})
export class BitvPrintPreviewComponent implements OnInit, OnDestroy {

  private readonly LANGUAGE = 'de';

  bitv: Bitv;
  application: ApplicationReceived;
  loading = true;
  documentHeaderInformation: string;
  testobjects: any [];
  categoryMapByObjectId: Map<string, Treenode[]>;

  loadingAbout = true;
  about: string;
  public screenshots: Observable<Screenshot []>;
  public screeshotsMap: Set<string | number> = new Set<string>();
  private onDestroy = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private bitvService: BitvService,
              private applicationsService: ApplicationsService,
              private screenshotService: BitvScreenshotService,
              private router: Router,
              private aboutService: AboutService) {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  ngOnInit(): void {

    HilfeFunktionen.setApplicationTitle('BALM - Barrierefreiheitstest - PDF-Preview');

    const bitvId = this.route.snapshot.paramMap.get('id');
    const applicationid = this.route.snapshot.paramMap.get('applicationid');
    this.handleWriteAndReadAccess(applicationid, bitvId);
    this.aboutService.getAbout(this.LANGUAGE).pipe(takeUntil(this.onDestroy)).subscribe(aboutJsonArray => {
      this.loadingAbout = false;
      this.about = this.transformImpressum(aboutJsonArray[0].aboutHtml);
    });
  }

  handleWriteAndReadAccess(applicationid, bitvId) {
    forkJoin([
      this.bitvService.getBitvById(bitvId, this.LANGUAGE),
      this.bitvService.getBitvCategories(this.LANGUAGE),
      this.applicationsService.getApplicationById(applicationid, this.LANGUAGE),
      this.bitvService.getPruefgegenstandByApplication(applicationid),
      this.screenshotService.getScreenshotsForBalmTest(bitvId)
    ]).pipe(takeUntil(this.onDestroy)).subscribe({
      next: ([bitvData, categories, applicationData, testObjectData, screenshots]) => {
        this.application = applicationData;
        this.screeshotsMap = new Set(screenshots.map(screenshot => screenshot.evaluationStepId));
        this.screenshots = of(screenshots).pipe(
          switchMap((metadatas: Screenshot[]) => {
            return forkJoin(metadatas.map(
              screenshotData => this.screenshotService.loadScreenshotImage(screenshotData)
            ));
          }));
        this.bitv = bitvData;
        if (this.bitv.applicationId !== this.application.id) {
          this.router.navigateByUrl('/empty');
        }
        this.documentHeaderInformation = this.generateHeader(this.bitv);
        const result = this.bitv.testObjects.map(obj => {
          return ({
            ...obj,
            ...this.bitv.bitvEvaluationStepsByTestobject.find(b => b.testobjectid === obj.testobjectId)
          });
        });
        this.testobjects = result.map(obj => (
          {...obj, ...testObjectData.find(t => t.id === obj.testobjectId)}
        ));

        this.categoryMapByObjectId = new Map<string, Treenode[]>(this.testobjects.map(testObject => [
          testObject.testobjectId,
          BalmStepsHelper.assignEvaluationStepsToCategories(testObject.bitvEvaluationSteps, categories)
        ]));

        this.loading = false;
      },
      error: err => {
        if (err.status === 403) {
          this.router.navigateByUrl('/no-access-error-page');
        } else if (err.status === 404) {
          this.router.navigateByUrl('/empty');
        }
      }
    });
  }

  notApplicableSteps(testobject: any) {
    return testobject.bitvEvaluationSteps.filter(x => x.evaluationresult === EvaluationCriteria.notApplicable).length;
  }

  fulfilledSteps(testobject: any) {
    return testobject.bitvEvaluationSteps.filter(x => x.evaluationresult === EvaluationCriteria.fulfilled).length;
  }

  notFulfilledSteps(testobject: any) {
    return testobject.bitvEvaluationSteps.filter(x => x.evaluationresult === EvaluationCriteria.no).length;
  }

  notTestedSteps(testobject: any) {
    return testobject.bitvEvaluationSteps.filter(x => x.evaluationresult === null).length;
  }

  getSummary(testobject: any) {
    const notTested = this.notTestedSteps(testobject);
    const notFulfilled = this.notFulfilledSteps(testobject);
    const fulfilled = this.fulfilledSteps(testobject);
    const notApplicable = this.notApplicableSteps(testobject);
    const allTests = testobject.bitvEvaluationSteps.length;

    if (notApplicable === 0 && notFulfilled === 0 && fulfilled === 0 && notTested === allTests) {
      return 'Nicht getestet';
    }
    if (notTested >= 1 && notTested < allTests) {
      return 'Nicht vollständig getestet';
    }
    if (notApplicable >= 1 && notFulfilled === 0 && fulfilled === 0 && notTested === 0) {
      return 'Nicht anwendbar';
    }
    if (notFulfilled === 0 && notTested === 0 && fulfilled >= 1) {
      return 'Erfüllt';
    }
    if (notTested === 0 && notFulfilled >= 1) {
      return 'Nicht erfüllt';
    }
  }

  private transformImpressum(aboutHtml: string) {
    return aboutHtml.replace(/h2/gi, 'h3').replace('<h1>Impressum</h1>', '');
  }

  print() {
    window.print();
  }

  getDate() {
    return new Date();
  }

  generateHeader(bitv: Bitv) {
    let header: string;

    switch (bitv.objectType) {
      case BitvType.WEB:
        switch (bitv.evaluationType) {
          case 'EINGEHENDE_PRUEFUNG':
            header = 'eingehenden Webprüfung der Anwendung';
            break;
          case 'VOLLSTAENDIGE_VEREINFACHTE_PRUEFUNG':
            header = 'vollständigen vereinfachten Webprüfung der Anwendung';
            break;
          case 'VEREINFACHTE_PRUEFUNG':
            header = 'vereinfachten Webprüfung der Anwendung';
            break;
          default:
            header = 'Prüfung der Webanwendung';
            break;
        }
        break;
      case BitvType.DOCUMENT:
        header = 'eingehenden Dokumentenprüfung der Anwendung';
        break;
      case BitvType.SOFTWARE:
        header = 'eingehenden Softwareprüfung der Anwendung';
        break;
      case BitvType.MOBILE_APP:
        header = 'eingehenden Prüfung der mobilen Anwendung';
        break;
      default:
        header = 'Prüfung der Anwendung';
        break;
    }
    return header;
  }

  getEvaluationStepObjectFromTreenode(evaluationStep: Treenode): Evaluationstep {
    return evaluationStep.object as Evaluationstep;
  }

  hasEvaluationStepScreenshots(evaluationStep: Treenode): boolean {
    return this.screeshotsMap.has(evaluationStep.object.id);
  }

  loadScreenshotsForTestObject(screenshots: Screenshot[], testObject: TestObject): Observable<Screenshot[]> {
    const evaluationStepIds: string [] = testObject.evaluationSteps.map(step => `${step.id}`);
    return of(screenshots.filter(screenshot => evaluationStepIds.includes(`${screenshot.evaluationStepId}`)))
      .pipe(switchMap(
        (metadatas: Screenshot[]) => {
          return forkJoin(
            metadatas.map(screenshotData =>
              this.screenshotService.loadScreenshotImage(screenshotData)
            ));
        }));
  }

  getScreenshotsForEvaluationStep(evaluationStep: Treenode, screenshots: Screenshot[]): Screenshot[] {
    return screenshots.length > 0 ?
      screenshots.filter(screenshot => screenshot.evaluationStepId === evaluationStep.object.id) :
      [];
  }
}
