<ng-container nfaDisableCopyPaste *ngIf="factor !== null">
  <button mat-button class="header-style" (click)="toggleFactor()">
    <label class="header-style">{{factor.identifier}} {{factor.description}}</label>
    <mat-icon id="this-is-us" inline class="chavron-icon"
      [@indicatorRotate]="hideFactor ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </button>
  <div class="content-info" nfaDisableCopyPaste [hidden]="hideFactor">
    <div class="catalogBox" nfaDisableCopyPaste>
      <span class="catalogBoxLeftFirst">Definition: </span>
      <span class="catalogBoxRightFirst">{{factor.explanation}}</span>
      <span class="catalogBoxLeftSecond">Dokumente: </span>
      <span class="catalogBoxRightSecond">
        <span *ngFor="let document of factor.documents; let i = index">{{document.description}}<span
            *ngIf="i < factor.documents.length - 1">, </span></span>
      </span>
    </div>
  </div>
  <br>
  <button mat-button class="header-style" (click)="toggleCriterion()">
    <label class="header-style" nfaDisableCopyPaste> {{criterion.identifier}} {{criterion.description}}</label>
    <mat-icon inline class="chavron-icon"
      [@indicatorRotate]="hideCriterion ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </button>
  <div class="content-info" nfaDisableCopyPaste [hidden]="hideCriterion">
    <div class="catalogBox" nfaDisableCopyPaste>
      <span class="catalogBoxLeftFirst">Definition: </span>
      <span class="catalogBoxRightFirst">{{criterion.definition}}</span>
      <span class="catalogBoxLeftSecond">Testarten</span>
      <div *ngIf="criterion.testTypes">
        <div class="catalogBoxRightSecond">
          <li *ngFor="let testType of criterion.testTypes">
            <a [routerLink]="['/testmethods']"
              [queryParams]="{typeOfTestMethodIdentifier: 'testTypes', testTypeIdentifier: testType.identifier}">
              {{testType.labelKey}}
            </a>
          </li>
        </div>
      </div>
      <span class=" catalogBoxLeftThird">Testentwurfsmethoden</span>
      <div *ngIf="criterion.testDesignTechniques">
        <div class="catalogBoxRightThird" nfaDisableCopyPaste>
          <li *ngFor="let testDesignTechnique of criterion.testDesignTechniques">
            <a [routerLink]="['/testmethods']"
              [queryParams]="{typeOfTestMethodIdentifier: 'testDesignTechniques', testDesignTechniqueIdentifier: testDesignTechnique.identifier}">
              {{testDesignTechnique.labelKey}}
            </a>
          </li>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <div class="header-style2">
    <span>Metriken:</span>
  </div>
  <div class="metricButtons">
    <mat-button-toggle-group class="metricButtonGroup" multiple=false>
      <mat-button-toggle class="metricButton" *ngFor="let metric of metrics" mat-button (click)="selectMetric(metric)"
        [ngClass]="containsRecommendations(metric) ? 'metricButton--highlighted': '' " id="metric{{metric.label}}">
        {{metric.label}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <button mat-button class="header-style" (click)="toggleMetric()">
    <label class="header-style">{{selectedMetric.label}}</label>
    <mat-icon inline class="chavron-icon"
      [@indicatorRotate]="hideMetric ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </button>
  <div class="content-info" nfaDisableCopyPaste [hidden]="hideMetric">
    <div class="catalogBox" nfaDisableCopyPaste>
      <span class="catalogBoxLeftFirst">Definition: </span>
      <span class="catalogBoxRightFirst">{{selectedMetric.explanation}}</span>
      <span class="catalogBoxLeftSecond">Formel: </span>
      <mathjax [content]="selectedMetric.formula" class="box"></mathjax>
      <span class="catalogBoxLeftThird">Interpretation: </span>
      <span class="catalogBoxRightThird"><mathjax [content]="selectedMetric.interpretation"></mathjax></span>
      <span class="catalogBoxLeftFourth">Nutzungsempfehlung: </span>
      <div *ngIf="hasCriterionUnitTestRecommendation(criterion)">
        <span class="catalogBoxRightFourth">{{selectedMetric.unitTestRecommendation}}</span>
      </div>
      <span class="catalogBoxLeftFifth">Referenz: </span>
      <span class="catalogBoxRightFifth">{{selectedMetric.reference}}</span>
    </div>
  </div>
  <br>
  <br>
  <div class="header-style2">
    <span>Nicht-funktionale Anforderungen:</span>
  </div>
</ng-container>
