import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

import {About} from './types';

@Injectable({providedIn: 'root'})
export class AboutService {

  private apiUrl = '/nfabackend/webapi';

  constructor(private http: HttpClient) {
  }

  getAbout(language: string): Observable<About[]> {
    return this.http.get<About[]>(`${this.apiUrl}/about`, {params: {language}}).pipe(
      catchError(() => throwError(()=> new Error('Not able to get about')))
    )
  }
}
