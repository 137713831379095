<div class="balm-content-host">
  <h2 class="h2-desktop"
      data-cy="stepHeadline">{{_selectedevaluationstep.evaluationStep.number}} {{_selectedevaluationstep.evaluationStep.name}}</h2>

  <div class="balm-evaluation-content-container">
    <div id="evaluation-step" class="mat-elevation-z0 evaluation-step scrollable-input">
      <div>
        <div class="balm-content-section">
          <div class="mobile">
              <mat-form-field class="centration-selected-step" appearance="outline">
                <mat-select [ngModel]="_selectedevaluationstep.evaluationStep.name">
                  <mat-optgroup *ngFor="let categories of categoriesHeaders" [label]="categories.name">
                    <mat-option *ngFor="let evaluationstep of categories.children"
                                [value]="evaluationstep.name"
                                (click)="selectItem(evaluationstep)">{{ evaluationstep.number }} {{ evaluationstep.name }}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
          </div>
          <h3 id="evaluation-header-label" class="balm-evaluation-header">Bewertung</h3>

          <div class="mobile toggleGroupMobile">
              <mat-button-toggle
                class="mobileToggle"
                data-cy="open-radiobutton-mobile"
                (click)="resetEvaluation(); inputChanged();"
                [checked]="_selectedevaluationstep.evaluationresult == undefined">
                <mat-icon *ngIf="_selectedevaluationstep.evaluationresult == undefined">check</mat-icon>
                <span class="underline-button-text"> Offen </span>
              </mat-button-toggle>

              <mat-button-toggle
                *ngFor="let evalautionOption of allEvaluationOptions"
                class="mobileToggle"
                [attr.data-cy]="getEnumStringForOption(evalautionOption)+'-radiobutton-mobile'"
                [value]="evalautionOption"
                [checked]="_selectedevaluationstep.evaluationresult == evalautionOption"
                (click)="evaluationClicked(evalautionOption); inputChanged();">
                <mat-icon *ngIf="_selectedevaluationstep.evaluationresult == evalautionOption">check</mat-icon>
                <span class="underline-button-text">{{evalautionOption}}</span>
              </mat-button-toggle>
          </div>

          <div class="toggleGroup">
            <mat-button-toggle-group
              class="evaluation-toggle-group"
              aria-labelledby="evaluation-header-label"
              [disabled]="_bitv.status.toLowerCase() === 'completed'"
              [value]="_selectedevaluationstep.evaluationresult">
              <mat-button-toggle
                class="evaluation-toggle-button"
                data-cy="open-radiobutton"
                (click)="resetEvaluation(); inputChanged();"
                [checked]="_selectedevaluationstep.evaluationresult == undefined">
                <mat-icon *ngIf="_selectedevaluationstep.evaluationresult == undefined">check</mat-icon>
                <span class="underline-button-text"> Offen </span>
              </mat-button-toggle>
              <mat-button-toggle
                *ngFor="let evalautionOption of allEvaluationOptions"
                class="evaluation-toggle-button"
                [attr.data-cy]="getEnumStringForOption(evalautionOption)+'-radiobutton'"
                [value]="evalautionOption"
                (click)="evaluationClicked(evalautionOption); inputChanged();">
                <mat-icon *ngIf="_selectedevaluationstep.evaluationresult == evalautionOption">check</mat-icon>
                <span class="underline-button-text">{{evalautionOption}}</span>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="balm-content-section">
          <button mat-button class="section-header-button" data-cy="expand-screenshot-section"
                  (click)="toggleExpandScreenshotSection()"><h3>Screenshots</h3>
            <mat-icon iconPositionEnd>{{screenshotsExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
          </button>
          <nfa-bitv-screenshot *ngIf="screenshotsExpanded"
                               [bitvEvaluationStep]="_selectedevaluationstep">
          </nfa-bitv-screenshot>
        </div>
        <div class="balm-content-section summary-container">
          <h3 id="evaluation-comment-heading" class="balm-evaluation-header">Zusammenfassung</h3>
          <editor
            [init]="this.initEditor()"
            [disabled]="_bitv.status.toLowerCase() === 'completed'"
            [(ngModel)]="_selectedevaluationstep.comment"
            (ngModelChange)="inputChanged()"
            data-cy="comment-field"
          ></editor>
          <div class="navigation-buttons">
            <button mat-flat-button
                    [ngClass]="_selectedevaluationstep.id === _allevaluationsteps[0].id ? 'mat-mdc-unelevated-button-tertiary-disabled' :'mat-mdc-unelevated-button-tertiary'"
                    [disabled]="_selectedevaluationstep.id === _allevaluationsteps[0].id"
                    (click)="backClick(_selectedevaluationstep)"
                    data-cy="back_evaluationstep">
              <mat-icon class="mat-icon-24">chevron_left</mat-icon>
              Zurück
            </button>
            <button mat-stroked-button
                    class="mat-mdc-unelevated-button-secondary"
                    (click)="nextClick(_selectedevaluationstep)">
              {{nextClickLabel(_selectedevaluationstep)}}
              <mat-icon *ngIf="!this.isLastStep(selectedevaluationstep)" class="mat-icon-24" iconPositionEnd>
                chevron_right
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="evaluation-informaiton" class="mat-elevation-z0 evaluation-information scrollable-input">
      <balm-info [evaluationstepinfo]="evaluationstepinfo"
                 [evaluationstepName]="selectedevaluationstep.evaluationStep.name"
                 [evaluationstepMeta]="_selectedevaluationstep.evaluationStep">
      </balm-info>
    </div>
  </div>
</div>
