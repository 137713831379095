import { Language } from '../app-state/settings';
import { QumapHelpService } from './qumap-help.service';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Treenode, TreenodeCategory } from '../shared/sidenav/treenode';
import { KeycloakService } from 'keycloak-angular';
import { HilfeFunktionen } from '../shared/global';

@Component({
  selector: 'nfa-qumap-help',
  templateUrl: './qumap-help.component.html',
  styleUrls: ['./qumap-help.component.scss']
})
export class QumapHelpComponent implements OnInit, AfterViewInit {
  language = Language.DEUTSCH;
  documentIdentifier: string;
  navbarExpanded: boolean;
  helpCategories: Treenode[];
  helpCategoryTree: Treenode[];
  currentRoute: string;
  headers: Treenode[] = [];

  authorizationBalm = this.keycloak.isUserInRole('BALM');
  authorizationTen = this.keycloak.isUserInRole('TEN');

  private myauthorization: boolean;

  @ViewChild('helpContent') helpContent: ElementRef;
  @ViewChild('tenonly') tenOnly: ElementRef;

  constructor(private qumapHelpService: QumapHelpService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private readonly keycloak: KeycloakService) {
    this.navbarExpanded = HilfeFunktionen.initialNavbarExpanded();
  }

  ngOnInit() {
    this.qumapHelpService.getHelpCategories(this.language).subscribe(categories => {
      this.helpCategories = categories;
      this.buildHelpCategoryTree();
    });

    document.getElementById('app-title').innerText = 'QuMaP - Hilfe';

  }

  ngAfterViewInit(): void {

    const helpitemid = this.activatedRoute.snapshot.paramMap.get('helpitemid');

    if (helpitemid !== null) {
      this.loadHelpArticle(helpitemid);
    } else {
      this.router.navigateByUrl(`/qumap-help/start`);
    }
  }

  navbarExpanderClick(matSideNav: any) {
    this.navbarExpanded = !this.navbarExpanded;
    matSideNav.toggle();
  }

  loadHelpArticle(textId: string): void {
    this.router.navigate([`/qumap-help/${textId}`]);
    this.qumapHelpService.getQumapHelp(textId).subscribe(
      text => {
        this.helpContent.nativeElement.innerHTML = text;
        this.showTenOnlyDiv(textId);
      }
    );
  }

  private buildHelpCategoryTree() {

    this.helpCategoryTree = this.helpCategories.filter(category => category.parent_id === 0);

    this.helpCategoryTree.forEach(parentCategory => {

      if (parentCategory.name === 'msg.TEN') {
        this.myauthorization = this.authorizationTen;
      } else if (parentCategory.name === 'msg.BALM') {
        this.myauthorization = this.authorizationBalm;
      } else {
        this.myauthorization = true;
      }

      const newitem: Treenode = {
        name: parentCategory.name,
        expanded: true,
        category: TreenodeCategory.ROOT,
        authorization: this.myauthorization
      };

      this.headers.push(newitem);

      parentCategory.children = this.helpCategories.filter(childCategory => childCategory.parent_id === parentCategory.id);
      const schublade = parentCategory.id - 1;
      this.headers[schublade].children = parentCategory.children;

      parentCategory.children.forEach(childCategory => {
        childCategory.category = TreenodeCategory.NODE;
        childCategory.authorization = true;

      });
    });

  }

  private showTenOnlyDiv(myroute: string): void {
    if (!!document.querySelector('.tenonly')) {
      const tenOnlyDiv = document.querySelector('.tenonly');
      if (myroute === 'qumap-edit-application') {
        this.tenOnly.nativeElement.innerHTML = `
          <h2>Zusätzliche Eigenschaften in msg.TEN</h2>
          <p>msg.TEN-Nutzer können für Anwendungen zusätzlich folgende Angaben machen:</p>
          <ul>
          <li>Im Bereich „Metadaten Anwendung“ kann die IT-Systemart angegeben werden und ob es sich um den eigenen Betrieb handelt.</li>
          <li>Im zusätzlichen Bereich „Phase Anwendung“ müssen die Phase („Initialisierung“, „Definition“ oder „Realisierung“) und der Typ des Anforderungsdokuments (z. B. „User Story“, „Lastenheft“) jeweils in einer Auswahlliste angegeben werden. Abhängig von der gewählten Phase stehen andere Anforderungsdokumente zur Auswahl.</li>
          </ul>
        `;
      } else {
        this.tenOnly.nativeElement.innerHTML = '';
      }
    }
  }
}

