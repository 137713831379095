import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TestMethodsAction, TestMethodsActions } from './test-methods.actions';
import { TestType } from '../adapter/testtype';
import { TestDesignTechnique } from '../adapter/testDesignTechnique';

export const FEATURE_NAME = 'test-methods';

// State
export interface TestMethodsState {
  testTypes: TestType[];
  testDesignTechniques: TestDesignTechnique[];

  // meta data
  loading: boolean;
  loadadTypes: boolean;
  loadadDesignTechniques: boolean;
  error: string | null;
}

// Initialisierung
export const initialState: TestMethodsState = {
  testTypes: [],
  testDesignTechniques: [],
  loading: false,
  loadadTypes: false,
  loadadDesignTechniques: false,
  error: null
};

// Reducer-Funktion
export function testMethodsReducer(state = initialState, action: TestMethodsAction): TestMethodsState {

  switch (action.type) {
    case TestMethodsActions.LoadTestTypes: {
      return {...state, loading: true};
    }
    case TestMethodsActions.LoadTestTypesSuccess: {
      return {...state, loading: false, testTypes: action.testTypes, loadadTypes: true};
    }
    case TestMethodsActions.LoadCancel: {
      return {...state, loading: false};
    }
    case TestMethodsActions.LoadTestTypesFailure: {
      return {...state, loading: false, testTypes: [], error: action.payload.error, loadadTypes: false};
    }
    case TestMethodsActions.LoadTestDesignTechniques: {
      return {...state, loading: true};
    }
    case TestMethodsActions.LoadTestDesignTechniquesSuccess: {
      return {
        ...state,
        loading: false,
        testDesignTechniques: action.testDesignTechniques,
        loadadDesignTechniques: true
      };
    }
    case TestMethodsActions.LoadTestDesignTechniquesFailure: {
      return {
        ...state,
        loading: false,
        testDesignTechniques: [],
        error: action.payload.error,
        loadadDesignTechniques: false
      };
    }

    default:
      return state;
  }
}

// Selektoren
export const getTestMethodsState = createFeatureSelector<TestMethodsState>(FEATURE_NAME);
export const getTestTypesList = createSelector(getTestMethodsState, state => state.testTypes);
export const getTestDesignTechniquesList = createSelector(getTestMethodsState, state => state.testDesignTechniques);
export const areTypesLoaded = createSelector(getTestMethodsState, state => state.loadadTypes);
export const areDesignTechniquesLoaded = createSelector(getTestMethodsState, state => state.loadadDesignTechniques);

