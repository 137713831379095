<div class="page-header">
  <div class="page-header__heading page-header__heading--fix-width">
    <h1 nfaDisableCopyPaste>{{showUserSpecificApplications?'Meine Anwendungen':'Alle Anwendungen'}}</h1>
    <button mat-icon-button aria-label="Hilfe" data-cy="helpBtn" class="helpBtn helpIcon" (click)="onHelpButtonClick()">
      <mat-icon class="helpButtonIcon" matTooltip="Hilfe">
        help_outline
      </mat-icon>
    </button>
  </div>
  <div>
    <mat-button-toggle-group class="margin--left" name="processesToggle" aria-label="Wechseln zwischen Anwendungssichten (meine/alle)"
      value="{{showUserSpecificApplications?'meine':'alle'}}">
      <mat-button-toggle value="meine" class="applicationsToggleButton"
        (click)="showUserSpecificApplications = true; refreshApplicationTable()">
        <span class="applicationsToggleButtonText">meine</span>
      </mat-button-toggle>
      <mat-button-toggle value="alle" class="applicationsToggleButton mat-button-toggle-startseite"
        (click)="showUserSpecificApplications = false; refreshApplicationTable()">
        <span class="applicationsToggleButtonText">alle</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="page-header__actions">
    <button #createnewapplication
            mat-flat-button
            class="mat-mdc-unelevated-button-primary"
            id="createnewapplication"
            color="primary"
            routerLink="/applications/new">
      <!--Primär Startseite-->
      <mat-icon>add</mat-icon>
      <span>Neue Anwendung anlegen</span>
    </button>
  </div>
</div>
<div class="scrollable-container">
  <table mat-table class="applicationTable" [dataSource]="applications" matSort (matSortChange)="sortApplications($event)">
    <ng-container class="tablerow" matColumnDef="name">
      <td class="tableitem" mat-cell *matCellDef="let element; let i = index" id="{{'applications-element'+i }}">
        <div>
          <span class="projectName" [innerHTML]="element.name | uppercase | highlightSearch:filterText" id="{{'applicationName'+i }}">
          </span>
          <button mat-icon-button
                  routerLink="/applications/{{element.id}}"
                  id="{{'open'+i }}"
                  [matTooltip]="canUserEdit(element)?'Bearbeiten':'Anzeigen'"
                  [attr.aria-label]="canUserEdit(element)?'Bearbeiten':'Anzeigen'">
            <mat-icon class="icon-table" *ngIf="!canUserEdit(element)">
              remove_red_eye
            </mat-icon>
            <mat-icon class="icon-table" *ngIf="canUserEdit(element)">
              edit
            </mat-icon>
          </button>
        </div>
        <span class="tableitem" id="{{'customerName'+i }}"> Kundenname: <span class="tableitemdetail">{{element.customerName}}</span> </span>
        <span *ngIf="authorizationTen" class="tableitem" id="{{'phase'+i }}">Phase:
          <span class="tableitemdetail" *ngIf="element.qualityProcesses.length === 0"><i>Keine Phase ausgewählt</i></span>
          <span *ngFor="let qualityProcess of element.qualityProcesses; let i = index">
            <span class="tableitemdetail" *ngIf="i === element.qualityProcesses.length - 1">{{qualityProcess.phase}}</span>
          </span>
        </span>
        <div>
          <span *ngIf="authorizationTen" class="tableitem" id="{{'state'+i }}"> Status:
            <span *ngFor="let qualityProcess of element.qualityProcesses; let i = index">
              <span *ngIf="i === element.qualityProcesses.length - 1">
                <span *ngIf="qualityProcess.status.id==='IP'">
                  <span class="tableitemdetail"> {{qualityProcess.status.name}}</span>
                  <mat-icon class="tableIconGold">circle</mat-icon>
                </span>
                <span *ngIf="qualityProcess.status.id==='CO'">
                  <span class="tableitemdetail">{{qualityProcess.status.name}}</span>
                  <mat-icon class="tableIconGreen">circle</mat-icon>
                </span>
              </span>
            </span>
          </span>
          <span *ngIf="authorizationTen"> <span class="tableitem" id="{{'document'+i }}">Dokument:

              <span class="tableitemdetail" *ngIf="element.qualityProcesses.length === 0"><i>Kein Anforderungsdokument ausgewählt</i></span>
              <span *ngFor="let qualityProcess of element.qualityProcesses; let j = index">
                <span class="tableitemdetail" *ngIf="j === element.qualityProcesses.length - 1">{{qualityProcess.document.description}}</span></span>
            </span>
          </span>
          <span class="tableitem" id="{{'lastUpdate'+i }}">Letzte Änderung: <span class="tableitemdetail">{{element.lastUpdateString}}</span>
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container class="tablerow" matColumnDef="buttons">
      <td mat-cell *matCellDef="let element; let i = index" id="{{'buttons'+i }}">
        <div class="buttonContainer">
          <div *ngIf="authorizationTen">
            <button mat-flat-button
                    class="applicationsTableButton mat-mdc-unelevated-button-secondary"
                    *ngIf="canUserSeeTEN()"
                    [routerLink]="provideTENRouterLink(element)"
                    id="{{'TEN'+i }}">
              TEN
            </button>
          </div>
          <div *ngIf="authorizationBalm">
            <button mat-flat-button
                    class="applicationsTableButton mat-mdc-unelevated-button-secondary"
                    *ngIf="canUserSeeBALM()"
                    [routerLink]="provideBALMRouterLink(element)"
                    id="{{'BALM'+i }}">
              BALM
            </button>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsApplications;"></tr>
  </table>
  <mat-paginator [length]="resultsLength" [pageSize]="10"></mat-paginator>
</div>
