import { Factor } from '../adapter/factor';
import { CatalogAction, CatalogActions } from './catalog.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Metric } from '../adapter/metric';
import { TemplateNode } from '../adapter/templateNode';
import { TemplateName } from '../adapter/templateName';
import { Sector } from '../adapter/sector';
import { NfrReceived } from '../adapter/nfrReceived';

// State
export interface CatalogState {
  factors: Factor[];
  nfrs: { [metricId: string]: NfrReceived[] | undefined };
  allNfrs: NfrReceived[];
  selectedMetric?: Metric;
  templateNodes?: TemplateNode[];
  templateNames?: TemplateName[];
  sectors?: Sector[];

  // meta data
  loading: boolean;
  factorsLoadedAt: Date | null;
  templateNamesLoaded: boolean;
  templateNodesLoaded: boolean;
  sectorsLoaded: boolean;
  saving: boolean;
  error: string | null;
}

// Initialisierung
export const initialState: CatalogState = {
  factors: [],
  sectors: [],
  nfrs: {},
  allNfrs: [],
  templateNodes: [],
  templateNames: [],
  loading: false,
  factorsLoadedAt: null,
  templateNamesLoaded: false,
  templateNodesLoaded: false,
  sectorsLoaded: true,
  saving: false,
  error: null
};

// Reducer-Funktion
export function catalogReducer(state = initialState, action: CatalogAction): CatalogState {

  switch (action.type) {
    case CatalogActions.LoadCatalog: {
      return {...state, loading: true};
    }
    case CatalogActions.LoadCatalogSuccess: {
      return {...state, loading: false, factors: action.factors, factorsLoadedAt: new Date()};
    }
    case CatalogActions.LoadCatalogFailure: {
      return {...state, loading: false, factors: [], error: action.payload.error, factorsLoadedAt: null};
    }
    case CatalogActions.LoadCanceled: {
      return {...state, loading: false};
    }
    case CatalogActions.LoadTemplates: {
      return {...state, loading: true};
    }
    case CatalogActions.LoadTemplatesSuccess: {
      return {...state, loading: false, templateNodes: action.templateNodes, templateNodesLoaded: true};
    }
    case CatalogActions.LoadTemplatesFailure: {
      return {...state, loading: false, templateNodes: [], error: action.payload.error};
    }
    case CatalogActions.LoadSectors: {
      return {...state, loading: true};
    }
    case CatalogActions.LoadSectorsSuccess: {
      return {...state, loading: false, sectors: action.sectors, sectorsLoaded: true};
    }
    case CatalogActions.LoadSectorsFailure: {
      return {...state, loading: false, sectors: [], error: action.payload.error, sectorsLoaded: false};
    }
    case CatalogActions.LoadTemplateNames: {
      return {...state, loading: true};
    }
    case CatalogActions.LoadTemplateNamesSuccess: {
      return {...state, loading: false, templateNames: action.templateNames, templateNamesLoaded: true};
    }
    case CatalogActions.LoadTemplateNamesFailure: {
      return {...state, loading: false, templateNames: [], error: action.payload.error};
    }
    case CatalogActions.LoadNfrsForMetric:
    case CatalogActions.LoadNfrsForMultipleMetric: {
      return {...state, loading: true};
    }
    case CatalogActions.LoadNfrsForMetricSuccess: {

      const nfrs = {...state.nfrs};
      nfrs[action.payload.metricId] = action.payload.nfrs;
      return {...state, loading: false, nfrs};
    }
    case CatalogActions.LoadNfrsForMetricFailure: {
      return {...state, loading: false, error: action.payload.error};
    }
    case CatalogActions.LoadNfrsForMultipleMetricSuccess: {
      const nfrs = {...state.nfrs};
      for (const nfrMulti of action.payload) {
        nfrs[nfrMulti.metricId] = nfrMulti.nfrs;
      }

      return {...state, loading: false, nfrs};
    }
    case CatalogActions.LoadNfrs: {
      return {...state, loading: true};
    }
    case CatalogActions.LoadNfrsSuccess: {
      const allNfrs = action.payload.allNfrs;
      return {...state, loading: false, allNfrs};
    }
    case CatalogActions.LoadNfrsFailure: {
      return {...state, loading: false, error: action.payload.error};
    }
    case CatalogActions.CreateNewNfr: {
      return {...state, saving: true};
    }
    case CatalogActions.CreateNewNfrSuccess: {
      return {...state, saving: false};
    }
    case CatalogActions.CreateNewNfrFailure: {
      return {...state, saving: false, error: action.payload.error};
    }
    case CatalogActions.NavigateToNfr: {
      return {...state, selectedMetric: action.payload.metric};
    }
    default:
      return state;
  }
}

// Selektoren
export const getCatalogState = createFeatureSelector<CatalogState>('catalog');
export const getFactorList = createSelector(getCatalogState, state => state.factors);
export const getFactorsLoadedAt = createSelector(getCatalogState, state => state.factorsLoadedAt);
export const getTemplateNodes = createSelector(getCatalogState, state => state.templateNodes);
export const areTemplateNodesLoaded = createSelector(getCatalogState, state => state.templateNodesLoaded);
export const getTemplateNames = createSelector(getCatalogState, state => state.templateNames);
export const areTemplateNamesLoaded = createSelector(getCatalogState, state => state.templateNamesLoaded);
export const getSectors = createSelector(getCatalogState, state => state.sectors);
export const areSectorsLoaded = createSelector(getCatalogState, state => state.sectorsLoaded);
export const getError = createSelector(getCatalogState, state => state.error);
export const getSelectedMetric = createSelector(getCatalogState, state => state.selectedMetric);
export const getAllNfrs = createSelector(getCatalogState, state => state.allNfrs);
