import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {JiraData, JiraUrl} from '../model/jiraData';

@Injectable({
  providedIn: 'root',
})
export class JiraExportService {

  private apiUrl = '/nfabackend/webapi';

  constructor(private http: HttpClient) {
  }

  invokeJiraExport(jiraData: JiraData): Observable<JiraData> {
    return this.http.post<JiraData>(`${this.apiUrl}/ten/jira/export`, jiraData).pipe(
      catchError((err) => throwError(err)),
    );
  }

  getJiraInstanceUrl(applicationId: number): Observable<JiraUrl> {
    return this.http.get<JiraUrl>(`${this.apiUrl}/ten/jira/${applicationId}/url`).pipe(
      catchError((err) =>
        throwError(() => new Error('Something went wrong. Could not find Jira-URL for organization')),
      ));
  }
}
