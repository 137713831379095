<div class="progress-bar-container">
  <div class="progress-bar">
    <div class="conform" [style]="calculateBarLength(conformCount)"></div>
    <div class="not-conform" [style]="calculateBarLength(notConformCount)"></div>
    <div class="not-applicable" [style]="calculateBarLength(notApplicableCount)"></div>
    <div class="open" [style]="calculateBarLength(openCount)"></div>
  </div>
  <div class="progress-bar progress-legend">
    <div class="progress-legend-title">{{evaluationstepsCount}} Prüfschritte:</div>
    <div class="progress-legend-entry"
         [matTooltip]="conformCount + ' von ' + evaluationstepsCount"
         data-cy="fulfilledSteps">
      <div class="progress-legend-conform"></div>
      {{conformCount }} Erfüllt
    </div>
    <div class="progress-legend-entry"
         [matTooltip]="notConformCount + ' von ' + evaluationstepsCount"
         data-cy="noSteps">
      <div class="progress-legend-not-conform" data-cy="not-conform-count"></div>
      {{notConformCount }} Nicht erfüllt
    </div>
    <div class="progress-legend-entry"
         [matTooltip]="notApplicableCount + ' von ' + evaluationstepsCount"
         data-cy="notApplicableSteps">
      <div class="progress-legend-not-applicable" data-cy="not-applicable-count"></div>
      {{notApplicableCount }} Nicht anwendbar
    </div>
    <div class="progress-legend-entry"
         [matTooltip]="openCount + ' von ' + evaluationstepsCount"
         data-cy="openSteps">
      <div class="progress-legend-open" data-cy="open-count"></div>
      {{openCount }} Offen
    </div>
    <div class="progress-legend-result">
      <span>Aktuelles Ergebnis: {{getEvaluationResult()}}</span>
      <mat-icon [ngClass]="compliant?'conformity-indicator':'non-conformity-indicator'">{{getResultIcon()}}</mat-icon>
    </div>
  </div>
</div>

<div class="mobile-progress">
  <mat-card>
    <span>Prüfschritte:</span>
    <div class="progression-percentage">
      <p>{{calculateProgressionPercentage(conformCount)}}% Erfüllt</p>
      <p>{{calculateProgressionPercentage(notConformCount)}}% Nicht erfüllt</p>
      <p>{{calculateProgressionPercentage(notApplicableCount)}}% Nicht anwendbar</p>
      <p>{{calculateProgressionPercentage(openCount)}}% Offen</p>
    </div>
      <span>Aktuelles Ergebnis: {{getEvaluationResult()}}  <mat-icon [ngClass]="compliant?'conformity-indicator':'non-conformity-indicator'">{{getResultIcon()}}</mat-icon>
</span>
       </mat-card>
</div>
