<h1 mat-dialog-title>NFA erstellen für {{metric.label}} ({{metric.identifier}})
  <!--<button mat-button mat-dialog-close
    style="float: right;">
    <mat-icon inline="true">close</mat-icon>
  </button>-->
</h1>
<form #nfrForm="ngForm" (ngSubmit)="submit()">
  <mat-dialog-content>
    <span *ngIf="templateNames$ | async as templateNames">
      <!-- Schablonen-Typ auswaehlen -->
      <mat-form-field class="info">
        <mat-label>Schablonen-Typ</mat-label>
        <mat-select placeholder="Schablonen-Typ" (selectionChange)="changeTemplate($event.value)">
          <span *ngFor="let templateName of templateNames">
            <mat-option
              *ngIf="(isFactor('17') || isFactor('18')) && (templateName.identifier === '5' || templateName.identifier === '6')"
              [value]="templateName.identifier">
              {{templateName.description}}
            </mat-option>
            <mat-option
              *ngIf="(isFactor('7') || isFactor('14')) && (templateName.identifier === '3' || templateName.identifier === '4')"
              [value]="templateName.identifier">
              {{templateName.description}}
            </mat-option>
            <mat-option *ngIf="(templateName.identifier === '1' || templateName.identifier === '2')"
              [value]="templateName.identifier">
              {{templateName.description}}
            </mat-option>
          </span>
        </mat-select>
      </mat-form-field>
      <span *ngIf="!isTemplateActive(-1)">
        <mat-card class="mat-card--position-detached mat-elevation-z2" *ngIf="catalogState$|async as catalogState">
          <mat-card-content *ngIf="catalogState.error === null; else noContent">
            <mat-card-actions class="mat-card-actions__alligned" *ngIf="templateNodes$ | async as templateNodes">
              <!-- Bezeichnungen -->
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  Bezeichnungen
                </mat-expansion-panel-header>

                <div class="grid-item">
                  <mat-form-field class="info">
                    <mat-label>Bezeichnung (deutsch)</mat-label>
                    <input name="nameGerman" matInput placeholder="Bezeichnung (deutsch)"
                      [(ngModel)]="newNfr.nameGerman" class="form-control" required>
                  </mat-form-field>
                </div>
                <div class="grid-item">
                  <mat-form-field class="info">
                    <!-- Nicht required, falls Sektor nur Public Sector -->
                    <mat-label>Bezeichnung (englisch)</mat-label>
                    <input [required]="!isPublicSector()" name="nameEnglish" matInput
                      placeholder="Bezeichnung (englisch)" [(ngModel)]="newNfr.nameEnglish" class="form-control">
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
              <!-- Referenz -->
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  Referenz
                </mat-expansion-panel-header>

                <div class="grid-item">
                  <mat-form-field class="info">
                    <mat-label>Referenz</mat-label>
                    <input name="reference" matInput placeholder="Referenz" [(ngModel)]="newNfr.reference"
                      class="form-control" class="info">
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
              <!-- Branchen -->
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  Branche
                </mat-expansion-panel-header>
                <!--TODO Button für alle Branchen auswählen-->
                <!--<button mat-raised-button (click)="selectAllSectors(sectorSelect, sectors)">Alle auswählen</button>-->
                <mat-selection-list name="select_sectors" [(ngModel)]="newNfr.sectors" required="true">
                  <mat-list-option value="{{sector.value}}" *ngFor="let sector of sectors">
                    {{sector.name}}
                  </mat-list-option>
                </mat-selection-list>
              </mat-expansion-panel>
              <!-- Fragen -->
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  Fragen
                </mat-expansion-panel-header>
                <div class="grid-item">
                  <mat-form-field class="info">
                    <mat-label>Frage (deutsch)</mat-label>
                    <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5" name="questionGerman" matInput placeholder="Frage (deutsch)"
                      [(ngModel)]="newNfr.questionGerman" class="form-control" required></textarea>
                  </mat-form-field>
                </div>
                <div class="grid-item">
                  <mat-form-field class="info">
                    <!-- Nicht required, falls Sektor nur Public Sector -->
                    <mat-label>Frage (englisch)</mat-label>
                    <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5" [required]="!isPublicSector()" name="questionEnglish" matInput
                      placeholder="Frage (englisch)" [(ngModel)]="newNfr.questionEnglish"
                      class="form-control"></textarea>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
              <!-- Check-Boxen -->
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  Schablonen
                </mat-expansion-panel-header>
                <div *ngIf="!isTemplateActive(-1)">
                  <!-- Konkretisierende Werte -->
                  <mat-checkbox name="concreteValue" [(ngModel)]="concreteValue">
                    Konkretisierende Werte
                  </mat-checkbox>
                  <br />
                  <!-- Werte für Bedingungen -->
                  <div *ngIf="isTemplateActive(2)||isTemplateActive(4)||isTemplateActive(6)">
                    <mat-checkbox name="conditionValues" [(ngModel)]="conditionValues">
                      Werte für Bedingungen
                    </mat-checkbox>
                    <br />
                  </div>
                  <!-- Verbindliche Werte -->
                  <mat-checkbox name="obligatoryValues" [(ngModel)]="obligatoryValues" (change)="setObligatoryValues()">
                    Verbindliche Werte
                  </mat-checkbox>
                  <br />
                  <!-- Singular/Plural -->
                  <div *ngIf="obligatoryValues">
                    <mat-checkbox name="plural" [(ngModel)]="plural">
                      Plural
                    </mat-checkbox>
                    <br />
                  </div>
                  <!-- Varbiabler Betrachtungsgegenstand -->
                  <div
                    *ngIf="isTemplateActive(1)  || isTemplateActive(2) || isTemplateActive(3) || isTemplateActive(4)">
                    <mat-checkbox [disabled]="obligatoryValues" name="variableObject" [(ngModel)]="variableObject"
                      (change)="setVariableValues()">
                      Variabler Betrachtungsgegenstand
                    </mat-checkbox>
                    <br />
                  </div>
                  <!-- Variabler Wert -->
                  <div
                    *ngIf="isTemplateActive(1)  || isTemplateActive(2) || isTemplateActive(3) || isTemplateActive(4)">
                    <mat-checkbox [disabled]="obligatoryValues" name="variableValue" [(ngModel)]="variableValue">
                      Variabler Wert
                    </mat-checkbox>
                    <br />
                  </div>
                  <!-- NFA, für die unterschiedliche Werte gesetzt werden kann -->
                  <!-- TODO: Funktionalität -->
                  <mat-checkbox [disabled]="obligatoryValues" name="differentValues" [(ngModel)]="differentValues">
                    NFA, für die unterschiedliche Werte gesetzt werden kann
                  </mat-checkbox>
                  <br />
                </div>

                <!-- Eigenschaftsschablone -->
                <mat-card *ngIf="isTemplateActive(1)">
                  <!-- Deutsch -->
                  <h3>{{templateNames[0].description}}</h3>
                  <!-- Eigenschaft -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[0].description}}</mat-label>
                    <input name="characteristic" matInput placeholder="{{ templateNodes[0].description }}"
                      [(ngModel)]="nfrTemplate.characteristic" class="form-control" required>
                  </mat-form-field>
                  <!-- Betrachtungsgegenstand -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[1].description}}</mat-label>
                    <input name="property" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[1].description }}" [(ngModel)]="nfrTemplate.property"
                      [required]="!nfrTemplate.propertyVariable" class="form-control">
                  </mat-form-field>
                  <!-- Variabler Wert -->
                  <span *ngIf="variableValue">
                    <!-- Variabler Wert -->
                    <!-- TODO: Enum hinzufügen -->
                    <mat-form-field>
                      <input matInput disabled placeholder="Variabler Wert">
                    </mat-form-field>
                    <!-- Betrachtungsgegenstand -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[1].description}}</mat-label>
                      <input name="propertyVariable" [disabled]="variableObject" [required]="!nfrTemplate.property"
                        matInput placeholder="{{ templateNodes[1].description }}"
                        [(ngModel)]="nfrTemplate.propertyVariable" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Rechtliche Verbindlichkeit -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].description}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" required>
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.description}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.description}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- Konkretisierender Wert -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Konkretisierender Wert</mat-label>
                    <input [disabled]="!obligatoryValues" name="concretizing_value" matInput
                      placeholder="Konkretisierender Wert" [(ngModel)]="nfrTemplate.concretizing_value"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Vergleichsoperator -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].description}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && operator1 === 'zwischen' || text.identifier == '4.16' && operator1 === 'von'; else description">
                          {{operator1}}
                        </span>
                        <ng-template #description><span>{{text.description}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Kein Wertebereich -->
                  <!-- Wert -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].description}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].description }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Wertebereich -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Untere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Untere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" required>
                    </mat-form-field>
                    {{operator2}}
                    <mat-form-field>
                      <mat-label>Obere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Obere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" required>
                    </mat-form-field>
                  </span>
                  <!-- betragen/sein -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[16].templateTexts[0].description}} /
                      {{templateNodes[16].templateTexts[1].description}}</mat-label>
                    <mat-select name="be" [(ngModel)]="nfrTemplate.be" class="form-control" required>
                      <div *ngFor="let text of templateNodes[16].templateTexts">
                        <mat-option *ngIf="text.identifier=='17.1' || text.identifier=='17.2'"
                          [value]="text.description">
                          {{text.description}}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                  <!-- Punkt -->
                  .
                  <br />

                  <!-- English -->
                  <h3>{{templateNames[0].descriptionEnglish}}</h3>
                  <!-- Characteristic -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[0].descriptionEnglish}}</mat-label>
                    <input name="en_characteristic" matInput placeholder="{{ templateNodes[0].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_characteristic" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Property matter -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[1].descriptionEnglish}}</mat-label>
                    <input name="en_property" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[1].descriptionEnglish }}" [(ngModel)]="nfrTemplate.en_property"
                      [required]="!nfrTemplate.en_propertyVariable && !isPublicSector()" class="form-control">
                  </mat-form-field>
                  <!-- Variabler Wert -->
                  <span *ngIf="variableValue">
                    <!-- Variable value -->
                    <!-- TODO: Enum hinzufügen -->
                    <mat-form-field>
                      <input matInput disabled placeholder="Variable value">
                    </mat-form-field>
                    <!-- Property matter -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[1].descriptionEnglish}}</mat-label>
                      <input name="en_propertyVariable" [disabled]="variableObject"
                        [required]="!nfrTemplate.en_property && !isPublicSector()" matInput
                        placeholder="{{ templateNodes[1].descriptionEnglish }}"
                        [(ngModel)]="nfrTemplate.en_propertyVariable" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Legal liability -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].descriptionEnglish}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" [required]="!isPublicSector()">
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.descriptionEnglish}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.descriptionEnglish}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- Be -->
                  {{templateNodes[16].templateTexts[0].descriptionEnglish}}
                  <!-- Qualifying expression -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].descriptionEnglish}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && enOperator1 === 'between' || text.identifier == '4.16' && enOperator1 === 'from'; else description">
                          {{enOperator1}}
                        </span>
                        <ng-template #description><span>{{text.descriptionEnglish}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- No value range -->
                  <!-- Value -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].descriptionEnglish}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].descriptionEnglish }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Value range -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Lower bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Lower bound of the value" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                    {{enOperator2}}
                    <mat-form-field>
                      <mat-label>Upper bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Upper bound of the value" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                  </span>
                  <!-- Concretizing value -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Concretizing value</mat-label>
                    <input [disabled]="!obligatoryValues" name="en_concretizing_value" matInput
                      placeholder="Concretizing value" [(ngModel)]="nfrTemplate.en_concretizing_value"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Point -->
                  .
                </mat-card>

                <!-- Eigenschaftsschablone mit Bedingung -->
                <mat-card *ngIf=isTemplateActive(2)>
                  <h3>{{templateNames[1].description}}</h3>
                  <!-- Bedingungskonjunktion -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[5].description}}</mat-label>
                    <mat-select name="conditional" [(ngModel)]="nfrTemplate.conditional" class="form-control" required>
                      <mat-option *ngFor="let text of templateNodes[5].templateTexts" [value]="text">
                        {{text.description}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Bedingung -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[6].description}}</mat-label>
                    <input name="condition" matInput placeholder="{{ templateNodes[6].description }}"
                      [(ngModel)]="nfrTemplate.condition" class="form-control" required>
                  </mat-form-field>
                  <!-- Werte für Bedingungen -->
                  <span *ngIf="conditionValues">
                    <!-- Vergleichsoperator -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[3].description}}</mat-label>
                      <mat-select name="qualifying_expression2" (selectionChange)="changeValueRange2($event.value)"
                        [(ngModel)]="nfrTemplate.qualifying_expression2" class="form-control">
                        <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                          <span
                            *ngIf="text.identifier == '4.15' && operator3 === 'zwischen' || text.identifier == '4.16' && operator3 === 'von' ; else description">
                            {{operator3}}
                          </span>
                          <ng-template #description><span>{{text.description}}</span></ng-template>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- Kein Wertebereich -->
                    <!-- Wert -->
                    <mat-form-field *ngIf="!valueRange2">
                      <mat-label>{{templateNodes[4].description}}</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_2" id="value_2" matInput
                        placeholder="{{ templateNodes[4].description }}" [(ngModel)]="nfrTemplate.value_2"
                        class="form-control" required>
                    </mat-form-field>
                    <!-- Wertebereich -->
                    <!-- TODO: Enums anlegen für obere und untere Grenze -->
                    <span *ngIf="valueRange2">
                      <mat-form-field>
                        <mat-label>Untere Grenze des Wertes</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_1" matInput
                          placeholder="Untere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_2_1"
                          class="form-control" required>
                      </mat-form-field>
                      {{operator4}}
                      <mat-form-field>
                        <mat-label>Obere Grenze des Wertes</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_2" matInput
                          placeholder="Obere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_2_2"
                          class="form-control" required>
                      </mat-form-field>
                    </span>
                    <!-- 2. Teil der Bedingung -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[7].description}}</mat-label>
                      <input name="second_part_condition" matInput placeholder="{{ templateNodes[7].description }}"
                        [(ngModel)]="nfrTemplate.second_part_condition" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Komma -->
                  ,
                  <!-- Rechtliche Verbindlichkeit -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].description}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" required>
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.description}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.description}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- Eigenschaft -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[0].description}}</mat-label>
                    <input name="characteristic" matInput placeholder="{{ templateNodes[0].description }}"
                      [(ngModel)]="nfrTemplate.characteristic" class="form-control" required>
                  </mat-form-field>
                  <!-- Betrachtungsgegenstand -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[1].description}}</mat-label>
                    <input name="property" [required]="!nfrTemplate.propertyVariable" [disabled]="variableObject"
                      matInput placeholder="{{ templateNodes[1].description }}" [(ngModel)]="nfrTemplate.property"
                      class="form-control">
                  </mat-form-field>
                  <!-- Variabler Wert -->
                  <span *ngIf="variableValue">
                    <!-- Variabler Wert -->
                    <!-- TODO: Enum hinzufügen -->
                    <mat-form-field>
                      <input matInput disabled placeholder="Variabler Wert">
                    </mat-form-field>
                    <!-- Betrachtungsgegenstand -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[1].description}}</mat-label>
                      <input name="propertyVariable" [disabled]="variableObject" [required]="!nfrTemplate.property"
                        matInput placeholder="{{ templateNodes[1].description }}"
                        [(ngModel)]="nfrTemplate.propertyVariable" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Konkretisierender Wert -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Konkretisierender Wert</mat-label>
                    <input [disabled]="!obligatoryValues" name="concretizing_value" matInput
                      placeholder="Konkretisierender Wert" [(ngModel)]="nfrTemplate.concretizing_value"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Vergleichsoperator -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].description}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && operator1 === 'zwischen' || text.identifier == '4.16' && operator1 === 'von'; else description">
                          {{operator1}}
                        </span>
                        <ng-template #description><span>{{text.description}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Kein Wertebereich -->
                  <!-- Wert -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].description}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].description }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Wertebereich -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Untere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Untere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" required>
                    </mat-form-field>
                    {{operator2}}
                    <mat-form-field>
                      <mat-label>Obere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Obere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" required>
                    </mat-form-field>
                  </span>
                  <!-- betragen/sein -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[16].templateTexts[0].description}} /
                      {{templateNodes[16].templateTexts[1].description}}</mat-label>
                    <mat-select name="be" [(ngModel)]="nfrTemplate.be" class="form-control" required>
                      <div *ngFor="let text of templateNodes[16].templateTexts">
                        <mat-option *ngIf="text.identifier=='17.1' || text.identifier=='17.2'"
                          [value]="text.description">
                          {{text.description}}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                  <!-- Punkt -->
                  .
                  <br />

                  <!-- English -->
                  <h3>{{templateNames[1].descriptionEnglish}}</h3>
                  <!-- Conjunction for the condition -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[5].descriptionEnglish}}</mat-label>
                    <mat-select name="conditional" [(ngModel)]="nfrTemplate.conditional" class="form-control"
                      [required]="!isPublicSector()">
                      <mat-option *ngFor="let text of templateNodes[5].templateTexts" [value]="text">
                        {{text.descriptionEnglish}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Condition -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[6].descriptionEnglish}}</mat-label>
                    <input name="en_condition" matInput placeholder="{{ templateNodes[6].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_condition" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Value for Conditions -->
                  <span *ngIf="conditionValues">
                    <!-- Qualifying expression  -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[3].descriptionEnglish}}</mat-label>
                      <mat-select name="qualifying_expression2" (selectionChange)="changeValueRange2($event.value)"
                        [(ngModel)]="nfrTemplate.qualifying_expression2" class="form-control">
                        <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                          <span
                            *ngIf="text.identifier == '4.15' && enOperator3 === 'between' || text.identifier == '4.16' && enOperator3 === 'from' ; else description">
                            {{enOperator3}}
                          </span>
                          <ng-template #description><span>{{text.descriptionEnglish}}</span></ng-template>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- No value range -->
                    <!-- Value -->
                    <mat-form-field *ngIf="!valueRange2">
                      <mat-label>{{templateNodes[4].descriptionEnglish}}</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_2" id="value_2" matInput
                        placeholder="{{ templateNodes[4].descriptionEnglish }}" [(ngModel)]="nfrTemplate.value_2"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                    <!-- Value range -->
                    <!-- TODO: Enums anlegen für obere und untere Grenze -->
                    <span *ngIf="valueRange2">
                      <mat-form-field>
                        <mat-label>Lower bound of the value</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_1" matInput
                          placeholder="Lower bound of the value" [(ngModel)]="nfrTemplate.value_range_2_1"
                          class="form-control" [required]="!isPublicSector()">
                      </mat-form-field>
                      {{enOperator4}}
                      <mat-form-field>
                        <mat-label>Upper bound of the value</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_2" matInput
                          placeholder="Upper bound of the value" [(ngModel)]="nfrTemplate.value_range_2_2"
                          class="form-control" [required]="!isPublicSector()">
                      </mat-form-field>
                    </span>
                    <!-- 2nd part of the condition -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[7].descriptionEnglish}}</mat-label>
                      <input name="en_second_part_condition" matInput
                        placeholder="{{ templateNodes[7].descriptionEnglish }}"
                        [(ngModel)]="nfrTemplate.en_second_part_condition" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Comma -->
                  ,
                  <!-- Characteristic -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[0].descriptionEnglish}}</mat-label>
                    <input name="en_characteristic" matInput placeholder="{{ templateNodes[0].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_characteristic" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Property matter -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[1].descriptionEnglish}}</mat-label>
                    <input name="en_property" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[1].descriptionEnglish }}" [(ngModel)]="nfrTemplate.en_property"
                      [required]="!nfrTemplate.en_propertyVariable && !isPublicSector()" class="form-control">
                  </mat-form-field>
                  <!-- Variabler Wert -->
                  <span *ngIf="variableValue">
                    <!-- Variable value -->
                    <!-- TODO: Enum hinzufügen -->
                    <mat-form-field>
                      <input matInput disabled placeholder="Variable value">
                    </mat-form-field>
                    <!-- Property matter -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[1].descriptionEnglish}}</mat-label>
                      <input name="en_propertyVariable" [disabled]="variableObject"
                        [required]="!nfrTemplate.en_property && !isPublicSector()" matInput
                        placeholder="{{ templateNodes[1].descriptionEnglish }}"
                        [(ngModel)]="nfrTemplate.en_propertyVariable" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Legal liability -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].descriptionEnglish}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" [required]="!isPublicSector()">
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.descriptionEnglish}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.descriptionEnglish}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- Be -->
                  {{templateNodes[16].templateTexts[0].descriptionEnglish}}
                  <!-- Qualifying expression -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].descriptionEnglish}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && enOperator1 === 'between' || text.identifier == '4.16' && enOperator1 === 'from'; else description">
                          {{enOperator1}}
                        </span>
                        <ng-template #description><span>{{text.descriptionEnglish}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- No value range -->
                  <!-- Value -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].descriptionEnglish}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].descriptionEnglish }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Value range -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Lower bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Lower bound of the value" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                    {{enOperator2}}
                    <mat-form-field>
                      <mat-label>Upper bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Upper bound of the value" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                  </span>
                  <!-- Concretizing value -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Concretizing value</mat-label>
                    <input [disabled]="!obligatoryValues" name="en_concretizing_value" matInput
                      placeholder="Concretizing value" [(ngModel)]="nfrTemplate.en_concretizing_value"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Point -->
                  .
                </mat-card>

                <!-- Umgebungsschablone -->
                <mat-card *ngIf=isTemplateActive(3)>
                  <h3>{{templateNames[2].description}}</h3>
                  <!-- Komponente Betrachtungsgegenstand -->
                  <mat-form-field *ngIf="!variableObject">
                    <mat-label>{{templateNodes[8].description}}</mat-label>
                    <input name="component" matInput placeholder="{{ templateNodes[8].description }}"
                      [(ngModel)]="nfrTemplate.component" class="form-control">
                  </mat-form-field>
                  <!-- Betrachtungsgegenstand -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[9].description}}</mat-label>
                    <input name="property" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[9].description }}" [(ngModel)]="nfrTemplate.property"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Rechtliche Verbindlichkeit -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].description}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" required>
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.description}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.description}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- so gestaltet sein, dass -->
                  {{templateNodes[16].templateTexts[4].description}}
                  <!-- Betrachtungsgegenstand -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[10].description}}</mat-label>
                    <input name="property2" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[10].description }}" [(ngModel)]="nfrTemplate.property2"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Konkretisierender Wert -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Konkretisierender Wert</mat-label>
                    <input [disabled]="!obligatoryValues" name="concretizing_value" matInput
                      placeholder="Konkretisierender Wert" [(ngModel)]="nfrTemplate.concretizing_value"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Eigenschaft -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[0].description}}</mat-label>
                    <input name="characteristic" [required]="!nfrTemplate.characteristic2" matInput
                      placeholder="{{ templateNodes[0].description }}" [(ngModel)]="nfrTemplate.characteristic"
                      class="form-control">
                  </mat-form-field>
                  <!-- Variabler Wert -->
                  <span *ngIf="variableValue">
                    <!-- Variabler Wert -->
                    <!-- TODO: Enum hinzufügen -->
                    <mat-form-field>
                      <input matInput disabled placeholder="variabler Wert">
                    </mat-form-field>
                    <!-- Eigenschaft -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[0].description}}</mat-label>
                      <input name="characteristic2" [required]="!nfrTemplate.characteristic" matInput
                        placeholder="{{ templateNodes[0].description }}" [(ngModel)]="nfrTemplate.characteristic2"
                        class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Vergleichsoperator -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].description}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && operator1 === 'zwischen' || text.identifier == '4.16' && operator1 === 'von'; else description">
                          {{operator1}}
                        </span>
                        <ng-template #description><span>{{text.description}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Kein Wertebereich -->
                  <!-- Wert -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].description}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].description }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Wertebereich -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Untere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Untere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" required>
                    </mat-form-field>
                    {{operator2}}
                    <mat-form-field>
                      <mat-label>Obere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Obere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" required>
                    </mat-form-field>
                  </span>
                  <!-- betrieben werden kann -->
                  {{templateNodes[16].templateTexts[5].description}}.
                  <br />
                  <!-- English -->
                  <h3>{{templateNames[2].descriptionEnglish}}</h3>
                  <!-- Component subject matter -->
                  <mat-form-field *ngIf="!variableObject">
                    <mat-label>{{templateNodes[8].descriptionEnglish}}</mat-label>
                    <input name="en_component" matInput placeholder="{{ templateNodes[8].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_component" class="form-control">
                  </mat-form-field>
                  <!-- Subject matter -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[9].descriptionEnglish}}</mat-label>
                    <input name="en_property" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[9].descriptionEnglish }}" [(ngModel)]="nfrTemplate.en_property"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Legal liability -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].descriptionEnglish}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" [required]="!isPublicSector()">
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.descriptionEnglish}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.descriptionEnglish}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- be designed in a way -->
                  {{templateNodes[16].templateTexts[4].descriptionEnglish}}
                  <!-- Object -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[10].descriptionEnglish}}</mat-label>
                    <input name="en_property2" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[10].descriptionEnglish }}" [(ngModel)]="nfrTemplate.en_property2"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- can be operated -->
                  {{templateNodes[16].templateTexts[5].descriptionEnglish}}
                  <!-- Characteristic -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[0].descriptionEnglish}}</mat-label>
                    <input name="en_characteristic" [required]="!nfrTemplate.en_characteristic2 && !isPublicSector()"
                      matInput placeholder="{{ templateNodes[0].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_characteristic" class="form-control">
                  </mat-form-field>
                  <!-- Variable Value -->
                  <span *ngIf="variableValue">
                    <!-- Variable Value -->
                    <!-- TODO: Enum hinzufügen -->
                    <mat-form-field>
                      <input matInput disabled placeholder="variable value">
                    </mat-form-field>
                    <!-- Characteristic -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[0].descriptionEnglish}}</mat-label>
                      <input name="en_characteristic2" [required]="!nfrTemplate.en_characteristic && !isPublicSector()"
                        matInput placeholder="{{ templateNodes[0].descriptionEnglish }}"
                        [(ngModel)]="nfrTemplate.en_characteristic2" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Qualifying expression -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].descriptionEnglish}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && enOperator1 === 'between' || text.identifier == '4.16' && enOperator1 === 'from'; else description">
                          {{enOperator1}}
                        </span>
                        <ng-template #description><span>{{text.descriptionEnglish}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- No value range -->
                  <!-- Value -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].descriptionEnglish}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].descriptionEnglish }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Value range -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Lower bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Lower bound of the value" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                    {{enOperator2}}
                    <mat-form-field>
                      <mat-label>Upper bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Upper bound of the value" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                  </span>
                  <!-- Concretizing value -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Concretizing value</mat-label>
                    <input [disabled]="!obligatoryValues" name="en_concretizing_value" matInput
                      placeholder="Concretizing value" [(ngModel)]="nfrTemplate.en_concretizing_value"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Point -->
                  .
                </mat-card>

                <!-- Umgebungsschablone mit Bedingung -->
                <mat-card *ngIf=isTemplateActive(4)>
                  <h3>{{templateNames[3].description}}</h3>
                  <!-- Komponente Betrachtungsgegenstand -->
                  <mat-form-field *ngIf="!variableObject">
                    <mat-label>{{templateNodes[8].description}}</mat-label>
                    <input name="component" matInput placeholder="{{ templateNodes[8].description }}"
                      [(ngModel)]="nfrTemplate.component" class="form-control">
                  </mat-form-field>
                  <!-- Betrachtungsgegenstand -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[9].description}}</mat-label>
                    <input name="property" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[9].description }}" [(ngModel)]="nfrTemplate.property"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Rechtliche Verbindlichkeit -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].description}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" required>
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.description}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.description}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- so gestaltet sein, dass -->
                  {{templateNodes[16].templateTexts[4].description}}
                  <!-- Bedingungskonjunktion -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[5].description}}</mat-label>
                    <mat-select name="conditional" [(ngModel)]="nfrTemplate.conditional" class="form-control" required>
                      <mat-option *ngFor="let text of templateNodes[5].templateTexts" [value]="text">
                        {{text.description.toLowerCase()}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Bedingung -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[6].description}}</mat-label>
                    <input name="condition" matInput placeholder="{{ templateNodes[6].description }}"
                      [(ngModel)]="nfrTemplate.condition" class="form-control" required>
                  </mat-form-field>
                  <!-- Werte für Bedingungen -->
                  <span *ngIf="conditionValues">
                    <!-- Vergleichsoperator -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[3].description}}</mat-label>
                      <mat-select name="qualifying_expression2" (selectionChange)="changeValueRange2($event.value)"
                        [(ngModel)]="nfrTemplate.qualifying_expression2" class="form-control">
                        <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                          <span
                            *ngIf="text.identifier == '4.15' && operator3 === 'zwischen' || text.identifier == '4.16' && operator3 === 'von'; else description">
                            {{operator3}}
                          </span>
                          <ng-template #description><span>{{text.description}}</span></ng-template>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- Kein Wertebereich -->
                    <!-- Wert -->
                    <mat-form-field *ngIf="!valueRange2">
                      <mat-label>{{templateNodes[4].description}}</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_2" id="value_2" matInput
                        placeholder="{{ templateNodes[4].description }}" [(ngModel)]="nfrTemplate.value_2"
                        class="form-control" required>
                    </mat-form-field>
                    <!-- Wertebereich -->
                    <!-- TODO: Enums anlegen für obere und untere Grenze -->
                    <span *ngIf="valueRange2">
                      <mat-form-field>
                        <mat-label>Untere Grenze des Wertes</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_1" matInput
                          placeholder="Untere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_2_1"
                          class="form-control" required>
                      </mat-form-field>
                      {{operator4}}
                      <mat-form-field>
                        <mat-label>Obere Grenze des Wertes</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_2" matInput
                          placeholder="Obere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_2_2"
                          class="form-control" required>
                      </mat-form-field>
                    </span>
                    <!-- 2. Teil der Bedingung -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[7].description}}</mat-label>
                      <input name="second_part_condition" matInput placeholder="{{ templateNodes[7].description }}"
                        [(ngModel)]="nfrTemplate.second_part_condition" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Komma -->
                  ,
                  <!-- Betrachtungsgegenstand -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[10].description}}</mat-label>
                    <input name="property2" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[10].description }}" [(ngModel)]="nfrTemplate.property2"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Konkretisierender Wert -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Konkretisierender Wert</mat-label>
                    <input [disabled]="!obligatoryValues" name="concretizing_value" matInput
                      placeholder="Konkretisierender Wert" [(ngModel)]="nfrTemplate.concretizing_value"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Eigenschaft -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[0].description}}</mat-label>
                    <input name="characteristic" [required]="!nfrTemplate.characteristic2" matInput
                      placeholder="{{ templateNodes[0].description }}" [(ngModel)]="nfrTemplate.characteristic"
                      class="form-control">
                  </mat-form-field>
                  <!-- Variabler Wert -->
                  <span *ngIf="variableValue">
                    <!-- Variabler Wert -->
                    <!-- TODO: Enum hinzufügen -->
                    <mat-form-field>
                      <input matInput disabled placeholder="variabler Wert">
                    </mat-form-field>
                    <!-- Eigenschaft -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[0].description}}</mat-label>
                      <input name="characteristic2" [required]="!nfrTemplate.characteristic" matInput
                        placeholder="{{ templateNodes[0].description }}" [(ngModel)]="nfrTemplate.characteristic2"
                        class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Vergleichsoperator -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].description}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && operator1 === 'zwischen' || text.identifier == '4.16' && operator1 === 'von'; else description">
                          {{operator1}}
                        </span>
                        <ng-template #description><span>{{text.description}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Kein Wertebereich -->
                  <!-- Wert -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].description}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].description }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Wertebereich -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Untere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Untere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" required>
                    </mat-form-field>
                    {{operator2}}
                    <mat-form-field>
                      <mat-label>Obere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Obere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" required>
                    </mat-form-field>
                  </span>
                  <!-- betrieben werden kann -->
                  {{templateNodes[16].templateTexts[5].description}}.
                  <br />

                  <!-- English -->
                  <h3>{{templateNames[3].descriptionEnglish}}</h3>
                  <!-- Component subject matter -->
                  <mat-form-field *ngIf="!variableObject">
                    <mat-label>{{templateNodes[8].descriptionEnglish}}</mat-label>
                    <input name="en_component" matInput placeholder="{{ templateNodes[8].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_component" class="form-control">
                  </mat-form-field>
                  <!-- Subject matter -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[9].descriptionEnglish}}</mat-label>
                    <input name="en_property" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[9].descriptionEnglish }}" [(ngModel)]="nfrTemplate.en_property"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Legal liability -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].descriptionEnglish}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" [required]="!isPublicSector()">
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.descriptionEnglish}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.descriptionEnglish}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- be designed in a way -->
                  {{templateNodes[16].templateTexts[4].descriptionEnglish}}
                  <!-- Conjunction for the condition -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[5].descriptionEnglish}}</mat-label>
                    <mat-select name="conditional" [(ngModel)]="nfrTemplate.conditional" class="form-control"
                      [required]="!isPublicSector()">
                      <mat-option *ngFor="let text of templateNodes[5].templateTexts" [value]="text">
                        {{text.descriptionEnglish.toLowerCase()}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Condition -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[6].descriptionEnglish}}</mat-label>
                    <input name="en_condition" matInput placeholder="{{ templateNodes[6].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_condition" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Value for Conditions -->
                  <span *ngIf="conditionValues">
                    <!-- Qualifying expression  -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[3].descriptionEnglish}}</mat-label>
                      <mat-select name="qualifying_expression2" (selectionChange)="changeValueRange2($event.value)"
                        [(ngModel)]="nfrTemplate.qualifying_expression2" class="form-control">
                        <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                          <span
                            *ngIf="text.identifier == '4.15' && enOperator3 === 'between' || text.identifier == '4.16' && enOperator3 === 'from' ; else description">
                            {{enOperator3}}
                          </span>
                          <ng-template #description><span>{{text.descriptionEnglish}}</span></ng-template>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- No value range -->
                    <!-- Value -->
                    <mat-form-field *ngIf="!valueRange2">
                      <mat-label>{{templateNodes[4].descriptionEnglish}}</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_2" id="value_2" matInput
                        placeholder="{{ templateNodes[4].descriptionEnglish }}" [(ngModel)]="nfrTemplate.value_2"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                    <!-- Value range -->
                    <!-- TODO: Enums anlegen für obere und untere Grenze -->
                    <span *ngIf="valueRange2">
                      <mat-form-field>
                        <mat-label>Lower bound of the value</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_1" matInput
                          placeholder="Lower bound of the value" [(ngModel)]="nfrTemplate.value_range_2_1"
                          class="form-control" [required]="!isPublicSector()">
                      </mat-form-field>
                      {{enOperator4}}
                      <mat-form-field>
                        <mat-label>Upper bound of the value</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_2" matInput
                          placeholder="Upper bound of the value" [(ngModel)]="nfrTemplate.value_range_2_2"
                          class="form-control" [required]="!isPublicSector()">
                      </mat-form-field>
                    </span>
                    <!-- 2nd part of the condition -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[7].descriptionEnglish}}</mat-label>
                      <input name="en_second_part_condition" matInput
                        placeholder="{{ templateNodes[7].descriptionEnglish }}"
                        [(ngModel)]="nfrTemplate.en_second_part_condition" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Comma -->
                  ,
                  <!-- Object -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[10].descriptionEnglish}}</mat-label>
                    <input name="en_property2" [disabled]="variableObject" matInput
                      placeholder="{{ templateNodes[10].descriptionEnglish }}" [(ngModel)]="nfrTemplate.en_property2"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- can be operated -->
                  {{templateNodes[16].templateTexts[5].descriptionEnglish}}
                  <!-- Characteristic -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[0].descriptionEnglish}}</mat-label>
                    <input name="en_characteristic" [required]="!nfrTemplate.en_characteristic2 && !isPublicSector()"
                      matInput placeholder="{{ templateNodes[0].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_characteristic" class="form-control">
                  </mat-form-field>
                  <!-- Variable Value -->
                  <span *ngIf="variableValue">
                    <!-- Variable Value -->
                    <!-- TODO: Enum hinzufügen -->
                    <mat-form-field>
                      <input matInput disabled placeholder="variable value">
                    </mat-form-field>
                    <!-- Characteristic -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[0].descriptionEnglish}}</mat-label>
                      <input name="en_characteristic2" [required]="!nfrTemplate.en_characteristic && !isPublicSector()"
                        matInput placeholder="{{ templateNodes[0].descriptionEnglish }}"
                        [(ngModel)]="nfrTemplate.en_characteristic2" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Qualifying expression -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].descriptionEnglish}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && enOperator1 === 'between' || text.identifier == '4.16' && enOperator1 === 'from'; else description">
                          {{enOperator1}}
                        </span>
                        <ng-template #description><span>{{text.descriptionEnglish}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- No value range -->
                  <!-- Value -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].descriptionEnglish}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].descriptionEnglish }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Value range -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Lower bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Lower bound of the value" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                    {{enOperator2}}
                    <mat-form-field>
                      <mat-label>Upper bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Upper bound of the value" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                  </span>
                  <!-- Concretizing value -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Concretizing value</mat-label>
                    <input [disabled]="!obligatoryValues" name="en_concretizing_value" matInput
                      placeholder="Concretizing value" [(ngModel)]="nfrTemplate.en_concretizing_value"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Point -->
                  .
                </mat-card>

                <!-- Prozessschablone -->
                <mat-card *ngIf=isTemplateActive(5)>
                  <h3>{{templateNames[4].description}}</h3>
                  <!-- Akteur -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[13].description}}</mat-label>
                    <input name="actor" matInput placeholder="{{ templateNodes[13].description }}"
                      [(ngModel)]="nfrTemplate.actor" class="form-control" required>
                  </mat-form-field>
                  <!-- Rechtliche Verbindlichkeit -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].description}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" required>
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.description}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.description}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- Präzisierung des Objekts -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[11].description}}</mat-label>
                    <input name="details" matInput placeholder="{{ templateNodes[11].description }}"
                      [(ngModel)]="nfrTemplate.details" class="form-control">
                  </mat-form-field>
                  <!-- Objekt -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[10].description}}</mat-label>
                    <input name="object" matInput placeholder="{{ templateNodes[10].description }}"
                      [(ngModel)]="nfrTemplate.object" class="form-control" required>
                  </mat-form-field>
                  <!-- Präzisierung des Objekts0 -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[11].description}}</mat-label>
                    <input name="details0" matInput placeholder="{{ templateNodes[11].description }}"
                      [(ngModel)]="nfrTemplate.details0" class="form-control">
                  </mat-form-field>
                  <!-- Konkretisierender Wert -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Konkretisierender Wert</mat-label>
                    <input [disabled]="!obligatoryValues" name="concretizing_value" matInput
                      placeholder="Konkretisierender Wert" [(ngModel)]="nfrTemplate.concretizing_value"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Vergleichsoperator -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].description}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && operator1 === 'zwischen' || text.identifier == '4.16' && operator1 === 'von'; else description">
                          {{operator1}}
                        </span>
                        <ng-template #description><span>{{text.description}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Kein Wertebereich -->
                  <!-- Wert -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].description}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].description }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Wertebereich -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Untere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Untere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" required>
                    </mat-form-field>
                    {{operator2}}
                    <mat-form-field>
                      <mat-label>Obere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Obere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" required>
                    </mat-form-field>
                  </span>
                  <!-- Prozesswort -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[14].description}}</mat-label>
                    <input name="process_verb" matInput placeholder="{{ templateNodes[14].description }}"
                      [(ngModel)]="nfrTemplate.process_verb" class="form-control" required>
                  </mat-form-field>
                  <!-- Punkt -->
                  .
                  <br />

                  <!-- English -->
                  <h3>{{templateNames[4].descriptionEnglish}}</h3>
                  <!-- Concretizing value -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Concretizing value</mat-label>
                    <input [disabled]="!obligatoryValues" name="en_concretizing_value" matInput
                      placeholder="Concretizing value" [(ngModel)]="nfrTemplate.en_concretizing_value"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Actor -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[13].descriptionEnglish}}</mat-label>
                    <input name="en_actor" matInput placeholder="{{ templateNodes[13].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_actor" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Legal liability -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].descriptionEnglish}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" [required]="!isPublicSector()">
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.descriptionEnglish}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.descriptionEnglish}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- Process verb -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[14].descriptionEnglish}}</mat-label>
                    <input name="en_process_verb" matInput placeholder="{{ templateNodes[14].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_process_verb" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Detailed defintion of object -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[11].descriptionEnglish}}</mat-label>
                    <input name="details" matInput placeholder="{{ templateNodes[11].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_details" class="form-control">
                  </mat-form-field>
                  <!-- Object -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[10].descriptionEnglish}}</mat-label>
                    <input name="en_object" matInput placeholder="{{ templateNodes[10].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_object" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Detailed defintion of object0 -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[11].descriptionEnglish}}</mat-label>
                    <input name="details0" matInput placeholder="{{ templateNodes[11].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_details0" class="form-control">
                  </mat-form-field>
                  <!-- Qualifying expression -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].descriptionEnglish}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && enOperator1 === 'between' || text.identifier == '4.16' && enOperator1 === 'from'; else description">
                          {{enOperator1}}
                        </span>
                        <ng-template #description><span>{{text.descriptionEnglish}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- No value range -->
                  <!-- Value -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].descriptionEnglish}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].descriptionEnglish }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Value range -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Lower bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Lower bound of the value" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                    {{enOperator2}}
                    <mat-form-field>
                      <mat-label>Upper bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Upper bound of the value" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                  </span>
                  <!-- Point -->
                  .
                </mat-card>

                <!-- Prozessschablone mit Bedingung -->
                <mat-card *ngIf=isTemplateActive(6)>
                  <h3>{{templateNames[5].description}}</h3>
                  <!-- Bedingungskonjunktion -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[5].description}}</mat-label>
                    <mat-select name="conditional" [(ngModel)]="nfrTemplate.conditional" class="form-control" required>
                      <mat-option *ngFor="let text of templateNodes[5].templateTexts" [value]="text">
                        {{text.description}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Bedingung -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[6].description}}</mat-label>
                    <input name="condition" matInput placeholder="{{ templateNodes[6].description }}"
                      [(ngModel)]="nfrTemplate.condition" class="form-control" required>
                  </mat-form-field>
                  <!-- Werte für Bedingungen -->
                  <span *ngIf="conditionValues">
                    <!-- Vergleichsoperator -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[3].description}}</mat-label>
                      <mat-select name="qualifying_expression2" (selectionChange)="changeValueRange2($event.value)"
                        [(ngModel)]="nfrTemplate.qualifying_expression2" class="form-control">
                        <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                          <span
                            *ngIf="text.identifier == '4.15' && operator3 === 'zwischen' || text.identifier == '4.16' && operator3 === 'von'; else description">
                            {{operator3}}
                          </span>
                          <ng-template #description><span>{{text.description}}</span></ng-template>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- Kein Wertebereich -->
                    <!-- Wert -->
                    <mat-form-field *ngIf="!valueRange2">
                      <mat-label>{{templateNodes[4].description}}</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_2" id="value_2" matInput
                        placeholder="{{ templateNodes[4].description }}" [(ngModel)]="nfrTemplate.value_2"
                        class="form-control" required>
                    </mat-form-field>
                    <!-- Wertebereich -->
                    <!-- TODO: Enums anlegen für obere und untere Grenze -->
                    <span *ngIf="valueRange2">
                      <mat-form-field>
                        <mat-label>Untere Grenze des Wertes</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_1" matInput
                          placeholder="Untere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_2_1"
                          class="form-control" required>
                      </mat-form-field>
                      {{operator4}}
                      <mat-form-field>
                        <mat-label>Obere Grenze des Wertes</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_2" matInput
                          placeholder="Obere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_2_2"
                          class="form-control" required>
                      </mat-form-field>
                    </span>
                    <!-- 2. Teil der Bedingung -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[7].description}}</mat-label>
                      <input name="second_part_condition" matInput placeholder="{{ templateNodes[7].description }}"
                        [(ngModel)]="nfrTemplate.second_part_condition" class="form-control">
                    </mat-form-field>
                  </span>
                  ,
                  <!-- Rechtliche Verbindlichkeit -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].description}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" required>
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.description}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.description}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- Akteur -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[13].description}}</mat-label>
                    <input name="actor" matInput placeholder="{{ templateNodes[13].description }}"
                      [(ngModel)]="nfrTemplate.actor" class="form-control" required>
                  </mat-form-field>
                  <!-- Präzisierung des Objekts -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[11].description}}</mat-label>
                    <input name="details" matInput placeholder="{{ templateNodes[11].description }}"
                      [(ngModel)]="nfrTemplate.details" class="form-control">
                  </mat-form-field>
                  <!-- Objekt -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[10].description}}</mat-label>
                    <input name="object" matInput placeholder="{{ templateNodes[10].description }}"
                      [(ngModel)]="nfrTemplate.object" class="form-control" required>
                  </mat-form-field>
                  <!-- Präzisierung des Objekts0 -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[11].description}}</mat-label>
                    <input name="details0" matInput placeholder="{{ templateNodes[11].description }}"
                      [(ngModel)]="nfrTemplate.details0" class="form-control">
                  </mat-form-field>
                  <!-- Konkretisierender Wert -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Konkretisierender Wert</mat-label>
                    <input [disabled]="!obligatoryValues" name="concretizing_value" matInput
                      placeholder="Konkretisierender Wert" [(ngModel)]="nfrTemplate.concretizing_value"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Vergleichsoperator -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].description}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && operator1 === 'zwischen' || text.identifier == '4.16' && operator1 === 'von'; else description">
                          {{operator1}}
                        </span>
                        <ng-template #description><span>{{text.description}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Kein Wertebereich -->
                  <!-- Wert -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].description}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].description }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" required>
                  </mat-form-field>
                  <!-- Wertebereich -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Untere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Untere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" required>
                    </mat-form-field>
                    {{operator2}}
                    <mat-form-field>
                      <mat-label>Obere Grenze des Wertes</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Obere Grenze des Wertes" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" required>
                    </mat-form-field>
                  </span>
                  <!-- Prozesswort -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[14].description}}</mat-label>
                    <input name="process_verb" matInput placeholder="{{ templateNodes[14].description }}"
                      [(ngModel)]="nfrTemplate.process_verb" class="form-control" required>
                  </mat-form-field>
                  <!-- Punkt -->
                  .
                  <br />

                  <!-- English -->
                  <h3>{{templateNames[5].descriptionEnglish}}</h3>
                  <!-- Conjunction for the condition -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[5].descriptionEnglish}}</mat-label>
                    <mat-select name="conditional" [(ngModel)]="nfrTemplate.conditional" class="form-control"
                      [required]="!isPublicSector()">
                      <mat-option *ngFor="let text of templateNodes[5].templateTexts" [value]="text">
                        {{text.descriptionEnglish}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Condition -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[6].descriptionEnglish}}</mat-label>
                    <input name="en_condition" matInput placeholder="{{ templateNodes[6].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_condition" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Value for Conditions -->
                  <span *ngIf="conditionValues">
                    <!-- Qualifying expression  -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[3].descriptionEnglish}}</mat-label>
                      <mat-select name="qualifying_expression2" (selectionChange)="changeValueRange2($event.value)"
                        [(ngModel)]="nfrTemplate.qualifying_expression2" class="form-control">
                        <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                          <span
                            *ngIf="text.identifier == '4.15' && enOperator3 === 'between' || text.identifier == '4.16' && enOperator3 === 'from' ; else description">
                            {{enOperator3}}
                          </span>
                          <ng-template #description><span>{{text.descriptionEnglish}}</span></ng-template>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- No value range -->
                    <!-- Value -->
                    <mat-form-field *ngIf="!valueRange2">
                      <mat-label>{{templateNodes[4].descriptionEnglish}}</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_2" id="value_2" matInput
                        placeholder="{{ templateNodes[4].descriptionEnglish }}" [(ngModel)]="nfrTemplate.value_2"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                    <!-- Value range -->
                    <!-- TODO: Enums anlegen für obere und untere Grenze -->
                    <span *ngIf="valueRange2">
                      <mat-form-field>
                        <mat-label>Lower bound of the value</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_1" matInput
                          placeholder="Lower bound of the value" [(ngModel)]="nfrTemplate.value_range_2_1"
                          class="form-control" [required]="!isPublicSector()">
                      </mat-form-field>
                      {{enOperator4}}
                      <mat-form-field>
                        <mat-label>Upper bound of the value</mat-label>
                        <input [disabled]="!obligatoryValues" name="value_range_2_2" matInput
                          placeholder="Upper bound of the value" [(ngModel)]="nfrTemplate.value_range_2_2"
                          class="form-control" [required]="!isPublicSector()">
                      </mat-form-field>
                    </span>
                    <!-- 2nd part of the condition -->
                    <mat-form-field>
                      <mat-label>{{templateNodes[7].descriptionEnglish}}</mat-label>
                      <input name="en_second_part_condition" matInput
                        placeholder="{{ templateNodes[7].descriptionEnglish }}"
                        [(ngModel)]="nfrTemplate.en_second_part_condition" class="form-control">
                    </mat-form-field>
                  </span>
                  <!-- Concretizing value -->
                  <!-- TODO: Konkretisierender Wert in Enum hinzufügen -->
                  <mat-form-field *ngIf="concreteValue">
                    <mat-label>Concretizing value</mat-label>
                    <input [disabled]="!obligatoryValues" name="en_concretizing_value" matInput
                      placeholder="Concretizing value" [(ngModel)]="nfrTemplate.en_concretizing_value"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Comma -->
                  ,
                  <!-- Actor -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[13].descriptionEnglish}}</mat-label>
                    <input name="en_actor" matInput placeholder="{{ templateNodes[13].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_actor" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Legal liability -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[2].descriptionEnglish}}</mat-label>
                    <mat-select [disabled]="!obligatoryValues" name="requirement_level" id="requirement_level"
                      [(ngModel)]="nfrTemplate.requirement_level" class="form-control" [required]="!isPublicSector()">
                      <span *ngFor="let text of templateNodes[2].templateTexts">
                        <mat-option [value]="text"
                          *ngIf="plural && (text.identifier == 3.2 || text.identifier == 3.4 || text.identifier == 3.6 || text.identifier == 3.8)">
                          {{text.descriptionEnglish}}</mat-option>
                        <mat-option [value]="text"
                          *ngIf="!plural && (text.identifier == 3.1 || text.identifier == 3.3 || text.identifier == 3.5 || text.identifier == 3.7)">
                          {{text.descriptionEnglish}}</mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                  <!-- Process verb -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[14].descriptionEnglish}}</mat-label>
                    <input name="en_process_verb" matInput placeholder="{{ templateNodes[14].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_process_verb" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Detailed defintion of object -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[11].descriptionEnglish}}</mat-label>
                    <input name="details" matInput placeholder="{{ templateNodes[11].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_details" class="form-control">
                  </mat-form-field>
                  <!-- Object -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[10].descriptionEnglish}}</mat-label>
                    <input name="en_object" matInput placeholder="{{ templateNodes[10].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_object" class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Detailed defintion of object0 -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[11].descriptionEnglish}}</mat-label>
                    <input name="details0" matInput placeholder="{{ templateNodes[11].descriptionEnglish }}"
                      [(ngModel)]="nfrTemplate.en_details0" class="form-control">
                  </mat-form-field>
                  <!-- Qualifying expression -->
                  <mat-form-field>
                    <mat-label>{{templateNodes[3].descriptionEnglish}}</mat-label>
                    <mat-select name="qualifying_expression" (selectionChange)="changeValueRange($event.value)"
                      [(ngModel)]="nfrTemplate.qualifying_expression" class="form-control">
                      <mat-option *ngFor="let text of templateNodes[3].templateTexts" [value]="text">
                        <span
                          *ngIf="text.identifier == '4.15' && enOperator1 === 'between' || text.identifier == '4.16' && enOperator1 === 'from'; else description">
                          {{enOperator1}}
                        </span>
                        <ng-template #description><span>{{text.descriptionEnglish}}</span></ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- No value range -->
                  <!-- Value -->
                  <mat-form-field *ngIf="!valueRange">
                    <mat-label>{{templateNodes[4].descriptionEnglish}}</mat-label>
                    <input [disabled]="!obligatoryValues" name="value_1" id="value_1" matInput
                      placeholder="{{ templateNodes[4].descriptionEnglish }}" [(ngModel)]="nfrTemplate.value_1"
                      class="form-control" [required]="!isPublicSector()">
                  </mat-form-field>
                  <!-- Value range -->
                  <!-- TODO: Enums anlegen für obere und untere Grenze -->
                  <span *ngIf="valueRange">
                    <mat-form-field>
                      <mat-label>Lower bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_1" matInput
                        placeholder="Lower bound of the value" [(ngModel)]="nfrTemplate.value_range_1_1"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                    {{enOperator2}}
                    <mat-form-field>
                      <mat-label>Upper bound of the value</mat-label>
                      <input [disabled]="!obligatoryValues" name="value_range_1_2" matInput
                        placeholder="Upper bound of the value" [(ngModel)]="nfrTemplate.value_range_1_2"
                        class="form-control" [required]="!isPublicSector()">
                    </mat-form-field>
                  </span>
                  <!-- Point -->
                  .

                </mat-card>
              </mat-expansion-panel>
            </mat-card-actions>
          </mat-card-content>

          <ng-template #noContent>
            <mat-card-content>
              Es können keine Daten geladen werden!

              {{catalogState.error | json}}

            </mat-card-content>
          </ng-template>
        </mat-card>
      </span>
      <br />
    </span>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button #cancelBtn mat-flat-button (click)="closeDialog()">
      Abbrechen
    </button>
    <button *ngIf="!isTemplateActive(-1)" #saveBtn type="submit" mat-flat-button color="primary"
      [disabled]="nfrForm.form.invalid">
      Speichern
    </button>
  </mat-dialog-actions>
</form>
