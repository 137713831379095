<div role="treeitem"
     class="treeitem-container"
     tabindex="{{selected === item?0:-1}}"
     [attr.aria-owns]="item.id+'-group'">
  <button class="sidenavButton"
          [ngClass]="{'disabledFactor' : isDisabledFactor(item) || !inDocuments(item)}"
          [attr.aria-selected]="isSelected()"
          (click)="onItemSelected(item)">
    <div [ngClass]="getStyleByCategory()">
      <div class="outer-container">
        <div *ngIf="shouldShowStatus() && item.getStatusIcon!==undefined && item.getStatusStyle!==undefined && item.getStatus!==undefined"
             class="container"
             [ngClass]="item.getStatusStyle()" aria-label="{{item.getStatus()}}">
          <mat-icon>
            {{item.getStatusIcon()}}
          </mat-icon>
        </div>
        <!-- <div class="container"> {{item.designation}} </div> -->
        <div class="container" style="white-space: nowrap;"> {{item.number}} </div>
        <div class="container crop-text"
             matTooltip="{{item.number}} {{item.name}}"
             matTooltipClass="custom-tooltip"
             matTooltipPosition="right"> {{item.name}} </div>
      </div> <!-- TODO: custom class is not applied correctly -->
    </div>
    <div class="expandIcon" *ngIf="item.children && item.children.length">
      <mat-icon [@indicatorRotate]="item.expanded ? 'expanded': 'collapsed'">
        expand_more
      </mat-icon>
    </div>
  </button>
</div>
<ul *ngIf="item.children && item.children.length" role="group" class="sidenav-tree-list" id="{{item.id}}-group">
  <li *ngFor="let child of item.children" role="none">
    <ng-container *ngIf="item.expanded">
      <nfa-sidenav-tree *ngIf="(child.authorization)"
                        [item]="child"
                        [selected]="selected"
                        [showStatus]="showStatus"
                        [factors]="factors"
                        [documentIdentifier]="documentIdentifier"
                        (itemSelected)="onSelectedItemIntern($event)">
      </nfa-sidenav-tree>
    </ng-container>
  </li>
</ul>

