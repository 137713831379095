import { AppAuthGuard } from './guards/app.authguard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogComponent } from './catalog/catalog/catalog.component';
import { TestMethodsComponent } from './test-methods/test-methods.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ChooseNfrComponent } from './applications/choose-nfr/choose-nfr.component';
import { ApplicationComponent } from './applications/application/application.component';
import { AboutComponent } from './about/about.component';
import { QumapHelpComponent } from './qumap-help/qumap-help.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TenAuthorizationGuard } from './guards/ten-authorization.guard';
import { BalmAuthorizationGuard } from './guards/balm-authorization.guard';
import { NfrPrintPreviewComponent } from './applications/nfr-print-preview/nfr-print-preview.component';
import { BitvPrintPreviewComponent } from './bitv/bitv-print-preview/bitv-print-preview.component';
import { DeactivateGuard } from './guards/deactivate.guard';
import { JiraExportComponent } from './applications/jira-export/jira-export.component';
import { NoAccessErrorPageComponent } from './shared/no-access-error-page/no-access-error-page.component';
import { RoutingParams } from './shared/global';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'applications',
    pathMatch: 'full'
  },
  {
    path: 'catalog',
    component: CatalogComponent,
    canActivate: [AppAuthGuard, TenAuthorizationGuard]
  },
  {
    path: 'testmethods',
    component: TestMethodsComponent,
    canActivate: [AppAuthGuard, TenAuthorizationGuard]
  },
  {
    path: 'applications',
    component: ApplicationsComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'applications/new',
    component: ApplicationComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'applications/:id',
    component: ApplicationComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'applications/:id/ten',
    component: ChooseNfrComponent,
    canActivate: [AppAuthGuard, TenAuthorizationGuard]
  },
  {
    path: 'applications/:id/ten/preview',
    component: NfrPrintPreviewComponent,
    canActivate: [AppAuthGuard, TenAuthorizationGuard]
  },
  {
    path: 'applications/:id/preview',
    component: NfrPrintPreviewComponent,
    canActivate: [AppAuthGuard, TenAuthorizationGuard]
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    canActivate: [DeactivateGuard, AppAuthGuard, TenAuthorizationGuard]
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'qumap-help',
    component: QumapHelpComponent
  },
  {
    path: 'qumap-help/:helpitemid',
    component: QumapHelpComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: `applications/:${RoutingParams.appIdParam}/bitv`,
    loadChildren: () => import('./bitv/bitv.module').then(m => m.BitvModule),
    canActivate: [AppAuthGuard, BalmAuthorizationGuard]
  },
  {
    path: `applications/:${RoutingParams.appIdParam}/balm`,
    loadChildren: () => import('./bitv/bitv.module').then(m => m.BitvModule),
    canActivate: [AppAuthGuard, BalmAuthorizationGuard]
  },
  {
    path: 'bitv/:id/preview',
    component: BitvPrintPreviewComponent,
    canActivate: [AppAuthGuard, BalmAuthorizationGuard]
  },
  {
    path: 'applications/:id/jiraExport',
    component: JiraExportComponent,
    canActivate: [AppAuthGuard, TenAuthorizationGuard]
  },
  {
    path: 'no-access-error-page',
    component: NoAccessErrorPageComponent
  },
  {
    path: '**',
    component: NoAccessErrorPageComponent, // TODO check if correct for all use cases
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AppRoutingModule {
}
