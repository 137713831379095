import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from '../adapter/contact';

@Component({
  selector: 'nfa-show-contact-detail',
  templateUrl: './show-contact-detail.component.html'
})
export class ShowContactDetailComponent implements OnInit {

  contact: Contact;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.contact = this.data.activeContact;
  }

}
