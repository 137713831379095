<div>
  <div class="page-header">
    <div class="page-header__heading">
      <h1>msg.BALM{{(application !== undefined ? ' für die Anwendung ' + application.name : '')}}
      </h1>
      <button mat-icon-button aria-label="Hilfe" class="helpBtn" (click)="onHelpButtonClick()">
        <mat-icon class="helpButtonIcon" matTooltip="Hilfe">
          help_outline
        </mat-icon>
      </button>
    </div>
  </div>
  <div class="scrollable-container">

    <div id="uebersichtPruefgegenstaende">
      <div [hidden]="serverError">
        <div class="balm-overview-header-container">
          <h2 id="uebersichtPruefgegenstaendeUeberschrift" class="balm-overview-label">
            Prüfgegenstände</h2>
          <button *ngIf="canUserEdit(userAccessRights)" type="button" id="pruefgegenstandHinzufuegenButton"
                  color="primary" mat-flat-button
                  class="mat-mdc-unelevated-button-primary" (click)="openDialog(false, {})">
            <mat-icon>add</mat-icon>
            <span>Prüfgegenstand hinzufügen</span>
          </button>
        </div>

        <mat-spinner id="ladenPruefgegenstaenden" *ngIf="isLoadingDataSource || userAccessRights === undefined"></mat-spinner>
        <div>
          <table mat-table
                 id="uebersichtTabellePruefgegenstaende"
                 tabindex="0"
                 aria-labelledby="uebersichtPruefgegenstaendeUeberschrift"
                 *ngIf="!isLoadingDataSource && userAccessRights !== undefined"
                 [dataSource]="dataSource"
                 class="mat-elevation-z0">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef id="nameColumn"> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef id="idColumn" [hidden]="true"> Id </th>
              <td mat-cell *matCellDef="let element" [hidden]="true"> {{element.id}} </td>
            </ng-container>

            <!-- Typ Column -->
            <ng-container matColumnDef="typ">
              <th mat-header-cell *matHeaderCellDef id="typColumn"> Typ </th>
              <td mat-cell *matCellDef="let element"> {{element.typ}} </td>
            </ng-container>

            <!-- URL Column -->
            <ng-container matColumnDef="url">
              <th mat-header-cell *matHeaderCellDef id="urlColumn"> URL </th>
              <td mat-cell *matCellDef="let element"> {{element.url}} </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef id="actionColumn"> Aktionen </th>
              <td mat-cell *matCellDef="let element; let i = index" id="{{'buttons'+i}}" class="action-link">
                <div class="actions">
                  <button id="{{'bearbeiten'+i}}" mat-flat-button class="bearbeitenButton mat-mdc-unelevated-button-secondary" (click)="openDialog(true, element)" aria-label="Prüfgegenstand bearbeiten">
                    <mat-icon>edit</mat-icon>
                    <span>Bearbeiten</span>
                  </button>
                  <button id="{{'loeschen'+i}}" mat-flat-button class="loeschenButton mat-mdc-unelevated-button-secondary" (click)="openDeleteDialog(element)" aria-label="Prüfgegenstand löschen">
                    <mat-icon>delete</mat-icon>
                    <span>Löschen</span>
                  </button>
                </div>
              </td>
            </ng-container>

            <ng-container *ngIf="dataSource.data.length === 0" matColumnDef="noTestItems">
              <td mat-footer-cell id="keinePruefgegenstaendeVorhanden" *matFooterCellDef colspan="6"><i>Es sind keine Prüfgegenstände vorhanden.</i></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="canUserEdit( userAccessRights) ? displayedColumns : displayedColumnsReadOnly"></tr>
            <tr tabindex="0" mat-row *matRowDef="let row; columns: (canUserEdit(userAccessRights) ? displayedColumns : displayedColumnsReadOnly);" (keydown)="onKey($event)"></tr>
            <ng-container *ngIf="dataSource.data.length === 0">
              <tr mat-footer-row *matFooterRowDef="['noTestItems']"></tr>
            </ng-container>
          </table>
        </div>
        <mat-paginator id="pruefgegenstaendeTabellePaginator"
                       #evaluationObjectsPaginator
                       [length]="dataSource.data.length"
                       [pageSize]="5"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       aria-label="Mehr Prüfgegenstände anzeigen">
        </mat-paginator>
      </div>
      <div id="serverError" *ngIf="serverError" role="alert" tabindex="0">
        <div class="serverError">
          <mat-icon>warning_amber</mat-icon>
          <span>Leider ist ein Fehler beim Laden der Tabelle mit den Prüfgegenständen aufgetreten.</span>
        </div>
      </div>
    </div>

    <nfa-balm-evaluation-overview [applicationId]="applicationId"
                                  [userAccessRight]="userAccessRights"
                                  (bitvs)="bitvs = $event">
    </nfa-balm-evaluation-overview>
  </div>
</div>
