import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Screenshot } from '../../../../types/evaluationstep';

@Injectable({
  providedIn: 'root'
})
export class BitvScreenshotService {

  private language = 'de';
  private apiUrl = '/nfabackend/webapi';


  constructor(private http: HttpClient) {
  }

  create(bitvid: number, screenshot: File, comment: string): Observable<Screenshot> {
    const uploadData = new FormData();
    uploadData.append('screenshot', screenshot, screenshot.name);
    if (comment) {
      uploadData.append('comment', comment);
    }
    return this.http.post<Screenshot>(
      `${this.apiUrl}/${this.language}/bitvEvaluationstep/${bitvid}/screenshots`, uploadData).pipe(
      switchMap(screenshotData => {
        const httpHeaders = {accept: 'image/*'};
        return this.http.get(`${this.apiUrl}/${screenshotData.pictureUrl}`,
          {responseType: 'blob', headers: httpHeaders}).pipe(switchMap(imageBlob => {
          return this.toDataURL(imageBlob).pipe(map(dataURL => {
            screenshotData.dataUrl = dataURL;
            return screenshotData;
          }));
        }));
      }));
  }

  getScreenshots(bitvEvaluationStepId: number): Observable<Screenshot[]> {
    return this.http.get<Screenshot[]>(
      `${this.apiUrl}/${this.language}/bitvEvaluationstep/${bitvEvaluationStepId}/screenshots`).pipe(
      switchMap(
        (metadatas: Screenshot[]) => {
          return forkJoin(
            metadatas.map(screenshotData => {
                const httpHeaders = {accept: 'image/*'};
                return this.http.get(`${this.apiUrl}${screenshotData.pictureUrl}`,
                  {responseType: 'blob', headers: httpHeaders}).pipe(switchMap(imageBlob => {
                  return this.toDataURL(imageBlob).pipe(map(dataURL => {
                    screenshotData.dataUrl = dataURL;
                    return screenshotData;
                  }));
                }));
              }
            ));
        }));
  }

  loadScreenshotImage(screenshotData: Screenshot): Observable<Screenshot> {
    const httpHeaders = {accept: 'image/*'};
    return this.http.get(
      `${this.apiUrl}${screenshotData.pictureUrl}`,
      {responseType: 'blob', headers: httpHeaders}
    ).pipe(switchMap(imageBlob => {
      return this.toDataURL(imageBlob).pipe(map(dataURL => {
        screenshotData.dataUrl = dataURL;
        return screenshotData;
      }));
    }));
  }

  getScreenshotsForBalmTest(balmId: number | string): Observable<Screenshot[]> {
    return this.http.get<Screenshot[]>(`${this.apiUrl}/${this.language}/bitv/${balmId}/screenshots`);
  }

  toDataURL(blob): Observable<string> {
    return new Observable(observer => {
      const reader = new FileReader();
      reader.onloadend = () => {
        observer.next(reader.result.toString());
        observer.complete();
      };
      reader.onerror = err => observer.error(err);
      reader.readAsDataURL(blob);
    });
  }

  delete(screenshotId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/screenshots/${screenshotId}`);
  }

  update(screenshotId: number, file: File, comment: string): Observable<Screenshot> {
    const updateData = new FormData();
    updateData.append('screenshot', file);
    if (comment) {
      updateData.append('comment', comment);
    }
    return this.http.put<Screenshot>(
      `${this.apiUrl}/screenshots/${screenshotId}`, updateData).pipe(
      switchMap(screenshotData => {
          const httpHeaders = {accept: 'image/*'};
          return this.http.get(`${this.apiUrl}/${screenshotData.pictureUrl}`,
            {responseType: 'blob', headers: httpHeaders}).pipe(switchMap(imageBlob => {
            return this.toDataURL(imageBlob).pipe(map(dataURL => {
              screenshotData.dataUrl = dataURL;
              return screenshotData;
            }));
          }));
        }
      ));
  }


  dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    return new File([byteArray], undefined, {type: mimeString});
  }
}
