import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BitvScreenshotService } from '../bitv-add-screenshot/bitv-screenshot.service';
import { BitvScreenshotPasteService } from '../bitv-screenshot-paste.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nfa-bitv-image-selection',
  templateUrl: './bitv-image-selection.component.html',
  styleUrls: ['./bitv-image-selection.component.scss']
})
export class BitvImageSelectionComponent implements OnInit, OnDestroy {

  private dataUrlPrivate: string;
  private initialDataUrl: string;

  file: File;
  @Output() dataUrlChange = new EventEmitter();
  subscription: Subscription;

  @Input()
  set dataUrl(value) {
    this.dataUrlPrivate = value;
    this.file = this.screenshotService.dataURItoBlob(this.dataUrlPrivate);
  }

  get dataUrl(): string{
    return this.dataUrlPrivate;
  }

  constructor(private screenshotService: BitvScreenshotService, private screenshotPasteService: BitvScreenshotPasteService) { }

  ngOnInit() {
    this.initialDataUrl = this.dataUrlPrivate;
    this.subscription = this.screenshotPasteService.screenshotPaste$.subscribe((file: File) => this.onScreenshotPaste(file));
  }

  onSelect(event) {
    this.file = event.addedFiles[0];
    this.screenshotService.toDataURL(this.file).subscribe(data => {
      this.dataUrlPrivate = data;
    });
  }

  onScreenshotPaste(file: File) {
    if (file != null && file.type.includes('image')) {
      this.screenshotService.toDataURL(file).subscribe(
        data => {
          this.dataUrlPrivate = data;
        });
      this.file = file;
    }
  }

  // used by screenshot-item-component
  emitChanges() {
    this.dataUrlChange.emit(this.dataUrlPrivate);
  }
  // used by screenshot-item-component
  reset() {
    this.dataUrlPrivate = this.initialDataUrl;
    this.dataUrlChange.emit(this.dataUrlPrivate);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
