export interface ApplicationNfr {
  id?: number;
  existing: boolean;
  edited: boolean;
  priority: string;
  criticality: string;
  protectionNeeds: string;
  basedOn: string;
  version: string;
  annotation: string;
  nfr: number;
  formulation: string;
  active: boolean;
  application: number;
  index: number;
  projectPhase: string;
  rv: string;
  vb: string;
  kw: string;
  vw: string;
  wert: string;
  ow: string;
  wertebereich: string;
  metric: string;
  nameGerman: string;
}

export interface CustomApplicationNfr {
  id?: number;
  name: string;
  active: boolean;
  edited: boolean;
  formulation: string;
  priority: string;
  criticality: string;
  protectionNeeds: string;
  basedOn: string;
  version: string;
  annotation: string;
  application: number;
  projectPhase: string;
  metric: string;
}

export enum Priority {
  blocker = "Blocker",
  critical ="Kritisch",
  major = "Major",
  normal = "Normal",
  minor = "Minor",
  trivial = "Trivial",
  improvement = "Verbesserung",
  bug = "Bug"
}

export enum Criticality {
  high = "hoch",
  medium = "mittel",
  low = "niedrig"
}

export enum ProtectionNeeds {
  strictlyConfidential = "Streng vertraulich",
  confidential = "Vertraulich",
  internal = "Intern",
  public = "Öffentlich"
}
