import { Component, OnInit } from '@angular/core';

import { Language } from '../app-state/settings';
import { PrivacyPolicyService } from './privacy-policy.service';
import { HilfeFunktionen } from '../shared/global';

@Component({
  selector: 'nfa-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  providers: [PrivacyPolicyService]
})
export class PrivacyPolicyComponent implements OnInit {
  language = Language.DEUTSCH;

  constructor(private privacyPolicyService: PrivacyPolicyService) {
  }

  ngOnInit() {
    HilfeFunktionen.setApplicationTitle('Datenschutz');
    this.loadPrivacyPolicy();
  }

  loadPrivacyPolicy() {
    const privacyPolicy = document.querySelector('.privacy-policy');
    this.privacyPolicyService.getPrivacyPolicy(this.language).subscribe(privacyPolicyJsonArray => {
      privacyPolicy.innerHTML = privacyPolicyJsonArray[0].privacyPolicyHtml;
    });
  }
}
