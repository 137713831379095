import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData
} from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard  {

  constructor(private dialog: MatDialog) {
  }

  canDeactivate(component: UnsavedChangesProvider, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return component.hasNoUnsavedChanges() ?
      true :
      this.dialog
        .open(ConfirmationDialogComponent, {
          disableClose: true,
          autoFocus: 'dialog',
          panelClass: 'confirmation-dialog-container',
          ariaModal: true,
          role: 'dialog',
          data: {
            ...new ConfirmationDialogData(),
            dialogType: 'warning',
            header: 'Ungespeicherte Änderungen',
            cancelOptionText: 'Abbrechen',
            confirmOptionText: 'Ja',
            rejectOptionText: 'Nein',
            message: 'Der Prüfschritt enthält ungespeicherte Änderungen.',
            confirmationQuestion: 'Möchten Sie diese Änderungen speichern bevor Sie die Seite verlassen?',
            confirmationProvider: component
          }
        })
        .afterClosed();
  }

}

export interface UnsavedChangesProvider {
  hasNoUnsavedChanges: () => boolean;
}
