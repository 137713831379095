import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

const NAME_PIC_DEP_SAME = 'personInChargeAndDeputySame';
const NAME_EMPLOYEES_PIC = 'employeesPiCError';
const NAME_EMPLOYEES_DEP = 'employeesDepError';

export function validateOrga(organizationControl: UntypedFormControl): { [key: string]: any } | null {
  if (organizationControl.value !== null) {
    return this.organizations.find(orga =>
      organizationControl.value.toLowerCase() === orga.toLowerCase()
    ) === undefined ? {invalid: true} : null;
  } else {
    return null;
  }
}

export function validateUser(userInput: UntypedFormControl): { [key: string]: any } | null {
  if (userInput.value !== null) {
    // @ts-ignore
    return this.currentEmployeeSelectedOrganizationUsers.find(user =>
      userInput.value.toLowerCase() === user.id
    ) === undefined ? {invalid: true} : null;
  } else {
    return null;
  }
}

export function validatePersonInChargeDeputee(): ValidatorFn {
  return (personsInChargeMSG: UntypedFormGroup): { [key: string]: any } | null => {
    const personInChargeControl = personsInChargeMSG.controls.identifierOfPersonInChargeMSG;
    const deputeeControl = personsInChargeMSG.controls.identifierOfDeputyPersonInChargeMSG;

    if (personInChargeControl !== null && personInChargeControl.value !== null &&
      deputeeControl !== null && deputeeControl.value !== null &&
      personInChargeControl.value !== '' &&
      personInChargeControl.value === deputeeControl.value) {
      setError(personInChargeControl, NAME_PIC_DEP_SAME);
      setError(deputeeControl, NAME_PIC_DEP_SAME);
      const error = {};
      error[NAME_PIC_DEP_SAME] = true;
      return error;
    } else {
      removeError(personInChargeControl, NAME_PIC_DEP_SAME);
      removeError(deputeeControl, NAME_PIC_DEP_SAME);
      removeError(personsInChargeMSG, NAME_PIC_DEP_SAME);
      return null;
    }
  };
}

export function validateEmployeesPiC(): ValidatorFn {
  return (personsInChargeMSG: UntypedFormGroup): { [key: string]: any } => {
    const personInChargeControl = personsInChargeMSG.controls.identifierOfPersonInChargeMSG;
    const employeesIdentifier = personsInChargeMSG.controls.employeesIdentifier;
    const validationResult = employeeValidation(
      personInChargeControl,
      employeesIdentifier,
      NAME_EMPLOYEES_PIC
    );

    if (validationResult === null) {
      removeError(personsInChargeMSG, NAME_EMPLOYEES_PIC);
    }

    return validationResult !== null ? {...personsInChargeMSG.errors, ...validationResult} : null;
  };
}

export function validateEmployeesDep(): ValidatorFn {
  return (personsInChargeMSG: UntypedFormGroup): { [key: string]: any } => {
    const identifierOfDeputyPersonInChargeMSG = personsInChargeMSG.controls.identifierOfDeputyPersonInChargeMSG;
    const employeesIdentifier = personsInChargeMSG.controls.employeesIdentifier;
    const validationResult = employeeValidation(
      identifierOfDeputyPersonInChargeMSG,
      employeesIdentifier,
      NAME_EMPLOYEES_DEP
    );
    if (validationResult === null) {
      removeError(personsInChargeMSG, NAME_EMPLOYEES_DEP);
    }
    return validationResult !== null ? {...personsInChargeMSG.errors, ...validationResult} : null;
  };
}

export function validateDeletedEmployees(employeesControl: UntypedFormControl): { [key: string]: any } | null {

  if (employeesControl.value !== null) {
    const deletedUsers = [...employeesControl.value]
      .filter(id => !this.currentEmployeeSelectedOrganizationUsers.map(user => user.id).includes(id));

    return deletedUsers.length > 0 ? { deletedEmployees: true } : null;
  } else {
    return null;
  }
}

function employeeValidation(
  control: AbstractControl,
  employeesControl: AbstractControl,
  errorName: string
): { [key: string]: any } {
  if (employeesControl.value ?
    employeesControl.value
      .some(element => element === control.value) : false) {
    setError(control, errorName);
    setError(employeesControl, errorName);
    const error = {};
    error[errorName] = true;
    return error;
  } else {
    removeError(control, errorName);
    removeError(employeesControl, errorName);
    return null;
  }
}

function setError(control: AbstractControl, errorCode: string): void {
  const errors = {...control.errors};
  errors[errorCode] = true;
  control.setErrors(errors);
  control.markAsTouched();
}

function removeError(control: AbstractControl, errorCode: string): void {
  if (control.hasError(errorCode)) {
    const errors = {...control.errors};
    delete errors[errorCode];
    control.setErrors(errors);
    control.updateValueAndValidity({onlySelf: true});
  }
}
