import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationsService } from './applications.service';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationReceived } from './model/applicationReceived';
import { QualityProcessReceived } from './model/qualityProcessReceived';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Sorter } from '../shared/utils/sorter';
import { DomSanitizer } from '@angular/platform-browser';
import { AppState } from '../app-state/app.reducer';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { KeycloakService } from 'keycloak-angular';
import { PersonsInChargeMSG } from './model/personsInChargeMSG';
import { resetJiraState } from './application-state/application.actions';
import { HilfeFunktionen } from '../shared/global';
import { QumapHelpService } from '../qumap-help/qumap-help.service';
import { Language } from '../app-state/settings';

@Component({
  selector: 'nfa-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})

export class ApplicationsComponent implements OnInit, AfterViewInit {
  readonly LANGUAGE: Language = Language.DEUTSCH;

  displayedColumnsApplications: string[] = ['name', 'buttons'];
  applications: MatTableDataSource<ApplicationReceived> = new MatTableDataSource([]);
  applicationsReceived: ApplicationReceived[] = [];
  qualityProcesses: QualityProcessReceived[];
  showUserSpecificApplications = true;
  filterText: string;
  errorMessage: any;

  results: any[];

  resultsLength = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  authorizationBalm = this.keycloak.isUserInRole('BALM');
  authorizationTen = this.keycloak.isUserInRole('TEN');

  datepipe = new DatePipe('de-DE');

  constructor(
    public dialog: MatDialog,
    private applicationService: ApplicationsService,
    private sanitizer: DomSanitizer,
    private store: Store<AppState>,
    private keycloak: KeycloakService,
    private qumapHelpService: QumapHelpService
  ) {
  }

  ngOnInit(): void {
    HilfeFunktionen.setApplicationTitle('Anwendungen');
    this.applicationService.getAllApplications('de').subscribe(
      (data: ApplicationReceived[]) => {
        this.applicationsReceived = data;
        if (this.applicationsReceived && this.applicationsReceived.length >= 1) {
          this.setLastUpdateString();
        }
        this.filterApplications();
        if (this.applications && this.applications.data.length >= 1) {
          this.sortApplications({active: 'timeStamp', direction: 'desc'});
        }
      },
      error => this.errorMessage = error
    );

    this.applicationService.getAllQualityProcesses('de').subscribe((data: QualityProcessReceived[]) => {
      this.qualityProcesses = data;
    });
    this.store.dispatch(resetJiraState());
  }

  private filterApplications() {
    this.applications.data = this.applicationsReceived;
    if (this.showUserSpecificApplications) {
      const tokenInstance: any = this.keycloak.getKeycloakInstance().tokenParsed;
      const selfId = tokenInstance.id;

      this.applications.data = this.applicationsReceived.filter((application: ApplicationReceived) => {
        const personsInChargeMSG = application.personsInChargeMSG || {employeesIdentifier: []} as PersonsInChargeMSG;
        const personInChargeIds = [
          personsInChargeMSG.creator,
          personsInChargeMSG.identifierOfPersonInChargeMSG,
          personsInChargeMSG.identifierOfDeputyPersonInChargeMSG,
          ...personsInChargeMSG.employeesIdentifier
        ];
        return personInChargeIds.includes(selfId);
      });
    }
  }

  ngAfterViewInit(): void {
    this.refreshApplicationTable();
    this.applications.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Anwendungen pro Seite: ';
    this.paginator._intl.getRangeLabel = this.getRangeLabel;
  }

  getRangeLabel = (page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return '0 von ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' von ' + length;
  }

  refreshApplicationTable() {
    this.filterApplications();
    this.sortApplications({active: 'timeStamp', direction: 'desc'});
  }

  applyFilter(filterValue: string) {
    this.applications.filterPredicate = (data, filter) => (data.name.indexOf(filter) !== -1
      || data.customerName.indexOf(filter) !== -1);
    this.filterText = filterValue.trim().toLowerCase();
    this.applications.filter = this.filterText;
  }

  sortApplications(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return this.applications;
    }
    this.applications.data = this.applications.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return Sorter.compare(a.name, b.name, isAsc);
        case 'customerName':
          return Sorter.compare(a.customerName, b.customerName, isAsc);
        case 'projectphase':
          return Sorter.compare(a.qualityProcesses[a.qualityProcesses.length - 1].phase,
            b.qualityProcesses[b.qualityProcesses.length - 1].phase, isAsc
          );
        case 'projectdocument':
          return Sorter.compare(a.qualityProcesses[a.qualityProcesses.length - 1].document.description,
            b.qualityProcesses[b.qualityProcesses.length - 1].document.description, isAsc
          );
        case 'timeStamp':
          return Sorter.compare(a.lastUpdate, b.lastUpdate, isAsc);
        default:
          return 0;
      }
    });
  }

  setLastUpdateString() {
    const timeNow = new Date();
    // timeNow = new Date('2019.11.22 10:00');
    for (const filteredApplication of this.applicationsReceived) {
      const timeDiff = timeNow.getTime() - filteredApplication.lastUpdate;
      const timeDiffMinutes = timeDiff / 60000;
      if (timeDiffMinutes <= 1) {
        filteredApplication.lastUpdateString = 'vor weniger als 1 Minute';
      } else if (timeDiffMinutes > 1 && timeDiffMinutes < 2) {
        filteredApplication.lastUpdateString = 'vor ' + Math.floor(timeDiffMinutes) + ' Minute';
      } else if (timeDiffMinutes >= 2 && timeDiffMinutes < 60) {
        filteredApplication.lastUpdateString = 'vor ' + Math.floor(timeDiffMinutes) + ' Minuten';
      } else if (timeDiffMinutes >= 60 && timeDiffMinutes < 60 * 2) {
        filteredApplication.lastUpdateString = 'vor ' + Math.floor(timeDiffMinutes / 60) + ' Stunde';
      } else if (timeDiffMinutes >= 60 * 2 && timeDiffMinutes < 60 * 24) {
        filteredApplication.lastUpdateString = 'vor ' + Math.floor(timeDiffMinutes / 60) + ' Stunden';
      } else if (timeDiffMinutes >= 60 * 24) {
        const date1 = this.datepipe.transform(new Date(filteredApplication.lastUpdate), 'dd.MM.yyyy');
        const time1 = (new Date(filteredApplication.lastUpdate)).toLocaleTimeString(
          navigator.language, {hour: '2-digit', minute: '2-digit', hour12: false});
        filteredApplication.lastUpdateString = date1.concat(', ', time1, ' Uhr');
      }
    }
  }

  canUserEdit(element: any) {
    return element.canUserEditApplication;
  }

  provideBALMRouterLink(element): string {
    return `/applications/${element.id}/balm`;
  }

  canUserSeeBALM() {
    return this.keycloak.isUserInRole('BALM');
  }

  provideTENRouterLink(element): string {
    return `/applications/${element.id}/ten`;
  }

  canUserSeeTEN() {
    return this.keycloak.isUserInRole('TEN');
  }

  onHelpButtonClick() {
    this.qumapHelpService.getQumapHelpAndShowInDialog('qumap-my-applications',this.dialog);
  }
}
