<div id="tuk-sme" class="image-selection">
    <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [accept]="'image/*'"
        style="width: 100%; height: 100%;">
        <ngx-dropzone-label data-cy="pictureUploadDropzone">Screenshot hierher ziehen <br> oder <br> <div class="link-like">Datei auswählen</div>
        </ngx-dropzone-label>
        <ngx-dropzone-preview *ngIf="file" class="preview--small" (change)="onScreenshotPaste($event)">
            <ngx-dropzone-label><img class="preview--screenshot" [src]="dataUrl"></ngx-dropzone-label>
        </ngx-dropzone-preview>
    </ngx-dropzone>
</div>
