import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Application } from '../applications/model/application';
import { ApplicationsService } from '../applications/applications.service';
import { firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { PersonsInChargeMSG } from '../applications/model/personsInChargeMSG';
import { ApplicationReceived } from '../applications/model/applicationReceived';

@Injectable({
  providedIn: 'root'
})
export class UserAccessRightsService {

  private keycloakProfile: KeycloakProfile;
  private userId: string;

  constructor(
    private keycloakService: KeycloakService,
    private applicationsService: ApplicationsService) {
    this.keycloakService.loadUserProfile().then(value => {
      this.keycloakProfile = value;
      this.userId = value.id;
    });

  }

  public async checkAccessRightById(appId: number): Promise<UserAccessRight> {
    const application = await firstValueFrom(this.applicationsService.getApplicationById(appId));
    return firstValueFrom(this.checkAccessRight(application));
  }

  public checkAccessRight(application: Application | ApplicationReceived): Observable<UserAccessRight> {
    return this.applicationsService
      .getAllUsersOfOrganisation(application.personsInChargeMSG.organizationIdentifier)
      .pipe(
        switchMap(users =>
          users.map(user => user.id).includes(this.userId) ?
            of(this.readOrWriteAccess(application.personsInChargeMSG)) :
            of(UserAccessRight.NOACCESS))
      );
  }

  private readOrWriteAccess(personsInChargeMSG: PersonsInChargeMSG): UserAccessRight {

    const writeAccessUsers = [...personsInChargeMSG.employeesIdentifier,
      personsInChargeMSG.creator,
      personsInChargeMSG.identifierOfPersonInChargeMSG,
      personsInChargeMSG.identifierOfDeputyPersonInChargeMSG
    ];

    return writeAccessUsers.includes(this.userId) ? UserAccessRight.WRITEACCES : UserAccessRight.READACCESS;
  }

}

export enum UserAccessRight {
  READACCESS,
  WRITEACCES,
  NOACCESS
}
