import { Component, OnInit, ViewChild } from '@angular/core';
import { Contact } from './adapter/contact';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ContactsAdapterService } from './adapter/contacts-adapter.service';
import { ShowContactDetailComponent } from './show-contact-detail/show-contact-detail.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'nfa-contacts',
  templateUrl: './contacts.component.html'
})
export class ContactsComponent implements OnInit {

  allContacts: Contact[] = [];
  dataSource: MatTableDataSource<Contact>;
  displayedColumns: string[] = ['lastName', 'firstName', 'position', 'eMailPrimary', 'phoneNumber', 'actions'];

  resultsLength = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private contactsAdapterService: ContactsAdapterService, public detailDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.contactsAdapterService.getAllContacts('de').subscribe((contact: Contact[]) => {
      this.allContacts = contact;
      this.dataSource = new MatTableDataSource(contact);
      this.dataSource.paginator = this.paginator;
      this.paginator._intl.itemsPerPageLabel = 'Stakeholder pro Seite: ';
      this.paginator._intl.getRangeLabel = this.getRangeLabel;
    });
  }

  getRangeLabel = (page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return '0 von ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' von ' + length;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  sortContact(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'firstName':
          return this.compare(a.firstName, b.firstName, isAsc);
        case 'lastName':
          return this.compare(a.lastName, b.lastName, isAsc);
        case 'position':
          return this.compare(a.position, b.position, isAsc);
        case 'eMailPrimary':
          return this.compare(a.eMailPrimary, b.eMailPrimary, isAsc);
        case 'phoneNumber':
          return this.compare(a.phoneNumber, b.phoneNumber, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: string, b: string, isAsc: boolean) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onShowContactDetail(oneContact: Contact) {
    this.detailDialog.open(ShowContactDetailComponent, {
      disableClose: true,
      autoFocus: false,
      data: {activeContact: oneContact}
    });
  }

}
