import { Injectable } from '@angular/core';
import { Contact } from './contact';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Gender } from './gender';
import { Factor } from './factor';

@Injectable()
export class ContactsAdapterService {

  private apiUrl = '/nfabackend/webapi';

  constructor(private http: HttpClient) {
  }

  saveContact(language: string, contact: Contact): Observable<void | Observable<never>> {
    return this.http.post<void>(`${this.apiUrl}/${language}/contacts/createNewContact`, contact).pipe(
      catchError(() => throwError('Kontakt konnte nicht gespeichert werden.'))
    );
  }

  getGender(language: string): Observable<Gender[]> {
    return this.http.get<Gender[]>(`${this.apiUrl}/${language}/contacts/gender`).pipe(
      catchError(() => throwError('Anrede konnte nicht geladen werden.'))
    );
  }

  getAllContacts(language: string): Observable<Contact[]> {
    return this.http.get<Contact[]>(`${this.apiUrl}/${language}/contacts/allContacts`).pipe(
      catchError(() => throwError('Kontakte konnten nicht geladen werden.'))
    );
  }

  getAllFactors(language: string): Observable<Factor[]> {
    return this.http.get<Factor[]>(`${this.apiUrl}/${language}/contacts/allFactors`).pipe(
      catchError(() => throwError('Faktoren konnten nicht geladen werden.'))
    );
  }

}


