export class Sorter {

  static compare(a: string | number, b: string | number, isAsc: boolean ) {
    return (this.replaceUmlaute(a.toString()) < this.replaceUmlaute(b.toString()) ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private static replaceUmlaute(input: string) {
    return input.toLowerCase().replace(String.fromCharCode(0xE4), 'ae')
                              .replace(String.fromCharCode(0xF6), 'oe')
                              .replace(String.fromCharCode(0xFC), 'ue');
  }
}
