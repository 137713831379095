<ng-container *ngIf="catalogState$ | async as catalogState">

    <ng-container *ngIf="catalogState.error === null; else noContent">

      <div class="page-header">
        <div class="page-header__heading">
          <button mat-flat-button
                  color="primary"
                  (click)="navbarExpanderClick(sharedSidenav)"
                  aria-label="Navigationsbereich ein/ausblenden">
            <mat-icon class="navbar__expand-toggle--expanded icon-no-margin" *ngIf="navbarExpanded">chevron_left</mat-icon>
            <mat-icon class="navbar__expand-toggle--collapsed icon-no-margin" *ngIf="!navbarExpanded">chevron_right</mat-icon>
          </button>
          <h1>NFA-Katalog</h1>
          <button mat-icon-button aria-label="Hilfe" data-cy="helpBtn" class="helpBtn" (click)="onHelpButtonClick()">
            <mat-icon class="helpButtonIcon" matTooltip="Hilfe">
              help_outline
            </mat-icon>
          </button>
        </div>
      </div>

        <mat-sidenav-container>
          <mat-sidenav #sharedSidenav mode="side" [opened]="navbarExpanded" class="mat-sidenav--fixed-height">
            <nfa-sidenav [treeRoots]="headers" (itemSelected)="navigateToItem($event)"
                         [documentIdentifier]="documentIdentifier">
            </nfa-sidenav>
          </mat-sidenav>
          <mat-sidenav-content class="mat-sidenav-container--leancatalog">
            <div>
              <mat-card>
                <nfa-information-header [factor]="selectedFactorWrapper" [criterion]="selectedCriterionWrapper"
                                        [metrics]="selectedCriterionWrapper.metrics" [nfrsOfMetrics]="catalogState.nfrs"
                                        [nfrRecommendations]="nfrsOfSystemType" [selectedMetric]="selectedMetric"
                                        (selectedMetricChanged)="changeSelectedMetric($event)">
                </nfa-information-header>

                <div [ngClass]="{'catalogContentBox': !openMenu['menuOpen']}">

                  <ng-container>
                    <!------------------------------------ NFA --------------------------------->

                    <div class="nfa-panel">
                      <mat-expansion-panel *ngFor="let nfr of catalogState.nfrs[selectedMetric.identifier]">
                        <mat-expansion-panel-header>
                          <mat-panel-title>{{nfr.nameGerman}}
                          </mat-panel-title>
                          <mat-panel-description>{{nfr.formulationGerman}}
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                          <span>Referenz: </span>
                          <span *ngIf="this.nfr.reference !== ''"> {{nfr.reference}}</span>
                          <span class="default-value-reference" *ngIf="!this.nfr.reference "> Keine Referenz </span>
                        </div>
                        <div>
                          <span>Prozesse: </span>
                          <span class="default-value-reference"> In noch keinem Prozess ausgewählt </span>
                        </div>
                        <div *ngIf="branches$ | async as branches">
                          <!-- TODO: Name der Branche anzeigen d.h. Public Sector statt PUBLIC_SECTOR etc -->
                          <span>Branchen: </span>
                          <span>
                          <span *ngFor="let sector of nfr.sectors; let i = index">{{sector.name}}<span
                            *ngIf="i < nfr.sectors.length - 1">, </span></span>
                        </span>
                        </div>
                      </mat-expansion-panel>


                      <!--button class="addNfr-button" mat-raised-button (click)="openCreateNfr(selectedMetric)">
                <mat-icon inline="true">add_circle</mat-icon>
                NFA erstellen
              </button-->
                    </div>
                  </ng-container>
                </div>
              </mat-card>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>

    </ng-container>
    <ng-template #noContent>Es können keine Daten geladen werden!</ng-template>
  </ng-container>

