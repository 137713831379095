import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TestDesignTechnique } from './adapter/testDesignTechnique';
import { TestType } from './adapter/testtype';
import {
  getTestDesignTechniquesList,
  getTestTypesList,
  TestMethodsState
} from './test-methods-state/test-methods.reducer';
import { LoadTestDesignTechniques, LoadTestTypes } from './test-methods-state/test-methods.actions';
import { Language } from '../app-state/settings';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HilfeFunktionen } from '../shared/global';
import { QumapHelpService } from '../qumap-help/qumap-help.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'nfa-test-methods',
  templateUrl: './test-methods.component.html',
  styleUrls: ['./test-methods.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TestMethodsComponent implements OnInit {
  readonly LANGUAGE: Language = Language.DEUTSCH;

  selectedValue: number;
  dataFlowTestVariantValue = 0;
  combinatorialTestDesignTechniquesVariantValue = 0;

  testTypesData$: Observable<TestType[]> = this.store$.pipe(select(getTestTypesList));
  testDesignTechniqueData$: Observable<TestDesignTechnique[]> = this.store$.pipe(select(getTestDesignTechniquesList));

  constructor(private route: ActivatedRoute,
              private store$: Store<TestMethodsState>,
              private qumapHelpService: QumapHelpService,
              private dialog: MatDialog) {
    this.store$.dispatch(new LoadTestTypes(Language.DEUTSCH));
    this.store$.dispatch(new LoadTestDesignTechniques(Language.DEUTSCH));
  }

  ngOnInit() {
    HilfeFunktionen.setApplicationTitle('Testverfahren');
    this.route.queryParams.subscribe(queryParam => {
      const typeOfTestMethodIdentifier = queryParam.typeOfTestMethodIdentifier;
      if (typeOfTestMethodIdentifier) {
        this.setTabActiveByIdentifier(typeOfTestMethodIdentifier);
      }
    });

    this.route.queryParams.subscribe(queryParam => {
      const typeOfTestMethodIdentifier = queryParam.typeOfTestMethodIdentifier;
      if (typeOfTestMethodIdentifier) {
        const testTypeIdentifier = queryParam.testTypeIdentifier;

        if (testTypeIdentifier) {
          this.setSpecificTestMethodIntoView(typeOfTestMethodIdentifier, testTypeIdentifier);
        }
        const testDesignTechniqueIdentifier = queryParam.testDesignTechniqueIdentifier;
        if (testDesignTechniqueIdentifier) {
          this.setSpecificTestMethodIntoView(typeOfTestMethodIdentifier, testDesignTechniqueIdentifier);
        }
      }
    });
  }

  setTabActiveByIdentifier(typeOfTestMethodIdentifier: string) {
    switch (typeOfTestMethodIdentifier) {
      case 'testTypes': {
        this.setTestTypeTabActive();
        break;
      }
      case 'testDesignTechniques': {
        this.setTestDesignTechniquesTabActive();
        break;
      }
      default: {
        this.setTestTypeTabActive();
        break;
      }
    }
  }

  setSpecificTestMethodIntoView(typeOfTestMethod: string, testMethodIdentifierRestrictedToTypeOfTestMethod: string) {
    const testMethodRowIdentifier = this.getTestMethodRowIdentifier(typeOfTestMethod, testMethodIdentifierRestrictedToTypeOfTestMethod);
    const observer = new MutationObserver((mutations, me) => {
      const testMethodElement = document.getElementById(testMethodRowIdentifier);
      if (testMethodElement) {
        testMethodElement.scrollIntoView();
        me.disconnect();
        return;
      }
    });
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  }

  getTestMethodRowIdentifier(typeOfTestMethod: string, testMethodIdentifierRestrictedToTypeOfTestMethod: string) {
    switch (typeOfTestMethod) {
      case 'testTypes': {
        return this.getTestTypeRowIdentifier(testMethodIdentifierRestrictedToTypeOfTestMethod);
      }
      case 'testDesignTechniques': {
        return this.getTestDesignTechniqueRowIdentifier(testMethodIdentifierRestrictedToTypeOfTestMethod);
      }
      default: {
        return '';
      }
    }
  }

  setTestTypeTabActive() {
    this.selectedValue = 0;
  }

  setTestDesignTechniquesTabActive() {
    this.selectedValue = 1;
  }

  getTestTypeRowIdentifier(identifier: string) {
    return `testTypesRow${identifier}`;
  }

  getTestDesignTechniqueRowIdentifier(identifier: string) {
    return `testDesignTechniqueRow${identifier}`;
  }

  getTestTypeIdentifier(identifier: string): string {
    return 'Test type' + identifier;
  }

  getTestDesignTechniqueIdentifier(identifier: string): string {
    return 'Test designtechnique' + identifier;
  }

  onHelpButtonClick() {
    this.qumapHelpService.getQumapHelpAndShowInDialog('test-methods',this.dialog);
  }
}
