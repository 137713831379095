import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

import {PrivacyPolicy} from './types';

@Injectable()
export class PrivacyPolicyService {

  private apiUrl = '/nfabackend/webapi';

  constructor(private http: HttpClient) {
  }

  getPrivacyPolicy(language: string): Observable<PrivacyPolicy[]> {
    return this.http.get<PrivacyPolicy[]>(`${this.apiUrl}/privacy_policy`, {params: {language}}).pipe(
      catchError(() => throwError('Not able to get privacy policy'))
    );
  }
}
