import { EvaluationCriteria } from '../balm-evaluation/balm-evaluation-criteria.enum';
import { Treenode, TreenodeCategory } from '../../shared/sidenav/treenode';

export interface Evaluationstep {
  id?: number;
  evaluationStep: EvaluationstepMeta;
  evaluationresult?: string;
  comment?: string;
  screenshots?: Screenshot[];
}

export interface EvaluationstepMeta {
  id?: number;
  number?: string;
  name: string;
  bitvCategorieId?: number;
  refersto: string;
  fullfillmentcriteria?: string;
  unapplicablecriteria?: string;
  wcag_successcriteria?: string;
  wcag_conformityLevel?: string;
  wcag_principles?: string;
  accessibilityRequirements?: AccessibilityRequirement[];
  evaluationStepInfo?: number;
}

export interface EvaluationstepInfo {
  id: number;
  why?: string;
  how?: string;
  what?: string;
}

export interface Screenshot {
  id: number;
  evaluationStepId?: number | string;
  comment: string;
  commentNew: string;
  pictureUrl: string;
  dataUrl: string;
  editMode: boolean;
}

export interface AccessibilityRequirement {
  id: number;
  name: string;
}

export const checkIfStillCompliant = (evaluationSteps: Evaluationstep[]): boolean =>
  evaluationSteps.every(x => x.evaluationresult !== undefined && x.evaluationresult !== EvaluationCriteria.no);


export class BalmEvaluationStepNode implements Treenode {
  id: number;
  expanded = true;
  name = '';
  category: TreenodeCategory;
  object: Evaluationstep;
  number: string | undefined;
  authorization: boolean | undefined;
  parent: Treenode | undefined;

  constructor(id: number,
              name: string,
              category: TreenodeCategory,
              object: Evaluationstep,
              number: string | undefined,
              parent: Treenode | undefined) {
    this.id = id;
    this.name = name;
    this.category = category;
    this.object = object;
    this.number = number;
    this.parent = parent;
    this.authorization = true;
  }

  getStatus(): string {
    return `Status: ${this.object?.evaluationresult}`;
  }

  getStatusIcon(): string {
    switch (this.object?.evaluationresult) {
      case EvaluationCriteria.fulfilled:
        return 'check';
      case EvaluationCriteria.no:
        return 'close';
      case EvaluationCriteria.notApplicable:
        return '';
      default:
        return '';
    }
  }

  getStatusStyle(): string {
    switch (this.object?.evaluationresult) {
      case EvaluationCriteria.fulfilled:
        return 'conformity-indicator';
      case EvaluationCriteria.no:
        return 'non-conformity-indicator';
      case EvaluationCriteria.notApplicable:
        return 'not-applicable-indicator';
      default:
        return '';
    }
  }
}
