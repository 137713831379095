import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StoreModule} from '@ngrx/store';
import {CatalogModule} from './catalog/catalog.module';
import {EffectsModule} from '@ngrx/effects';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MaterialModule} from './material/material.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import * as appStore from './app-state/app.reducer';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {initKeycloak} from './app-init';
import {EmptyModule} from './empty/empty.module';
import {environment} from '../environments/environment';
import {TestMethodsModule} from './test-methods/test-methods.module';
import {ApplicationsModule} from './applications/applications.module';
import {NfaPackagesModule} from './nfa-packages/nfa-packages.module';
import {ContactsModule} from './contacts/contacts.module';
import {SharedModule} from './shared/shared.module';
import {FormsModule} from '@angular/forms';
import {BitvModule} from './bitv/bitv.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {UsernameInterceptor} from './shared/interceptors/username.interceptor';
import {AboutComponent} from './about/about.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {CatalogEffects} from './app-state/app.effects';
import {ApplicationsService} from './applications/applications.service';
import {LayoutModule} from '@angular/cdk/layout';
import {MatMenuModule} from '@angular/material/menu';
import {registerLocaleData} from '@angular/common';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeDe from '@angular/common/locales/de';
import {QumapHelpModule} from './qumap-help/qumap-help.module';
import {ConfirmationDialogComponent} from './shared/confirmation-dialog/confirmation-dialog.component';
import {SaveConfSnackbarComponent} from "./shared/save-confirm-snackbar/save-confirm-snackbar-component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { EditorModule } from "@tinymce/tinymce-angular";

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    PrivacyPolicyComponent,
    ConfirmationDialogComponent,
    SaveConfSnackbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(
      {
        appState: appStore.reducer
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false
        }
      }
    ),
    EffectsModule.forRoot([CatalogEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 100,
      logOnly: environment.production
    }),
    CatalogModule,
    BitvModule,
    TestMethodsModule,
    ApplicationsModule,
    NfaPackagesModule,
    EmptyModule,
    MaterialModule,
    LayoutModule,
    KeycloakAngularModule,
    ContactsModule,
    MatMenuModule,
    SharedModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    QumapHelpModule,
    EditorModule,
    MatProgressBarModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UsernameInterceptor,
      multi: true
    },
    ApplicationsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
