import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Factor } from './factor';
import { Injectable } from '@angular/core';
import { Nfr } from './nfr';
import { catchError } from 'rxjs/operators';
import { TemplateNode } from './templateNode';
import { TemplateName } from './templateName';
import { Sector } from './sector';
import { NfrReceived } from './nfrReceived';
import { MultiNfrRecieved } from './multiNfrRecieved';

@Injectable()
export class CatalogAdapterService {

  private apiUrl = '/nfabackend/webapi';

  constructor(private http: HttpClient) {
  }

  getFactors(language: string): Observable<Factor[]> {
    return this.http.get<Factor[]>(`${this.apiUrl}/${language}/factors`).pipe(
      catchError(() => throwError(()=>new Error('Faktoren konnten nicht geladen werden')))
    );
  }

  getTemplateNodes(language: string): Observable<TemplateNode[]> {
    return this.http.get<TemplateNode[]>(`${this.apiUrl}/${language}/templatenodes`).pipe(
      catchError(() => throwError(()=>new Error('Templates konnten nicht geladen werden')))
    );
  }

  getTemplateNames(language: string): Observable<TemplateName[]> {
    return this.http.get<TemplateName[]>(`${this.apiUrl}/${language}/templatenames`).pipe(
      catchError(() => throwError(() => new Error('Template Namen konnten nicht geladen werden')))
    );
  }

  getSectors(language: string): Observable<Sector[]> {
    return this.http.get<Sector[]>(`${this.apiUrl}/${language}/branches`).pipe(
      catchError(() => throwError(() => new Error('Sektoren konnten nicht geladen werden')))
    );
  }

  getNfrListForMetric(language: string, metricId: string): Observable<NfrReceived[]> {
    return this.http.get<NfrReceived[]>(`${this.apiUrl}/${language}/nfas/metric?metric=${metricId}`).pipe(
      catchError(() => throwError(() => new Error(('NFAs konnten nicht geladen werden')))
    ));
  }

  getNfrListForMultipleMetric(language: string, metrics: string[]): Observable<MultiNfrRecieved[]> {
    return this.http.get<MultiNfrRecieved[]>(`${this.apiUrl}/${language}/nfas/metric/multi`, {params: {metrics}}).pipe(
      catchError(() => throwError(() => new Error(('NFAs konnten nicht geladen werden')))
    ));
  }

  getAllNfrs(language: string): Observable<NfrReceived[]> {
    return this.http.get<NfrReceived[]>(`${this.apiUrl}/${language}/nfas`).pipe(
      catchError(() => throwError(() => new Error(('NFAs konnten nicht geladen werden')))
    ));
  }

  saveNfr(language: string, nfr: Nfr): Observable<void | Observable<never>> {
    return this.http.post<void>(`${this.apiUrl}/${language}/nfas?metric=${nfr.metric}`, nfr).pipe(
      catchError(() => throwError(() => new Error(('')))
    ));
  }
}
